import React from "react";
import { Button, Spinner } from "reactstrap";
import classnames from "classnames";

const LoadingButton = ({ children, loading, ...rest }) => (
  <Button {...rest} >
    {(loading) && (
      <Spinner
        className={classnames({
          "position-relative": true,
          visible: loading,
          invisible: !loading
        })}
        size="sm"
        // type="grow"
      />
    )}
    {!(loading) && (
      <span
        className={classnames({
          invisible: loading,
          visible: !loading,
        })}
      >
        {children}
      </span>
    )}
  </Button>
);

export default LoadingButton;
