import React, { useCallback, useEffect } from "react"
import { useDropzone } from "react-dropzone"
import { useFormContext } from "react-hook-form"
import {
  Row,
  Col,
  Card,
} from "reactstrap"
import fileAdd from "../../../assets/icons/file-add.svg";
// import { getUserRole, userRoles } from "@ele/components/Common/userAccess";

export const FileInputDisbled = props => {
  //const userRole = getUserRole()
  const { label, downloadPath, downloadAttachment } = props
  let fileName = "";
  if (downloadPath) fileName = downloadPath?.docs?.split('/')?.slice(-1)?.join()
  // const cloudIcon = userRole === userRoles.USER_SEARCHER ? 'fa-cloud-download-alt' : 'fa-cloud-upload-alt'
  const cloudIcon = 'fa-cloud-upload-alt'
  return (
    <>
      <div className="dropzone">
        <div className="upload-wrapper p-2">
          <h6 className="m-0" style={{ width: "160px" }}>{label}</h6>
          <i className={`text-primary fas ${cloudIcon} cloud-upload`} />
          {downloadPath && (<Row className="align-items-center"><div role="button" onClick={() => downloadAttachment(downloadPath.id, fileName)} className="text-primary downloadable-file">{fileName}</div></Row>)}
        </div>
      </div>
    </>
  )
}
const FileInput = props => {
  // const userRole = getUserRole()
  // const cloudIcon = userRole === userRoles.USER_SEARCHER ? 'fa-cloud-download-alt' : 'fa-cloud-upload-alt'
  const cloudIcon = 'fa-cloud-upload-alt'
  const { name, label = name, mode = 'update', multiple = false, downloadPath, downloadAttachment } = props
  let fileName = "";
  if (downloadPath) fileName = downloadPath?.docs?.split('/')?.slice(-1)?.join()
  const { register, unregister, setValue, watch } = useFormContext()
  const files = watch(name)
  const files_url = watch(name + "_url")
  const onDrop = useCallback(
    (droppedFiles) => {
      /*
         This is where the magic is happening.
         Depending upon the mode we are replacing old files with new one,
         or appending new files into the old ones, and also filtering out the duplicate files. 
      */
      let newFiles = mode === 'update' ? droppedFiles : [...(files || []), ...droppedFiles]
      if (mode === 'append') {
        newFiles = newFiles.reduce((prev, file) => {
          const fo = Object.entries(file)
          if (
            prev.find((e) => {
              const eo = Object.entries(e)
              return eo.every(
                ([key, value], index) => key === fo[index][0] && value === fo[index][1],
              )
            })
          ) {
            return prev
          } else {
            return [...prev, file]
          }
        }, [])
      }
      // End Magic.
      setValue(name, newFiles, { shouldValidate: true })
    },
    [setValue, name, mode, files]
  )
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: props.accept,
    multiple: multiple
  })
  useEffect(() => {
    register(name)
    return () => {
      unregister(name)
    }
  }, [register, unregister, name])
  return (
    <>
      {/*<label className=" " htmlFor={name}>
        {label}
      </label>*/}
      <div className="dropzone">

        <input {...getInputProps()} />
        <div className="upload-wrapper p-2">
          {/* <h6 className="m-0">{label}</h6> */}
          <div
            className="m-0 d-flex justify-content-center align-items-center cursor-pointer"
            {...getRootProps()}
          >
            <img src={fileAdd} alt="" width="38" />
            <div className="click-lable">Click to upload</div>
          </div>
          <div className="dropzone-previews" id="file-previews">
            {/*(!files?.length && files_url?.length) &&
                (<img
                  data-dz-thumbnail=""
                  height="80"
                  className="avatar-sm rounded bg-light"
                  src={files_url}
                />)
                */}
            {downloadPath && !files && (<Row className="align-items-center"><div role="button" onClick={() => downloadAttachment(downloadPath.id, fileName)} className="text-primary downloadable-file">{fileName}</div></Row>)}
            {!!files?.length && files?.map((f, i) => {
              return (
                <div
                  key={i + "-file"}
                >
                  <div className="">
                    <Row className="align-items-center">
                      {/*<Col className="col-auto">
                          <img
                            data-dz-thumbnail=""
                            height="80"
                            className="avatar-sm rounded bg-light"
                            alt={f.name}
                            src={URL.createObjectURL(f)}
                          />
                        </Col>*/}
                      <Col>
                        {/* <div className="text-primary downloadable-file"> {f.name} </div> */}
                        {/*<p className="mb-0" >
                            <strong>{f.formattedSize}</strong>
                            </p>*/}
                      </Col>
                    </Row>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
      {/* <div className="d-flex mb-3 mt-3">
        <div className="upload-tag">PDF</div>
        <div className="upload-tag">DOCX</div>
        <div className="upload-tag">TXT</div>
        <div className="upload-limit">{'> 10MB'}</div>
      </div> */}
    </>
  )
}

export default FileInput