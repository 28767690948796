export const driverList = {
  series: [
    {
      element: "#element1",
      popover: {
        title: "Add Series",
        description: "To add a new series",
      },
    },
    {
      element: "#element2",
      popover: {
        title: "Filter Series",
        description: "You can Filter and see the series that you created",
      },
    },
    {
      element: "#element3",
      popover: {
        title: "Series Description",
        description: "You can see the series that you created",
      },
    },
    {
      popover: {
        title: "Happy Coding",
        description:
          "And that is all, go ahead and start adding tours to your applications.",
      },
    },
  ],
}
