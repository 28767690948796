import { get, post } from "../../helpers/api_helper"

export const postEstimation = data => {
  return !data?.id
    ? post(`estimation/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : data?.id && data?.action === 1
    ? post(`estimation/update/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`estimation/edit-revision/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })

  // console.log(`data chk`, data)
}

export const searchEstimation = data => {
  return get(`estimation/search?${data?.params}`)
}
export const fetchEstimation = data => {
  return get(`estimation/fetch-revision/${data}`)
}
export const fetchRevisions = data => {
  return get(`estimation/revision/${data}`)
}
export const updateRevisionStatus = data => {
  return get(`estimation/status/${data}`)
}

export const updateEstimationStatus = data => {
  return get(`estimation/updatestatus/${data}`)
}
