import React, { useEffect, useState } from "react"
import Select from "react-select"
import { Controller } from "react-hook-form"
import { getExpenseType } from "../../../services/Inventory/inventory"

function ucfirst(str) {
  var firstLetter = str.substr(0, 1)
  return firstLetter.toUpperCase() + str.substr(1)
}

const Expense = ({ control, name, onSelectChange, disabled }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [expenseData, setExpenseData] = useState([])

  const loadOptions = async () => {
    const data = await getExpenseType()
    setIsLoading(false)
    const expense = data?.data?.map(dta => {
      return {
        label: dta?.label && ucfirst(dta.label),
        value: dta.id,
        ...dta,
      }
    })

    setExpenseData(expense)
  }

  useEffect(() => {
    loadOptions()
  }, [])

  return (
    <div>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, ref, value } }) => {
          return (
            <Select
              options={expenseData}
              placeholder={"Select Expense"}
              isLoading={isLoading}
              inputRef={ref}
              onChange={e => {
                onChange(e)
                if (onSelectChange) onSelectChange(e)
              }}
              isDisabled={disabled}
              value={value}
              classNamePrefix="react-select"
              className="elevate-dropdown"
              menuPortalTarget={document.querySelector(".modal")}
            />
          )
        }}
      />
    </div>
  )
}

export default Expense
