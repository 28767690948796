import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  PopoverBody,
  Row,
  UncontrolledPopover,
} from "reactstrap"
import UpdateTable from "../../../components/Common/UpdatedTable"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import action from "../../../assets/icons/action.svg"
import { toastError, toastSuccess } from "../../../helpers/msg_helper"
import { FormProvider, useFieldArray, useForm, useWatch } from "react-hook-form"
import { UFInput } from "../../../components/Common/Fields/Input"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import LoadingButton from "../../../components/Common/LoadingButton"
import {
  fetchEntitlementEdit,
  fetchHrmsEntitlement,
  postHrmsEntitlement,
  updateEntitlementStatus,
} from "../../../services/Hrms/hrms"
import HrmsSearch from "../search"
import InputType from "../../../components/Common/chooseFields/inputType"

const schema = yup.object().shape({
  name: yup.string().required("Entitlement is Required"),
  price: yup
    .string()
    .matches(
      /^([+]?)\d+(\.\d{1,4})?$/,
      "Price must be a valid number with up to 4 digits after the decimal point"
    )
    .matches(/^[0-9]*\.?[0-9]*$/, "Only numbers are allowed")
    .required("Price is required"),
})
const Entitlement = () => {
  document.title = "Entitlement | Everise"

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: true,
    defaultValues: {
      name: "",
      addons: [
        {
          input_type: null,
        },
      ],
    },
  })
  // const methods = useForm({
  //   resolver: yupResolver(schema),
  //   mode: "onChange",
  //   shouldFocusError: true,
  //   defaultValues: {
  //     name: "",
  //     addons: [
  //       {
  //         input_type: null,
  //       },
  //     ],
  //   },
  // })

  const inputWatch = useWatch({ control, name: "addons" })

  const [isLoading, setIsLoading] = useState(false)

  const [page, setPage] = useState("")

  const [list, setList] = useState()
  const [count, setCount] = useState(0)
  const [search, setSearch] = useState("")
  const [edit, setEdit] = useState("")

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchHrmsEntitlement({
        params: `${search ? `&searchText=${search}` : "&searchText"}${
          page ? `&page=${page}` : ""
        }`,
      })
      setList(response)
    }
    fetchData()
  }, [count, search, page])

  useEffect(() => {
    if (edit.id) {
      if (!edit?.addons.length) {
        reset({
          ...edit,
          addons: [
            {
              input_type: null,
            },
          ],
        })
      } else {
        reset({ ...edit })
      }
    }
  }, [edit.id])

  const handleAdd = async data => {
    try {
      setIsLoading(true)
      const dataArr = data?.addons
        ?.map(dt => ({
          master_id: data?.id,
          id: dt?.id ? dt?.id : "",
          input_type: dt?.input_type?.id,
          label: dt?.label,
          value: dt?.value ? dt?.value : "",
        }))
        .filter(item => item.input_type !== undefined)
      const createdSeries = await postHrmsEntitlement({
        ...data,
        ...(!data?.addons?.length
          ? {
              id: "",
              addons: dataArr,
            }
          : {
              addons: dataArr,
            }),
      })
      toastSuccess(createdSeries?.message)
      reset({
        name: "",
      })
      setCount(count + 1)
    } catch (err) {
      toastError(err?.message)
    } finally {
      setIsLoading(false)
      setEdit("")
    }
  }

  const handleEdit = async item => {
    const response = await fetchEntitlementEdit(item)
    setEdit(response?.data)
  }

  const handleStatus = async id => {
    try {
      const response = await updateEntitlementStatus(id)
      toastSuccess(response?.message)
      setCount(count + 1)
    } catch (err) {
      toastError(err?.message)
    }
  }

  const columns = [
    {
      label: "Entitlement",
      renderCell: item => <div className="capitalize-letter">{item?.name}</div>,
      sortKey: "name",
    },
    {
      label: "Benefits",
      renderCell: item => (
        <div>
          {item?.price
            ? Number(Number(item?.price).toFixed(2)).toLocaleString("en", {
                minimumFractionDigits: 2,
              })
            : 0}{" "}
          AED
        </div>
      ),
    },
    {
      label: "Status",
      renderCell: item => (
        <div>
          <div
            className={`font-13 ${
              item?.status === 1 ? "status-active" : "status-inactive"
            }`}
          >
            {item?.status === 1 ? "Active" : "Inactive"}
          </div>
        </div>
      ),
    },
    {
      label: "Action",
      renderCell: item => (
        <>
          <div>
            <span id={`PopoverLegacy-${item?.id}`}>
              <img src={action} alt="" className="pointer" />
            </span>
          </div>
          <UncontrolledPopover
            placement="bottom"
            target={`PopoverLegacy-${item?.id}`}
            trigger="legacy"
          >
            <PopoverBody>
              {item?.status === 1 && (
                <div
                  className="table-popup-content"
                  onClick={() => handleEdit(item?.id)}
                >
                  Edit
                </div>
              )}
              <div
                className={`${
                  item?.status === 1 ? "popover-border" : ""
                } table-popup-content`}
                onClick={() => handleStatus(item?.id)}
              >
                {item?.status === 1 ? "Deactivate" : "Activate"}
              </div>
            </PopoverBody>
          </UncontrolledPopover>
        </>
      ),
    },
  ]

  const {
    fields: inputTypeFields,
    append: handleAddRow,
    remove: handleRemoveRow,
  } = useFieldArray({
    control,
    name: "addons",
  })

  const handlePaginate = page => {
    setPage(page)
  }

  const handleSearchChange = data => {
    setSearch(data)
    setPage(1)
  }

  const handleCancel = () => {
    reset({
      name: "",
    })
    setEdit("")
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="d-flex justify-content-between">
            <Breadcrumbs
              title="HRMS"
              breadcrumbObject={[
                { title: "HRMS", link: "/hrms" },
                { title: "Entitlement", link: "/hrms-entitlement" },
              ]}
            />
          </div>

          <Row className="mt-2">
            <Col xs={5}>
              <Card className="me-2 px-2 pb-2 input-card">
                <div className="input-card-head">
                  {edit?.id ? "Edit" : "Add"}
                </div>
                <hr className="table-divide" />
                <FormProvider>
                  <Form id={`ev-form`} onSubmit={handleSubmit(handleAdd)}>
                    <Row className="mt-2">
                      <Col sm={12}>
                        <FormGroup className="mb-4">
                          <Label>
                            Entitlement<span className="mandatory">*</span>
                          </Label>
                          <UFInput
                            control={control}
                            placeholder="Enter the Entitlement"
                            name={"name"}
                            invalid={!!errors.name}
                          />
                          <FormFeedback invalid>
                            {errors.name?.message}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col sm={12}>
                        <FormGroup className="mb-4">
                          <Label>
                            Price<span className="mandatory">*</span>
                          </Label>
                          <UFInput
                            control={control}
                            placeholder="Enter the Price"
                            name={"price"}
                            invalid={!!errors.price}
                          />
                          <FormFeedback invalid>
                            {errors.price?.message}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                      {/* {inputTypeFields.map((item, index) => (
                        <Row key={item.id}>
                          <Col sm={5}>
                            {edit && (
                              <FormGroup className="mb-4">
                                <Label>
                                  Additional Fields
                                  <span className="mandatory">*</span>
                                </Label>
                                <InputType
                                  control={control}
                                  placeholder="Select"
                                  name={`addons.${index}.input_type`}
                                  invalid={
                                    !!errors?.addons?.[index]?.input_type
                                      ?.message
                                  }
                                  target={"body"}
                                />
                                <FormFeedback invalid>
                                  {errors.addons?.[index]?.input_type?.message}
                                </FormFeedback>
                              </FormGroup>
                            )}
                          </Col>
                          {inputWatch?.[index]?.input_type?.id == 1 ||
                          inputWatch?.[index]?.input_type?.id == 2 ? (
                            <>
                              <Col sm={5}>
                                <FormGroup className="mb-4">
                                  <Label>Label</Label>
                                  <UFInput
                                    control={control}
                                    placeholder="Enter the Label"
                                    name={`addons.${index}.label`}
                                    invalid={
                                      !!errors.addons?.[index]?.label?.message
                                    }
                                  />
                                  <FormFeedback invalid>
                                    {errors.addons?.[index]?.label?.message}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                              <Col
                                sm={1}
                                className=" d-flex align-items-center"
                              >
                                {inputTypeFields?.length > 1 ? (
                                  <div>
                                    <Button
                                      className="btn-secondary me-2"
                                      form={`ev-form`}
                                      color={"secondary"}
                                      type="button"
                                      onClick={() => handleRemoveRow(index)}
                                    >
                                      -
                                    </Button>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </Col>
                              <Col
                                sm={1}
                                className=" d-flex align-items-center"
                              >
                                {inputTypeFields?.length == index + 1 ? (
                                  <div>
                                    <Button
                                      className="btn-secondary me-2"
                                      form={`ev-form`}
                                      color={"secondary"}
                                      type="button"
                                      onClick={() =>
                                        handleAddRow({ input_type: null })
                                      }
                                    >
                                      +
                                    </Button>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </Col>
                              <Col sm={10}>
                                <FormGroup className="mb-4">
                                  <Label>Options</Label>
                                  <UFInput
                                    control={control}
                                    placeholder="Enter the Options"
                                    name={`addons.${index}.value`}
                                    invalid={!!errors.value}
                                  />
                                  <FormFeedback invalid>
                                    {errors.value?.message}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                            </>
                          ) : inputWatch?.[index]?.input_type ? (
                            <>
                              <Col sm={5}>
                                <FormGroup className="mb-4">
                                  <Label>Label</Label>
                                  <UFInput
                                    control={control}
                                    placeholder="Enter the Label"
                                    name={`addons.${index}.label`}
                                    invalid={
                                      !!errors.addons?.[index]?.label?.message
                                    }
                                  />
                                  <FormFeedback invalid>
                                    {errors.addons?.[index]?.label?.message}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                              <Col
                                sm={1}
                                className=" d-flex align-items-center"
                              >
                                {inputTypeFields?.length > 1 ? (
                                  <div>
                                    <Button
                                      className="btn-secondary btn-row-add me-2"
                                      form={`ev-form`}
                                      color={"secondary"}
                                      type="button"
                                      onClick={() => handleRemoveRow(index)}
                                    >
                                      -
                                    </Button>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </Col>
                              <Col
                                sm={1}
                                className=" d-flex align-items-center"
                              >
                                {inputTypeFields?.length == index + 1 ? (
                                  <div>
                                    <Button
                                      className="btn-secondary me-2"
                                      form={`ev-form`}
                                      color={"secondary"}
                                      type="button"
                                      onClick={() =>
                                        handleAddRow({ input_type: null })
                                      }
                                    >
                                      +
                                    </Button>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </Col>
                            </>
                          ) : (
                            <></>
                          )}
                        </Row>
                      ))} */}
                    </Row>
                    <div className="float-end">
                      <Button
                        className="btn-secondary me-2"
                        form={`ev-form`}
                        color={"secondary"}
                        onClick={handleCancel}
                      >
                        Cancel
                      </Button>
                      <LoadingButton
                        form={`ev-form`}
                        color={"primary"}
                        loading={isLoading}
                        disabled={isLoading}
                        type="submit"
                      >
                        {edit?.id ? "Update" : "Create"}
                      </LoadingButton>
                    </div>
                  </Form>
                </FormProvider>
                <div className="text-medium mt-3 p-2 note-background">
                  Note:{" "}
                  <span className="text-mute ">
                    The charges will be handled on a monthly basis
                  </span>
                </div>
              </Card>
            </Col>
            <Col xs={7}>
              <Card className="me-2 px-2 pb-2">
                <HrmsSearch
                  handleSearchChange={handleSearchChange}
                  placeholder="Entitlement"
                />
                <hr className="table-divide" />
                <Row className="mt-2 ever-rise-table">
                  <Col xs={12}>
                    {list?.data?.data && (
                      <UpdateTable
                        className="mt-5"
                        nodes={list?.data?.data}
                        paginate={list?.data}
                        columns={columns}
                        CurrentPage={page}
                        handlePagination={handlePaginate}
                        tableWidth="30% 30% 25% 15%"
                      />
                    )}
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Entitlement
