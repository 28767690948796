import axios from "axios"
import accessToken from "./jwt-token-access/auth-token-header"
import { isEmpty } from "lodash"

const token = accessToken
const API_URL = process.env.REACT_APP_APIKEY

const axiosApi = axios.create({
  baseURL: API_URL,
})

const getAccessToken = () => {
  const authUser = token().Authorization
  return authUser ? authUser : null
}

axiosApi.interceptors.request.use(
  config => {
    const token = getAccessToken()
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    config.headers["X-CSRF-TOKEN"] = window.csrf_token
    return config
  },
  error => Promise.reject(error)
)

axiosApi.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.data) {
      if (
        error.response.status == 401 &&
        !isEmpty(localStorage.getItem("authUser"))
      ) {
        localStorage.removeItem("authUser")
        window.location.replace("/login")
      }

      return Promise.reject(error.response.data)
    }
    return Promise.reject(error.message)
  }
)

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}
