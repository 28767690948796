import React, { useEffect, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  Card,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledAccordion,
} from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import LoadingButton from "../../../components/Common/LoadingButton"
import { useNavigate, useParams } from "react-router-dom"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import EmployeeList from "../../../components/Common/chooseFields/employee"
import ReliveType from "../../../components/Common/chooseFields/reliveType"
import { UFDate, UFEditor } from "../../../components/Common/Fields/Input"
import {
  fetchEmployee,
  fetchEos,
  fetchEosEmpData,
  fileDownload,
  postHrmsEos,
} from "../../../services/Hrms/hrms"
import moment from "moment"
import closeIcon from "../../../assets/icons/close.svg"
import PayrollView from "../payrollGenerate/view"
import DownloadIcon from "../../../assets/icons/download.svg"
import { toastError, toastSuccess } from "../../../helpers/msg_helper"

const EditEndOfService = () => {
  document.title = "Final Settlement | Ever Rise"

  const navigate = useNavigate()
  const { mode, id } = useParams()
  console.log(`mode`, mode)

  const [isLoading, setIsLoading] = useState(false)
  const [empData, setEmpData] = useState()
  const [empDataPersonal, setEmpDataPersonal] = useState()
  const [viewData, setViewData] = useState()
  const [modal, setModal] = useState(false)
  const [refresh, setRefresh] = useState(0)

  const schema = yup.object().shape({
    eod: yup.mixed().required("End of Duty is Required"),
    employee_id: yup.mixed().required("Employee Id is Required"),
    type: yup.mixed().required("Relive Type is Required"),
    description: yup.string().required("Description is Required"),
  })

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: true,
    defaultValues: {},
  })

  const [totalPayables, setTotalPayables] = useState(0)
  const [totalRecievables, setTotalRecievables] = useState(0)

  useEffect(() => {
    const fetchView = async () => {
      const responce = await fetchEos(id)
      reset({
        ...responce?.data,
        employee_id: {
          label: responce?.data?.employee?.employee_code,
          value: responce?.data?.employee?.id,
          id: responce?.data?.employee?.id,
        },
        type: {
          label: responce?.data?.type,
          value: responce?.data?.type == "Resignation" ? 1 : 0,
        },
      })
    }
    setRefresh(refresh + 1)
    if (id) {
      fetchView()
    }
  }, [id])

  useEffect(
    () => {
      const fetchEmpData = async () => {
        const data = getValues()
        const responce = await fetchEosEmpData({
          params: `${
            data?.employee_id?.id ? `employee_id=${data?.employee_id.id}` : ""
          }${
            data?.eod
              ? `${data?.employee_id?.id ? "&" : ""}eod=${moment(
                  data?.eod
                ).format("DD-MM-YYYY")}`
              : ""
          }${
            data?.type
              ? `${data?.employee_id?.id ? "&" : ""}reliving_type=${data?.type.id}`
              : ""
          }`,
        })
        let totalPayablesAmt = responce?.data?.payable?.reduce(
          (a, b) => Number(b.amount) + a,
          0
        )
        setTotalPayables(totalPayablesAmt)

        let totalRecievablesAmt = responce?.data?.recivable?.reduce(
          (a, b) => Number(b.amount) + a,
          0
        )
        setTotalRecievables(totalRecievablesAmt)
        setEmpData(responce)
      }
      const fetchEmpDataPer = async () => {
        const data = getValues()
        const empResponce = await fetchEmployee({
          id: data?.employee_id?.id ? data?.employee_id?.id : "",
          type: "personal",
        })
        setEmpDataPersonal(empResponce)
      }
      if (watch("employee_id")?.id && watch("eod") && watch("type")?.label) {
        fetchEmpData()
        fetchEmpDataPer()
      }
    },
    [watch("employee_id")?.id, watch("eod"), watch("type")],
    refresh
  )

  const toggle = () => {
    setModal(!modal)
    if (!modal === false) {
      setViewData()
    }
  }

  const handleView = item => {
    setViewData(item)
    toggle()
  }

  const handleDocumentDownload = ({ path, url, downloadName, extension }) => {
    const handleDownload = async () => {
      const response = await fileDownload({
        url: url,
        path,
        downloadName: downloadName,
        extension: extension,
      })
    }
    // const fileExtension = viewData?.document.split(".").pop()
    handleDownload()
  }
  const getExtension = filePath => {
    const parts = filePath.split(".")
    return parts[1]
  }

  const handleRegistration = async data => {
    try {
      setIsLoading(true)
      const updateEos = await postHrmsEos({
        ...data,
        employee_id: data?.employee_id?.id,
        type: data?.type?.label,
        eod: moment(data?.eod).format("YYYY-MM-DD"),
        description: data?.description,
      })
      toastSuccess(updateEos?.message)
      navigate("/hrms-final-settlement")
    } catch (err) {
      toastError(err?.message)
    } finally {
      setIsLoading(false)
    }
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <FormProvider>
            <Form
              id={`employee-form`}
              onSubmit={handleSubmit(handleRegistration)}
            >
              <div className="sticky-top bg-white w-100">
                <div className="d-flex justify-content-between me-2">
                  <Breadcrumbs
                    title="HRMS"
                    breadcrumbObject={[
                      { title: "HRMS", link: "/hrms" },
                      {
                        title: "Final Settlement",
                        link: "/hrms-final-settlement",
                      },
                      {
                        title: "Add Final Settlement",
                        link: "/hrms-final-settlement/add",
                      },
                    ]}
                  />
                  {mode != "view" ? (
                    <LoadingButton
                      form={`employee-form`}
                      color={"primary"}
                      loading={isLoading}
                      disabled={isLoading}
                      className={"px-2 mb-3 btn btn-primary"}
                      type="submit"
                    >
                      {mode === "edit" ? "Update" : "Save"}
                    </LoadingButton>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <Container fluid className="ps-lg-0">
                <Card className="invoice-main-card p-3 pt-4">
                  <Row className="">
                    <Col sm={12} className="mb-4">
                      <div>End Of Service</div>
                    </Col>
                    <Col sm={3}>
                      <FormGroup className="mb-4">
                        <Label>
                          Employee ID
                          <span className="mandatory">*</span>
                        </Label>
                        <EmployeeList
                          control={control}
                          name={"employee_id"}
                          placeholder={"Employee Code"}
                          target={"body"}
                          invalid={!!errors.employee_id}
                          disabled={id ? true : false}
                        />
                        <FormFeedback invalid>
                          {errors.employee_id?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col sm={3}>
                      <FormGroup className="mb-4">
                        <Label>
                          Relive Type
                          <span className="mandatory">*</span>
                        </Label>
                        <ReliveType
                          control={control}
                          name={"type"}
                          placeholder={"Select"}
                          target={"body"}
                          invalid={!!errors.type}
                          disabled={mode === "view" ? true : false}
                        />
                        <FormFeedback invalid>
                          {errors.type?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col sm={3}>
                      <FormGroup className="mb-4">
                        <Label>
                          End of Duty
                          <span className="mandatory">*</span>
                        </Label>
                        <UFDate
                          control={control}
                          name={"eod"}
                          invalid={!!errors.eod}
                          disableCondition={mode === "view" ? true : false}
                        />

                        <FormFeedback invalid>
                          {errors.eod?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col sm={6} className="pb-4">
                      {watch("type")?.label && watch("eod") ? (
                        <div className="text-medium mt-3 p-2 note-background-eos">
                          Note:{" "}
                          <span className="text-mute ">
                            {watch("type")?.label == "Resignation"
                              ? "No Deductions"
                              : watch("type")?.label == "Termination" &&
                                watch("employee_id")?.employment_type == "1"
                              ? "One month salary will be deducted"
                              : "Three months salary will be deducted"}
                          </span>
                        </div>
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Row>
                  <hr className="m-0" />
                  {empData ? (
                    <>
                      <div className="mt-4 mb-2 customer-heading">
                        Personal Details
                      </div>
                      <Row>
                        <Col sm={6}>
                          <div className="note-background-eos mt-4 p-2">
                            <Row>
                              <Col sm={6} className="py-2">
                                Name
                              </Col>
                              <Col sm={1} className="py-2">
                                :
                              </Col>
                              <Col sm={5} className="py-2">
                                {empData?.data?.employee?.first_name
                                  ? empData?.data?.employee?.first_name
                                  : "-"}
                              </Col>
                              <Col sm={6} className="py-2">
                                Name
                              </Col>
                              <Col sm={1} className="py-2">
                                :
                              </Col>
                              <Col sm={5} className="py-2">
                                {empData?.data?.employee?.designation
                                  ? empData?.data?.employee?.designation?.name
                                  : "-"}
                              </Col>
                              <Col sm={6} className="py-2">
                                E-mail
                              </Col>
                              <Col sm={1} className="py-2">
                                :
                              </Col>
                              <Col sm={5} className="py-2">
                                {empData?.data?.employee?.personal_details
                                  ?.personal_email_id
                                  ? empData?.data?.employee?.personal_details
                                      ?.personal_email_id
                                  : "-"}
                              </Col>
                              <Col sm={6} className="py-2">
                                Personal Number
                              </Col>
                              <Col sm={1} className="py-2">
                                :
                              </Col>
                              <Col sm={5} className="py-2">
                                {empData?.data?.employee?.personal_details
                                  ?.personal_contact_number
                                  ? `+${empData?.data?.employee?.personal_details?.personal_contact_number}`
                                  : "-"}
                              </Col>
                              <Col sm={6} className="py-2">
                                Date of Join
                              </Col>
                              <Col sm={1} className="py-2">
                                :
                              </Col>
                              <Col sm={5} className="py-2">
                                {empData?.data?.employee?.personal_details
                                  ?.date_of_join
                                  ? moment(
                                      empData?.data?.employee?.personal_details
                                        ?.date_of_join
                                    ).format("DD-MM-YYYY")
                                  : "-"}
                              </Col>
                            </Row>
                          </div>
                          {/* <div className="">
                            <table className="employee-table mb-4">
                              <thead>
                                <tr>
                                  <th>Name</th>
                                  <th>Cost</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Employee Name</td>
                                  <td>{empData?.data?.employee?.first_name}</td>
                                </tr>
                                <tr>
                                  <td>E-mail</td>
                                  <td>
                                    {empData?.data?.employee?.personal_details
                                      ?.personal_email_id
                                      ? empData?.data?.employee
                                          ?.personal_details?.personal_email_id
                                      : "-"}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div> */}
                        </Col>
                        <Col sm={6}>
                          <div className="note-background-eos mt-4 p-2">
                            <Row>
                              <Col sm={6} className="py-2">
                                DOJ to EOD Days
                              </Col>
                              <Col sm={1} className="py-2">
                                :
                              </Col>
                              <Col sm={5} className="py-2">
                                {empData?.data?.total_days} Days
                              </Col>

                              <Col sm={6} className="py-2">
                                Pending Fine
                              </Col>
                              <Col sm={1} className="py-2">
                                :
                              </Col>
                              <Col sm={5} className="py-2">
                                {empData?.data?.other_unpaid_amount} AED
                              </Col>
                              <Col sm={6} className="py-2">
                                No. of Leave
                              </Col>
                              <Col sm={1} className="py-2">
                                :
                              </Col>
                              <Col sm={5} className="py-2">
                                {empData?.data?.total_absent_days} Days
                              </Col>
                              <Col sm={6} className="py-2">
                                Loan Amount
                              </Col>
                              <Col sm={1} className="py-2">
                                :
                              </Col>
                              <Col sm={5} className="py-2">
                                {empData?.data?.unpaid_loan} AED
                              </Col>

                              <Col sm={6} className="py-2">
                                Annual Leave
                              </Col>
                              <Col sm={1} className="py-2">
                                :
                              </Col>
                              <Col sm={5} className="py-2">
                                {empData?.data?.annual_leave} Days
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>

                      {empDataPersonal ? (
                        <div>
                          <div className="my-4 customer-heading">Clearance</div>
                          <Row>
                            <Col sm={6}>
                              <div className="">
                                {empDataPersonal?.data?.benefit ? (
                                  <>
                                    <div className="d-flex justify-content-center text-medium pb-2">
                                      Benefits
                                    </div>
                                    <table className="employee-table mb-4">
                                      <thead>
                                        <tr>
                                          <th>Name</th>
                                          <th>Cost</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {empDataPersonal?.data?.benefit?.map(
                                          (dt, i) => (
                                            <tr>
                                              <td>{dt?.name}</td>
                                              <td>
                                                {dt?.price ? dt?.price : 0}
                                              </td>
                                            </tr>
                                          )
                                        )}
                                      </tbody>
                                    </table>
                                  </>
                                ) : (
                                  <>No Benefits</>
                                )}
                              </div>
                            </Col>
                            <Col sm={6}>
                              <div className="">
                                {empDataPersonal?.data?.entitlements ? (
                                  <>
                                    <div className="d-flex justify-content-center text-medium pb-2">
                                      Entitlements
                                    </div>
                                    <table className="employee-table mb-4">
                                      <thead>
                                        <tr>
                                          <th>Name</th>
                                          <th>Cost</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {empDataPersonal?.data?.entitlements?.map(
                                          (dt, i) => (
                                            <tr>
                                              <td>{dt?.name}</td>
                                              <td>
                                                {dt?.price ? dt?.price : 0}
                                              </td>
                                            </tr>
                                          )
                                        )}
                                      </tbody>
                                    </table>
                                  </>
                                ) : (
                                  <>No Entitlements</>
                                )}
                              </div>
                            </Col>
                          </Row>
                        </div>
                      ) : (
                        <></>
                      )}
                      <hr className="m-0" />

                      <div>
                        <div className="my-4 customer-heading">Payables</div>
                        <table className="employee-table mb-4">
                          <thead>
                            <tr>
                              <th>Component</th>
                              <th>Amount</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {empData?.data?.payable?.map((dt, i) => (
                              <tr>
                                <td>
                                  {dt?.type == "payroll"
                                    ? "Salary Slip"
                                    : dt?.type == "bonus"
                                    ? "Bonus"
                                    : ""}
                                </td>
                                <td>
                                  {dt?.amount
                                    ? Number(dt?.amount).toFixed(2)
                                    : 0}{" "}
                                  AED
                                </td>
                                <td>
                                  <Button
                                    color={"primary"}
                                    className={"px-2 btn btn-primary"}
                                    onClick={() =>
                                      handleView({
                                        id: 4,
                                        name: dt?.type,
                                        value: dt,
                                        product_id: "",
                                      })
                                    }
                                  >
                                    View
                                  </Button>
                                </td>
                              </tr>
                            ))}
                            <tr>
                              <td>Gratuity</td>
                              <td>{empData?.data?.gratuity}</td>
                              <td></td>
                            </tr>
                            <tr>
                              <td>Leave Encashment</td>
                              <td>{empData?.data?.leave_encashment}</td>
                              <td></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <hr className="m-0" />
                      <div>
                        <div className="my-4 customer-heading">Receivables</div>
                        <table className="employee-table mb-4">
                          <thead>
                            <tr>
                              <th>Component</th>
                              <th>Amount</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {empData?.data?.recivable?.map((dt, i) => (
                              <tr>
                                <td>
                                  {dt?.type == "loan_payment"
                                    ? "Loan"
                                    : dt?.type == "fine"
                                    ? "Fine"
                                    : ""}
                                </td>
                                <td>
                                  {dt?.amount
                                    ? Number(dt?.amount).toFixed(2)
                                    : 0}{" "}
                                  AED
                                </td>
                                <td>
                                  <Button
                                    color={"primary"}
                                    className={"px-2 btn btn-primary"}
                                    onClick={() =>
                                      handleView({
                                        id: 4,
                                        name: dt?.type,
                                        value: dt,
                                        product_id: "",
                                      })
                                    }
                                  >
                                    View
                                  </Button>
                                </td>
                              </tr>
                            ))}
                            <tr>
                              <td>Salary Deduction</td>
                              <td>
                                {Number(
                                  Number(
                                    empData?.data?.employee?.total_salary
                                  ) *
                                    Number(
                                      watch("type")?.label == "Resignation"
                                        ? 0
                                        : watch("type")?.label ==
                                            "Termination" &&
                                          watch("employee_id")
                                            ?.employment_type == "1"
                                        ? 1
                                        : 3
                                    )
                                ).toFixed(2)}{" "}
                                AED
                              </td>
                              <td></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <hr className="m-0" />
                      <div className="my-4 customer-heading">Totals</div>
                      <table className="employee-table mb-4">
                        <thead>
                          <tr>
                            <td>Name</td>
                            <td>Amount</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Total Payables</td>
                            <td>
                              {Number(
                                Number(totalPayables) +
                                  Number(empData?.data?.gratuity) +
                                  Number(empData?.data?.leave_encashment)
                              ).toFixed(2)}{" "}
                              AED
                            </td>
                          </tr>
                          <tr>
                            <td>Total Reciveables</td>
                            <td>
                              {Number(
                                Number(totalRecievables) +
                                  Number(
                                    empData?.data?.employee?.total_salary
                                  ) *
                                    Number(
                                      watch("type")?.label == "Resignation"
                                        ? 0
                                        : watch("type")?.label ==
                                            "Termination" &&
                                          watch("employee_id")
                                            ?.employment_type == "1"
                                        ? 1
                                        : 3
                                    )
                              ).toFixed(2)}{" "}
                              AED
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <hr className="m-0" />

                      {/* <Card className="invoice-main-card p-3 pt-4"> */}
                      {/* <Row className="">
                        <Col sm={12}>
                          <UncontrolledAccordion defaultOpen={["1"]} stayOpen>
                            <AccordionItem>
                              <AccordionHeader targetId="1">
                                <h6> Other Payments</h6>
                              </AccordionHeader>
                              <AccordionBody accordionId="1">
                                <Row className="mb-4 mt-2">
                                  <Col sm={12}>Hello in</Col>
                                </Row>
                              </AccordionBody>
                            </AccordionItem>
                          </UncontrolledAccordion>
                        </Col>
                      </Row> */}
                      {/* </Card> */}
                    </>
                  ) : (
                    <></>
                  )}
                  <Row>
                    <Col sm={3}>
                      <FormGroup className="my-4">
                        <Label>
                          Description
                          <span className="mandatory">*</span>
                        </Label>
                        <UFEditor
                          control={control}
                          placeholder="Enter the Bio"
                          rows="5"
                          name={"description"}
                          invalid={!!errors.description}
                          className={"pb-0"}
                          disabled={mode === "view" ? true : false}
                        />
                        <FormFeedback invalid>
                          {errors.description?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                </Card>
              </Container>
            </Form>
          </FormProvider>
          <Modal
            isOpen={modal}
            toggle={toggle}
            size={viewData?.id == 1 ? "xl" : "xl"}
            unmountOnClose={false}
          >
            <ModalHeader className="custom-modal-header">
              <div className="d-flex justify-content-between">
                <h5 className="ms-4">
                  {viewData?.name == "payroll"
                    ? "Payroll"
                    : "Other Transactions"}
                </h5>
                <img
                  src={closeIcon}
                  alt=""
                  onClick={toggle}
                  width="10"
                  role="button"
                />
              </div>
            </ModalHeader>
            <ModalBody>
              {viewData?.name == "payroll" ? (
                <PayrollView
                  fromDashboard={true}
                  idDashBoard={viewData?.value?.id}
                />
              ) : (
                <>
                  <Row className="py-3 ms-3">
                    <Col lg={3} className="secondary-text">
                      Transaction Code
                    </Col>
                    <Col className="primary-text">{viewData?.value?.code}</Col>
                  </Row>
                  <Row className="py-3 ms-3">
                    <Col lg={3} className="secondary-text">
                      Employee Code
                    </Col>
                    <Col className="primary-text">
                      {viewData?.value?.employee?.employee_code}
                    </Col>
                  </Row>
                  <Row className="py-3 ms-3">
                    <Col lg={3} className="secondary-text">
                      Employee Name
                    </Col>
                    <Col className="primary-text">
                      {viewData?.value?.employee?.first_name}
                    </Col>
                  </Row>
                  <Row className="py-3 ms-3">
                    <Col lg={3} className="secondary-text">
                      Date
                    </Col>
                    <Col className="primary-text">
                      {moment(viewData?.value?.date).format("DD-MM-YYYY")}
                    </Col>
                  </Row>
                  <Row className="py-3 ms-3">
                    <Col lg={3} className="secondary-text">
                      Transaction Type
                    </Col>
                    <Col className="primary-text">
                      {viewData?.value?.transaction_type?.name}
                    </Col>
                  </Row>
                  <Row className="py-3 ms-3">
                    <Col lg={3} className="secondary-text">
                      Document
                    </Col>
                    <Col className="primary-text">
                      <span
                        className="pointer view-eye"
                        onClick={() =>
                          handleDocumentDownload({
                            id: viewData?.value?.id,
                            path: viewData?.value?.document?.split("/").pop(),
                            url: `other-payments/download/${viewData?.value?.id}`,
                            downloadName: `other_payment`,
                            extension: getExtension(viewData?.value?.document),
                          })
                        }
                      >
                        <img src={DownloadIcon} />
                      </span>
                    </Col>
                  </Row>
                  <Row className="py-3 ms-3">
                    <Col lg={3} className="secondary-text">
                      Payment Type
                    </Col>
                    <Col className="primary-text">
                      {viewData?.value?.is_loan == 0
                        ? "Instant Payment"
                        : "Loan"}
                    </Col>
                  </Row>
                  <Row className="py-3 ms-3">
                    <Col lg={3} className="secondary-text">
                      Description
                    </Col>
                    <Col className="primary-text">
                      {viewData?.value?.description}
                    </Col>
                  </Row>
                  {viewData?.value?.is_loan == 1 ? (
                    <>
                      <Row className="py-3 ms-3">
                        <Col lg={3} className="secondary-text">
                          Tenure
                        </Col>
                        <Col className="primary-text">
                          {viewData?.value?.temure} Months
                        </Col>
                      </Row>
                      <Row className="py-3 ms-3">
                        <Col lg={3} className="secondary-text">
                          Monthly Pay
                        </Col>
                        <Col className="primary-text">
                          {viewData?.value?.month_amount} Months
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </ModalBody>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  )
}

export default EditEndOfService
