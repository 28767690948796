import React from "react"
import { useController, Controller } from "react-hook-form"
import { Input, InputGroupText } from "reactstrap"

import Flatpickr from "react-flatpickr"
import Switch from "react-switch"
import { EditorState, ContentState } from "draft-js"
import { Editor } from "react-draft-wysiwyg"
import "flatpickr/dist/themes/light.css"
import Date from "../../../assets/icons/date.svg"
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect"
export const UFInput = ({ control, name, onChange, rules, ot, ...props }) => {
  const {
    field: { ref, onChange: fieldOnChange, ...inputProps },
    fieldState: { invalid },
  } = useController({
    name,
    control,
    rules,
    defaultValue: props.defaultValue !== undefined ? props.defaultValue : "",
  })
  const handleChange = e => {
    fieldOnChange(e)
    if (typeof onChange === "function") {
      onChange(e)
    }
  }
  return (
    <div className={`d-flex ${ot ? "ot-input" : ""}`}>
      {props?.nonEditableValue && (
        <InputGroupText className="prefix-right-border">
          {props?.nonEditableValue && props?.nonEditableValue}
        </InputGroupText>
      )}
      <Input
        invalid={invalid}
        {...props}
        {...inputProps}
        innerRef={ref}
        onChange={handleChange}
      />
      {props?.nonEditableValueLast && (
        <InputGroupText className="prefix-left-border">
          {props?.nonEditableValueLast && props?.nonEditableValueLast}
        </InputGroupText>
      )}
    </div>
  )
}

export const UFCheckInput = ({ control, name, rules, ...props }) => {
  const {
    field: { ref, value, ...inputProps },
    fieldState: { invalid },
  } = useController({
    name,
    control,
    rules,
    defaultValue: props.defaultValue !== undefined ? props.defaultValue : "",
  })
  return (
    <Input
      type="checkbox"
      checked={value ? true : false}
      invalid={invalid}
      {...props}
      {...inputProps}
      innerRef={ref}
    />
  )
}

export const UFSwitch = ({ control, name }) => {
  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
      </div>
    )
  }

  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
      </div>
    )
  }

  const {
    field: { ref, onChange, value, ...inputProps },
    fieldState: { invalid, isTouched, isDirty },
    formState: { touchedFields, dirtyFields },
  } = useController({
    name,
    control,
    rules: { required: true },
  })

  return (
    <Switch
      uncheckedIcon={<Offsymbol />}
      checkedIcon={<OnSymbol />}
      borderRadius={50}
      onColor="#E96729"
      offColor="#484848"
      height={25}
      onChange={data => onChange(data ? 1 : 0)}
      checked={value === 1 ? true : false}
      {...inputProps}
      name={name}
      ref={ref}
    />
  )
}

export const UFDate = ({
  control,
  name,
  rules,
  altFormat = "d-m-Y",
  dateFormat = "Y-m-d",
  minDate = null,
  maxDate = null,
  mode,
  time,
  placeholder = "Select Date",
  onDateChange,
  readOnly = false,
  disableCondition,
  className,
  // enable = null
}) => {
  const {
    field: { ref, onChange, ...inputProps },
    fieldState: { invalid, isTouched, isDirty },
    formState: { touchedFields, dirtyFields },
  } = useController({
    name,
    control,
    rules: { required: true },
  })
  const isDisabled = disableCondition ? true : false || readOnly
  return (
    <Flatpickr
      options={{
        enableTime: false,
        altInput: true,
        mode: mode ? mode : "single",
        altFormat,
        dateFormat,
        time_24hr: false,
        minDate: minDate,
        maxDate: maxDate,
        allowInput: !isDisabled,
        noCalendar: readOnly || isDisabled,
        readOnly: isDisabled,
        // enable: enable,
      }}
      name={name}
      {...inputProps}
      onChange={data => {
        console.log(`mode`, data)
        mode === "range" ? onChange([data[0], data[1]]) : onChange(data[0])
        if (onDateChange) onDateChange(data)
      }}
      render={({ defaultValue, value, ...props }, ref) => {
        return (
          <div className="date-parent">
            <Input
              invalid={invalid}
              value={value}
              placeholder={placeholder}
              defaultValue={defaultValue}
              innerRef={ref}
              className={className ? className : `date-field`}
              readOnly={isDisabled}
            />
            <img src={Date} className="date-img" />
          </div>
        )
      }}
    />
  )
}

export const UFMonthYear = ({
  control,
  name,
  altFormat = "d-m-Y",
  dateFormat = "Y-m-d",
  minDate = null,
  maxDate = null,
  mode,
  placeholder = "Select Date",
  onDateChange = "",
  readOnly = false,
  month = false,
  defaultValue,
}) => {
  const {
    field: { onChange, ...inputProps },
    fieldState: { invalid },
    // fieldState: { invalid, isTouched, isDirty },
    // formState: { touchedFields, dirtyFields }
  } = useController({
    name,
    control,
    rules: { required: true },
  })
  return (
    <Flatpickr
      options={{
        enableTime: false,
        altInput: true,
        mode: mode ? mode : "single",
        altFormat,
        dateFormat,
        time_24hr: false,
        minDate: minDate,
        maxDate: maxDate,
        allowInput: !readOnly,
        noCalendar: readOnly,
        ...(month && {
          plugins: [
            new monthSelectPlugin({
              shorthand: true,
              dateFormat: "m.y", 
              altFormat: "F Y", 
            }),
          ],
        }),
      }}
      name={name}
      {...inputProps}
      defaultValue={defaultValue}
      onChange={data => {
        mode === "range" ? onChange([data[0], data[1]]) : onChange(data[0])
        if (onDateChange) onDateChange(data)
      }}
      render={({ defaultValue, value }, ref) => {
        return (
          <Input
            value={value}
            placeholder={placeholder}
            defaultValue={defaultValue}
            innerRef={ref}
            invalid={invalid}
            className="date-icon w-100"
          />
        )
      }}
    />
  )
}

// export const UFDateOnly = ({ control, name,rules}) => {
//   const {
//     field: { ref, onChange,  ...inputProps },
//     fieldState: { invalid, isTouched, isDirty },
//     formState: { touchedFields, dirtyFields }
//   } = useController({
//     name,
//     control,
//     rules: { required: true }
//   });

//   return <Flatpickr
//     options={{
//       enableTime: true,
//       altInput: true,
//       mode: "single",
//       altFormat: "F j, Y",
//       dateFormat: "Y-m-d",
//       time_24hr: false
//     }}
//     data-enable-time
//     inputRef={ref}
//     {...inputProps}
//     onChange={data => {
//       onChange(data[0])
//     }}
//     render={
//       ({defaultValue, value, ...props}, ref) => {
//         return <Input invalid={invalid} value={value}  defaultValue={defaultValue} innerRef={ref} />
//       }
//     }
//     />
// }

export const UFEditor = ({ control, name, rules, ...props }) => {
  const nullValueHandle = val => {
    if (!val) {
      return ""
    } else {
      return val
    }
  }
  const {
    field: { ref, ...inputProps },
    fieldState: { invalid, isTouched, isDirty },
    formState: { touchedFields, dirtyFields },
  } = useController({
    name,
    control,
    rules: { required: true },
    defaultValue: nullValueHandle(props.defaultValue),
  })

  //const content = ContentState.createFromText(value != undefined ? value : "");
  return (
    <Input
      type="textarea"
      {...props}
      invalid={invalid}
      {...inputProps}
      innerRef={ref}
    />
  )
  /*return <Editor
    name={name}
    editorRef={ref}
    editorState={EditorState.createWithContent(content)}
    onEditorStateChange={onChange}
    />*/
}

export const RichTextEditor = ({ control, name, invalid }) => {
  const {
    field: { value = "", onBlur, onChange }, // Set a default value if it's not provided
    // fieldState: { invalid, isTouched, isDirty }, // Remove this line
    // formState: { touchedFields, dirtyFields }
  } = useController({
    // fieldState: { invalid, isTouched, isDirty }, // Remove this line
    name,
    control,
    defaultValue: "", // Set a default value if it's not provided
  })
  return (
    <Editor
      editorState={value}
      wrapperClassName="wrapperClassName"
      editorClassName="editorClassName"
      toolbarClassName="richtoolclass"
      invalid={invalid}
      onBlur={onBlur}
      editorStyle={{ minHeight: "110px", maxHeight: "110px" }}
      onEditorStateChange={onChange}
      toolbar={{
        options: ["inline", "fontSize", "list", "textAlign"],
        inline: { inDropdown: false },
        list: { inDropdown: false },
        textAlign: { inDropdown: false },
        link: { inDropdown: false },
      }}
    />
  )
}

export const UFInputDashboard = ({
  control,
  name,
  onChange,
  rules,
  ...props
}) => {
  const {
    field: { ref, onChange: fieldOnChange, ...inputProps },
    fieldState: { invalid },
  } = useController({
    name,
    control,
    rules,
    defaultValue: props.defaultValue !== undefined ? props.defaultValue : "",
  })
  const handleChange = e => {
    fieldOnChange(e)
    if (typeof onChange === "function") {
      onChange(e)
    }
  }
  return (
    <Input
      invalid={invalid}
      {...props}
      {...inputProps}
      innerRef={ref}
      onChange={handleChange}
      className="ufinput-dashboard"
    />
  )
}

export const UFTime = ({
  control,
  name,
  altFormat = "d-m-Y",
  dateFormat = "Y-m-d",
  minDate = null,
  maxDate = null,
  mode,
  placeholder = "Select Time",
  onDateChange = "",
  readOnly = false,
  month = false,
  defaultValue,
}) => {
  const {
    field: { onChange, ...inputProps },
    fieldState: { invalid },
    // fieldState: { invalid, isTouched, isDirty },
    // formState: { touchedFields, dirtyFields }
  } = useController({
    name,
    control,
    rules: { required: true },
  })
  return (
    <Flatpickr
      options={{
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true,
      }}
      name={name}
      {...inputProps}
      defaultValue={defaultValue}
      onChange={data => {
        mode === "range" ? onChange([data[0], data[1]]) : onChange(data[0])
        if (onDateChange) onDateChange(data)
      }}
      render={({ defaultValue, value }, ref) => {
        return (
          <Input
            value={value}
            placeholder={placeholder}
            defaultValue={defaultValue}
            innerRef={ref}
            invalid={invalid}
            className="date-icon w-100"
          />
        )
      }}
    />
  )
}
