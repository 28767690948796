import { get, post } from "../../helpers/api_helper"

// fetch Category
export const fetchGRNCosting = data => {
  return get(`grn-costing/search?${data?.params}`)
}

// post grncaosting
export const postGRNCosting = data => {
  return !data?.id
    ? post(`grn-costing/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`grn-costing/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}

// grnCosting fetch id
export const fetchGrnCostingSingle = data => {
  return get(`grn-costing/${data}`)
}

export const fetchCostCenterData = data => {
  return get(`project-master/costcenter-fetch/${data}`)
}

// post Acc Recievable
export const postAccRecievable = data => {
  return !data?.id
    ? post(`account-recivables/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`account-recivables/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}

// fetch AccReceivable
export const fetchAccReceivable = data => {
  return get(`account-recivables/search?${data?.params}`)
}

// grnCosting fetch id
export const fetchAccReceivableSingle = data => {
  return get(`account-recivables/${data}`)
}

// post AccPayables
export const postAccPayables = data => {
  return !data?.id
    ? post(`account-payables/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`account-payables/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}

// fetch AccPayables
export const fetchPayablesSearch = data => {
  return get(`account-payables/search?${data?.params}`)
}
// fetch AccPayables id
export const fetchPayables = id => {
  return get(`account-payables/${id}}`)
}
