// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.radio-toolbar input[type="radio"] {
  opacity: 0;
  position: fixed;
  width: 0; }

.radio-toolbar label {
  display: inline-block;
  cursor: pointer;
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 4px; }

.radio-toolbar input[type="radio"]:checked + label {
  border: none; }

#radio-title {
  margin-bottom: 5px; }
`, "",{"version":3,"sources":["webpack://./src/components/CommonForBoth/rightbar.scss"],"names":[],"mappings":"AAEA;EACE,UAAU;EACV,eAAe;EACf,QAAQ,EAAA;;AAEV;EACE,qBAAqB;EAErB,eAAe;EACf,iBAAiB;EACjB,eAAe;EACf,kBAAkB,EAAA;;AAEpB;EAEE,YAAY,EAAA;;AAId;EACE,kBAAkB,EAAA","sourcesContent":["// @import \"../../assets/scss/theme.scss\";\n\n.radio-toolbar input[type=\"radio\"] {\n  opacity: 0;\n  position: fixed;\n  width: 0;\n}\n.radio-toolbar label {\n  display: inline-block;\n  // background-color: $gray-300;\n  cursor: pointer;\n  padding: 5px 10px;\n  font-size: 14px;\n  border-radius: 4px;\n}\n.radio-toolbar input[type=\"radio\"]:checked + label {\n  // background-color: $primary;\n  border: none;\n  // color: $white;\n}\n\n#radio-title {\n  margin-bottom: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
