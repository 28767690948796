import React, { useEffect, useState } from "react"
import Select from "react-select"
import { Controller } from "react-hook-form"
import { getEstimationCategoryList } from "../../../services/Settings/dropdown"

function ucfirst(str) {
  var firstLetter = str.substr(0, 1)
  return firstLetter.toUpperCase() + str.substr(1)
}

const EstimationCategory = ({
  control,
  name,
  onSelectChange,
  target,
  placeholder,
  classCode,
  type,
  disabled,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [fetchResponse, setFetchResponse] = useState([])
  const loadOptions = async () => {
    setIsLoading(true)
    const data = await getEstimationCategoryList(classCode?.value, type)
    setIsLoading(false)
    const response = data?.data?.map(dta => {
      return {
        label:
          dta?.category_code &&
          ucfirst(`${dta.category_code}-${dta?.description}`),
        value: dta.id,
        ...dta,
      }
    })

    setFetchResponse(response)
  }
  useEffect(() => {
    if (classCode?.value) {
      loadOptions()
    }
  }, [classCode?.value])

  return (
    <div>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, ref, value } }) => {
          return (
            <Select
              options={fetchResponse}
              placeholder={placeholder ? placeholder : "Select"}
              isLoading={isLoading}
              inputRef={ref}
              onChange={e => {
                onChange(e)
                if (onSelectChange) onSelectChange(e)
              }}
              isDisabled={disabled ? true : false}
              value={value}
              classNamePrefix="react-select"
              className="elevate-dropdown"
              menuPortalTarget={document.querySelector(
                target ? target : ".modal"
              )}
            />
          )
        }}
      />
    </div>
  )
}

export default EstimationCategory
