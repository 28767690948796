import React, { useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Button, Card, Col, Container, Row } from "reactstrap"
import Logo from "../../../assets/images/logo.svg"
import phoneImg from "../../../assets/images/phone.svg"
import webImg from "../../../assets/images/web.svg"
import locationImg from "../../../assets/images/location.svg"
import moment from "moment"
import { downloadgrn } from "../../../services/procurement/grn"
import parse from "html-react-parser"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import {
  fetchAccReceivableSingle,
  fetchPayables,
} from "../../../services/finance/grnCosting"

const ViewAccPayables = ({ fromDashboard, viewValue }) => {
  document.title = "Account Payable View | Ever Rise"
  const { id } = useParams()

  const viewId = fromDashboard ? viewValue?.value?.id : id

  const [grnDetails, setGrnDetails] = useState()
  const [tabledata, setTabledata] = useState()

  const selectLoginState = state => state.Login
  const LoginProperties = createSelector(selectLoginState, login => ({
    loggedUser: login.loggedUser,
  }))

  const { loggedUser } = useSelector(LoginProperties)

  useEffect(() => {
    const fetchGRNdata = async () => {
      const response = await fetchPayables(viewId)
      setGrnDetails(response?.data)
      setTabledata(response?.data?.grn?.grnquantitieslist)
    }
    if (viewId !== undefined) fetchGRNdata()
  }, [])

  const result = tabledata?.reduce(
    (acc, item) => {
      const received = item.received_quantity || 0
      return {
        totalreceived: Number(acc.totalreceived) + Number(received),
      }
    },
    { totalreceived: 0 }
  )

  const { totalreceived } = result || {}
  return (
    <React.Fragment>
      <div className={`page-content ${fromDashboard && "pt-0"}`}>
        <div className="container-fluid">
          <div className="d-flex justify-content-between me-2">
            {!fromDashboard && (
              <Breadcrumbs
                title="Finance"
                breadcrumbObject={[
                  { title: "Finance", link: "/finance" },
                  {
                    title: "Account Payable",
                    link: "/finance-account-payables",
                  },
                  {
                    title: "Account Payable View",
                    link: `/finance-account-payable/view/${viewId}`,
                  },
                ]}
              />
            )}
          </div>
          <Container fluid className="ps-lg-0">
            <Card className="supplier-card">
              <div className={`purchase-view-div ${fromDashboard && "wdt-96"}`}>
                <Row>
                  <Col>
                    <div className="d-flex justify-content-between me-2">
                      <h3 className="main-heading">Account Payable</h3>
                      <img className="view-logo" src={Logo} alt="logo" />
                    </div>

                    <Row className=" pb-2 mt-3">
                      <Col lg="3">
                        <span>Account Payable No</span>
                        <br />
                        <span className="fw-bold-700">{grnDetails?.code}</span>
                      </Col>
                      <Col lg="3">
                        <span>Customer Code</span>
                        <br />
                        <span className="fw-bold-700">
                          {grnDetails?.cus_id?.customer_code}
                        </span>
                      </Col>
                      <Col lg="3">
                        <span>Customer Name</span>
                        <br />
                        <span className="fw-bold-700">
                          {grnDetails?.cus_id?.customer_name}
                        </span>
                      </Col>
                      <Col lg="3">
                        <span>Project NO</span>
                        <br />
                        <span className="fw-bold-700">
                          {grnDetails?.project_id?.project_code}
                        </span>
                      </Col>
                    </Row>
                    <Row className=" pb-2 mt-3">
                      <Col lg="3">
                        <span>Cost Center</span>
                        <br />
                        <span className="fw-bold-700">
                          {grnDetails?.grn?.po?.supplier?.name}
                        </span>
                      </Col>
                      <Col lg="3">
                        <span>Sub-category</span>
                        <br />
                        <span className="fw-bold-700">
                          {grnDetails?.sub_cat_id?.code}
                        </span>
                      </Col>
                      <Col lg="3">
                        <span>Payment Mode</span>
                        <br />
                        <span className="fw-bold-700 capitalize-letter">
                          {grnDetails?.payment_mode_id?.name}
                        </span>
                      </Col>
                    </Row>
                    <div className="table_height mt-5">
                      <div className="d-flex justify-content-between me-2">
                        <p className="quotation-ten-amount">
                          {grnDetails?.pay_terms &&
                            parse(grnDetails?.pay_terms)}
                        </p>
                        <p>
                          Amount Received{" "}
                          <span className="bg-total">
                            {Number(
                              Number(grnDetails?.amount).toFixed(4)
                            ).toLocaleString("en", {
                              minimumFractionDigits: 2,
                            })}{" "}
                            AED
                          </span>
                        </p>
                      </div>
                    </div>

                    <div className="contact-us d-flex align-items-end justify-content-between text-blue border-top mt-5 py-3 font-size-12">
                      <div className="font-bold-800">Contact Us</div>
                      <div>
                        <img
                          src={phoneImg}
                          alt="location"
                          className="footer-logo"
                        />{" "}
                        +{loggedUser?.settings?.phone}
                      </div>
                      <div>
                        <img
                          src={webImg}
                          alt="location"
                          className="footer-logo"
                        />{" "}
                        {loggedUser?.settings?.website}
                      </div>
                      <div>
                        <img
                          src={locationImg}
                          alt="location"
                          className="footer-logo"
                        />{" "}
                        {loggedUser?.settings?.companyaddress}
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              {/* {!fromDashboard && ( */}
              <Row>
                <Col lg={fromDashboard ? 12 : 8}>
                  <div
                    className={`d-flex justify-content-between me-2 ${
                      fromDashboard ? "pe-3" : "pr-50"
                    }`}
                  >
                    <span></span>
                    <Button
                      form={`supplier-form`}
                      color={"primary"}
                      className={"px-2 mb-3 btn btn-primary"}
                      onClick={() => downloadgrn(viewId, "grn.pdf")}
                    >
                      Download
                    </Button>
                  </div>
                </Col>
              </Row>
              {/* )} */}
            </Card>
          </Container>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ViewAccPayables
