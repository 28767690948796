import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
  Col,
  Row,
  Card,
  UncontrolledPopover,
  PopoverBody,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"
import UpdateTable from "../../../components/Common/UpdatedTable"
import EditLeaveManagement from "./edit"
import action from "../../../assets/icons/action.svg"
import { toastSuccess } from "../../../helpers/msg_helper"
import closeIcon from "../../../assets/icons/close.svg"
import ProductFilter from "./filter"
import {
  searchHrmsLeaveManagement,
  updateLeaveManagementStatus,
} from "../../../services/Hrms/hrms"
import { capitalize } from "lodash"

const LeaveManagement = () => {
  document.title = "Leave Management | Ever Rise"
  const [editClass, setEditClass] = useState({
    modal: false,
    value: "",
    id: "",
  })
  const [refresh, setRefresh] = useState(0)
  const [viewData, setViewData] = useState()
  const [modal, setModal] = useState(false)

  const toggle = () => setModal(!modal)

  const handleUpdate = () => {
    setRefresh(refresh + 1)
  }
  const handleReset = () => {
    setEditClass({
      modal: false,
      value: "",
      id: "",
    })
  }

  const [classData, setClassData] = useState()
  const [status, setStatus] = useState("")
  const [search, setSearch] = useState("")
  const [leaveId, setLeaveId] = useState("")
  const [employeeId, setEmployeeId] = useState("")
  const [page, setPage] = useState("")
  const [dateFrom, setDatefrom] = useState("")
  const [dateTo, setDateto] = useState("")
  const [financial, setFinancial] = useState(true)

  console.log("view data :", viewData)
  const handleEdit = item => {
    setEditClass({ modal: false, value: item, id: item?.id })
  }
  const handleView = item => {
    setViewData(item)
    toggle()
  }
  const handleActivate = item => {
    const updateStatus = async item => {
      const response = await updateLeaveManagementStatus(item)
      toastSuccess(response?.message)
      setRefresh(refresh + 1)
    }
    updateStatus(item)
  }

  const columns = [
    {
      label: "Leave Request ID",
      renderCell: item => {
        return (
          <div onClick={() => handleView(item)} className="pointer fw-bold">
            {item?.leave_req_code}
          </div>
        )
      },
    },
    {
      label: "Request Date",
      renderCell: item => item?.requested_date,
    },
    {
      label: "Employee ID",
      renderCell: item => item?.employee?.employee_code,
    },
    {
      label: "Employee Name",
      renderCell: item => (
        <div className="capitalize-letter">{item?.employee?.first_name}</div>
      ),
    },
    {
      label: "Leave Type",
      renderCell: item =>
        item?.leave_type?.name ? capitalize(item?.leave_type?.name) : "-",
    },
    {
      label: "Status",
      renderCell: item => (
        <div>
          <span
            className={`font-13 ${
              item?.status === 1 ? "status-active" : "status-inactive"
            }`}
          >
            {item?.status === 1 ? "Accept" : "Reject"}
          </span>
        </div>
      ),
    },
    {
      label: "Action",
      renderCell: item => (
        <>
          <div>
            <span id={`PopoverLegacy-${item?.id}`}>
              <img src={action} alt="" className="pointer" />
            </span>
          </div>
          <UncontrolledPopover
            placement="bottom"
            target={`PopoverLegacy-${item?.id}`}
            trigger="legacy"
          >
            <PopoverBody>
              {item?.status === 1 && (
                <div
                  className="table-popup-content"
                  onClick={() => handleEdit(item)}
                >
                  Edit
                </div>
              )}
              <div
                className={`${
                  item?.status === 1 ? "popover-border" : ""
                } table-popup-content`}
                onClick={() => handleActivate(item?.id)}
              >
                {item?.status === 1 ? "Reject" : "Accept"}
              </div>
            </PopoverBody>
          </UncontrolledPopover>
        </>
      ),
    },
  ]

  const handlePaginate = page => {
    setPage(page)
  }

  useEffect(() => {
    const fetchClass = async () => {
      const response = await searchHrmsLeaveManagement({
        params: `${
          status == "0" || status == "1" || status == "2"
            ? `&status=${status}`
            : ""
        }${search ? `&searchText=${search}` : "&searchText"}${
          leaveId ? `&leave_id=${leaveId}` : ""
        }${employeeId ? `&employee_id=${employeeId}` : ""}${
          dateFrom ? `&from_date=${dateFrom}` : ""
        }${dateTo ? `&to_date=${dateTo}` : ""}${
          page ? `&page=${page}` : ""
        }${`&financial=${financial}`}`,
      })
      setClassData(response)
    }
    fetchClass()
  }, [
    refresh,
    status,
    search,
    leaveId,
    employeeId,
    page,
    dateFrom,
    dateTo,
    financial,
  ])

  const handleStatusChange = id => {
    setStatus(id)
    setPage(1)
  }
  const handleLeaveIdChange = id => {
    setLeaveId(id)
    setPage(1)
  }
  const handleEmployeeIdChange = id => {
    setEmployeeId(id)
    setPage(1)
  }
  const handleDateChange = (fromdate, todate) => {
    setDatefrom(fromdate)
    setDateto(todate)
    setPage(1)
  }
  const handleSearchChange = data => {
    setSearch(data)
    setPage(1)
  }

  const handleFinacialChange = id => {
    setFinancial(id)
    setPage(1)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="d-flex justify-content-between">
            <Breadcrumbs
              title="HRMS"
              breadcrumbObject={[
                { title: "HRMS", link: "/hrms" },
                { title: "Leave Management", link: "/hrms-leave-management" },
              ]}
            />
            <EditLeaveManagement
              key={"x"}
              onUpdate={handleUpdate}
              onReset={handleReset}
              editValue={editClass}
              buttonLabel="+&nbsp; Add Leave"
              className="px-2 mb-3 btn btn-primary"
            />
          </div>
          <Card className="me-2 px-2 pb-2">
            <ProductFilter
              handleStatusChange={handleStatusChange}
              handleSearchChange={handleSearchChange}
              handleLeaveIdChange={handleLeaveIdChange}
              handleEmployeeIdChange={handleEmployeeIdChange}
              handleDateChange={handleDateChange}
              handleFinacialChange={handleFinacialChange}
              sampleUrl={"download/products.xlsx"}
              importUrl={"products/import"}
            />
            <hr className="table-divide" />
            <Row className="mt-2 ever-rise-table">
              <Col xs={12}>
                {classData && (
                  <UpdateTable
                    className="mt-5"
                    nodes={classData?.data?.data}
                    paginate={classData?.data}
                    columns={columns}
                    CurrentPage={page}
                    handlePagination={handlePaginate}
                    tableWidth="15% 15% 15% 15% 15% 15% 10%"
                  />
                )}
              </Col>
            </Row>
          </Card>
        </div>
      </div>
      <Modal isOpen={modal} toggle={toggle} size="lg" unmountOnClose={false}>
        <>
          <ModalHeader className="custom-modal-header">
            <div className="d-flex justify-content-between">
              <h5 className="ms-4">Details</h5>
              <img
                src={closeIcon}
                alt=""
                onClick={toggle}
                width="10"
                role="button"
              />
            </div>
          </ModalHeader>
          <ModalBody>
            <Row className="py-3 ms-3">
              <Col lg={2} className="secondary-text">
                Employee Code
              </Col>
              <Col className="primary-text">
                {viewData?.employee?.employee_code}
              </Col>
            </Row>
            <Row className="py-3 ms-3">
              <Col lg={2} className="secondary-text">
                Employee Name
              </Col>
              <Col className="primary-text">
                {viewData?.employee?.first_name}
              </Col>
            </Row>

            <Row className="py-3 ms-3">
              <Col lg={2} className="secondary-text">
                Leave Type
              </Col>
              <Col className="primary-text">{viewData?.leave_type?.name}</Col>
            </Row>
            <Row className="py-3 ms-3">
              <Col lg={2} className="secondary-text">
                Request Date
              </Col>
              <Col className="primary-text">{viewData?.requested_date}</Col>
            </Row>
            <Row className="py-3 ms-3">
              <Col lg={2} className="secondary-text">
                Leave From
              </Col>
              <Col className="primary-text">{viewData?.leave_from}</Col>
            </Row>
            <Row className="py-3 ms-3">
              <Col lg={2} className="secondary-text">
                Leave To
              </Col>
              <Col className="primary-text">{viewData?.leave_to}</Col>
            </Row>
          </ModalBody>
        </>
      </Modal>
    </React.Fragment>
  )
}

export default LeaveManagement
