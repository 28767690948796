import { Form, Row } from "reactstrap"
import { UFInput } from "../../../../components/Common/Fields/Input"
import { useForm } from "react-hook-form"
import resetIcon from "../../../../assets/icons/reset.svg"
import { useEffect } from "react"

const ShopKeeperFilter = ({ handleSearchChange }) => {
  const { control, watch, reset } = useForm()

  useEffect(() => {
    handleSearchChange(watch("search"))
  }, [watch("search")])

  const handleReset = () => {
    handleSearchChange()
    reset({ search: "" })
  }
  return (
    <>
      <div className="d-flex justify-content-between align-items-center my-2 me-2">
        <UFInput
          control={control}
          placeholder="Search"
          name={"search"}
          className={"search-input"}
        />
        <div className="export-div pointer me-2 py-2" onClick={() => handleReset()}>
          <img src={resetIcon} height={15} width={15} className="me-2" /> Reset
        </div>
      </div>
    </>
  )
}

export default ShopKeeperFilter
