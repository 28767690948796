import { yupResolver } from "@hookform/resolvers/yup"
import React, { useState, useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormFeedback,
  Spinner,
  Form,
  FormGroup,
  Label,
} from "reactstrap"
import * as yup from "yup"
import LoadingButton from "../../../components/Common/LoadingButton"
import { UFEditor } from "../../../components/Common/Fields/Input"
import classnames from "classnames"
import closeIcon from "../../../assets/icons/close.svg"
import { Link } from "react-router-dom"
import {
  editSeries,
  fetchCategoryId,
  postInventoryCategory,
} from "../../../services/Inventory/inventory"
import { toastError, toastSuccess } from "../../../helpers/msg_helper"
import Series from "../../../components/Common/chooseFields/series"
import ClassList from "../../../components/Common/chooseFields/classDropDown"
import Expense from "../../../components/Common/chooseFields/expense"
import Designation from "../../../components/Common/chooseFields/designation"

const schema = yup.object().shape({
  series_id: yup.mixed().required("Series is Required"),
  class_id: yup.mixed().required("Series is Required"),
  description: yup.string().required("Description is Required"),
  expense_type: yup.mixed().required("Expence Type is Required"),
})

const Edit = ({ loading, onUpdate, id, error, close, ...props }) => {
  const [subContract, setSubContract] = useState(false)
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
  })
  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: true,
  })
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    console.log(`props?.editItem`, props?.editItem)
    if (props?.editItem?.modal) {
      const fetchData = async id => {
        const response = await fetchCategoryId(id)
        reset({
          ...response?.data,
          series_id: {
            value: response?.data?.series?.id,
            label: response?.data?.series?.description,
          },
          class_id: {
            value: response?.data?.classcode?.id,
            label: response?.data?.classcode?.class_code,
          },
          is_subcontract:
            response?.data?.is_subcontract === 1
              ? setSubContract(true)
              : setSubContract(false),
          designation_id: {
            value: response?.data?.designation?.id,
            label: response?.data?.designation?.name,
          },
          description: response?.data?.description,
        })
        console.log(`response`, response)
      }
      fetchData(props?.editItem?.value?.id)
    }
  }, [props?.editItem?.modal])

  const handleCategoryAdd = async data => {
    try {
      setIsLoading(true)
      const createdCategory = await postInventoryCategory({
        ...data,
        series_id: data?.series_id?.value,
        class_id: data?.class_id?.value,
        is_subcontract: subContract ? 1 : 0,
        expense_type: data?.expense_type?.id,
        designation_id: data?.designation_id?.value,
      })
      toastSuccess(createdCategory?.message)
      onUpdate()
    } catch (err) {
      toastError(err?.message)
    } finally {
      setIsLoading(false)
      reset()
      close()
    }
  }

  const handleCheck = dt => {
    setSubContract(!dt)
  }

  //   const handleSeriesEdit = async data => {
  //     try {
  //       setIsLoading(true)
  //       const createdSeries = await postInventoryCategory({
  //         data,
  //         id: props?.editItem?.value?.id,
  //       })
  //       toastSuccess(createdSeries?.message)
  //       onUpdate()
  //     } catch (err) {
  //       toastError(err?.message)
  //     } finally {
  //       setIsLoading(false)
  //       reset()
  //       close()
  //     }
  //   }

  useEffect(() => {
    if (watch("designation_id") && !props?.editItem?.modal) {
      setValue("description", watch("designation_id")?.name)
    }
  }, [watch("designation_id")])

  return (
    <>
      <ModalHeader className="custom-modal-header">
        <div className="d-flex justify-content-between">
          <h5>{props?.editItem?.modal ? "Edit Category" : "New Category"}</h5>{" "}
          <img
            src={closeIcon}
            alt=""
            onClick={close}
            width="10"
            role="button"
          />
        </div>
      </ModalHeader>
      {loading ? (
        <div
          className="px-8"
          style={{
            width: "100%",
            textAlign: "center",
            minHeight: "200px",
            paddingTop: "90px",
          }}
        >
          <Spinner
            className={classnames({
              "mx-2": true,
              "position-relative": true,
              visible: true,
            })}
            size="lg"
          />
        </div>
      ) : (
        <>
          <ModalBody>
            <FormProvider {...methods}>
              <Form id={`ev-form`} onSubmit={handleSubmit(handleCategoryAdd)}>
                <Row>
                  <Col sm={12}>
                    <FormGroup className="mb-4">
                      <Label>
                        Series<span className="mandatory">*</span>
                      </Label>
                      <Series
                        control={control}
                        name={"series_id"}
                        disabled={props?.editItem?.modal ? true : false}
                      />
                      <FormFeedback invalid>
                        {errors.series_id?.message}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm={12}>
                    <FormGroup className="mb-4">
                      <Label>
                        Class Code<span className="mandatory">*</span>
                      </Label>
                      <ClassList
                        control={control}
                        name={"class_id"}
                        seriesId={watch("series_id")}
                        disabled={props?.editItem?.modal ? true : false}
                      />
                      <FormFeedback invalid>
                        {errors.class_id?.message}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm={12}>
                    <FormGroup className="mb-4">
                      <Label>
                        Expense Type<span className="mandatory">*</span>
                      </Label>
                      <Expense
                        control={control}
                        name={"expense_type"}
                        // disabled={props?.editItem?.modal ? true : false}
                      />
                      <FormFeedback invalid>
                        {errors.expense_type?.message}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  {watch("expense_type")?.id === 2 && (
                    <Col sm={12}>
                      <FormGroup className="mb-4">
                        <Label>
                          Designation<span className="mandatory">*</span>
                        </Label>
                        <Designation
                          control={control}
                          name={"designation_id"}
                          // disabled={props?.editItem?.modal ? true : false}
                        />
                        <FormFeedback invalid>
                          {errors.designation_id?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                  )}
                  <Col lg={"auto"}>
                    <FormGroup>
                      <input
                        control={control}
                        type="checkbox"
                        className="ever-rise-checkbox"
                        name={"is_subcontract"}
                        defaultChecked={false}
                        checked={subContract}
                        onClick={() => handleCheck(subContract)}
                        // disabled={props?.editItem?.modal ? true : false}
                      />
                      <Label className="ms-2"> Sub Contract</Label>
                    </FormGroup>
                  </Col>
                  <Col sm={12}>
                    <FormGroup className="mb-4">
                      <Label>
                        Description<span className="mandatory">*</span>
                      </Label>
                      <UFEditor
                        control={control}
                        placeholder="Enter the Description"
                        rows="3"
                        name={"description"}
                        invalid={!!errors.description}
                      />
                      <FormFeedback invalid>
                        {errors.description?.message}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </FormProvider>
          </ModalBody>
          <ModalFooter>
            <LoadingButton
              form={`ev-form`}
              color={"primary"}
              loading={isLoading}
              disabled={isLoading}
              type="submit"
            >
              {props?.editItem?.modal ? "Update" : "Save"}
            </LoadingButton>
          </ModalFooter>
        </>
      )}
    </>
  )
}

const EditCategory = ({ buttonLabel, className, ...props }) => {
  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)

  useEffect(() => {
    if (props?.editItem?.modal) {
      toggle()
    }
  }, [props?.editItem?.modal])

  return (
    <>
      <Link to="#" className={className} onClick={toggle}>
        {buttonLabel}
      </Link>
      <Modal isOpen={modal} toggle={toggle} backdrop={"static"} >
        <Edit
          close={() => {
            setModal(false)
            props?.onReset()
          }}
          {...props}
        />
      </Modal>
    </>
  )
}

export default EditCategory
