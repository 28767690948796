import React, { useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { useFormContext } from 'react-hook-form';
import { Row, Col } from 'reactstrap';
import { UncontrolledTooltip } from 'reactstrap';
// import { downloadAttachment } from '../../../services/common';
import { toastError } from '../../../helpers/msg_helper';
import { fileDownload } from '../../../services/Hrms/hrms';
// import { AiFillDelete } from 'react-icons/ai';
const MultiFileInput = props => {
  const {
    name,
    // label = name,
    mode = 'update',
    onlyIcon = false,
    multiple = true,
    downloadPath,
    tooltip,
    downloadAttachmentByID,
    from, //to find the url to download
  } = props;
console.log(`downloadPath`,downloadPath,tooltip,downloadAttachmentByID,from);
  const { register, unregister, setValue, watch } = useFormContext();
  const files = watch(name);

  // const files_url = watch(name + "_url")

  const validateImage = (imageList) => {
    if (imageList) {
      if (imageList.length > 1) {
        if (imageList.length > 3) {
          toastError('You can upload 3 file at a time');
          return true;
        } else {
          let selectedImage = imageList;
          // console.log('Multiple: ', props.accept);
          let isValidFile = false;
          props.accept.split(',').forEach(extension => {
            selectedImage.forEach(img => {
              // console.log('Upload FIle Name: ', img['name']);
              if (img['name'].toLowerCase().match(extension)) {
                isValidFile = true;
              }
            });
          });
          if (!isValidFile) {
            toastError(`Only ${props.accept} files are allowed`);
            return true;
          } else if (selectedImage.size > 1048576) {
            toastError('Maximum file size exceeded');
            return true;
          } else {
            return false;
          }
        }
      } else if (imageList.length === 1) {
        let selectedImage = imageList[0];
        let isValidFile = false;
        // props.accept.split(',').forEach(extension => {
        //   if (selectedImage.type.match(extension)) {
        //     isValidFile = true;
        //   }
        // });
        props.accept.split(',').forEach(extension => {
          if (selectedImage['name'].toLowerCase().match(extension)) {
            isValidFile = true;
          }
        });
        if (!isValidFile) {
          toastError(`Only ${props.accept} files are allowed`);
          return true;
        } else if (selectedImage.size > 1048576) {
          toastError('Maximum file size exceeded');
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  };
  const onDrop = useCallback(
    droppedFiles => {
      let valid = validateImage(droppedFiles, multiple);
      if (!valid) {
        /*
           This is where the magic is happening.
           Depending upon the mode we are replacing old files with new one,
           or appending new files into the old ones, and also filtering out the duplicate files. 
        */
        let newFiles =
          mode === 'update'
            ? droppedFiles
            : [...(files || []), ...droppedFiles];
        if (mode === 'append') {
          newFiles = newFiles.reduce((prev, file) => {
            const fo = Object.entries(file);
            if (
              prev.find(e => {
                const eo = Object.entries(e);
                return eo.every(
                  ([key, value], index) =>
                    key === fo[index][0] && value === fo[index][1],
                );
              })
            ) {
              return prev;
            } else {
              return [...prev, file];
            }
          }, []);
        }
        // End Magic.
        setValue(name, newFiles, { shouldValidate: true });
      }
    },
    [setValue, name, mode, files],
  );
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: props.accept,
    multiple: multiple,
  });
  useEffect(() => {
    register(name);
    return () => {
      unregister(name);
    };
  }, [register, unregister, name]);

  const deleteFile = index => {
    files.splice(index, 1);
    setValue(name, files, { shouldValidate: true });
  };

  return (
    <>
      {/*<label className=" " htmlFor={name}>
        {label}
      </label>*/}
      <div className="dropzone">
        <input {...getInputProps()} />
        <div className="upload-wrapper p-2 px-3">
          {/* <h6 className="m-0">{label}</h6> */}
          {onlyIcon && (
            <UncontrolledTooltip placement="bottom" target="doc">
              {tooltip && tooltip}
            </UncontrolledTooltip>
          )}
          {!onlyIcon && <div className="no-file-label">no file choosen</div>}
          <div className="m-0" {...getRootProps()}>
            <i className={`text-primary cloud-upload`} id={"doc"} />
          </div>
          <div className="dropzone-previews" id="file-previews">
            {Array.isArray(downloadPath) &&
              !files &&
              downloadPath?.map(e => {
                return (
                  <>
                    {' '}
                    <Row className="align-items-center">
                      <div
                        role="button"
                        onClick={() =>
                            fileDownload(downloadAttachmentByID, e, from)
                        }
                        className="blue-text downloadable-file">
                        {e?.split('/')?.slice(-1)?.join()}
                      </div>
                    </Row>
                  </>
                );
              })}

            {!!files?.length &&
              files?.map((f, i) => {
                return (
                  <div key={i + '-file'}>
                    <div className="">
                      <Row className="align-items-center">
                        {/*<Col className="col-auto">
                          <img
                            data-dz-thumbnail=""
                            height="80"
                            className="avatar-sm rounded bg-light"
                            alt={f.name}
                            src={URL.createObjectURL(f)}
                          />
                        </Col>*/}
                        <Col sm="10">
                          <div className="text-primary downloadable-file">
                            {' '}
                            {f.name}
                          </div>
                        </Col>
                        {/* <Col sm="2" className="alignEnd">
                          <span onClick={() => deleteFile(i)}>
                            <AiFillDelete />
                          </span>{' '}
                        </Col> */}
                      </Row>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default MultiFileInput;