import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { logoutUser } from "../../store/auth/login/actions"
// import { SpinnerLoader } from "../../components/Spinner"

const Logout = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(logoutUser(navigate))
  }, [])

  return <></>
}

export default Logout
