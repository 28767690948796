import { get, post } from "../../helpers/api_helper"

export const searchCentralStoreProductList = data => {
  return get(`central-store/search?${data?.params}`)
}
export const searchsiteStoreProductList = data => {
  return get(`central-store/search?${data?.params}`)
}

export const requestProduct = data => {
  return post(`central-store/request`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
}


export const requestedList = data => {
    return get(`store/request-search?${data?.params}`)
}

export const handleStatus = (id, status) => {
    return post(`central-store/request-statuschange?id=${id}&status=${status}`)
}

export const fetchStoreHistory = data => {
    return get(`store-history/request-search?${data?.params}`)
}