import ProjectManagement from "../../pages/ProjectManagement"
import Customer from "../../pages/ProjectManagement/customer"
import ProjectMasterDashboard from "../../pages/ProjectManagement/projectDashboard"
import ProjectDashboardIndex from "../../pages/ProjectManagement/projectDashboard/dashboard"
import FinanceDashboard from "../../pages/ProjectManagement/projectDashboard/financeDashboard"
import HrmsIndex from "../../pages/ProjectManagement/projectDashboard/hrms"
import ProcurementIndex from "../../pages/ProjectManagement/projectDashboard/procurement"
import ShopKeeperIndex from "../../pages/ProjectManagement/projectDashboard/shopKeeper"
import TimeKeeper from "../../pages/ProjectManagement/projectDashboard/timeKeeper"
import ProjectMaster from "../../pages/ProjectManagement/projectMaster"
import EditProjectMaster from "../../pages/ProjectManagement/projectMaster/edit"

export const projectManagementRoutes = [
  { path: "/project", component: <ProjectManagement /> },
  { path: "/project-customer", component: <Customer /> },
  { path: "/project-master", component: <ProjectMaster /> },
  { path: "/project-master/:action/:id", component: <EditProjectMaster /> },
  // { path: "/project-master/edit/:id" , component: <EditProjectMaster /> },
  { path: "/project-location", component: <ProjectManagement /> },
  { path: "/project-master-dashboard", component: <ProjectMasterDashboard /> },
  { path: "/project-dashboard/:id", component: <ProjectDashboardIndex /> },
  { path: "/project-store-dash/:id", component: <ShopKeeperIndex /> },
  { path: "/project-procurement-dash/:id", component: <ProcurementIndex /> },
  { path: "/project-hrms-dash/:id", component: <HrmsIndex /> },
  { path: "/project-timekeeper-dash/:id", component: <TimeKeeper /> },
  { path: `project-finance-dash/:id`, component: <FinanceDashboard /> },
]
