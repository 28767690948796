import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormFeedback,
  Spinner,
} from "reactstrap"
import classnames from "classnames"
import { Link } from "react-router-dom"
import LoadingButton from "../../../components/Common/LoadingButton"
import { UFInput } from "../../../components/Common/Fields/Input"
import { useForm, FormProvider } from "react-hook-form"
import { Form, FormGroup, Label } from "reactstrap"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import closeIcon from "../../../assets/icons/close.svg"
import { toastError, toastSuccess } from "../../../helpers/msg_helper"
import {
  fetchProduct,
  postInventoryProduct,
} from "../../../services/Inventory/inventory"
import MaterialList from "../../../components/Common/chooseFields/materialList"
import Group from "../../../components/Common/chooseFields/group"
import Brands from "../../../components/Common/chooseFields/brands"
import Type from "../../../components/Common/chooseFields/type"
import Unit from "../../../components/Common/chooseFields/unit"

const schema = yup.object().shape({
  description: yup.string().required("Description is Required"),
  category_id: yup.mixed().required("Category Code is Required"),
  group_id: yup.mixed().required("Group Code is Required"),
  brand_id: yup.mixed().required("Brand Code is Required"),
  type_id: yup.mixed().required("Type Code is Required"),
  unit_id: yup.mixed().required("Unit Code is Required"),
  part_number: yup.string().required("Part Number is Required"),
  price_aed: yup
    .string()
    .matches(
      /^([+]?)\d+(\.\d{1,4})?$/,
      "Price must be a valid number with up to 4 digits after the decimal point"
    )
    .required("Price is required"),
})
const Edit = ({ loading, onUpdate, close, getEditValue }) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      category_id: null,
      group_id: null,
      brand_id: null,
      type_id: null,
      unit_id: null,
      price_aed: "",
      part_number: "",
      description: "",
    },
    mode: "onChange",
    shouldFocusError: true,
  })
  // const methods = useForm({
  //   resolver: yupResolver(schema),
  //   mode: "onChange",
  //   shouldFocusError: true,
  // })
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (getEditValue) {
      reset({
        ...getEditValue?.data,
        category_id: {
          label: getEditValue?.data?.category?.category_code,
          value: getEditValue?.data?.category?.id,
        },
        group_id: {
          label: getEditValue?.data?.group?.description,
          value: getEditValue?.data?.group?.id,
        },
        brand_id: {
          label: getEditValue?.data?.brands?.description,
          value: getEditValue?.data?.brands?.id,
        },
        type_id: {
          label: getEditValue?.data?.types?.description,
          value: getEditValue?.data?.types?.id,
        },
        unit_id: {
          label: getEditValue?.data?.unit?.unit_code,
          value: getEditValue?.data?.unit?.id,
        },
      })
    }
  }, [getEditValue])

  const handleRegistration = async data => {
    try {
      setIsLoading(true)
      const updateDepartment = await postInventoryProduct({
        ...data,
        category_id: data?.category_id?.value,
        group_id: data?.group_id?.value,
        brand_id: data?.brand_id?.value,
        type_id: data?.type_id?.value,
        unit_id: data?.unit_id?.value,
      })
      toastSuccess(updateDepartment?.message)
      onUpdate()
      close()
    } catch (err) {
      toastError(err?.message)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <ModalHeader className="custom-modal-header">
        <div className="d-flex justify-content-between">
          <h5>{getEditValue?.data?.id ? "Edit Products" : "New Products"}</h5>
          <img
            src={closeIcon}
            alt=""
            onClick={close}
            width="10"
            role="button"
          />
        </div>
      </ModalHeader>
      {loading ? (
        <div
          className="px-8"
          style={{
            width: "100%",
            textAlign: "center",
            minHeight: "200px",
            paddingTop: "90px",
          }}
        >
          <Spinner
            className={classnames({
              "mx-2": true,
              "position-relative": true,
              visible: true,
            })}
            size="md"
          />
        </div>
      ) : (
        <>
          <ModalBody>
            <FormProvider>
              <Form id={`ev-form`} onSubmit={handleSubmit(handleRegistration)}>
                <Row>
                  <Col sm={6}>
                    <FormGroup className="mb-4">
                      <Label>
                        Category Code<span className="mandatory">*</span>
                      </Label>
                      <MaterialList
                        control={control}
                        name={"category_id"}
                        invalid={!!errors.category_id}
                        disabled={getEditValue?.data?.id}
                      />
                      <FormFeedback invalid>
                        {errors.category_id?.message}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm={6}>
                    <FormGroup className="mb-4">
                      <Label>
                        Group Code<span className="mandatory">*</span>
                      </Label>
                      <Group
                        control={control}
                        name={"group_id"}
                        invalid={!!errors.group_id}
                      />
                      <FormFeedback invalid>
                        {errors.group_id?.message}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm={6}>
                    <FormGroup className="mb-4">
                      <Label>
                        Brand Code<span className="mandatory">*</span>
                      </Label>
                      <Brands
                        control={control}
                        name={"brand_id"}
                        invalid={!!errors.brand_id}
                      />
                      <FormFeedback invalid>
                        {errors.brand_id?.message}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm={6}>
                    <FormGroup className="mb-4">
                      <Label>
                        Type Code<span className="mandatory">*</span>
                      </Label>
                      <Type
                        control={control}
                        name={"type_id"}
                        invalid={!!errors.type_id}
                      />
                      <FormFeedback invalid>
                        {errors.type_id?.message}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm={6}>
                    <FormGroup className="mb-4">
                      <Label>
                        Unit Code<span className="mandatory">*</span>
                      </Label>
                      <Unit
                        control={control}
                        name={"unit_id"}
                        invalid={!!errors.unit_id}
                      />
                      <FormFeedback invalid>
                        {errors.unit_id?.message}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm={6}>
                    <FormGroup className="mb-4">
                      <Label>
                        Price (AED)<span className="mandatory">*</span>
                      </Label>
                      <UFInput
                        control={control}
                        placeholder="Enter the Price"
                        rows="3"
                        name={"price_aed"}
                        invalid={!!errors.price_aed}
                      />
                      <FormFeedback invalid>
                        {errors.price_aed?.message}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm={6}>
                    <FormGroup className="mb-4">
                      <Label>
                        Part Number<span className="mandatory">*</span>
                      </Label>
                      <UFInput
                        control={control}
                        placeholder="Enter the Part Number"
                        rows="3"
                        name={"part_number"}
                        invalid={!!errors.part_number}
                      />
                      <FormFeedback invalid>
                        {errors.part_number?.message}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm={6}>
                    <FormGroup className="mb-4">
                      <Label>
                        Description<span className="mandatory">*</span>
                      </Label>
                      <UFInput
                        control={control}
                        placeholder="Enter the Description"
                        rows="3"
                        name={"description"}
                        invalid={!!errors.description}
                      />
                      <FormFeedback invalid>
                        {errors.description?.message}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </FormProvider>
          </ModalBody>
          <ModalFooter>
            <LoadingButton
              form={`ev-form`}
              color={"primary"}
              loading={isLoading}
              disabled={isLoading}
              type="submit"
            >
              {getEditValue?.data?.id ? "Update" : "Save"}
            </LoadingButton>
          </ModalFooter>
        </>
      )}
    </>
  )
}

const EditProduct = ({ buttonLabel, className, editValue, ...props }) => {
  const [modal, setModal] = useState(false)
  const [getEditValue, setGetEditValue] = useState("")

  const toggle = () => setModal(!modal)
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchProduct(editValue?.id)
      if (response?.status) {
        setGetEditValue(response)
      }
    }
    if (editValue?.id) {
      toggle()
      fetchData()
    }
  }, [editValue?.id])
  return (
    <>
      <Link to="#" className={className} onClick={toggle}>
        {buttonLabel}
      </Link>
      <Modal isOpen={modal} toggle={toggle} size="lg" backdrop={"static"}>
        <Edit
          close={() => {
            setModal(false)
            setGetEditValue("")
            props?.onReset()
          }}
          getEditValue={getEditValue}
          {...props}
        />
      </Modal>
    </>
  )
}

export default EditProduct
