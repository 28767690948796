import { yupResolver } from "@hookform/resolvers/yup"
import React, { useState, useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormFeedback,
  Spinner,
  Form,
  FormGroup,
  Label,
} from "reactstrap"
import * as yup from "yup"
import LoadingButton from "../../components/Common/LoadingButton"
import { UFInput } from "../../components/Common/Fields/Input"
import classnames from "classnames"
import closeIcon from "../../assets/icons/close.svg"
import { postUser } from "../../services/Inventory/inventory"
import { toastError, toastSuccess } from "../../helpers/msg_helper"
import RoleLists from "../../components/Common/chooseFields/rolesLists"

const schema = yup.object().shape({
  role: yup.mixed().required("Role is Required"),
  password: yup.string(),
  confirm_password: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must Match"),
  // .required("Confirm Password is Required"),
})

const Edit = ({
  loading,
  onUpdate,
  id,
  error,
  fromProfile,
  close,
  ...props
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  })
  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: true,
  })
  const [isLoading, setIsLoading] = useState(false)
  console.log(`fromProfile`, fromProfile, props)

  useEffect(() => {
    if (props?.editItem?.modal) {
      reset({
        id: fromProfile
          ? props?.editItem?.value?.id
          : props?.editItem?.value?.id,
        role: {
          label: props?.editItem?.value?.roles?.[0]?.name,
          value: props?.editItem?.value?.roles?.[0]?.id,
        },
      })
    }
  }, [props?.editItem?.modal])

  const handleUserAdd = async data => {
    console.log(`data`, data)
    try {
      setIsLoading(true)
      const createdUser = await postUser({
        ...data,
        role: data?.role?.value,
        ...(data?.password !== "" && { password: data?.password }),
        ...(data?.confirm_password !== "" && {
          confirm_password: data?.confirm_password,
        }),
      })
      toastSuccess(createdUser?.message)
      {
        !fromProfile && onUpdate()
      }
    } catch (err) {
      toastError(err?.message)
    } finally {
      setIsLoading(false)
      reset()
      close()
    }
  }

  return (
    <>
      <ModalHeader className="custom-modal-header">
        <div className="d-flex justify-content-between">
          <h5>
            {props?.editItem?.modal && fromProfile
              ? "Change Password"
              : !fromProfile
              ? "Edit User"
              : "New User"}
          </h5>{" "}
          <img
            src={closeIcon}
            alt=""
            onClick={close}
            width="10"
            role="button"
          />
        </div>
      </ModalHeader>
      {loading ? (
        <div
          className="px-8"
          style={{
            width: "100%",
            textAlign: "center",
            minHeight: "200px",
            paddingTop: "90px",
          }}
        >
          <Spinner
            className={classnames({
              "mx-2": true,
              "position-relative": true,
              visible: true,
            })}
            size="lg"
          />
        </div>
      ) : (
        <>
          <ModalBody>
            <FormProvider {...methods}>
              <Form id={`ev-form`} onSubmit={handleSubmit(handleUserAdd)}>
                {!fromProfile ? (
                  <Row className="py-3 px-2 pb-4">
                    <Col lg={6}>
                      <Row className="supplier-td-head">Name</Row>
                      <Row className="supplier-td-body capitalize-letter">
                        {`${props?.editItem?.value?.basic_details?.first_name} ${props?.editItem?.value?.basic_details?.middle_name} ${props?.editItem?.value?.basic_details?.last_name}`}
                      </Row>
                    </Col>
                    <Col lg={6}>
                      <Row className="supplier-td-head">Designation</Row>
                      <Row className="supplier-td-body capitalize-letter">
                        {
                          props?.editItem?.value?.basic_details?.designation
                            ?.name
                        }
                      </Row>
                    </Col>
                  </Row>
                ) : (
                  <></>
                )}

                <Row>
                  {!fromProfile ? (
                    <Col sm={12}>
                      <FormGroup className="mb-4">
                        <Label>
                          Role<span className="mandatory">*</span>
                        </Label>
                        <RoleLists
                          control={control}
                          placeholder="Select the Role"
                          name={"role"}
                        />
                        <FormFeedback invalid>
                          {errors.role?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                  ) : (
                    <></>
                  )}
                  <Col sm={12}>
                    <FormGroup className="mb-4">
                      <Label>Password</Label>
                      <UFInput
                        control={control}
                        placeholder="Enter the Password"
                        name={"password"}
                        invalid={!!errors.password}
                        type={"password"}
                      />
                      <FormFeedback invalid>
                        {errors.password?.message}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm={12}>
                    <FormGroup className="mb-4">
                      <Label>Confirm Password</Label>
                      <UFInput
                        control={control}
                        placeholder="Enter the Confirm Password"
                        name={"confirm_password"}
                        invalid={!!errors.confirm_password}
                        type={"password"}
                      />
                      <FormFeedback invalid>
                        {errors.confirm_password?.message}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </FormProvider>
          </ModalBody>
          <ModalFooter>
            <LoadingButton
              form={`ev-form`}
              color={"primary"}
              loading={isLoading}
              disabled={isLoading}
              type="submit"
            >
              {props?.editItem?.modal ? "Update" : "Save"}
            </LoadingButton>
          </ModalFooter>
        </>
      )}
    </>
  )
}

const EditUser = ({ buttonLabel, className, fromProfile, ...props }) => {
  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)

  useEffect(() => {
    if (props?.editItem?.modal) {
      toggle()
    }
  }, [props?.editItem?.modal])
  console.log("props?.editItem :", fromProfile, props?.editItem)
  return (
    <>
      {/* <Link to="#" className={className} onClick={toggle}>
        {buttonLabel}
      </Link> */}
      <Modal isOpen={modal} toggle={toggle} backdrop={"static"}>
        {console.log(`fromprooo`, fromProfile)}
        <Edit
          close={() => {
            setModal(false)
            props?.onReset()
          }}
          fromProfile={fromProfile}
          {...props}
        />
      </Modal>
    </>
  )
}

export default EditUser
