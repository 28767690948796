import React from "react"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import { useController } from "react-hook-form"

const PhoneInputField = ({
  control,
  name,
  label,
  rules,
  defaultValue,
  ...rest
}) => {
  const {
    field: { ref, onChange, value },
    fieldState: { invalid },
  } = useController({
    name,
    control,
    rules,
    defaultValue: defaultValue !== undefined ? defaultValue : "",
  })

  return (
    <div>
      <PhoneInput
        country={"ae"}
        inputProps={{
          name,
          id: name,
          required: true,
        }}
        value={value} // Set value from react-hook-form
        onChange={phoneNumber => {
          onChange(phoneNumber)
        }}
        {...rest}
      />
      {invalid && <span className="text-danger">{invalid}</span>}
    </div>
  )
}

export default PhoneInputField
