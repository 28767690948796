import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormFeedback,
  Spinner,
  UncontrolledPopover,
  PopoverBody,
} from "reactstrap"
import classnames from "classnames"
import { Link, useParams } from "react-router-dom"
import LoadingButton from "../../../components/Common/LoadingButton"
import { UFInput } from "../../../components/Common/Fields/Input"
import { useForm, FormProvider } from "react-hook-form"
import { Form, FormGroup, Label } from "reactstrap"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import closeIcon from "../../../assets/icons/close.svg"
import { toastError, toastSuccess } from "../../../helpers/msg_helper"
import { fetchProduct } from "../../../services/Inventory/inventory"
import EducationalDegree from "../../../components/Common/chooseFields/eduDegree"
import EducationalDepartment from "../../../components/Common/chooseFields/eduDepartment"
import SingleFileInput from "../../../components/Common/Fields/singleFileInput"
import {
  fetchEmployeeEducation,
  fetchHrmsEmployeePayrollList,
  fetchHrmsEmployeeSalaryList,
  fileDownload,
  postHrmsEmployeeEducation,
} from "../../../services/Hrms/hrms"
import action from "../../../assets/icons/action.svg"
import moment from "moment"
import EditIncrement from "./editIncrement"
import UpdateTable from "../../../components/Common/UpdatedTable"

const schema = yup.object().shape({
  // degree_id: yup.mixed().required("Degree is Required"),
  // degree_department_id: yup.mixed().required("Department is Required"),
})
const Edit = ({ loading, onUpdate, close, getEditValue }) => {
  console.log("getEditValue :", getEditValue?.data?.data)
  const { id } = useParams()
  const [certificateSts, setCertificateSts] = useState()
  const [incrementRefresh, setIncrementRefresh] = useState(0)
  const [page, setPage] = useState("")
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      degree_id: null,
      degree_department_id: null,
      certificates: null,
    },
  })
  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: true,
  })
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (getEditValue?.data?.id) {
      setCertificateSts(getEditValue?.data?.certificates)
      reset({
        ...getEditValue?.data,
        basicdetails_id: id,
        degree_id: {
          label: getEditValue?.data?.degree?.degree,
          value: getEditValue?.data?.degree?.id,
        },
        degree_department_id: {
          label: getEditValue?.data?.degree_department?.name,
          value: getEditValue?.data?.degree_department?.id,
        },
      })
    }
  }, [getEditValue])

  const handleRegistration = async data => {
    try {
      setIsLoading(true)

      const updateDepartment = await postHrmsEmployeeEducation({
        ...data,
        degree_id: data?.degree_id?.value,
        degree_department_id: data?.degree_department_id?.value,
        ...(data?.certificates?.[0] instanceof File
          ? {
              certificates: data?.certificates?.[0],
            }
          : {
              certificates: "",
            }),
      })
      onUpdate()
      close()
      toastSuccess(updateDepartment?.message)
    } catch (err) {
      toastError(err?.message)
    } finally {
      setIsLoading(false)
    }
  }

  const handleChange = (name, file) => {
    setValue(name, file)
    setCertificateSts("")
  }

  const handleIncrementUpdate = () => {
    setIncrementRefresh(incrementRefresh + 1)
  }

  const handleIncrementReset = () => {
    // setEditIncrement({
    //   modal: false,
    //   value: "",
    //   id: "",
    // })
  }

  const columns = [
    {
      label: "Payroll Code",
      renderCell: item => item?.code,
    },
    {
      label: "Total Salary",
      renderCell: item => item?.total_salary,
    },
    {
      label: "Payroll Date",
      renderCell: item => item?.payroll_date,
    },
    {
      label: "Status",
      renderCell: item => (
        <div>
          <div
            className={`font-13 ${
              item?.status == 1
                ? "status-active"
                : item?.status == 2
                ? "status-active"
                : "status-inactive"
            }`}
          >
            {item?.status == 1
              ? "Generated"
              : item?.status == 2
              ? "Paid"
              : "Process"}
          </div>
        </div>
      ),
    },
    {
      label: "",
      renderCell: item => (
        <>
          <div>
            <span id={`PopoverLegacy-${item?.id}`}>
              <img src={action} alt="" className="pointer" />
            </span>
          </div>
          <UncontrolledPopover
            placement="bottom"
            target={`PopoverLegacy-${item?.id}`}
            trigger="legacy"
            container={".modal"}
          >
            <PopoverBody>
              <div
                className={`table-popup-content`}
                onClick={() =>
                  handleDocumentDownload({
                    url: `payroll-generate/pdf-statement/${item?.id}`,
                    downloadName: `payroll`,
                  })
                }
              >
                Download
              </div>
            </PopoverBody>
          </UncontrolledPopover>
        </>
      ),
    },
  ]

  const handleDocumentDownload = ({ path, url, downloadName }) => {
    const handleDownload = async () => {
      const response = await fileDownload({
        url: url,
        path,
        downloadName: downloadName,
        extension: "pdf",
      })
    }
    handleDownload()
  }

  const handlePaginate = page => {
    setPage(page)
  }

  return (
    <>
      <ModalHeader className="custom-modal-header">
        <div className="d-flex justify-content-between">
          <h5>Payroll History</h5>
          <img
            src={closeIcon}
            alt=""
            onClick={close}
            width="10"
            role="button"
          />
        </div>
      </ModalHeader>
      {loading ? (
        <div
          className="px-8"
          style={{
            width: "100%",
            textAlign: "center",
            minHeight: "200px",
            paddingTop: "90px",
          }}
        >
          <Spinner
            className={classnames({
              "mx-2": true,
              "position-relative": true,
              visible: true,
            })}
            size="md"
          />
        </div>
      ) : (
        <>
          <ModalBody>
            <Row className="mt-2 ever-rise-table">
              <Col xs={12}>
                {getEditValue && (
                  <UpdateTable
                    className="mt-5"
                    nodes={getEditValue?.data?.data}
                    paginate={getEditValue?.data}
                    columns={columns}
                    CurrentPage={page}
                    handlePagination={handlePaginate}
                    tableWidth="25% 25% 20% 15% 15%"
                  />
                )}
              </Col>
            </Row>
          </ModalBody>
        </>
      )}
    </>
  )
}

const PayrollHistory = ({
  buttonLabel,
  className,
  editValue,
  empId,
  ...props
}) => {
  const [modal, setModal] = useState(false)
  const [getEditValue, setGetEditValue] = useState("")
  const [page, setPage] = useState("")
  console.log("comming inside salary getEditValue ", getEditValue)
  const toggle = () => setModal(!modal)

  useEffect(() => {
    console.log("comming inside salary history  useEffect")
    const fetchData = async () => {
      const response = await fetchHrmsEmployeePayrollList({
        params: `${"&searchText"}${empId ? `&employee_id=${empId}` : ""}${
          page ? `&page=${page}` : ""
        }`,
      })
      console.log("response", response)
      if (response?.status) {
        setGetEditValue(response)
      }
    }
    if (modal) {
      fetchData()
    }
  }, [modal, empId, page])

  return (
    <>
      <Link to="#" className={className} onClick={toggle}>
        {buttonLabel}
      </Link>
      <Modal isOpen={modal} toggle={toggle} size="lg" backdrop={"static"}>
        <Edit
          close={() => {
            setModal(false)
            setGetEditValue("")
            props?.onReset()
          }}
          getEditValue={getEditValue}
          {...props}
        />
      </Modal>
    </>
  )
}

export default PayrollHistory
