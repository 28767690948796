import EstimationIndex from "../../pages/Estimation"
import EditEstimation from "../../pages/Estimation/edit"
import ViewEstimations from "../../pages/Estimation/view"

export const estimationRoutes = [
  { path: "/estimation", component: <EstimationIndex /> },
  { path: "/estimation-add", component: <EditEstimation /> },
  { path: "/estimation-edit/:id", component: <EditEstimation /> },
  { path: "/estimation-view/:id", component: <ViewEstimations /> },
]
