import React from "react"

const SkeletonLoading = () => (
  <>
    <div className="d-flex justify-content-center">
      <div className="skeleton skeleton-text"></div>
    </div>
    <div className="d-flex justify-content-center">
      <div className="skeleton skeleton-text"></div>
    </div>
    <div className="d-flex justify-content-center">
      <div className="skeleton skeleton-text"></div>
    </div>
    <div className="d-flex justify-content-center">
      <div className="skeleton skeleton-text"></div>
    </div>
    <div className="d-flex justify-content-center">
      <div className="skeleton skeleton-text"></div>
    </div>
    <div className="d-flex justify-content-center">
      <div className="skeleton skeleton-text"></div>
    </div>
    <div className="d-flex justify-content-center">
      <div className="skeleton skeleton-text"></div>
    </div>
    <div className="d-flex justify-content-center">
      <div className="skeleton skeleton-text"></div>
    </div>
    <div className="d-flex justify-content-center">
      <div className="skeleton skeleton-text"></div>
    </div>
    <div className="d-flex justify-content-center">
      <div className="skeleton skeleton-text"></div>
    </div>
    <div className="d-flex justify-content-center">
      <div className="skeleton skeleton-text"></div>
    </div>
    <div className="d-flex justify-content-center">
      <div className="skeleton skeleton-text"></div>
    </div>
    <div className="d-flex justify-content-center">
      <div className="skeleton skeleton-text"></div>
    </div>
    <div className="d-flex justify-content-center">
      <div className="skeleton skeleton-text"></div>
    </div>
  </>
)

export default SkeletonLoading
