import React, { useState } from "react"

const CustomPopover = ({ content, children }) => {
  const [showPopover, setShowPopover] = useState(false)

  const handleMouseEnter = () => {
    setShowPopover(true)
  }

  const handleMouseLeave = () => {
    setShowPopover(false)
  }

  return (
    <div
      className="popover-container"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      {showPopover && <div className="popover-content">{content}</div>}
    </div>
  )
}

export default CustomPopover
