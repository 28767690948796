import React, { useState, useEffect } from "react"
import {
    Row,
    Col,
} from "reactstrap"
import { isEmpty } from "lodash"
import classnames from "classnames"
import { Link } from "react-router-dom"
import LoadingButton from "../../components/Common/LoadingButton"
import { UFInput } from "../../components/Common/Fields/Input"
import Status from "../../components/Common/chooseFields/status";
import FileInput from "../../components/Common/Fields/FileInput"
import { useForm, FormProvider } from "react-hook-form";
import { Form, FormGroup, Label } from "reactstrap";
// import { postDepartment, getDepartment } from "../../services/inventory"
import { yupResolver } from "@hookform/resolvers/yup";
import { toastError, toastSuccess } from "../../helpers/msg_helper"
import * as yup from "yup";
import closeIcon from "../../assets/icons/close.svg";
import DropDown from "./dropDown"

const Filter = () => {
    const {
        control,
        reset
    } = useForm();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        // const fetchData = async () => {
        //   const department = await getDepartment(id)
        //   if (!isEmpty(department.data) && id === department?.data?.id) {
        //     reset({
        //       ...department.data
        //     })
        //   }
        // }
        // if (id !== undefined) fetchData();
    }, []);

    const handleChange = (data) => {
    };

    return (
        <>
        <div className="d-flex justify-content-between align-items-center my-2">
        <Form id="user-form" className="filter-wrapper">
            <Row className="mx-0">
                <Col sm={2} className="pe-1 ps-0">
                    <FormGroup className="form-group-bottom">
                        <UFInput control={control} placeholder="Product Code" name={"name"} />
                    </FormGroup>
                </Col>
                <Col sm={2} className="pe-1">
                    <FormGroup className="form-group-bottom">
                        <UFInput control={control} placeholder="Part Number" name={"description"} />
                    </FormGroup>
                </Col>
                <Col sm={2} className="pe-1">
                <Status control={control} name={"continent"} onSelectChange={()=>handleChange()}/>
                </Col>
            </Row>
        </Form>
        <DropDown/>
        </div>
        </>
    )
};

export default Filter;