import React, { useEffect, useState } from "react"
import Select from "react-select"
import { Controller } from "react-hook-form"
import { materiarRequestProductlist } from "../../../services/procurement/materialrequest"

function ucfirst(str) {
  var firstLetter = str.substr(0, 1)
  return firstLetter.toUpperCase() + str.substr(1)
}

const Productcode = ({ control, name, onSelectChange, target, placeholder, projectid ,selectedProduct,index}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [fetchResponse, setFetchResponse] = useState([])
  const loadOptions = async () => {
    const data = await materiarRequestProductlist(projectid)
    setIsLoading(true)
    const response = data?.data?.map(dta => {
      return {
        label: dta?.product?.product_code && ucfirst(dta?.product?.product_code),
        value: dta.product?.id,
        ...dta,
      }
    })
    if(index==0){ 
      setFetchResponse(response);
    }else{
      const usedProduct = selectedProduct?.map((dt)=>dt?.product_id?.value != "" && dt?.product_id?.value)
      setFetchResponse(() => {
        const unUsedProduct = response.filter(item1 => {
          return !usedProduct.some(item2 => item2 === item1.value);
        });
        return unUsedProduct;
      });
    }
    setIsLoading(false)
  }
  useEffect(() => {
    if(projectid !==undefined){
      loadOptions()
    }
   
  }, [index])

  return (
    <div>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, ref, value } }) => {
          return (
            <Select
              options={fetchResponse}
              placeholder={placeholder ? placeholder : "Select"}
              isLoading={isLoading}
              inputRef={ref}
              onChange={e => {
                onChange(e)
                if (onSelectChange) onSelectChange(e)
              }}
              value={value}
              classNamePrefix="react-select"
              className="elevate-dropdown"
              menuPortalTarget={document.querySelector(
                target ? target : ".modal"
              )}
            />
          )
        }}
      />
    </div>
  )
}

export default Productcode
