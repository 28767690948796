import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  PopoverBody,
  Row,
  UncontrolledPopover,
} from "reactstrap"
import { FormProvider, useFieldArray, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { toastError, toastSuccess } from "../../helpers/msg_helper"
import {
  UFDate,
  UFInput,
  UFMonthYear,
} from "../../components/Common/Fields/Input"
import LoadingButton from "../../components/Common/LoadingButton"
import closeIcon from "../../assets/icons/close.svg"
import lightTrash from "../../assets/icons/light-trash.svg"
import uploadicon from "../../assets/icons/import.svg"
import infoDark from "../../assets/icons/info-dark.svg"
import {
  importFileData,
  sampleFileDownload,
} from "../../services/Inventory/inventory"
import {
  fetchEstimation,
  postEstimation,
} from "../../services/estimation/estimation"
import EstimationClass from "../../components/Common/chooseFields/estimateClass"
import Customer from "../../components/Common/chooseFields/customer"
import EstimationCategory from "../../components/Common/chooseFields/estimateCategory"
import EstimationProduct from "../../components/Common/chooseFields/estimationProduct"
import Location from "../../components/Common/chooseFields/location"
import EstimationSubCategory from "../../components/Common/chooseFields/estimationSubCategory"
import { fetchCustomer } from "../../services/projectManager/customer"
import Unit from "../../components/Common/chooseFields/unit"
import { useSelector } from "react-redux"
import EstimationMaterialCategory from "../../components/Common/chooseFields/EstimationMaterialDrop"
import moment from "moment"
import { isEmpty } from "lodash"

const EditEstimation = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)
  const [isUpdateLoading, setIsUpdateLoading] = useState(false)
  const [isRevisionLoading, setIsRevisionLoading] = useState(false)
  const [modal, setModal] = useState(false)
  const [action, setAction] = useState()

  const loggedUser = useSelector(state => state.Login.loggedUser)
  console.log(`loggedUser`, loggedUser)

  const [contractCheck, setContractCheck] = useState(false)
  const [manpowerCheck, setManpowerCheck] = useState(false)
  const [overheadsCheck, setOverheadsCheck] = useState(false)
  const [prelimsCheck, setPrelimssCheck] = useState(false)
  const [materialCheck, setMaterialCheck] = useState(false)
  const [clientData, setClientData] = useState()
  const [materialToManCheck, setMaterialToManCheck] = useState([false])
  const [materialList, setMaterialList] = useState([])
  const [materialsRemove, setMaterialRemove] = useState([])
  const [manPowersRemove, setManpowersRemove] = useState([])
  const [subContractsRemove, setSubContractsRemove] = useState([])
  const [projectDuration, setProjectDuration] = useState({
    fromDate: "",
    toDate: "",
  })
  console.log(`manPowersRemove`, materialToManCheck)
  const schema = yup.object().shape({
    client_code: yup.mixed().required("Customer Code is Required"),
    location_id: yup.mixed().required("Location is Required"),
    project_name: yup.string().required("Project Name is Required"),
    bid_date: yup.mixed().required("Bid Date is Required"),
    // estimate_created_by: yup
    //   .string()
    //   .required("Estimate Created By is Required"),

    ...(materialCheck && {
      estimation_materials: yup.array().of(
        yup.object().shape({
          class_id: yup.mixed().required("Class Code is Required"),
          category_id: yup.mixed().required("Category Code is Required"),
          product_id: yup.mixed().required("Product Code is Required"),
          quantity: yup
            .string()
            .matches(/^([+]?)?[0-9]+$|^$/, "Quantity must be only digits")
            .required("Quantity is required"),
          price: yup
            .string()
            .matches(
              /^([+]?)\d+(\.\d{1,4})?$/,
              "Price must be a valid number with up to 4 digits after the decimal point"
            )
            .required("Price is required"),
        })
      ),
    }),
    ...(overheadsCheck && {
      overheads_per: yup
        .string()
        .matches(
          /^([+]?)\d+(\.\d{1,2})?$/,
          "Price must be a valid number with up to 2 digits after the decimal point"
        )
        .required("Price is required"),
    }),
    ...(prelimsCheck && {
      preliminary_per: yup
        .string()
        .matches(
          /^([+]?)\d+(\.\d{1,2})?$/,
          "Price must be a valid number with up to 2 digits after the decimal point"
        )
        .required("Price is required"),
    }),
    //   {
    //   estimation_overheads: yup.array().of(
    //     yup.object().shape({
    //       class_id: yup.mixed().required("Class Code is Required"),
    //       category_id: yup.mixed().required("Category Code is Required"),
    //       description: yup.string().required("Description is required"),
    //       price: yup
    //         .string()
    //         .matches(/^([+]?)?[0-9]+$|^$/, "Price must be only digits")
    //         .required("Price is required"),
    //     })
    //   ),
    // }
    ...(contractCheck && {
      estimation_subcontracts: yup.array().of(
        yup.object().shape({
          class_id: yup.mixed().required("Class Code is Required"),
          category_id: yup.mixed().required("Category Code is Required"),
          duration: yup.string().required("Duration is required"),
          description: yup.string().required("Description is required"),
          price: yup
            .string()
            .matches(
              /^([+]?)\d+(\.\d{1,4})?$/,
              "Price must be a valid number with up to 4 digits after the decimal point"
            )
            .required("Price is required"),
        })
      ),
    }),
    ...(manpowerCheck && {
      estimation_manpowers: yup.array().of(
        yup.object().shape({
          class_id: yup.mixed().required("Class Code is Required"),
          category_id: yup.mixed().required("Category Code is Required"),
          no_of_labours: yup.string().required("Number Of Labours is required"),
          price: yup
            .string()
            .matches(
              /^([+]?)\d+(\.\d{1,4})?$/,
              "Price must be a valid number with up to 4 digits after the decimal point"
            )
            .required("Price is required"),
          // price: yup
          //   .string()
          //   .matches(/^([+]?)?[0-9]+$|^$/, "Price must be only digits")
          //   .required("Price is required"),
        })
      ),
    }),
    ...(materialList?.length && {
      product_manpowers: yup.array().of(
        yup.object().shape({
          class_id: yup.mixed().required("Class Code is Required"),
          category_id: yup.mixed().required("Category Code is Required"),
          no_of_labours: yup.string().required("Number Of Labours is required"),
          price: yup
            .string()
            .matches(
              /^([+]?)\d+(\.\d{1,4})?$/,
              "Price must be a valid number with up to 4 digits after the decimal point"
            )
            .required("Price is required"),
        })
      ),
    }),
  })

  const toggle = () => {
    setModal(!modal)
    setFile()
    setFileName()
  }

  const [file, setFile] = useState()
  const [fileName, setFileName] = useState()

  // let fromDate = ""
  // let toDate = ""

  const onFileChange = event => {
    setFileName(event)
    setFile(event?.target?.files?.[0])
  }

  const handleImport = () => {
    const importFile = async () => {
      try {
        const response = await importFileData({
          importfile: file,
          url: "estimation/import",
        })
        toastSuccess(response?.message)
        setModal(false)
      } catch (err) {
        toastError(err?.message)
      }
    }
    importFile()
  }

  const handleSampleFile = () => {
    const handleCommonExport = async () => {
      const response = await sampleFileDownload("download/estimation.xlsx")
    }
    handleCommonExport()
  }

  const {
    control,
    handleSubmit,
    watch,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: true,
    defaultValues: {
      project_name: "",
      client_code: null,
      client_name: "",
      location_id: null,
      bid_date: null,
      client_contact_person: "",
      contact_number: "",
      estimate_created_by: "",
      tax: 0,
      preliminary_per: 0,
      discount: 0,
      preliminary_val: 0,
      year_month: null,
      estimation_manpowers: [
        {
          class_id: null,
          category_id: null,
          description: "",
          no_of_labours: "",
          price: "",
        },
      ],
      estimation_subcontracts: [
        {
          class_id: null,
          category_id: null,
          description: "",
          duration: "",
          price: "",
          unit: null,
        },
      ],
      estimation_materials: [
        {
          class_id: null,
          category_id: null,
          quantity: "",
          product_id: null,
          price: "",
          unit: null,
          is_manpower_material: false,
          id: "",
        },
      ],
      // estimation_overheads: {
      overheads_per: loggedUser?.settings?.overheads_per,
      preliminary_per: loggedUser?.settings?.preliminary_per,
      // },
    },
  })

  const {
    fields: manpowerFields,
    append: manpowerAppend,
    remove: manpowerRemove,
  } = useFieldArray({
    control,
    name: "estimation_manpowers",
  })
  const {
    fields: contractFields,
    append: contractAppend,
    remove: contractRemove,
  } = useFieldArray({
    control,
    name: "estimation_subcontracts",
  })
  const {
    fields: materialFields,
    append: materialAppend,
    remove: materialRemove,
  } = useFieldArray({
    control,
    name: "estimation_materials",
  })
  const {
    fields: overheadsFields,
    append: overheadsAppend,
    remove: overheadsRemove,
  } = useFieldArray({
    control,
    name: "estimation_overheads",
  })

  const handleCodeChange = () => {
    const { client_code } = getValues()
    fetchCodeData(client_code?.value)
    setValue("tax", loggedUser?.settings?.tax)
    setValue("preliminary_per", loggedUser?.settings?.preliminary_per)
  }
  const fetchCodeData = async idData => {
    const response = await fetchCustomer(idData)
    if (idData == response.data?.id) {
      setClientData(response.data)
      const previousDatas = getValues()
      reset({
        ...previousDatas,
        client_name: response.data?.customer_name,
        client_contact_person: response.data?.contact_person,
        contact_number: response.data?.customer_number,
      })
    }
  }

  useEffect(() => {
    const handleFetchEstimation = async () => {
      const response = await fetchEstimation(id)
      const materialArray = response?.data?.estimation_material?.map(dt => ({
        class_id: { value: dt?.class_id?.id, label: dt?.class_id?.class_code },
        category_id: {
          value: dt?.category_id?.id,
          label: dt?.category_id?.category_code,
        },
        quantity: dt?.quantity,
        product_id: {
          value: dt?.products?.id,
          label: dt?.products?.product_code,
        },
        price: dt?.price,
        unit: dt?.unit?.unit_code,
        unit_id: dt?.unit?.id,
        // unit: {
        //   value: dt?.unit?.id,
        //   id: dt?.unit?.id,
        //   label: dt?.unit?.unit_code,
        // },
        is_manpower_material: dt?.is_manpower_material == 1 ? true : false,
        //  setMaterialToManCheck(
        //   [...materialToManCheck],
        //   dt?.is_manpower_material == 1 ? true : false
        // ),
        description: dt?.products?.description,
        id: dt?.id,
        table_id: dt?.id,
      }))
      // const overheadsArray = response?.data?.estimation_overhead?.map(dt => ({
      //   class_id: { value: dt?.class_id?.id, label: dt?.class_id?.class_code },
      //   category_id: {
      //     value: dt?.category_id?.id,
      //     label: dt?.category_id?.category_code,
      //   },
      //   description: dt?.description,
      //   price: dt?.price,
      //   id: dt?.id,
      // }))
      const contractArray = response?.data?.estimation_subcontract?.map(dt => ({
        class_id: {
          value: dt?.class_id?.id,
          label: dt?.class_id?.class_code,
        },
        category_id: {
          value: dt?.category_id?.id,
          label: dt?.category_id?.category_code,
        },
        duration: dt?.duration,
        description: dt?.description,
        price: dt?.price,
        unit: {
          value: dt?.unit?.id,
          id: dt?.unit?.id,
          label: dt?.unit?.unit_code,
        },
        id: dt?.id,
        table_id: dt?.id,
      }))
      const withMaterialId = []
      const withoutMaterialId = []

      response?.data?.estimation_manpower.forEach(obj => {
        if (obj.hasOwnProperty("material_id") && obj.material_id !== null) {
          withMaterialId.push(obj)
        } else {
          withoutMaterialId.push(obj)
        }
      })

      const manpowerArray = withoutMaterialId?.map(dt => ({
        class_id: { value: dt?.class_id?.id, label: dt?.class_id?.class_code },
        category_id: {
          value: dt?.category_id?.id,
          label: dt?.category_id?.category_code,
        },
        description: dt?.category_id?.description,
        no_of_labours: dt?.no_of_labours,
        price: dt?.price,
        id: dt?.id,
        table_id: dt?.id,
      }))
      const materialManpowerArr = withMaterialId?.map(dt => ({
        class_id: { value: dt?.class_id?.id, label: dt?.class_id?.class_code },
        category_id: {
          value: dt?.category_id?.id,
          label: dt?.category_id?.category_code,
        },
        description: dt?.category_id?.description,
        no_of_labours: dt?.no_of_labours,
        price: dt?.price,
        material_id: dt?.material_id,
        id: dt?.id,
      }))
      setMaterialList(materialManpowerArr)
      console.log(`materialArray`, materialArray)
      reset({
        ...response?.data?.client_code,
        client_code: {
          label: response?.data?.client_code?.customers?.customer_code,
          value: response?.data?.client_code?.customers?.id,
        },
        location_id: {
          label: response?.data?.client_code?.location?.name,
          value: response?.data?.client_code?.location?.id,
        },
        year_month: [
          moment(response?.data?.client_code?.from_date).format(
            "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)"
          ),
          moment(response?.data?.client_code?.to_date).format(
            "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)"
          ),
        ],
        product_manpowers: withMaterialId?.length && materialManpowerArr,
        estimation_materials: response?.data?.estimation_material?.length
          ? materialArray
          : [
              {
                class_id: null,
                category_id: null,
                quantity: "",
                product_id: null,
                price: "",
                id: "",
              },
            ],
        overheads_per: response?.data?.overheads_per,
        preliminary_per: response?.data?.preliminary_per,
        // tax: response?.data?.tax,
        // estimation_overheads: response?.data?.estimation_overhead?.length
        //   ? overheadsArray
        //   : [
        //       {
        //         class_id: null,
        //         category_id: null,

        //         description: "",
        //         price: "",
        //       },
        //     ],
        estimation_subcontracts: response?.data?.estimation_subcontract?.length
          ? contractArray
          : [
              {
                class_id: null,
                category_id: null,
                description: "",
                duration: "",
                price: "",
              },
            ],
        estimation_manpowers: withoutMaterialId?.length
          ? manpowerArray
          : [
              {
                class_id: null,
                category_id: null,
                no_of_labours: "",
                description: "",
                price: "",
              },
            ],
      })
      setMaterialToManCheck(materialArray?.map(dt => dt?.is_manpower_material))
      setClientData(response?.data?.client_code)
      if (response?.data?.estimation_material?.length) {
        setMaterialCheck(true)
      }
      if (response?.data?.estimation_manpower?.length) {
        setManpowerCheck(true)
      }
      if (response?.data?.estimation_subcontract?.length) {
        setContractCheck(true)
      }
      if (response?.data?.client_code?.overheads_per != null) {
        setOverheadsCheck(true)
      }
      if (response?.data?.client_code?.preliminary_per != null) {
        setPrelimssCheck(true)
      }
    }
    if (id) {
      handleFetchEstimation()
    }
  }, [id])

  console.log("overheads_per :", watch("overheads_per"))

  const handleRegistration = async data => {
    // let data = getValues()
    console.log(`handleUpdate1`, data, action)

    if (
      materialCheck ||
      overheadsCheck ||
      contractCheck ||
      manpowerCheck ||
      prelimsCheck
    ) {
      try {
        // setIsLoading(true)
        action === 1 ? setIsLoading(true) : setIsRevisionLoading(true)
        const materialArray = data?.estimation_materials?.map((dt, i) => ({
          class_id: dt?.class_id?.value,
          category_id: dt?.category_id?.value,
          quantity: dt?.quantity,
          product_id: dt?.product_id?.value,
          price: dt?.price,
          unit: dt?.unit_id,
          is_manpower_material: materialToManCheck[i] ? 1 : 0,
          id: dt?.id ? dt?.id : "",
        }))
        // const overheadsArray = data?.estimation_overheads?.map(dt => ({
        //   class_id: dt?.class_id?.value,
        //   category_id: dt?.category_id?.value,

        //   description: dt?.description,
        //   price: dt?.price,
        //   id: dt?.id ? dt?.id : "",
        // }))
        const contractArray = data?.estimation_subcontracts?.map(dt => ({
          class_id: dt?.class_id?.value,
          category_id: dt?.category_id?.value,
          duration: dt?.duration,
          description: dt?.description,
          price: dt?.price,
          unit: dt?.unit?.value,
          id: dt?.id ? dt?.id : "",
        }))
        const manpowerArray = data?.estimation_manpowers?.map(dt => ({
          class_id: dt?.class_id?.value,
          category_id: dt?.category_id?.value,
          no_of_labours: dt?.no_of_labours,
          price: dt?.price,
          id: dt?.id ? dt?.id : "",
        }))
        const productManpowersArray = data?.product_manpowers
          ? data?.product_manpowers?.map(dt => ({
              class_id: dt?.class_id?.value,
              category_id: dt?.category_id?.value,
              no_of_labours: dt?.no_of_labours,
              price: dt?.price,
              material_id: dt?.material_id?.id,
              id: dt?.id ? dt?.id : "",
            }))
          : []
        const updateEstimation = await postEstimation({
          id: data?.id,
          bid_date: moment(data?.bid_date).format("YYYY-MM-DD"),
          removed_estimation_materials: materialsRemove,
          removed_estimation_manpowers: manPowersRemove,
          removed_estimation_subcontracts: subContractsRemove,
          client_code: data?.client_code?.value,
          location_id: data?.location_id?.value,
          project_name: data?.project_name,
          client_name: data?.client_name,
          client_contact_person: data?.client_contact_person,
          contact_number: data?.contact_number,
          estimate_created_by: loggedUser?.data?.name,
          tax: data?.tax ? data?.tax : 0,
          discount: data?.discount ? data?.discount : 0,
          amount: calulation("estimate_value").toFixed(2),
          from_date: projectDuration?.fromDate,
          to_date: projectDuration?.toDate,
          preliminary_val: data?.preliminary_val ? data?.preliminary_val : 0,
          ...(materialCheck && { estimation_materials: materialArray }),
          ...(overheadsCheck && { overheads_per: data?.overheads_per }),
          ...(prelimsCheck && { preliminary_per: data?.preliminary_per }),
          ...(contractCheck && { estimation_subcontracts: contractArray }),
          ...(manpowerCheck && {
            estimation_manpowers: [...manpowerArray, ...productManpowersArray],
          }),
          action: action,
          // ...(materialList?.length && {
          //   product_manpowers: productManpowersArray,
          // }),
        })
        navigate("/estimation")
        toastSuccess(updateEstimation?.message)
      } catch (err) {
        toastError(err?.message)
      } finally {
        setIsLoading(false)
        // setIsUpdateLoading(false)
        setIsRevisionLoading(false)
      }
    } else {
      toastError("Any one estimation should be added")
    }
  }

  const handleContractCheck = dt => {
    setContractCheck(!dt)
    if (!dt == false) {
      const getValue = getValues()
      reset({
        ...getValue,
        estimation_subcontracts: [
          {
            class_id: null,
            category_id: null,
            description: "",
            duration: "",
            price: "",
          },
        ],
      })
    }
  }
  const handleManpowerCheck = dt => {
    setManpowerCheck(!dt)
    if (!dt == false) {
      const getValue = getValues()
      reset({
        ...getValue,
        estimation_manpowers: [
          {
            class_id: null,
            category_id: null,
            no_of_labours: "",
            description: "",
            price: "",
          },
        ],
      })
    }
  }

  const handleMaterialCheck = dt => {
    setMaterialCheck(!dt)
    if (!dt == false) {
      const getValue = getValues()
      reset({
        ...getValue,
        estimation_materials: [
          {
            class_id: null,
            category_id: null,
            quantity: "",
            product_id: null,
            price: "",
          },
        ],
      })
    }
  }

  const handleMaterialToManCheck = (e, index) => {
    // const updateManCheck = [...materialToManCheck, ]
    setMaterialToManCheck(materialToMan => {
      const updatedValues = [...materialToMan]
      updatedValues[index] = !materialToMan[index]
      return updatedValues
    })
    setManpowerCheck(true)

    const data = getValues()
    const materials = data.estimation_materials[index]

    const materialWithId = {
      chk_id: index,
      class_id: {
        label: id
          ? materials?.class_id?.label
          : materials?.class_id?.class_code,
        value: id ? materials?.class_id?.value : materials?.class_id?.id,
      },
      // category_id: {
      //   label: id
      //     ? materials?.category_id?.label
      //     : materials?.category_id?.category_code,
      //   value: id ? materials?.category_id?.value : materials?.category_id?.id,
      // },
      // description: id
      //   ? materials?.description
      //   : materials?.category_id?.description,
      material_id: materials?.product_id,
    }
    if (!materialToManCheck[index] && e != false) {
      setMaterialList(prevMaterialList => [...prevMaterialList, materialWithId])
      reset({
        ...data,
        product_manpowers: [...materialList, materialWithId],
      })
    } else {
      const removedData = materialList?.filter((dt, index) => {
        return dt?.material_id?.id !== materialWithId?.material_id?.value
      })
      setMaterialList(removedData)

      reset({
        ...data,
        product_manpowers: [...removedData],
      })
    }
  }

  const handleMaterialRemove = e => {
    setMaterialRemove(prevMaterialsRemove => [...prevMaterialsRemove, e])
  }
  const handleManPowerRemove = e => {
    setManpowersRemove(prevMaterialsRemove => [...prevMaterialsRemove, e])
  }
  const handleSubContractsRemove = e => {
    setSubContractsRemove(prevMaterialsRemove => [...prevMaterialsRemove, e])
  }
  console.log(`materialRemove`, materialsRemove)
  const handleOverheadsCheck = dt => {
    setOverheadsCheck(!dt)
    // console.log(`getValue`, dt)

    if (dt == false) {
      // const getValue = getValues()
      setValue("overheads_per", loggedUser?.settings?.overheads_per)
      // reset({
      //   // ...getValue,
      //   overheads_per: loggedUser?.settings?.overheads_per,
      // })
    }
  }

  const handlePrelimsCheck = dt => {
    console.log(`handlePrelimsCheck`, dt)
    setPrelimssCheck(!dt)
    if (dt == false) {
      setValue("preliminary_per", loggedUser?.settings?.preliminary_per)
    }
    setValue("preliminary_val", 0)
  }

  const calulation = type => {
    const materialAmount = watch("estimation_materials")
    const manpowerAmount = watch("estimation_manpowers")
    const contractAmount = watch("estimation_subcontracts")
    const overheadsAmount = watch("overheads_per")
    const taxAmount = watch("tax")
    const discountAmount = watch("discount")
    const materialManpowerWatch = watch("product_manpowers")
    const prelimsBWatch = watch("preliminary_val")

    console.log(`prelimsBWatch`, prelimsBWatch)

    switch (type) {
      case "material": {
        return materialAmount?.reduce((a, b) => Number(b.price) + a, 0) || 0
      }
      // case "materialMan": {
      //   return materialManpowerWatch?.reduce((a, b) => Number(b.price) + a, 0) || 0
      // }
      case "materialMan": {
        if (Array.isArray(materialManpowerWatch)) {
          return materialManpowerWatch.reduce((a, b) => Number(b.price) + a, 0)
        } else {
          return 0
        }
      }
      case "manpower": {
        return manpowerAmount?.reduce((a, b) => Number(b.price) + a, 0) || 0
      }
      case "contract": {
        return contractAmount?.reduce((a, b) => Number(b.price) + a, 0) || 0
      }
      case "overheads": {
        return (
          ((calulation("material") +
            calulation("manpower") +
            calulation("contract") +
            calulation("prelims") +
            (prelimsCheck ? calulation("materialMan") : 0)) *
            Number(watch("overheads_per"))) /
          100
        )
        // overheadsAmount?.reduce((a, b) => Number(b.price) + a, 0) || 0
      }
      case "prelims": {
        return (
          ((calulation("material") +
            calulation("manpower") +
            calulation("materialMan")) *
            Number(watch("preliminary_per"))) /
          100
        )
      }
      // case "subtotal": {
      //   return (
      //     calulation("material") +
      //     calulation("manpower") +
      //     calulation("materialMan") +
      //     calulation("contract") +
      //     {overheadsCheck && calulation("overheads")}
      //   )
      // }
      case "subtotal": {
        let subtotal =
          calulation("material") +
          calulation("manpower") +
          calulation("materialMan") +
          calulation("contract") +
          Number(prelimsBWatch)

        if (overheadsCheck) {
          subtotal += calulation("overheads")
        }
        if (prelimsCheck) {
          subtotal += calulation("prelims")
        }

        return subtotal
      }
      case "estimate_value": {
        return (
          calulation("subtotal") +
          calulation("subtotal") * (Number(loggedUser?.settings?.tax) / 100) -
          calulation("subtotal") * (Number(discountAmount) / 100)
        )
      }
      default:
        return 0
    }
  }

  console.log(
    `calulation("overheads")`,
    calulation("overheads"),
    calulation("prelims")
  )

  const [productPrice, setProductPrice] = useState([])

  const handleCategoryChange = (e, i) => {
    setValue(`estimation_manpowers.${i}.description`, e?.description)
  }
  const handleManpowerCategoryChange = (e, i) => {
    setValue(`product_manpowers.${i}.description`, e?.description)
  }
  const handleOverheadsCatChange = (e, i) => {
    setValue(`estimation_overheads.${i}.description`, e?.description)
  }
  const handleContractCatChange = (e, i) => {
    setValue(`estimation_subcontracts.${i}.description`, e?.description)
  }

  const handleProductChange = (e, index) => {
    const getData = getValues()
    setValue(
      `estimation_materials.${index}.price`,
      getData?.estimation_materials?.[index]?.quantity
        ? getData?.estimation_materials?.[index]?.quantity * e?.price_aed
        : e?.price_aed
    )
    setValue(`estimation_materials.${index}.description`, e?.description)
    setValue(
      `estimation_materials.${index}.unit`,
      e?.unit?.unit_code
      // value: e?.unit?.id,
    )
    setValue(`estimation_materials.${index}.unit_id`, e?.unit?.id)
    if (productPrice.length <= index) {
      setProductPrice(prevPrices => [
        ...prevPrices,
        ...Array(index - prevPrices.length + 1).fill(null),
      ])
    }
    setProductPrice(prevPrices => {
      const updatedPrices = [...prevPrices]
      updatedPrices[index] = e?.price_aed
      return updatedPrices
    })
  }

  const handleQuantityChange = (e, index) => {
    setValue(
      `estimation_materials.${index}.price`,
      productPrice?.[index]
        ? Number(
            Number(e?.target?.value) * Number(productPrice?.[index])
          ).toFixed(4)
        : 0
    )
  }

  const handleClassChange = (e, i) => {
    setValue(`estimation_materials.${i}.category_id`, null)
    setValue(`estimation_materials.${i}.product_id`, null)
    setValue(`estimation_materials.${i}.price`, "")
    setValue(`estimation_materials.${i}.description`, "")
    setValue(`estimation_materials.${i}.unit`, null)
    setValue(`estimation_materials.${i}.quantity`, "")
  }

  const handleCategoryReset = (e, i) => {
    setValue(`estimation_materials.${i}.product_id`, null)
    setValue(`estimation_materials.${i}.price`, "")
    setValue(`estimation_materials.${i}.description`, "")
    setValue(`estimation_materials.${i}.unit`, null)
    setValue(`estimation_materials.${i}.quantity`, "")
  }

  const handleManpowerClass = i => {
    setValue(`estimation_manpowers.${i}.category_id`, null)
    setValue(`estimation_manpowers.${i}.description`, "")
    setValue(`estimation_manpowers.${i}.no_of_labours`, "")
    setValue(`estimation_manpowers.${i}.price`, "")
  }
  const handleManpowerMaterialClass = i => {
    setValue(`product_manpowers.${i}.category_id`, null)
    setValue(`product_manpowers.${i}.description`, "")
    setValue(`product_manpowers.${i}.no_of_labours`, "")
    setValue(`product_manpowers.${i}.price`, "")
  }

  const handleContractClass = i => {
    setValue(`estimation_subcontracts.${i}.category_id`, null)
    setValue(`estimation_subcontracts.${i}.description`, "")
    setValue(`estimation_subcontracts.${i}.unit`, null)
    setValue(`estimation_subcontracts.${i}.duration`, "")
    setValue(`estimation_subcontracts.${i}.price`, "")
  }

  const handleOverheadsClass = i => {
    setValue(`estimation_overheads.${i}.category_id`, null)
    setValue(`estimation_overheads.${i}.description`, "")
    setValue(`estimation_overheads.${i}.price`, "")
  }

  // const handleUpdate = async data => {
  //   console.log(`handleUpdate`, getValues())
  // }

  const handledate = data => {
    let fromDate = ""
    let toDate = ""
    fromDate = !isEmpty(data) ? moment(data[0]).format("YYYY-MM-DD") : ""
    toDate = !isEmpty(data)
      ? moment(data[1]).endOf("month").format("YYYY-MM-DD")
      : ""
    setProjectDuration({ fromDate, toDate })
    console.log(data, "toDate")
  }

  const scrollContainer = document.querySelector(".scroll-container")

  function handleScroll() {
    console.log(`scrolling`)
  }

  if (scrollContainer) {
    scrollContainer.addEventListener("scroll", handleScroll)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <FormProvider>
            <Form
              id={`supplier-form`}
              onSubmit={handleSubmit(handleRegistration)}
            >
              <div className="d-flex justify-content-between me-2">
                <Breadcrumbs
                  title="Estimation"
                  breadcrumbObject={[
                    { title: "Estimation", link: "/estimation" },
                    {
                      title: id ? "Edit Estimation" : "Create Estimation",
                      link: id ? `estimation-edit/${id}` : "estimation-add",
                    },
                  ]}
                />
                <div>
                  <LoadingButton
                    form={`supplier-form`}
                    color={"primary"}
                    loading={isLoading}
                    disabled={isLoading}
                    className={"px-2 mb-3 btn btn-primary"}
                    type="submit"
                    onClick={() => setAction(1)}
                  >
                    {id ? "Update" : "Save"}
                  </LoadingButton>
                  {/* <LoadingButton
                    type="button"
                    color="primary"
                    className="btn-sm btn-rounded waves-effect waves-light mb-3 me-2"
                    onClick={() => handleUpdate(1)}
                    loading={isUpdateLoading}
                  >
                    {id ? "Update" : "Save"}
                  </LoadingButton> */}
                  {id &&
                  (loggedUser?.permission?.some(
                    permissionItem =>
                      permissionItem?.name === "Convert to revision"
                  ) ||
                    loggedUser?.data?.id === 1) ? (
                    <LoadingButton
                      type="submit"
                      color="primary"
                      className="btn-sm btn-rounded waves-effect waves-light mb-3 ms-2"
                      onClick={() => setAction(2)}
                      loading={isRevisionLoading}
                    >
                      Create Revision
                    </LoadingButton>
                  ) : (
                    <></>
                  )}
                  {/* <LoadingButton
                    form={`supplier-form`}
                    color={"primary"}
                    loading={isLoading}
                    disabled={isLoading}
                    className={"px-2 mb-3 btn btn-primary"}
                    type="submit"
                  >
                    Update Revision
                  </LoadingButton> */}
                </div>
              </div>
              <Container fluid className="ps-lg-0 sticky-wrapper">
                <Card className="invoice-main-card scroll-container">
                  <div className="p-3 pt-4">
                    <Row className="mb-4 mt-2">
                      <Col sm={4}>
                        <FormGroup className="mb-4">
                          <Label>
                            Customer Code<span className="mandatory">*</span>
                          </Label>
                          <Customer
                            control={control}
                            placeholder="Select"
                            name={"client_code"}
                            invalid={!!errors.client_code}
                            target={"body"}
                            onSelectChange={handleCodeChange}
                            disabled={id ? true : false}
                          />
                          <FormFeedback invalid>
                            {errors.client_code?.message}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                      {clientData && (
                        <Col sm={4}>
                          <FormGroup className="mb-4">
                            <Label>
                              Customer Name<span className="mandatory"></span>
                            </Label>
                            <UFInput
                              control={control}
                              placeholder="Enter the Name"
                              name={"client_name"}
                              invalid={!!errors.client_name}
                              disabled={true}
                            />
                            <FormFeedback invalid>
                              {errors.client_name?.message}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                      )}
                      {clientData && (
                        <Col sm={4}>
                          <FormGroup className="mb-4">
                            <Label>
                              Customer Contact Person
                              <span className="mandatory"></span>
                            </Label>
                            <UFInput
                              control={control}
                              placeholder="Enter the Customer Contact Person"
                              name={"client_contact_person"}
                              invalid={!!errors.client_contact_person}
                              disabled={true}
                            />
                            <FormFeedback invalid>
                              {errors.client_contact_person?.message}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                      )}
                      {clientData && (
                        <Col sm={4}>
                          <FormGroup className="mb-4">
                            <Label>
                              Contact Number
                              <span className="mandatory"></span>
                            </Label>
                            <UFInput
                              control={control}
                              placeholder="Enter the Contact Number"
                              name={"contact_number"}
                              invalid={!!errors.contact_number}
                              disabled={true}
                            />
                            <FormFeedback invalid>
                              {errors.contact_number?.message}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                      )}
                      <Col sm={4}>
                        <FormGroup className="mb-4">
                          <Label>
                            Project Name<span className="mandatory">*</span>
                          </Label>
                          <UFInput
                            control={control}
                            placeholder="Enter the Project Name"
                            name={"project_name"}
                            invalid={!!errors.project_name}
                          />
                          <FormFeedback invalid>
                            {errors.project_name?.message}
                          </FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col sm={4}>
                        <FormGroup className="mb-4">
                          <Label>
                            Project location<span className="mandatory">*</span>
                          </Label>
                          <Location
                            control={control}
                            placeholder="Enter the Project Location"
                            name={"location_id"}
                            invalid={!!errors.location_id}
                            target={"body"}
                          />
                          <FormFeedback invalid>
                            {errors.location_id?.message}
                          </FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col sm={4}>
                        <FormGroup className="mb-4">
                          <Label>
                            Bid Date
                            <span className="mandatory">*</span>
                          </Label>
                          <UFDate
                            control={control}
                            placeholder="Select Date"
                            name={"bid_date"}
                            invalid={!!errors.bid_date}
                          />
                          <FormFeedback invalid>
                            {errors.bid_date?.message}
                          </FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col sm={4}>
                        <FormGroup className="mb-4">
                          <Label>
                            Project Duration
                            <span className="mandatory">*</span>
                          </Label>
                          <UFMonthYear
                            control={control}
                            name={"year_month"}
                            invalid={!!errors.year_month}
                            month={true}
                            placeholder={"Select Date"}
                            // defaultValue={moment().format("YYYY-MM-DD")}
                            mode={"range"}
                            onDateChange={e => handledate(e)}
                          />
                          <FormFeedback invalid>
                            {errors.year_month?.message}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>
                    <hr className="m-0" />
                    <Row className="py-4 mt-4 estimation-stickey">
                      <Col lg={1}>
                        <h5>Select</h5>
                      </Col>
                      <Col
                        lg={"auto"}
                        onClick={() => handleMaterialCheck(materialCheck)}
                      >
                        <FormGroup>
                          <input
                            type="checkbox"
                            className="ever-rise-checkbox"
                            name={"is_material"}
                            checked={materialCheck}
                          />
                          <Label className="ms-2">Material</Label>
                        </FormGroup>
                      </Col>
                      <Col
                        lg={"auto"}
                        onClick={() => handleManpowerCheck(manpowerCheck)}
                      >
                        <FormGroup>
                          <input
                            type="checkbox"
                            className="ever-rise-checkbox"
                            name={"is_manpower"}
                            checked={manpowerCheck}
                          />
                          <Label className="ms-2">Manpower</Label>
                        </FormGroup>
                      </Col>
                      <Col
                        lg={"auto"}
                        onClick={() => handleContractCheck(contractCheck)}
                      >
                        <FormGroup>
                          <input
                            type="checkbox"
                            className="ever-rise-checkbox"
                            name={"is_subcontract"}
                            checked={contractCheck}
                          />
                          <Label className="ms-2">Subcontract</Label>
                        </FormGroup>
                      </Col>
                      <Col
                        lg={"auto"}
                        onClick={() => handlePrelimsCheck(prelimsCheck)}
                      >
                        <FormGroup>
                          <input
                            type="checkbox"
                            className="ever-rise-checkbox"
                            name={"is_prelims"}
                            checked={prelimsCheck}
                          />

                          <Label className="ms-2">Prelims</Label>
                        </FormGroup>
                      </Col>
                      <Col
                        lg={"auto"}
                        onClick={() => handleOverheadsCheck(overheadsCheck)}
                      >
                        <FormGroup>
                          <input
                            type="checkbox"
                            className="ever-rise-checkbox"
                            name={"is_overheads"}
                            checked={overheadsCheck}
                          />

                          <Label className="ms-2">Overheads</Label>
                        </FormGroup>
                      </Col>
                    </Row>

                    {materialCheck && (
                      <>
                        <Row className="mb-4">
                          <h5>Material Details</h5>
                        </Row>
                        <Row className="mb-4">
                          <Col lg={12}>
                            <table className="estimation-table mb-4">
                              <thead>
                                <tr>
                                  <th className="wdt-15">C Code</th>
                                  <th className="wdt-20">Cat Code</th>
                                  <th className="wdt-20">Product</th>
                                  <th>Description</th>
                                  <th className="wdt-7">Unit</th>
                                  <th className="wdt-5">Qty</th>
                                  <th className="wdt-8">Price(AED)</th>
                                  <th>
                                    <img
                                      src={infoDark}
                                      className="info-icon"
                                      id={`PopoverLegacy-head`}
                                    />
                                    <UncontrolledPopover
                                      placement="bottom"
                                      target={`PopoverLegacy-head`}
                                      trigger="hover"
                                    >
                                      <PopoverBody>
                                        <div className="table-popup-content">
                                          <div className="">
                                            Check to add manpower for particular
                                            Product
                                          </div>
                                        </div>
                                      </PopoverBody>
                                    </UncontrolledPopover>
                                  </th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {materialFields.map((item, index) => (
                                  <tr key={`materialFields${index}`}>
                                    <td>
                                      {/* <FormGroup> */}
                                      <EstimationClass
                                        control={control}
                                        placeholder="Class"
                                        name={`estimation_materials.${index}.class_id`}
                                        type={"materials"}
                                        onSelectChange={e =>
                                          handleClassChange(e, index)
                                        }
                                      />
                                      {/* <FormFeedback invalid>
                                          {
                                            errors?.estimation_materials?.[
                                              index
                                            ]?.class_id?.message
                                          }
                                        </FormFeedback>{" "}
                                      </FormGroup> */}
                                    </td>
                                    <td>
                                      {/* <FormGroup> */}
                                      <EstimationMaterialCategory
                                        control={control}
                                        placeholder="Category"
                                        name={`estimation_materials.${index}.category_id`}
                                        type={"materials"}
                                        classCode={watch(
                                          `estimation_materials.${index}.class_id`
                                        )}
                                        onSelectChange={e =>
                                          handleCategoryReset(e, index)
                                        }
                                      />
                                      {/* <FormFeedback invalid>
                                          {
                                            errors?.estimation_materials?.[
                                              index
                                            ]?.category_id?.message
                                          }
                                        </FormFeedback>{" "}
                                      </FormGroup> */}
                                    </td>
                                    <td>
                                      {/* <FormGroup> */}
                                      <EstimationProduct
                                        control={control}
                                        placeholder="Product"
                                        name={`estimation_materials.${index}.product_id`}
                                        catCode={watch(
                                          `estimation_materials.${index}.category_id`
                                        )}
                                        onSelectChange={e =>
                                          handleProductChange(e, index)
                                        }
                                        selectedProduct={watch(
                                          `estimation_materials`
                                        )}
                                        index={index}
                                      />
                                      {/* <FormFeedback invalid>
                                          {
                                            errors?.estimation_materials?.[
                                              index
                                            ]?.product_id?.message
                                          }
                                        </FormFeedback>
                                      </FormGroup> */}
                                    </td>
                                    <td>
                                      {/* <FormGroup> */}
                                      <UFInput
                                        control={control}
                                        placeholder="Description"
                                        name={`estimation_materials.${index}.description`}
                                      />
                                      {/* <FormFeedback invalid>
                                          {
                                            errors?.estimation_materials?.[
                                              index
                                            ]?.description?.message
                                          }
                                        </FormFeedback>{" "}
                                      </FormGroup> */}
                                    </td>
                                    <td>
                                      {/* <FormGroup> */}
                                      <UFInput
                                        control={control}
                                        placeholder="Unit"
                                        name={`estimation_materials.${index}.unit`}
                                        disabled={true}
                                        className="disable-unit"
                                      />
                                      {/* <FormFeedback invalid>
                                          {
                                            errors?.estimation_materials?.[
                                              index
                                            ]?.unit?.message
                                          }
                                        </FormFeedback>{" "}
                                      </FormGroup> */}
                                      {/* <Unit
                                        control={control}
                                        placeholder="Unit"
                                        name={`estimation_materials.${index}.unit`}
                                        disabled={true}
                                      /> */}
                                    </td>
                                    <td>
                                      {/* <FormGroup> */}
                                      <UFInput
                                        control={control}
                                        placeholder="Qty"
                                        name={`estimation_materials.${index}.quantity`}
                                        onChange={e =>
                                          handleQuantityChange(e, index)
                                        }
                                      />
                                      {/* <FormFeedback invalid>
                                          {
                                            errors?.estimation_materials?.[
                                              index
                                            ]?.quantity?.message
                                          }
                                        </FormFeedback>{" "} */}
                                      {/* </FormGroup> */}
                                    </td>

                                    <td>
                                      {/* <FormGroup> */}
                                      <UFInput
                                        control={control}
                                        placeholder="Price"
                                        name={`estimation_materials.${index}.price`}
                                      />
                                      {/* <FormFeedback invalid>
                                          {
                                            errors?.estimation_materials?.[
                                              index
                                            ]?.price?.message
                                          }
                                        </FormFeedback>{" "}
                                      </FormGroup> */}
                                    </td>
                                    <td>
                                      <div
                                        className="pe-2"
                                        onClick={e =>
                                          handleMaterialToManCheck(e, index)
                                        }
                                      >
                                        <input
                                          type="checkbox"
                                          className="ever-rise-checkbox"
                                          name={`estimation_materials.${index}.is_manpower_material`}
                                          checked={materialToManCheck[index]}
                                          disabled={
                                            watch(
                                              `estimation_materials.${index}.product_id`
                                            )
                                              ? false
                                              : true
                                          }
                                        />
                                      </div>
                                    </td>
                                    <td className="ps-0">
                                      <div className="pb-1 px-2 d-flex justify-content-center">
                                        <img
                                          src={lightTrash}
                                          alt="light-trash"
                                          className="trash-img pointer"
                                          onClick={() => {
                                            materialRemove(index)
                                            handleMaterialToManCheck(
                                              false,
                                              index
                                            )
                                            handleMaterialRemove(item?.table_id)
                                          }}
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            <Col
                              lg="12"
                              xs={12}
                              md={2}
                              sm={12}
                              className="d-flex justify-content-between"
                            >
                              <Button
                                color="secondary"
                                className="addButton mt-2"
                                onClick={() => {
                                  materialAppend({
                                    class_id: "",
                                    category_id: "",
                                    quantity: "",
                                    product_id: "",
                                    price: "",
                                    is_manpower_material: false,
                                  })
                                  // setMaterialToManCheck([
                                  //   ...materialToManCheck,
                                  //   false,
                                  // ])
                                }}
                              >
                                Add Row
                              </Button>
                              <Row className="w-50 d-flex align-items-center">
                                <Col lg={6} className="text-end dark-text">
                                  Subtotal(AED)
                                </Col>
                                <Col lg={6} className="ps-5 dark-text">
                                  {calulation("material").toFixed(2)}
                                </Col>
                              </Row>
                            </Col>
                          </Col>
                        </Row>
                      </>
                    )}
                    {manpowerCheck && (
                      <>
                        <Row className="mb-4">
                          <h5>Manpower</h5>
                        </Row>
                        <Row className="mb-4">
                          <Col lg={12}>
                            <table className="estimation-table mb-4">
                              <thead>
                                <tr>
                                  <th className="wdt-20">Class </th>
                                  <th className="wdt-20">Category </th>
                                  <th>Description</th>
                                  <th>No.of Hours</th>
                                  <th>Price(AED)</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {materialList?.length ? (
                                  materialList?.map((dt, i) => (
                                    <tr>
                                      <td>
                                        <EstimationClass
                                          control={control}
                                          placeholder="Class"
                                          name={`product_manpowers.${i}.class_id`}
                                          type={"manpowers"}
                                          disabled={true}
                                          onSelectChange={e =>
                                            handleManpowerMaterialClass(i)
                                          }
                                        />
                                      </td>
                                      <td>
                                        <EstimationCategory
                                          control={control}
                                          placeholder="Catagory"
                                          name={`product_manpowers.${i}.category_id`}
                                          // disabled={true}
                                          type={"manpowers"}
                                          classCode={watch(
                                            `product_manpowers.${i}.class_id`
                                          )}
                                          onSelectChange={e =>
                                            handleManpowerCategoryChange(e, i)
                                          }
                                        />
                                      </td>
                                      <td>
                                        <UFInput
                                          className="product-manpowers"
                                          control={control}
                                          placeholder="Description"
                                          name={`product_manpowers.${i}.description`}
                                        />
                                      </td>
                                      <td>
                                        <UFInput
                                          control={control}
                                          placeholder="No.of Hours"
                                          name={`product_manpowers.${i}.no_of_labours`}
                                        />
                                      </td>
                                      <td>
                                        <UFInput
                                          control={control}
                                          placeholder="Price"
                                          name={`product_manpowers.${i}.price`}
                                        />
                                      </td>
                                      <td>
                                        <img
                                          src={infoDark}
                                          className="info-icon"
                                          id={`PopoverLegacy-${i}`}
                                        />
                                        <UncontrolledPopover
                                          placement="bottom"
                                          target={`PopoverLegacy-${i}`}
                                          trigger="hover"

                                          // placement="top"
                                        >
                                          <PopoverBody>
                                            <div className="table-popup-content">
                                              <div className="">
                                                Manpower for{" "}
                                                {dt?.material_id?.description}
                                              </div>
                                            </div>
                                          </PopoverBody>
                                        </UncontrolledPopover>
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <></>
                                )}
                                {manpowerFields.map((item, index) => (
                                  <tr key={`estimation_manpowers${index}`}>
                                    <td>
                                      <EstimationClass
                                        control={control}
                                        placeholder="Select"
                                        name={`estimation_manpowers.${index}.class_id`}
                                        type={"manpowers"}
                                        onSelectChange={e =>
                                          handleManpowerClass(index)
                                        }
                                      />
                                    </td>
                                    <td>
                                      <EstimationCategory
                                        control={control}
                                        placeholder="Select"
                                        name={`estimation_manpowers.${index}.category_id`}
                                        type={"manpowers"}
                                        classCode={watch(
                                          `estimation_manpowers.${index}.class_id`
                                        )}
                                        onSelectChange={e =>
                                          handleCategoryChange(e, index)
                                        }
                                      />
                                    </td>
                                    <td>
                                      <UFInput
                                        control={control}
                                        placeholder="Description"
                                        name={`estimation_manpowers.${index}.description`}
                                      />
                                    </td>
                                    <td>
                                      <UFInput
                                        control={control}
                                        placeholder="No.of Hours"
                                        name={`estimation_manpowers.${index}.no_of_labours`}
                                      />
                                    </td>
                                    <td>
                                      <UFInput
                                        control={control}
                                        placeholder="Price"
                                        name={`estimation_manpowers.${index}.price`}
                                      />
                                    </td>
                                    <td>
                                      <img
                                        src={lightTrash}
                                        alt="light-trash"
                                        className="trash-img pointer"
                                        onClick={() => {
                                          manpowerRemove(index)
                                          handleManPowerRemove(item?.table_id)
                                        }}
                                      />
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            <Col
                              lg="12"
                              xs={12}
                              md={2}
                              sm={12}
                              className="d-flex justify-content-between"
                            >
                              <Button
                                color="secondary"
                                className="addButton mt-2"
                                onClick={() =>
                                  manpowerAppend({
                                    class_id: "",
                                    category_id: "",
                                    no_of_labours: "",
                                    description: "",
                                    price: "",
                                  })
                                }
                              >
                                Add Row
                              </Button>
                              <Row className="w-50 d-flex align-items-center">
                                <Col lg={6} className="text-end dark-text">
                                  Subtotal(AED)
                                </Col>
                                <Col lg={6} className="ps-5 dark-text">
                                  {(
                                    calulation("manpower") +
                                    calulation("materialMan")
                                  ).toFixed(2)}
                                </Col>
                              </Row>
                            </Col>
                          </Col>
                        </Row>
                      </>
                    )}
                    {contractCheck && (
                      <>
                        <Row className="mb-4">
                          <h5>SubContract</h5>
                        </Row>
                        <Row className="mb-4">
                          <Col lg={12}>
                            <table className="estimation-table mb-4">
                              <thead>
                                <tr>
                                  <th className="wdt-15">Class </th>
                                  <th className="wdt-20">Category </th>
                                  <th>Description</th>
                                  <th>Unit</th>
                                  <th className="wdt-5">Qty</th>
                                  <th className="wdt-15">Price(AED)</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {contractFields.map((item, index) => (
                                  <tr key={`estimation_subcontracts${index}`}>
                                    <td>
                                      <EstimationClass
                                        control={control}
                                        placeholder="Class"
                                        name={`estimation_subcontracts.${index}.class_id`}
                                        type={"subcontracts"}
                                        onSelectChange={e =>
                                          handleContractClass(index)
                                        }
                                      />
                                    </td>
                                    <td>
                                      <EstimationCategory
                                        control={control}
                                        placeholder="Category"
                                        name={`estimation_subcontracts.${index}.category_id`}
                                        type={"subcontracts"}
                                        classCode={watch(
                                          `estimation_subcontracts.${index}.class_id`
                                        )}
                                        onSelectChange={e =>
                                          handleContractCatChange(e, index)
                                        }
                                      />
                                    </td>
                                    <td>
                                      <UFInput
                                        control={control}
                                        placeholder="Description"
                                        name={`estimation_subcontracts.${index}.description`}
                                      />
                                    </td>
                                    <td>
                                      <Unit
                                        control={control}
                                        placeholder={"Unit"}
                                        name={`estimation_subcontracts.${index}.unit`}
                                      />
                                    </td>
                                    <td>
                                      <UFInput
                                        control={control}
                                        placeholder="Qty"
                                        name={`estimation_subcontracts.${index}.duration`}
                                      />
                                    </td>
                                    <td>
                                      <UFInput
                                        control={control}
                                        placeholder="Price"
                                        name={`estimation_subcontracts.${index}.price`}
                                      />
                                    </td>
                                    <td>
                                      <img
                                        src={lightTrash}
                                        alt="light-trash"
                                        className="trash-img pointer"
                                        onClick={() => {
                                          contractRemove(index)
                                          handleSubContractsRemove(
                                            item?.table_id
                                          )
                                        }}
                                      />
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            <Col
                              lg="12"
                              xs={12}
                              md={2}
                              sm={12}
                              className="d-flex justify-content-between"
                            >
                              <Button
                                color="secondary"
                                className="addButton mt-2"
                                onClick={() =>
                                  contractAppend({
                                    class_id: "",
                                    category_id: "",
                                    description: "",
                                    duration: "",
                                    price: "",
                                  })
                                }
                              >
                                Add Row
                              </Button>
                              <Row className="w-50 d-flex align-items-center">
                                <Col lg={6} className="text-end dark-text">
                                  Subtotal(AED)
                                </Col>
                                <Col lg={6} className="ps-5 dark-text">
                                  {calulation("contract").toFixed(2)}
                                </Col>
                              </Row>
                            </Col>
                          </Col>
                        </Row>
                      </>
                    )}
                    {prelimsCheck && (
                      <>
                        <Row className="mb-4">
                          <h5>Prelims</h5>
                        </Row>
                        <Row className="mb-4">
                          <Col lg={12}>
                            {/* <table className="estimation-table mb-4">
                            <thead>
                              <tr>
                                <th className="wdt-20">Class </th>
                                <th className="wdt-20">Category </th>
                                <th>Description</th>
                                <th className="wdt-15">Price(AED)</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {overheadsFields.map((item, index) => (
                                <tr key={`estimation_overheads${index}`}>
                                  <td>
                                    <EstimationClass
                                      control={control}
                                      placeholder="Select"
                                      name={`estimation_overheads.${index}.class_id`}
                                      type={"overheads"}
                                      onSelectChange={e =>
                                        handleOverheadsClass(index)
                                      }
                                    />
                                  </td>
                                  <td>
                                    <EstimationCategory
                                      control={control}
                                      placeholder="Select"
                                      name={`estimation_overheads.${index}.category_id`}
                                      classCode={watch(
                                        `estimation_overheads.${index}.class_id`
                                      )}
                                      onSelectChange={e =>
                                        handleOverheadsCatChange(e, index)
                                      }
                                    />
                                  </td>
                                  <td>
                                    <UFInput
                                      control={control}
                                      placeholder="Description"
                                      name={`estimation_overheads.${index}.description`}
                                    />
                                  </td>
                                  <td>
                                    <UFInput
                                      control={control}
                                      placeholder="Price"
                                      name={`estimation_overheads.${index}.price`}
                                    />
                                  </td>
                                  <td>
                                    <img
                                      src={lightTrash}
                                      alt="light-trash"
                                      className="trash-img pointer"
                                      onClick={() => overheadsRemove(index)}
                                    />
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table> */}

                            {/* <table className="estimation-table mb-4">
                            <thead>
                              <tr>
                                <th className="wdt-20">Class </th>
                                <th className="wdt-20">Category </th>
                                <th>Description</th>
                                <th className="wdt-15">Price(AED)</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody></tbody>
                          </table> */}
                            {/* <Col
                            lg="12"
                            xs={12}
                            md={2}
                            sm={12}
                            className="d-flex justify-content-end"
                          > */}
                            <table className="estimation-table mb-4">
                              <thead>
                                <tr>
                                  <th className="wdt-70"></th>
                                  <th>Prelims (%)</th>
                                  <th>Subtotal(AED)</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="border-0 wdt-70 d-flex align-items-center justify-content-center">{`(Material + Manpower + SubContract)*${watch(
                                    "preliminary_per"
                                  )}/100`}</td>
                                  <td>
                                    <UFInput
                                      control={control}
                                      placeholder="Price"
                                      name={`preliminary_per`}
                                    />
                                  </td>
                                  <td>
                                    {" "}
                                    {(
                                      ((calulation("material") +
                                        calulation("manpower") +
                                        calulation("materialMan")) *
                                        Number(watch("preliminary_per"))) /
                                      100
                                    ).toFixed(2)}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            {/* <Row className="pb-4 d-flex align-items-center">
                              <Col sm={9} className="text-end">
                                Prelims (%)
                              </Col>
                              <Col sm={3} className="ps-5">
                                <UFInput
                                  control={control}
                                  placeholder="Price"
                                  name={`preliminary_per`}
                                />
                              </Col>
                            </Row> */}
                            {/* </Col> */}

                            {/* <Col
                              lg={12}
                              xs={12}
                              md={12}
                              sm={12}
                              className="d-flex justify-content-end"
                            > */}
                            {/* <Button
                              color="secondary"
                              className="addButton mt-2"
                              onClick={() =>
                                overheadsAppend({
                                  class_id: "",
                                  category_id: "",
                                  description: "",

                                  price: "",
                                })
                              }
                            >
                              Add div
                            </Button> */}
                            {/* <Row className="w-50 d-flex align-items-center">
                                <Col lg={6} className="text-end dark-text">
                                  Subtotal(AED)
                                </Col>
                                <Col lg={6} className="ps-5 dark-text">
                                  {(
                                    ((calulation("material") +
                                      calulation("manpower") +
                                      calulation("materialMan")) *
                                      Number(watch("preliminary_per"))) /
                                    100
                                  ).toFixed(2)}
                                </Col>
                              </Row>
                            </Col> */}
                          </Col>
                        </Row>
                      </>
                    )}
                    {overheadsCheck && (
                      <>
                        <Row className="mb-4">
                          <h5>Overheads</h5>
                        </Row>
                        <Row className="mb-4">
                          <Col lg={12}>
                            <table className="estimation-table mb-4">
                              <thead>
                                <tr>
                                  <th className="wdt-70"></th>
                                  <th>Overheads (%)</th>
                                  <th>Subtotal(AED)</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="border-0 wdt-70 d-flex align-items-center justify-content-center">{`(Material + Manpower + SubContract + Prelims )*${watch(
                                    "overheads_per"
                                  )}/100`}</td>
                                  <td>
                                    <UFInput
                                      control={control}
                                      placeholder="Price"
                                      name={`overheads_per`}
                                    />
                                  </td>
                                  <td>
                                    {calulation("overheads").toFixed(2)}
                                    {/* {(
                                      ((calulation("material") +
                                        calulation("manpower") +
                                        calulation("materialMan") +
                                        (prelimsCheck
                                          ? calulation("prelims")
                                          : 0)) *
                                        Number(watch("overheads_per"))) /
                                      100
                                    ).toFixed(2)} */}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            {/* <table className="estimation-table mb-4">
                              <thead>
                                <tr>
                                  <th className="wdt-20">Class </th>
                                  <th className="wdt-20">Category </th>
                                  <th>Description</th>
                                  <th className="wdt-15">Price(AED)</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {overheadsFields.map((item, index) => (
                                  <tr key={`estimation_overheads${index}`}>
                                    <td>
                                      <EstimationClass
                                        control={control}
                                        placeholder="Select"
                                        name={`estimation_overheads.${index}.class_id`}
                                        type={"overheads"}
                                        onSelectChange={e =>
                                          handleOverheadsClass(index)
                                        }
                                      />
                                    </td>
                                    <td>
                                      <EstimationCategory
                                        control={control}
                                        placeholder="Select"
                                        name={`estimation_overheads.${index}.category_id`}
                                        classCode={watch(
                                          `estimation_overheads.${index}.class_id`
                                        )}
                                        onSelectChange={e =>
                                          handleOverheadsCatChange(e, index)
                                        }
                                      />
                                    </td>
                                    <td>
                                      <UFInput
                                        control={control}
                                        placeholder="Description"
                                        name={`estimation_overheads.${index}.description`}
                                      />
                                    </td>
                                    <td>
                                      <UFInput
                                        control={control}
                                        placeholder="Price"
                                        name={`estimation_overheads.${index}.price`}
                                      />
                                    </td>
                                    <td>
                                      <img
                                        src={lightTrash}
                                        alt="light-trash"
                                        className="trash-img pointer"
                                        onClick={() => overheadsRemove(index)}
                                      />
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table> */}

                            {/* <table className="estimation-table mb-4">
                              <thead>
                                <tr>
                                  <th className="wdt-20">Class </th>
                                  <th className="wdt-20">Category </th>
                                  <th>Description</th>
                                  <th className="wdt-15">Price(AED)</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody></tbody>
                            </table> */}
                            {/* <Col
                              lg="12"
                              xs={12}
                              md={2}
                              sm={12}
                              className="d-flex justify-content-end"
                            > */}
                            {/* <Row className="pb-4 d-flex align-items-center">
                              <Col sm={9} className="text-end">
                                Overheads (%)
                              </Col>
                              <Col sm={3} className="ps-5">
                                <UFInput
                                  control={control}
                                  placeholder="Price"
                                  name={`overheads_per`}
                                />
                              </Col>
                            </Row> */}
                            {/* </Col> */}

                            {/* <Col
                              lg="12"
                              xs={12}
                              md={2}
                              sm={12}
                              className="d-flex justify-content-end"
                            > */}
                            {/* <Button
                                color="secondary"
                                className="addButton mt-2"
                                onClick={() =>
                                  overheadsAppend({
                                    class_id: "",
                                    category_id: "",
                                    description: "",

                                    price: "",
                                  })
                                }
                              >
                                Add div
                              </Button> */}
                            {/* <Row className="w-50 d-flex align-items-center">
                                <Col lg={6} className="text-end dark-text">
                                  Subtotal(AED)
                                </Col>
                                <Col lg={6} className="ps-5 dark-text">
                                  {(
                                    ((calulation("material") +
                                      calulation("manpower") +
                                      calulation("materialMan")) *
                                      Number(watch("overheads_per"))) /
                                    100
                                  ).toFixed(2)}
                                </Col>
                              </Row>
                            </Col> */}
                          </Col>
                        </Row>
                      </>
                    )}
                    <hr className="m-0" />
                    {!prelimsCheck && (
                      <Row className="d-flex align-items-center py-3 mt-5">
                        <Col lg={9} className="text-end dark-text">
                          Prelims B
                        </Col>
                        <Col lg={3} className="ps-5 dark-text ">
                          <UFInput
                            control={control}
                            placeholder="Enter the Prelims"
                            name={"preliminary_val"}
                            invalid={!!errors.preliminary_val}
                          />
                          {/* {(
                          (calulation("material") +
                            calulation("manpower") +
                            calulation("materialMan") +
                            calulation("contract") *
                              Number(watch("preliminary_per"))) /
                          100
                        ).toFixed(2)} */}
                          {/* {(
                          ((calulation("material") +
                            calulation("manpower") +
                            calulation("materialMan") +
                            calulation("contract")) *
                            Number(watch("preliminary_per"))) /
                          100
                        ).toFixed(2)} */}
                        </Col>
                      </Row>
                    )}
                    <Row className="d-flex align-items-center py-3">
                      <Col lg={9} className="text-end dark-text">
                        Net Total(AED)
                      </Col>
                      <Col lg={3} className="ps-5 dark-text ">
                        {calulation("subtotal").toFixed(2)}
                      </Col>
                    </Row>
                    <Row className="d-flex align-items-center py-3">
                      <Col lg={9} className="text-end dark-text">
                        Discount({watch("discount")}%)
                      </Col>
                      <Col lg={3} className="ps-5 dark-text ">
                        <UFInput
                          control={control}
                          placeholder="Enter the Discount"
                          name={"discount"}
                          invalid={!!errors.discount}
                        />
                      </Col>
                    </Row>
                    <Row className="d-flex align-items-center py-3">
                      <Col lg={9} className="text-end dark-text">
                        Tax ({loggedUser?.settings?.tax}%)
                      </Col>
                      {/* <Col lg={3} className="ps-5 dark-text ">
                        <UFInput
                          control={control}
                          placeholder="Enter the Tax"
                          name={"tax"}
                          invalid={!!errors.tax}
                        />
                      </Col> */}
                      <Col lg={3} className="ps-5 dark-text ">
                        {(
                          (calulation("subtotal") *
                            Number(loggedUser?.settings?.tax)) /
                          100
                        ).toFixed(2)}
                      </Col>
                    </Row>

                    <Row className="d-flex align-items-center py-3">
                      <Col lg={9} className="text-end dark-text">
                        Estimate Value(AED)
                      </Col>
                      <Col lg={3} className="ps-5 dark-text ">
                        {calulation("estimate_value").toFixed(2)}
                      </Col>
                    </Row>
                  </div>
                </Card>
              </Container>
            </Form>
          </FormProvider>
        </div>
      </div>
      <Modal isOpen={modal} toggle={toggle} backdrop={"static"}>
        <ModalHeader className="custom-modal-header">
          <div className="d-flex justify-content-between">
            <h5>{"Bulk Upload"}</h5>
            <img
              src={closeIcon}
              alt=""
              onClick={() => setModal(false)}
              width="10"
              role="button"
            />
          </div>
        </ModalHeader>

        <ModalBody>
          <div className="d-flex p-5 my-3">
            <Col className="file-import-div me-3 ">
              <label htmlFor="upload" className="m-0 pointer">
                <input
                  type="file"
                  id="upload"
                  name="upload"
                  accept=".xls,.xlsx"
                  onChange={event => {
                    onFileChange(event)
                  }}
                />
                {fileName ? (
                  <span>{fileName?.target?.files?.[0]?.name}</span>
                ) : (
                  <>
                    <img
                      src={uploadicon}
                      alt="Upload Excel"
                      className="upload-icon me-2 "
                    />
                    <span className="upload">Upload&nbsp;</span>
                    <span>the file</span>
                  </>
                )}
              </label>
            </Col>
            {/* <button
              className="btn btn-primary"
              type="submit"
              onClick={handleImport}
            >
              Import
            </button> */}
            <LoadingButton
              color={"primary"}
              loading={isLoading}
              disabled={isLoading}
              className="btn btn-primary"
              type="submit"
              onClick={handleImport}
            >
              Import
            </LoadingButton>
          </div>
        </ModalBody>
        <ModalFooter className="bulk-upload-footer">
          <Row className="d-flex justify-content-center">
            <Col lg={8} className="text-center">
              Please download the sample file, add items, price etc & upload it
              back to create your Documents
            </Col>
          </Row>
          <Row
            className="fw-bold d-flex justify-content-center w-100 pointer"
            onClick={() => handleSampleFile()}
          >
            Download Sample File &gt;
          </Row>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
}

export default EditEstimation
