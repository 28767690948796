import React, { useEffect, useState } from "react"
import Select from "react-select"
import { Controller } from "react-hook-form"
import { getLeaveManageList } from "../../../services/Settings/dropdown"

function ucfirst(str) {
  var firstLetter = str.substr(0, 1)
  return firstLetter.toUpperCase() + str.substr(1)
}

const LeaveManage = ({
  control,
  name,
  onSelectChange,
  target,
  placeholder,
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [fetchResponse, setFetchResponse] = useState([])

  const loadOptions = async () => {
    const data = await getLeaveManageList()
    setIsLoading(false)
    const response = data?.data?.map(dta => {
      return {
        label: dta?.leave_req_code && ucfirst(dta.leave_req_code),
        value: dta.id,
        ...dta,
      }
    })

    setFetchResponse(response)
  }
  useEffect(() => {
    loadOptions()
  }, [])

  return (
    <div>
      <Controller
        name={name}
        control={control}
        render={({
          field: { onChange, ref, value },
          fieldState: { invalid },
        }) => {
          return (
            <Select
              options={fetchResponse}
              placeholder={placeholder ? placeholder : "Select"}
              isLoading={isLoading}
              inputRef={ref}
              invalid={invalid}
              onChange={e => {
                onChange(e)
                if (onSelectChange) onSelectChange(e)
              }}
              value={value}
              classNamePrefix="react-select"
              className="elevate-dropdown"
              menuPortalTarget={document.querySelector(
                target ? target : ".modal"
              )}
            />
          )
        }}
      />
    </div>
  )
}

export default LeaveManage
