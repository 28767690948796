import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  PopoverBody,
  Row,
  UncontrolledPopover,
} from "reactstrap"
import UpdateTable from "../../../components/Common/UpdatedTable"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import action from "../../../assets/icons/action.svg"
import { toastError, toastSuccess } from "../../../helpers/msg_helper"
import { FormProvider, useForm } from "react-hook-form"
import { UFInput } from "../../../components/Common/Fields/Input"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import LoadingButton from "../../../components/Common/LoadingButton"
import {
  fetchHrmsSponsor,
  fetchSponsorEdit,
  fileDownload,
  postHrmsSponsor,
  updateSponsorStatus,
} from "../../../services/Hrms/hrms"
import HrmsSearch from "../search"
import FileInput from "../../../components/Common/Fields/FileInput"
import SingleFileInput from "../../../components/Common/Fields/singleFileInput"

const schema = yup.object().shape({
  sponsored_by: yup.string().required("Sponsor Name is Required"),
  license_no: yup.string().required("License Number is Required"),
  // importfile: yup
  //   .mixed()
  //   .required("Document is Required")
  //   .test(
  //     "fileType",
  //     "Only files are allowed",
  //     value =>
  //       value &&
  //       value[0].type === "application/pdf, image/png, image/jpg, image/jpeg"
  //   ),
})
const Sponsors = () => {
  document.title = "Sponsors | Everise"

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
  })
  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: true,
    defaultValues: {
      sponsored_by: "",
      license_no: "",
      importfile: "",
    },
  })
  const [isLoading, setIsLoading] = useState(false)
  const [certificateSts, setCertificateSts] = useState("")
  const [page, setPage] = useState("")

  const [list, setList] = useState()
  const [count, setCount] = useState(0)
  const [search, setSearch] = useState("")
  const [edit, setEdit] = useState("")

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchHrmsSponsor({
        params: `${search ? `&searchText=${search}` : "&searchText"}${
          page ? `&page=${page}` : ""
        }`,
      })
      setList(response)
    }
    fetchData()
  }, [count, search, page])

  useEffect(() => {
    if (edit.id) {
      reset({ ...edit })
      setCertificateSts(edit?.documents_path?.split("/").pop())
    }
  }, [edit.id])

  console.log(" certificate :", certificateSts)
  const handleAdd = async data => {
    try {
      setIsLoading(true)
      const createdSeries = await postHrmsSponsor({
        ...data,
        importfile: data?.importfile?.[0],
      })
      setCertificateSts("")
      toastSuccess(createdSeries?.message)
      reset({
        sponsored_by: "",
        license_no: "",
        importfile: "",
      })

      setCount(count + 1)
    } catch (err) {
      toastError(err?.message)
    } finally {
      setIsLoading(false)
      setEdit("")
    }
  }
  console.log("watch (in page ):", watch("importfile"))
  const handleEdit = async item => {
    const response = await fetchSponsorEdit(item)
    setEdit(response?.data)
  }
  const handleDocumentDownload = ({ path, url, downloadName }) => {
    const handleDownload = async () => {
      const response = await fileDownload({
        url: url,
        path,
        downloadName: downloadName,
      })
    }
    handleDownload()
  }
  const handleStatus = async id => {
    try {
      const response = await updateSponsorStatus(id)
      toastSuccess(response?.message)
      setCount(count + 1)
    } catch (err) {
      toastError(err?.message)
    }
  }

  const columns = [
    {
      label: "Sponsored By",
      renderCell: item => (
        <div className="capitalize-letter">{item?.sponsored_by}</div>
      ),
    },
    {
      label: "License No",
      renderCell: item => (
        <div className="uppercase-letter">{item?.license_no}</div>
      ),
    },
    // {
    //   label: "Documents",
    //   renderCell: item =>
    //     item?.documents_path ? item?.documents_path?.split("/").pop() : "-",
    // },
    {
      label: "Status",
      renderCell: item => (
        <div>
          <div
            className={`font-13 ${
              item?.status === 1 ? "status-active" : "status-inactive"
            }`}
          >
            {item?.status === 1 ? "Active" : "Inactive"}
          </div>
        </div>
      ),
    },
    {
      label: "Action",
      renderCell: item => (
        <>
          <div>
            <span id={`PopoverLegacy-${item?.id}`}>
              <img src={action} alt="" className="pointer" />
            </span>
          </div>
          <UncontrolledPopover
            placement="bottom"
            target={`PopoverLegacy-${item?.id}`}
            trigger="legacy"
          >
            <PopoverBody>
              {item?.status === 1 && (
                <div
                  className="table-popup-content"
                  onClick={() => handleEdit(item?.id)}
                >
                  Edit
                </div>
              )}
              <div
                className={`${
                  item?.status === 1 ? "popover-border" : ""
                } table-popup-content`}
                onClick={() => handleStatus(item?.id)}
              >
                {item?.status === 1 ? "Deactivate" : "Activate"}
              </div>
              <div
                className={`table-popup-content popover-border`}
                onClick={() =>
                  handleDocumentDownload({
                    id: item?.id,
                    path: item?.documents_path?.split("/").pop(),
                    url: `sponsors-download/${item?.id}`,
                    downloadName: `${item?.documents_path?.split("/").pop()}`,
                  })
                }
              >
                Download
              </div>
            </PopoverBody>
          </UncontrolledPopover>
        </>
      ),
    },
  ]

  const handlePaginate = page => {
    setPage(page)
  }

  const handleSearchChange = data => {
    setSearch(data)
    setPage(1)
  }

  const handleCancel = () => {
    reset({
      name: "",
    })
    setEdit("")
    setCertificateSts("")
  }

  const handleChange = (name, file) => {
    console.log(" handle Change :", name, file)
    setCertificateSts("")
    setValue(name, file)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="d-flex justify-content-between">
            <Breadcrumbs
              title="HRMS"
              breadcrumbObject={[
                { title: "HRMS", link: "/hrms" },
                { title: "Sponsors", link: "/hrms-sponser" },
              ]}
            />
          </div>

          <Row className="mt-2">
            <Col xs={5}>
              <Card className="me-2 px-2 pb-2 input-card">
                <div className="input-card-head">
                  {edit?.id ? "Edit" : "Add"}
                </div>
                <hr className="table-divide" />
                <FormProvider {...methods}>
                  <Form id={`ev-form`} onSubmit={handleSubmit(handleAdd)}>
                    <Row className="mt-2">
                      <Col sm={12}>
                        <FormGroup className="mb-4">
                          <Label>
                            Sponsored By<span className="mandatory">*</span>
                          </Label>
                          <UFInput
                            control={control}
                            placeholder="Enter the Name"
                            name={"sponsored_by"}
                            invalid={!!errors.sponsored_by}
                          />
                          <FormFeedback invalid>
                            {errors.sponsored_by?.message}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col sm={12}>
                        <FormGroup className="mb-4">
                          <Label>
                            License No<span className="mandatory">*</span>
                          </Label>
                          <UFInput
                            control={control}
                            placeholder="Enter License No"
                            name={"license_no"}
                            invalid={!!errors.license_no}
                          />
                          <FormFeedback invalid>
                            {errors.license_no?.message}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col sm={12}>
                        <FormGroup className="mb-3 mt-2">
                          <Label>
                            Documents<span className="mandatory">*</span>
                          </Label>
                          <SingleFileInput
                            accept=".png,.jpg,.jpeg,.svg,.pdf"
                            name={`importfile`}
                            label="Upload File"
                            onlyIcon={true}
                            tooltip="Document Upload"
                            handleChange={handleChange}
                            downloadPath={certificateSts}
                          />
                          {/* style={{ display: validation ? 'block' : 'none' }} */}
                          <FormFeedback invalid>
                            {errors.importfile?.message}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>
                    <div className="float-end">
                      <Button
                        className="btn-secondary me-2"
                        form={`ev-form`}
                        color={"secondary"}
                        onClick={handleCancel}
                      >
                        Cancel
                      </Button>
                      <LoadingButton
                        form={`ev-form`}
                        color={"primary"}
                        loading={isLoading}
                        disabled={isLoading}
                        type="submit"
                      >
                        {edit?.id ? "Update" : "Create"}
                      </LoadingButton>
                    </div>
                  </Form>
                </FormProvider>
              </Card>
            </Col>
            <Col xs={7}>
              <Card className="me-2 px-2 pb-2">
                <HrmsSearch
                  handleSearchChange={handleSearchChange}
                  placeholder="Search..."
                />
                <hr className="table-divide" />
                <Row className="mt-2 ever-rise-table">
                  <Col xs={12}>
                    {list?.data?.data && (
                      <UpdateTable
                        className="mt-5"
                        nodes={list?.data?.data}
                        paginate={list?.data}
                        columns={columns}
                        CurrentPage={page}
                        handlePagination={handlePaginate}
                        tableWidth="35% 35% 15% 15%"
                      />
                    )}
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Sponsors
