import { useState } from "react"
import UpdateTable from "../../components/Common/UpdatedTable"

const TableIntegration = () => {
  const [page, setPage] = useState("")

  const responseData = {
    last_page: 1,
    // currentPage: 1,
    data: [
      {
        id: 0,
        productCode: "EV-001",
        partNumber: "EVP-001",
        desc: "HEllo",
        status: "Replaced",
        phone: "789654123",
      },
      {
        id: 1,
        productCode: "EV-002",
        partNumber: "EVP-002",
        desc: "TTT",
        status: "Test",
        phone: "9876543",
      },
    ],
  }
  const columns = [
    {
      label: "SI.NO",
      renderCell: (item, index) => {
        const serialNumber = item?.id
        if (serialNumber <= 9) {
          return `0${serialNumber}`
        } else {
          return serialNumber
        }
      },
    },
    {
      label: "Product Code",
      renderCell: item => item?.productCode,
      sortKey: (a, b) => {
        return a?.productCode - b?.productCode
      },
    },
    {
      label: "Part Number",
      renderCell: item => item?.partNumber,
      sortKey: (a, b) => {
        return a?.partNumber - b?.partNumber
      },
    },
    {
      label: "Description",
      renderCell: item => item?.desc,
      sortKey: (a, b) => {
        return a?.desc - b?.desc
      },
    },
    {
      label: "Status",
      renderCell: item => item?.status,
      sortKey: (a, b) => {
        return a?.status - b?.status
      },
    },
    {
      label: "Phone",
      renderCell: item => item?.phone,
      sortKey: (a, b) => {
        return a?.phone - b?.phone
      },
    },
  ]

  const handlePaginate = page => {
    setPage(page)
  }
  return (
    <>
      <UpdateTable
        // className="mt-5"
        nodes={responseData?.data}
        paginate={responseData}
        columns={columns}
        handlePagination={handlePaginate}
        tableWidth="10% 15% 15% 20% 20% 20%"
      />
    </>
  )
}

export default TableIntegration
