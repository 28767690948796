import { toast } from 'react-toastify';

export const toastSuccess = (msg) => {
  toast.success(msg, { position: toast.POSITION.TOP_RIGHT })  
}

export const toastError = (errors) => {
  if(typeof errors === 'object' && errors !== null){
    if(Object.keys(errors).length){
      const errorsArray = Object.keys(errors).map((field) => {
        return errors[field].map((d) => {
          return `${field} : ${d}`
        })
      })
      
      toast.error(errorsArray.join(","), { position: toast.POSITION.TOP_RIGHT })
    }
  } else if(errors !== ""){ 
    toast.error(errors, { position: toast.POSITION.TOP_RIGHT })
  }
}