import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  PopoverBody,
  Row,
  UncontrolledPopover,
} from "reactstrap"
import UpdateTable from "../../../components/Common/UpdatedTable"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import action from "../../../assets/icons/action.svg"
import { toastError, toastSuccess } from "../../../helpers/msg_helper"
import { FormProvider, useForm } from "react-hook-form"
import { UFInput } from "../../../components/Common/Fields/Input"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import LoadingButton from "../../../components/Common/LoadingButton"
import {
  fetchEmployeeTypeEdit,
  fetchHrmsEmployeeType,
  postHrmsEmployeeType,
  updateEmployeTypeStatus,
} from "../../../services/Hrms/hrms"
import HrmsSearch from "../search"
import capitalizeFirstLetter from "../../../utils/capitalizeFirstLetter"

const schema = yup.object().shape({
  employee_type: yup.string().required("Employee Type is Required"),
})
const EmployeeType = () => {
  document.title = "Employee Type | Everise"

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  })
  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: true,
    defaultValues: {
      employee_type: "",
    },
  })
  const [isLoading, setIsLoading] = useState(false)

  const [page, setPage] = useState("")

  const [employeeTypeList, setEmployeeType] = useState()
  const [count, setCount] = useState(0)
  const [search, setSearch] = useState("")
  const [editEmployeeType, setEditEmployeeType] = useState("")

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchHrmsEmployeeType({
        params: `${search ? `&searchText=${search}` : "&searchText"}${
          page ? `&page=${page}` : ""
        }`,
      })
      setEmployeeType(response)
    }
    fetchData()
  }, [count, search, page])

  useEffect(() => {
    if (editEmployeeType.id) {
      reset({ ...editEmployeeType })
    }
  }, [editEmployeeType.id])

  const handleEmployee = async data => {
    try {
      setIsLoading(true)
      const createdSeries = await postHrmsEmployeeType(data)
      toastSuccess(createdSeries?.message)
      reset({
        employee_type: "",
      })
      setCount(count + 1)
    } catch (err) {
      toastError(err?.message)
    } finally {
      setIsLoading(false)
      setEditEmployeeType("")
    }
  }

  const handleEdit = async item => {
    const response = await fetchEmployeeTypeEdit(item)
    setEditEmployeeType(response?.data)
  }

  const handleStatus = async id => {
    try {
      const response = await updateEmployeTypeStatus(id)
      toastSuccess(response?.message)
      setCount(count + 1)
    } catch (err) {
      toastError(err?.message)
    }
  }

  const columns = [
    {
      label: "Employee Type",
      renderCell: item =>
        item?.employee_type ? capitalizeFirstLetter(item?.employee_type) : "-",
      sortKey: "employee_type",
    },
    {
      label: "Status",
      renderCell: item => (
        <div>
          <div
            className={`font-13 ${
              item?.status === 1 ? "status-active" : "status-inactive"
            }`}
          >
            {item?.status === 1 ? "Active" : "Inactive"}
          </div>
        </div>
      ),
    },
    {
      label: "Action",
      renderCell: item => (
        <>
          <div>
            <span id={`PopoverLegacy-${item?.id}`}>
              <img src={action} alt="" className="pointer" />
            </span>
          </div>
          <UncontrolledPopover
            placement="bottom"
            target={`PopoverLegacy-${item?.id}`}
            trigger="legacy"
          >
            <PopoverBody>
              {item?.status === 1 && (
                <div
                  className="table-popup-content"
                  onClick={() => handleEdit(item?.id)}
                >
                  Edit
                </div>
              )}
              <div
                className={`${
                  item?.status === 1 ? "popover-border" : ""
                } table-popup-content`}
                onClick={() => handleStatus(item?.id)}
              >
                {item?.status === 1 ? "Deactivate" : "Activate"}
              </div>
            </PopoverBody>
          </UncontrolledPopover>
        </>
      ),
    },
  ]

  const handlePaginate = page => {
    setPage(page)
  }

  const handleSearchChange = data => {
    setSearch(data)
    setPage(1)
  }

  const handleCancel = () => {
    reset({
      employee_type: "",
    })
    setEditEmployeeType("")
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="d-flex justify-content-between">
            <Breadcrumbs
              title="HRMS"
              breadcrumbObject={[
                { title: "HRMS", link: "/hrms" },
                { title: "Employee Type", link: "/hrms-employee-type" },
              ]}
            />
          </div>

          <Row className="mt-2">
            <Col xs={5}>
              <Card className="me-2 px-2 pb-2 input-card">
                <div className="input-card-head">
                  {editEmployeeType?.id ? "Edit" : "Add"}
                </div>
                <hr className="table-divide" />
                <FormProvider {...methods}>
                  <Form id={`ev-form`} onSubmit={handleSubmit(handleEmployee)}>
                    <Row className="mt-2">
                      <Col sm={12}>
                        <FormGroup className="mb-4">
                          <Label>
                            Employee Type<span className="mandatory">*</span>
                          </Label>
                          <UFInput
                            control={control}
                            placeholder="Enter the Employee Type"
                            name={"employee_type"}
                            invalid={!!errors.employee_type}
                          />
                          <FormFeedback invalid>
                            {errors.employee_type?.message}
                          </FormFeedback>
                        </FormGroup>
                        <div className="float-end">
                          <Button
                            className="btn-secondary me-2"
                            form={`ev-form`}
                            color={"secondary"}
                            onClick={handleCancel}
                          >
                            Cancel
                          </Button>
                          <LoadingButton
                            form={`ev-form`}
                            color={"primary"}
                            loading={isLoading}
                            disabled={isLoading}
                            type="submit"
                          >
                            {editEmployeeType?.id ? "Update" : "Create"}
                          </LoadingButton>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </FormProvider>
              </Card>
            </Col>
            <Col xs={7}>
              <Card className="me-2 px-2 pb-2">
                <HrmsSearch
                  handleSearchChange={handleSearchChange}
                  placeholder="Grade"
                />
                <hr className="table-divide" />
                <Row className="mt-2 ever-rise-table">
                  <Col xs={12}>
                    {employeeTypeList?.data?.data && (
                      <UpdateTable
                        className="mt-5"
                        nodes={employeeTypeList?.data?.data}
                        paginate={employeeTypeList?.data}
                        columns={columns}
                        CurrentPage={page}
                        handlePagination={handlePaginate}
                        tableWidth="40% 45% 15%"
                      />
                    )}
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default EmployeeType
