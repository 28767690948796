import React, { useEffect } from "react"

import { UFInput } from "../../components/Common/Fields/Input"
import { useForm } from "react-hook-form"
import { Form } from "reactstrap"

const HrmsSearch = ({
  handleSearchChange,
  placeholder
}) => {

  const { control, watch } = useForm()


  useEffect(() => {
    handleSearchChange(watch("search"))
  }, [watch("search")])

  return (
    <>
      <div className="d-flex align-items-center my-2 me-2 search-input-width">
          <UFInput
            control={control}
            placeholder={placeholder}
            name={"search"}
            className={"search-input"}
          />
      </div>
    </>
  )
}

export default HrmsSearch
