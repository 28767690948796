import { get, post } from "../../helpers/api_helper"

// currency
export const fetchCurrency = data => {
  return get(`currency/search?${data?.params}`)
}

export const updateCurrencyStatus = id => {
  return get(`currency/updatestatus/${id}`)
}

export const postSettingsCurrency = data => {
  return !data?.id
    ? post(`currency/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`currency/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}

export const getCurrencyList = () => {
  return get(`currency/list`)
}

//   country

export const postSettingsCountry = data => {
  return !data?.id
    ? post(`country/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`country/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}

export const fetchCountry = data => {
  return get(`country/search?${data?.params}`)
}

export const updateCountryStatus = id => {
  return get(`country/updatestatus/${id}`)
}

//   state

export const postSettingsState = data => {
  return !data?.id
    ? post(`state/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`state/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}

export const fetchState = data => {
  return get(`state/search?${data?.params}`)
}

export const updateStateStatus = id => {
  return get(`state/updatestatus/${id}`)
}

//   location

export const postSettingsLocation = data => {
  return !data?.id
    ? post(`locations/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`locations/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}

export const fetchLocation = data => {
  return get(`locations/search?${data?.params}`)
}

export const updateLocationStatus = id => {
  return get(`locations/updatestatus/${id}`)
}

// payment type

export const postSettingsPaymentType = data => {
  return !data?.id
    ? post(`paymentmethod/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`paymentmethod/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}

export const fetchPaymentType = data => {
  return get(`paymentmethod/search?${data?.params}`)
}

export const updatePaymentStatus = id => {
  return get(`paymentmethod/updatestatus/${id}`)
}

// campcode

export const postSettingsCampcode = data => {
  return !data?.id
    ? post(`camp-code/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`camp-code/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}

export const fetchCampcode = data => {
  return get(`camp-code/search?${data?.params}`)
}

export const updateCampcodeStatus = id => {
  return get(`camp-code/updatestatus/${id}`)
}

//general setting
export const postGeneralSettings = data => {
  return post(`settings/create`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
}

export const fetchGeneralSetting = id => {
  return get(`settings/fetch/${id}`)
}
//Prefix
export const postPrefix = data => {
  return post(`prefixes`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
}

export const fetchPrefix = () => {
  return get(`prefixes/fetch`)
}

// financial year
export const postFinancialYear = data => {
  return !data?.id
    ? post(`financeyear/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`financeyear/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}

export const fetchFinancialYears = data => {
  return get(`financeyear/search?${data?.params}`)
}

export const updateFinanceStatus = id => {
  return get(`financeyear/updatestatus/${id}`)
}

// Relationship
export const postRelationship = data => {
  return !data?.id
    ? post(`relationships/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`relationships/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}

export const fetchRelationshipSearch = data => {
  return get(`relationships/search?${data?.params}`)
}

export const updateRelationshipStatus = id => {
  return get(`relationships/updatestatus/${id}`)
}

// Vaccine
export const postVaccine = data => {
  return !data?.id
    ? post(`vaccin/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`vaccin/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}

export const fetchVaccineSearch = data => {
  return get(`vaccin/search?${data?.params}`)
}

export const updateVaccineStatus = id => {
  return get(`vaccin/updatestatus/${id}`)
}

// Booster
export const postBooster = data => {
  return !data?.id
    ? post(`booster/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`booster/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}

export const fetchBoosterSearch = data => {
  return get(`booster/search?${data?.params}`)
}

export const updateBoosterStatus = id => {
  return get(`booster/updatestatus/${id}`)
}