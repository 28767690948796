import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import {
  Col,
  Row,
  Card,
  CardBody,
  UncontrolledPopover,
  PopoverBody,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  FormFeedback,
  ModalFooter,
  Form,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
  ButtonGroup,
} from "reactstrap"
import {
  fetchProjectDashboard,
  fetchStoreCsRequest,
  fetchStoreDashRequest,
  fetchStoreGraph,
  fetchStoreIssueNote,
  fetchStoreMaterialRequest,
  postAttendance,
  postCentralRequest,
  postIssueNote,
  postRequestManpower,
} from "../../../../services/projectManager/projectMaster"
import { useNavigate, useParams } from "react-router-dom"
import UpdateTable from "../../../../components/Common/UpdatedTable"
import TimeLineDD from "../../../../components/Common/chooseFields/TimeLineDD"
import { FormProvider, useForm } from "react-hook-form"
import action from "../../../../assets/icons/action.svg"
import closeIcon from "../../../../assets/icons/close.svg"
import requestIcon from "../../../../assets/icons/request.svg"
import Chart from "react-apexcharts"
import { isEmpty, noConflict } from "lodash"
import {
  UFDate,
  UFEditor,
  UFInput,
  UFInputDashboard,
  UFTime,
} from "../../../../components/Common/Fields/Input"
import LoadingButton from "../../../../components/Common/LoadingButton"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import UsersList from "../../../../components/Common/chooseFields/userList"
import { toastError, toastSuccess } from "../../../../helpers/msg_helper"
import moment from "moment"
import EditMaterialRequest from "../../../Procurement/materialrequest/edit"
import {
  searchHrmsTimeSheet,
  searchHrmsTimeSheetUpdate,
} from "../../../../services/Hrms/hrms"
// import ShopKeeperFilter from "./filter"

const TimeKeeper = () => {
  document.title = "Store Keeper Dashboard | Ever Rise"

  const { id } = useParams()
  const navigate = useNavigate()

  //refresh state
  const [refresh, setRefresh] = useState(0)
  const [refresh1, setRefresh1] = useState(0)
  const [refresh2, setRefresh2] = useState(0)
  const [refresh3, setRefresh3] = useState(0)
  const [refresh4, setRefresh4] = useState(0)
  const [refreshAdd, setRefreshAdd] = useState(0)
  const [refreshChart, setRefreshChart] = useState(0)

  //pagination state
  const [page, setPage] = useState("")
  const [materialPage, setMaterialRequestPage] = useState("")
  const [csRequestPage, setCsRequestPage] = useState("")
  const [issueNotePage, setIssueNotePage] = useState("")

  //view and model chart
  const [viewData, setViewData] = useState()
  const [modal, setModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  //response state
  const [materialData, setMaterialData] = useState()
  const [materialRequestData, setMaterialRequestData] = useState()
  const [csRequestData, setCsRequestData] = useState()
  const [issueNoteData, setIssueNoteData] = useState()
  const [storeDashData, setStoreDashData] = useState()
  const [graphData, setGraphData] = useState()
  const [activeTab, setActiveTab] = useState("1")
  const [dateRange, setDateRange] = useState({})

  const [search, setSearch] = useState("")
  const [search1, setSearch1] = useState("")

  const [rSelected, setRSelected] = useState([])
  const [dateSelected, setDateStelected] = useState(
    moment().format("YYYY-MM-DD")
  )

  console.log(`rSelected`, rSelected)

  useEffect(() => {
    if (activeTab == 1 && materialData?.data?.data) {
      setRSelected(Array(materialData.data.data.length).fill(1))
    }
    if (activeTab == 2 && materialData?.data?.data) {
      const attendanceArr = materialData?.data?.data?.map((dt, i) => ({
        start_time: moment(dt?.start_time).format("HH:mm"),
        end_time: moment(dt?.end_time).format("HH:mm"),
        overtime: dt?.overtime,
      }))

      const presentValues = materialData.data.data.map((dt, i) =>
        dt?.present == 1 ? 1 : 0
      )

      setRSelected(presentValues)

      reset1({ attendance: attendanceArr })

      console.log(`attendanceArr`, attendanceArr, rSelected)
    }
  }, [materialData])

  console.log(`rSelected`, rSelected, materialData?.data?.data?.length)

  const schema1 = yup.object().shape({
    ...(viewData?.id === 4 && {
      project_quantity: yup
        .number()
        .min(1, "Quantity must be 1 or more")
        .max(
          Number(viewData?.value),
          `Quantity must be ${viewData?.value} or less`
        )
        .typeError("Quantity Must be Number")
        .required("Quantity is Required"),
      contact_person: yup.mixed().required("Contact Person is Required"),
      issue_date: yup.string().required("Issue Date is Required"),
    }),
    ...(viewData?.id === 5 && {
      central_quantity: yup
        .number()
        .min(1, "Quantity must be 1 or more")
        .max(
          Number(viewData?.value),
          `Quantity must be ${viewData?.value} or less`
        )
        .typeError(" Quantity Must be Number")
        .required(" Quantity is Required"),
      description: yup.string().required("Description is Required"),
    }),
    ...(viewData?.id === 7 && {
      req_manpower_no: yup
        .number()
        .min(1, "Number of Hours must be 1 or more")
        .max(
          Number(viewData?.req_manpower_no) -
            Number(
              viewData?.value?.manpower_requests_sum_req_manpower_no
                ? viewData?.value?.manpower_requests_sum_req_manpower_no
                : 0
            ),
          `Number of Hours must be ${
            Number(viewData?.req_manpower_no) -
            Number(
              viewData?.value?.manpower_requests_sum_req_manpower_no
                ? viewData?.value?.manpower_requests_sum_req_manpower_no
                : 0
            )
          } or less`
        )
        .typeError(" Number of Hours Must be Number")
        .required(" Number of Hours is Required"),
      req_remarks: yup.string().required("Remarks is Required"),
      request_date: yup.mixed().required("Date is Required"),
      exp_qty: yup
        .number()
        .min(1, "Expected Quantity must be 1 or more")
        .typeError("Expected Quantity must be Number"),
    }),
  })
  console.log(viewData, `viewData`)
  const toggle = () => {
    setModal(!modal)
    const controlValue = getValues1()

    reset1({
      ...controlValue,
      project_quantity: "",
      issue_date: "",
      // filter_date: "",
      contact_person: null,
      central_quantity: "",
      description: "",
      // end_date: "",
      // start_date: "",
    })
    if (!modal === false) {
      setViewData()
    }
  }
  const resetAfterCreatePo = () => {
    setModal(!modal)
    if (!modal === false) {
      setViewData()
    }
    setRefresh(refresh + 1)
    setRefresh1(refresh1 + 1)
    setRefresh4(refresh4 + 1)
  }
  const handleSearchChange = data => {
    setSearch1(data)
    setIssueNotePage(1)
    setCsRequestPage(1)
  }

  const handleView = item => {
    setViewData(item)
    toggle()
    if (item?.id == 4) {
      setValue1("project_store", item?.value)
      setValue1("product_id", item?.product_id)
    } else if (item?.id == 5) {
      setValue1("central_store", item?.value)
      setValue1("product_id", item?.product_id)
    } else if (item?.id == 7) {
      // setValue1("manpower_req", item?.product_id)
      console.log(`item`, item)
      reset1({
        category_code: item?.category_code,
        req_manpower_no:
          Number(item?.req_manpower_no) -
          Number(
            item?.value?.manpower_requests_sum_req_manpower_no
              ? item?.value?.manpower_requests_sum_req_manpower_no
              : 0
          ),
        project_manpower_id: item?.project_manpower_id,
        designation_id: item?.value?.category?.id,
        project_id: id,
      })
    }
  }

  const handleRequestdate = data => {
    let fromDate = ""
    let toDate = ""
    fromDate = !isEmpty(data) ? moment(data[0]).format("YYYY-MM-DD") : ""
    toDate = !isEmpty(data) ? moment(data[1]).format("YYYY-MM-DD") : ""
    setDateRange(fromDate, toDate)
  }
  console.log(`setDateRange`, dateRange)
  const {
    control: control1,
    handleSubmit: handleSubmit1,
    getValues: getValues1,
    setValue: setValue1,
    reset: reset1,
    watch: watch1,
    formState: { errors: errors1 },
  } = useForm({
    resolver: yupResolver(schema1),
    mode: "onChange",
    shouldFocusError: true,
    defaultValues: {
      date_search: moment().format("YYYY-MM-DD"),
      attendance: [
        {
          overtime: 0,
        },
      ],
    },
  })

  // const methods = useForm({
  //   resolver: yupResolver(schema1),
  //   mode: "onChange",
  //   shouldFocusError: true,
  //   defaultValues: {
  //     date_search: moment().format("YYYY-MM-DD"),
  //   },
  // })
  console.log(`date_search`, watch1("date_search"))
  useEffect(() => {
    setValue1("Time_1", { label: "Weekly", value: "thisweek" })
    setValue1("Time_2", { label: "Weekly", value: "thisweek" })
    setValue1("Time_3", { label: "Weekly", value: "thisweek" })
    setValue1("Time_chart", { label: "Weekly", value: "thisweek" })
    // setValue1("date", moment())
  }, [])

  useEffect(() => {
    const fetchClass = async () => {
      const chkApiCall =
        activeTab == 1 ? searchHrmsTimeSheet : searchHrmsTimeSheetUpdate
      const response = await chkApiCall({
        params: `${search ? `&searchText=${search}` : "&searchText"}${
          id ? `&project_id=${id}` : ""
        }${page ? `&page=${page}` : ""}${
          activeTab ? `&type=${activeTab == 1 ? 0 : 1}` : ""
        }${dateSelected && `&year_month=${dateSelected}`}`,
      })
      setMaterialData(response)
    }
    if (id) {
      fetchClass()
    }
  }, [refresh, id, activeTab, page, search, dateSelected, refreshAdd])

  const materialRequestColumns = [
    {
      label: "MR No",
      renderCell: item => item?.material_requests_code,
    },

    {
      label: "Request Date",
      renderCell: item => item?.request_date,
    },
    {
      label: "Quantity",
      renderCell: item => item?.requests_sum_quantity,
    },
  ]
  const csRequestColumns = [
    {
      label: "Product No",
      renderCell: item => item?.product_id?.product_code,
    },

    {
      label: "Date",
      renderCell: item => moment(item?.created_at).format("YYYY-MM-DD"),
    },
    {
      label: "Staff Name",
      renderCell: item => item?.request_id?.requested_from?.name,
    },
    {
      label: "Quantity",
      renderCell: item => item?.quantity,
    },
  ]
  const issueNoteColumns = [
    {
      label: "Product No",
      renderCell: item => item?.product_id?.product_code,
    },

    {
      label: "Date",
      renderCell: item => item?.issue_date,
    },
    {
      label: "Staff Name",
      renderCell: item => item?.contactperson?.name,
    },
    {
      label: "Quantity",
      renderCell: item => item?.quantity,
    },
  ]

  const handlePaginate = page => {
    setPage(page)
  }
  const handleMaterialRequestPaginate = page => {
    setMaterialRequestPage(page)
  }
  const handleCsRequestPaginate = page => {
    setCsRequestPage(page)
  }
  const handleissueNotePaginate = page => {
    setIssueNotePage(page)
  }

  // const mainCard = [
  //   {
  //     id: 1,
  //     name: "Total Material Request",
  //     dropdown: true,
  //     subName:
  //       watch1("Time_1")?.value == "today"
  //         ? "Last 24 Hours"
  //         : watch1("Time_1")?.value == "thisweek"
  //         ? "Last 7 days"
  //         : watch1("Time_1")?.value == "thismonth"
  //         ? "Last 30 days"
  //         : watch1("Time_1")?.value == "thisyear"
  //         ? "Last 365 days"
  //         : "",
  //     value: materialRequestData?.totalItems,
  //     viewAll: true,
  //   },
  //   {
  //     id: 2,
  //     name: "Total CS Request",
  //     dropdown: true,
  //     subName:
  //       watch1("Time_2")?.value == "today"
  //         ? "Last 24 Hours"
  //         : watch1("Time_2")?.value == "thisweek"
  //         ? "Last 7 days"
  //         : watch1("Time_2")?.value == "thismonth"
  //         ? "Last 30 days"
  //         : watch1("Time_2")?.value == "thisyear"
  //         ? "Last 365 days"
  //         : "",
  //     value: csRequestData?.totalItems,
  //     viewAll: true,
  //   },
  //   {
  //     id: 3,
  //     name: "Total Issue Note",
  //     dropdown: true,
  //     subName:
  //       watch1("Time_3")?.value == "today"
  //         ? "Last 24 Hours"
  //         : watch1("Time_3")?.value == "thisweek"
  //         ? "Last 7 days"
  //         : watch1("Time_3")?.value == "thismonth"
  //         ? "Last 30 days"
  //         : watch1("Time_3")?.value == "thisyear"
  //         ? "Last 365 days"
  //         : "",
  //     value: issueNoteData?.totalItems,
  //     viewAll: true,
  //   },
  // ]

  const handleTimeChange = (e, id) => {
    if (id == 1) {
      setMaterialRequestPage(1)
      setRefresh1(refresh1 + 1)
    } else if (id == 2) {
      setCsRequestPage(1)
      setRefresh2(refresh2 + 1)
    } else if (id == 3) {
      setIssueNotePage(1)
      setRefresh3(refresh3 + 1)
    } else if (id == "chart") {
      setRefreshChart(refreshChart + 1)
    }
  }

  const handleRegistration = async data => {
    try {
      setIsLoading(true)
      if (viewData?.id === 4) {
        const updateDepartment = await postIssueNote({
          quantity: data?.project_quantity,
          issue_date: moment(data?.issue_date).format("YYYY-MM-DD"),
          contactperson: data?.contact_person?.value,
          project_id: id,
          product_id: data?.product_id,
        })
        setRefresh(refresh + 1)
        setRefresh3(refresh3 + 1)
        setRefresh4(refresh4 + 1)
        setRefreshChart(refreshChart + 1)
      } else if (viewData?.id === 5) {
        const updateDepartment = await postCentralRequest({
          quantity: data?.central_quantity,
          description: data?.description,
          type: "centralrequest",
          project_id: id,
          product_id: data?.product_id,
        })
        setRefresh(refresh + 1)
        setRefresh2(refresh2 + 1)
        setRefresh4(refresh4 + 1)
        setRefreshChart(refreshChart + 1)
      } else if (viewData?.id === 7) {
        const requestManpower = await postRequestManpower({
          project_manpower_id: data?.project_manpower_id,
          from_date: !isEmpty(data)
            ? moment(data?.request_date[0]).format("YYYY-MM-DD")
            : "",
          to_date: !isEmpty(data)
            ? moment(data?.request_date[1]).format("YYYY-MM-DD")
            : "",
          designation_id: data?.designation_id,
          req_manpower_no: data?.req_manpower_no,
          req_remarks: data?.req_remarks,
          project_id: data?.project_id,
          exp_qty: data?.exp_qty,
        })
        setRefresh(refresh + 1)
        setRefresh4(refresh4 + 1)
        toastSuccess(requestManpower?.message)
      }
    } catch (err) {
      toastError(err?.message)
    } finally {
      toggle()
      setIsLoading(false)
    }
  }

  const toggleTab = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  const chartCard = [
    {
      name: "Requests",
      value:
        Number(graphData?.total?.new) +
        Number(graphData?.total?.issued) +
        Number(graphData?.total?.rejected),
    },
    {
      name: "New",
      value: graphData?.total?.new,
    },
    {
      name: "Issued",
      value: graphData?.total?.issued,
    },
    {
      name: "Rejected",
      value: graphData?.total?.rejected,
    },
  ]

  // const handleDateChange = e => {
  //   console.log(" handle change is working :", e[0], e[1], viewData)
  //   if (e[0] && e[1]) {
  //     setValue("start_date", moment(e[0]).format("YYYY-MM-DD"))
  //     setValue("end_date", moment(e[1]).format("YYYY-MM-DD"))
  //     if (viewData?.id == 1) {
  //       setRefresh1(refresh1 + 1)
  //     } else if (viewData?.id == 2) {
  //       setRefresh2(refresh2 + 1)
  //     } else if (viewData?.id == 3) {
  //       setRefresh3(refresh3 + 1)
  //     }
  //   }
  // }

  useEffect(() => {
    setSearch(watch1("search"))
    setPage(1)
  }, [watch1("search")])

  const handleAttendance = (i, val) => {
    console.log(`val`, val)
    const updatedRSelected = [...rSelected]
    updatedRSelected[i] = val
    setRSelected(updatedRSelected)
  }

  console.log(`dateSelected`, dateSelected, watch1("date"))

  const handleAttendanceAdd = async (i, item, chkState) => {
    console.log(`item`, item)
    const response = await postAttendance({
      ...(chkState == 2 && {
        id: item?.id,
      }),
      date: dateSelected,
      employee_id: item?.id,
      project_id: id,
      start_time: moment(
        chkState == 2 ? item?.start_time : watch1(`attendance.${i}.start_time`)
      ).format("YYYY-MM-DD HH:mm:ss"),
      end_time: moment(
        chkState == 2 ? item?.end_time : watch1(`attendance.${i}.end_time`)
      ).format("YYYY-MM-DD HH:mm:ss"),
      present: rSelected[i] == 1 ? 1 : 0,
      overtime: watch1(`attendance.${i}.overtime`),
    })
    reset1()
    toastSuccess(response?.message)
    setRefreshAdd(refreshAdd + 1)
  }
  const handledate = e => {
    setDateStelected(moment(e[0]).format("YYYY-MM-DD"))
    console.log(`handledate`, e)
  }
  console.log(`watch1)`, dateSelected)
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="d-flex justify-content-between">
            <Breadcrumbs
              title="Project Management"
              breadcrumbObject={[
                { title: "Project Management", link: "/project" },
                {
                  title: "Time Keeper",
                  link: `/project-timekeeper-dash/${id}`,
                },
              ]}
            />
          </div>
          <Card className="me-2 px-2 pb-2">
            <div className="mx-3 px-2 mt-4">
              <Row>
                <Col lg={12} className=" pe-2 ps-0">
                  <div className="tab-bg border-rds-round px-2 mb-4">
                    <div className="bold-heading  px-3">
                      <Nav tabs className="tab-active-bg">
                        <NavItem>
                          <NavLink
                            className={
                              activeTab === "1" ? "active pointer" : "pointer"
                            }
                            onClick={() => {
                              toggleTab("1")
                              setValue1("search", "")
                            }}
                          >
                            New
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={
                              activeTab === "2" ? "active pointer" : "pointer"
                            }
                            onClick={() => {
                              toggleTab("2")
                              setValue1("search", "")
                              // reset1({})
                            }}
                          >
                            Update
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <Row className="mt-2 mb-0">
                        <Col sm={4}>
                          <UFDate
                            control={control1}
                            placeholder="Select Date"
                            name={"date_search"}
                            maxDate={moment().format("YYYY-MM-DD")}
                            onDateChange={e => handledate(e)}
                            className="ufinput-dashboard"
                          />
                        </Col>
                      </Row>
                      <Row className=" ever-rise-table dashboardTable ">
                        <Col xs={12}>
                          <TabContent activeTab={activeTab} className="pt-3">
                            <TabPane tabId="1">
                              {materialData && activeTab == "1" && (
                                <table className="estimation-table mb-4">
                                  <thead>
                                    <tr>
                                      <th>Emp Id</th>
                                      <th>Designation</th>
                                      <th>IN Time</th>
                                      <th>Out Time</th>
                                      <th className="me-2">OT Hours</th>
                                      <th>Attendance</th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {materialData?.data?.data?.map(
                                      (item, index) => (
                                        <tr key={`materialFields${index}`}>
                                          <td>{item?.employee_code}</td>
                                          <td>
                                            {item?.designation?.name
                                              ? item?.designation?.name
                                              : "-"}
                                          </td>
                                          <td>
                                            <UFTime
                                              control={control1}
                                              name={`attendance.${index}.start_time`}
                                              className="time-input"
                                            />
                                          </td>
                                          <td>
                                            <UFTime
                                              control={control1}
                                              name={`attendance.${index}.end_time`}
                                              className="time-input"
                                            />
                                          </td>
                                          <td>
                                            <UFInput
                                              control={control1}
                                              name={`attendance.${index}.overtime`}
                                              className="me-2"
                                              ot={true}
                                            />
                                          </td>
                                          <td>
                                            <ButtonGroup>
                                              <Button
                                                color="primary"
                                                outline
                                                onClick={() =>
                                                  handleAttendance(index, 1)
                                                }
                                                active={rSelected[index] === 1}
                                                className="attendance-button"
                                              >
                                                P
                                              </Button>
                                              <Button
                                                color="primary"
                                                outline
                                                onClick={() =>
                                                  handleAttendance(index, 0)
                                                }
                                                active={rSelected[index] === 0}
                                                className="attendance-button"
                                              >
                                                A
                                              </Button>
                                            </ButtonGroup>
                                          </td>
                                          <td>
                                            <div
                                              className={"me-4 btn btn-primary"}
                                              onClick={() =>
                                                handleAttendanceAdd(
                                                  index,
                                                  item,
                                                  1
                                                )
                                              }
                                            >
                                              Submit
                                            </div>
                                          </td>
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </table>
                              )}
                            </TabPane>
                            <TabPane tabId="2">
                              {materialData && activeTab == "2" && (
                                <table className="estimation-table mb-4">
                                  <thead>
                                    <tr>
                                      <th>Emp Id</th>
                                      <th>Designation</th>
                                      <th>IN Time</th>
                                      <th>Out Time</th>
                                      <th className="me-2">OT Hours</th>
                                      <th>Attendance</th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {materialData?.data?.data?.map(
                                      (item, index) => (
                                        <tr key={`materialFields${index}`}>
                                          <td>
                                            {item?.employee_id?.employee_code}
                                          </td>
                                          <td>
                                            {item?.employee_id?.designation
                                              ?.name
                                              ? item?.employee_id?.designation
                                                  ?.name
                                              : "-"}
                                          </td>
                                          <td>
                                            <UFTime
                                              control={control1}
                                              name={`attendance.${index}.start_time`}
                                              className="time-input"
                                            />
                                          </td>
                                          <td>
                                            <UFTime
                                              control={control1}
                                              name={`attendance.${index}.end_time`}
                                              className="time-input"
                                            />
                                          </td>
                                          <td>
                                            <UFInput
                                              control={control1}
                                              name={`attendance.${index}.overtime`}
                                              className="me-2"
                                              ot={true}
                                            />
                                          </td>
                                          <td>
                                            <ButtonGroup>
                                              <Button
                                                color="primary"
                                                outline
                                                onClick={() =>
                                                  handleAttendance(index, 1)
                                                }
                                                active={rSelected[index] === 1}
                                                className="attendance-button"
                                              >
                                                P
                                              </Button>
                                              <Button
                                                color="primary"
                                                outline
                                                onClick={() =>
                                                  handleAttendance(index, 0)
                                                }
                                                active={rSelected[index] === 0}
                                                className="attendance-button"
                                              >
                                                A
                                              </Button>
                                            </ButtonGroup>
                                          </td>
                                          <td>
                                            <div
                                              className={"me-4 btn btn-primary"}
                                              onClick={() =>
                                                handleAttendanceAdd(
                                                  index,
                                                  item,
                                                  2
                                                )
                                              }
                                            >
                                              Update
                                            </div>
                                          </td>
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </table>
                              )}
                            </TabPane>
                          </TabContent>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Card>
          <Modal
            isOpen={modal}
            toggle={toggle}
            size={
              viewData?.id == 6
                ? "xl"
                : viewData?.id !== 4 && viewData?.id !== 5 && viewData?.id !== 7
                ? "lg"
                : "md"
            }
            unmountOnClose={false}
          >
            <>
              <ModalHeader className="custom-modal-header">
                <div className="d-flex justify-content-between">
                  <h5 className="ms-4">
                    {viewData?.id == 1
                      ? "Material Request"
                      : viewData?.id == 2
                      ? "Central Store Request"
                      : viewData?.id == 3
                      ? "Issue Note"
                      : viewData?.id == 4
                      ? "Store Issue Note"
                      : viewData?.id == 5
                      ? "Central Store Request"
                      : viewData?.id == 6
                      ? "Material Request"
                      : viewData?.id == 7
                      ? "Manpower Request"
                      : ""}
                  </h5>
                  <img
                    src={closeIcon}
                    alt=""
                    onClick={toggle}
                    width="10"
                    role="button"
                  />
                </div>
              </ModalHeader>
              <ModalBody>
                <FormProvider>
                  <Form
                    id={`ev-form`}
                    onSubmit={handleSubmit1(handleRegistration)}
                  >
                    {viewData?.id !== 4 &&
                      viewData?.id !== 5 &&
                      viewData?.id !== 6 &&
                      viewData?.id !== 7 && (
                        <Card className="me-2 px-2 pb-2">
                          <Row className="mt-2 ever-rise-table">
                            <Col xs={12}>
                              {materialRequestData?.data &&
                                viewData?.id == 1 && (
                                  <UpdateTable
                                    className="mt-5"
                                    nodes={materialRequestData?.data?.data}
                                    paginate={materialRequestData?.data}
                                    columns={materialRequestColumns}
                                    handlePagination={
                                      handleMaterialRequestPaginate
                                    }
                                    CurrentPage={materialPage}
                                    tableWidth="33% 33% 33%"
                                  />
                                )}
                              {csRequestData?.data && viewData?.id == 2 && (
                                <UpdateTable
                                  className="mt-5"
                                  nodes={csRequestData?.data?.data}
                                  paginate={csRequestData?.data}
                                  columns={csRequestColumns}
                                  CurrentPage={csRequestPage}
                                  handlePagination={handleCsRequestPaginate}
                                  tableWidth="25% 25% 25% 25%"
                                />
                              )}
                              {issueNoteData?.data && viewData?.id == 3 && (
                                <>
                                  <UpdateTable
                                    className="mt-5"
                                    nodes={issueNoteData?.data?.data}
                                    paginate={issueNoteData?.data}
                                    columns={issueNoteColumns}
                                    CurrentPage={issueNotePage}
                                    handlePagination={handleissueNotePaginate}
                                    tableWidth="25% 25% 25% 25%"
                                  />
                                </>
                              )}
                            </Col>
                          </Row>
                        </Card>
                      )}
                    {(viewData?.id === 4 ||
                      viewData?.id === 5 ||
                      viewData?.id === 7) && (
                      <>
                        <Row className="mt-2 ever-rise-table mx-2">
                          <Col xs={12}>
                            {viewData?.id == 4 && (
                              <>
                                <Row>
                                  <Col sm={12}>
                                    <FormGroup className="mb-4">
                                      <Label>
                                        Project Store Availability
                                        <span className="mandatory">*</span>
                                      </Label>

                                      <UFInput
                                        control={control1}
                                        placeholder="Enter the Customer Name"
                                        name={"project_store"}
                                        invalid={!!errors1.project_store}
                                        disabled={true}
                                      />
                                      <FormFeedback invalid>
                                        {errors1.project_store?.message}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                  <Col sm={12}>
                                    <FormGroup className="mb-4">
                                      <Label>
                                        Quantity
                                        <span className="mandatory">*</span>
                                      </Label>

                                      <UFInput
                                        control={control1}
                                        placeholder="Enter the Quantity"
                                        name={"project_quantity"}
                                        invalid={!!errors1.project_quantity}
                                      />
                                      <FormFeedback invalid>
                                        {errors1.project_quantity?.message}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                  <Col sm={12}>
                                    <FormGroup className="mb-4">
                                      <Label>
                                        Issue Date
                                        <span className="mandatory">*</span>
                                      </Label>
                                      <UFDate
                                        control={control1}
                                        name={"issue_date"}
                                        invalid={!!errors1.issue_date}
                                      />
                                      <FormFeedback invalid>
                                        {errors1.issue_date?.message}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                  <Col sm={12}>
                                    <FormGroup className="mb-4">
                                      <Label>
                                        Contact Person
                                        <span className="mandatory">*</span>
                                      </Label>

                                      <UsersList
                                        control={control1}
                                        placeholder="Select Contact Person"
                                        name={"contact_person"}
                                        invalid={!!errors1.contact_person}
                                      />

                                      <FormFeedback invalid>
                                        {errors1.contact_person?.message}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </>
                            )}
                            {viewData?.id == 5 && (
                              <>
                                <Row>
                                  <Col sm={12}>
                                    <FormGroup className="mb-4">
                                      <Label>
                                        Central Store Availability
                                        <span className="mandatory">*</span>
                                      </Label>

                                      <UFInput
                                        control={control1}
                                        placeholder=""
                                        name={"central_store"}
                                        invalid={!!errors1.central_store}
                                        disabled={true}
                                      />
                                      <FormFeedback invalid>
                                        {errors1.central_store?.message}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                  <Col sm={12}>
                                    <FormGroup className="mb-4">
                                      <Label>
                                        Quantity
                                        <span className="mandatory">*</span>
                                      </Label>

                                      <UFInput
                                        control={control1}
                                        placeholder="Enter the Quantity"
                                        name={"central_quantity"}
                                        invalid={!!errors1.central_quantity}
                                      />
                                      <FormFeedback invalid>
                                        {errors1.central_quantity?.message}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                  <Col sm={12}>
                                    <FormGroup className="mb-4">
                                      <Label>
                                        Description
                                        <span className="mandatory">*</span>
                                      </Label>
                                      <UFEditor
                                        control={control1}
                                        placeholder="Enter the Description"
                                        rows="5"
                                        name={"description"}
                                        invalid={!!errors1.description}
                                      />
                                      <FormFeedback invalid>
                                        {errors1.description?.message}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </>
                            )}
                            {viewData?.id == 7 && (
                              <>
                                <Row>
                                  <Col sm={12}>
                                    <FormGroup className="mb-4">
                                      <Label>
                                        Category Code
                                        <span className="mandatory">*</span>
                                      </Label>
                                      <UFInput
                                        control={control1}
                                        placeholder=""
                                        name={"category_code"}
                                        invalid={!!errors1.category_code}
                                        disabled={true}
                                      />
                                      <FormFeedback invalid>
                                        {errors1.category_code?.message}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                  <Col sm={12}>
                                    <FormGroup className="mb-4">
                                      <Label>
                                        Number of Hours
                                        <span className="mandatory">*</span>
                                      </Label>

                                      <UFInput
                                        control={control1}
                                        placeholder="Enter Hour"
                                        name={"req_manpower_no"}
                                        invalid={!!errors1.req_manpower_no}
                                      />
                                      <FormFeedback invalid>
                                        {errors1.req_manpower_no?.message}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                  <Col sm={12}>
                                    <FormGroup className="mb-4">
                                      <Label>
                                        From Date - To Date
                                        <span className="mandatory">*</span>
                                      </Label>
                                      <UFDate
                                        control={control1}
                                        name={"request_date"}
                                        mode="range"
                                        minDate={moment().format("YYYY-MM-DD")}
                                        // onDateChange={e => handleRequestdate(e)}
                                      />
                                      <FormFeedback invalid>
                                        {errors1.request_date?.message}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                  <Col sm={12}>
                                    <FormGroup className="mb-4">
                                      <Label>Minimum Manpower</Label>
                                      <UFInput
                                        control={control1}
                                        placeholder="Expected Quantity"
                                        name={"exp_qty"}
                                        invalid={!!errors1.exp_qty}
                                      />
                                      <FormFeedback invalid>
                                        {errors1.exp_qty?.message}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                  <Col sm={12}>
                                    <FormGroup className="mb-4">
                                      <Label>
                                        Remarks
                                        <span className="mandatory">*</span>
                                      </Label>
                                      <UFEditor
                                        control={control1}
                                        placeholder="Enter the Description"
                                        rows="5"
                                        name={"req_remarks"}
                                        invalid={!!errors1.req_remarks}
                                      />
                                      <FormFeedback invalid>
                                        {errors1.req_remarks?.message}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </>
                            )}
                          </Col>
                        </Row>
                      </>
                    )}
                    {(viewData?.id === 4 ||
                      viewData?.id === 5 ||
                      viewData?.id === 7) && (
                      <>
                        <hr />
                        <div className="pb-3 d-flex justify-content-end">
                          <LoadingButton
                            form={`ev-form`}
                            color={"primary"}
                            loading={isLoading}
                            disabled={isLoading}
                            className={"me-4"}
                            type="submit"
                          >
                            {viewData?.id === 4 ? "Issue Note" : "Request"}
                          </LoadingButton>
                        </div>
                      </>
                    )}
                  </Form>
                </FormProvider>
                {viewData?.id == 6 && (
                  <EditMaterialRequest
                    fromDashboard={true}
                    projectValue={{
                      value: id,
                      label: storeDashData?.data?.project_code,
                    }}
                    toggle={resetAfterCreatePo}
                  />
                )}
              </ModalBody>
            </>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  )
}

export default TimeKeeper
