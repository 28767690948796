import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Card,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  Row,
  UncontrolledAccordion,
} from "reactstrap"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { toastError, toastSuccess } from "../../../helpers/msg_helper"
import { UFEditor, UFInput } from "../../../components/Common/Fields/Input"
import LoadingButton from "../../../components/Common/LoadingButton"
import {
  fetchEmployee,
  postHrmsEmployeeControl,
} from "../../../services/Hrms/hrms"
import {
  fetchGeneralSetting,
  postGeneralSettings,
} from "../../../services/Settings/settings"
import PhoneInputField from "../../../components/Common/Fields/phoneInput"
import SingleFileInput from "../../../components/Common/Fields/singleFileInput"

const schema = yup.object().shape({
  po_pay_term: yup.string().required("Purchase Order Terms is Required"),
  po_remarks: yup.string().required("Purchase Remark is Required"),
  grn_text: yup.string().required("Purchase Remark is Required"),
  percentage: yup
    .number()
    .min(1, "Minimun Value must be greater than 1")
    .max(100, "Maximun Value must be less than 100")
    .required("Percentage is Required")
    .typeError("Percentage must be Number"),
  overheads_per: yup
    .number()
    .min(1, "Minimun Value must be greater than 1")
    .max(100, "Maximun Value must be less than 100")
    .required("Overheads Percentage is Required")
    .typeError("Overheads Percentage must be Number"),
  preliminary_per: yup
    .number()
    .min(1, "Minimun Value must be greater than 1")
    .max(100, "Maximun Value must be less than 100")
    .required("Preliminary Percentage is Required")
    .typeError("Preliminary Percentage must be Number"),
  tax: yup
    .number()
    .min(1, "Minimun Value must be greater than 1")
    .max(100, "Maximun Value must be less than 100")
    .required("Preliminary Percentage is Required")
    .typeError("Preliminary Percentage must be Number"),
})

const EditProjectSetting = () => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [refresh, setRefresh] = useState(0)
  const [file, setFile] = useState()
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: true,
    defaultValues: {
      percentage: 0,
      preliminary_per: 0,
      overheads_per: 0,
      tax: 0,
      po_remarks: "",
      po_pay_term: "",
      grn_text: "",
      // companylogo: "",
    },
  })
  //   const methods = useForm({
  //     resolver: yupResolver(schema),
  //     mode: "onChange",
  //     shouldFocusError: true,
  //   })

  const handleRegistration = async data => {
    try {
      setIsLoading(true)
      const updateDepartment = await postGeneralSettings({
        ...data,
        companylogo: data?.companylogo?.[0],
      })
      setRefresh(refresh + 1)
      toastSuccess(updateDepartment?.message)
      navigate("/settings")
    } catch (err) {
      toastError(err?.message)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    const handleFetchGeneralSetting = async () => {
      const response = await fetchGeneralSetting("1")
      reset({ ...response?.data })
    }

    handleFetchGeneralSetting()
  }, [refresh])

  const handleChange = (name, file) => {
    setValue(name, file)
    setFile(URL.createObjectURL(file[0]))
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <FormProvider>
            <Form
              id={`general-setting-form`}
              onSubmit={handleSubmit(handleRegistration)}
            >
              <div className="sticky-top bg-white w-100">
                <div className="d-flex justify-content-between me-2">
                  <Breadcrumbs
                    title="Setting"
                    breadcrumbObject={[
                      { title: "Setting", link: "/settings" },
                      { title: "Project Setting", link: "/settings-project" },
                    ]}
                  />
                  <LoadingButton
                    form={`general-setting-form`}
                    color={"primary"}
                    loading={isLoading}
                    disabled={isLoading}
                    className={"px-2 mb-3 btn btn-primary"}
                    type="submit"
                  >
                    Update
                  </LoadingButton>
                </div>
              </div>
              <Container fluid className="ps-lg-0">
                <Card className="invoice-main-card p-3 pt-4">
                  <Row className="">
                    <Row>
                      {/* <Col sm={3}>
                        <FormGroup className="mb-4">
                          <Label>
                            Company Website
                            <span className="mandatory">*</span>
                          </Label>
                          <UFInput
                            control={control}
                            placeholder="Enter website"
                            name={"website"}
                            invalid={!!errors.website}
                          />
                          <FormFeedback invalid>
                            {errors.website?.message}
                          </FormFeedback>
                        </FormGroup>
                      </Col> */}
                      {/* <Col sm={3}>
                        <FormGroup className="mb-4">
                          <Label>
                            Contact Number
                            <span className="mandatory">*</span>
                          </Label>
                          <UFInput
                            control={control}
                            placeholder="Enter phone"
                            name={"phone"}
                            invalid={!!errors.phone}
                          />
                          <FormFeedback invalid>
                            {errors.phone?.message}
                          </FormFeedback>
                        </FormGroup>
                      </Col> */}
                      {/* <Col sm={3}>
                        <FormGroup className="mb-4">
                          <Label>
                            Contact Number
                            <span className="mandatory">*</span>
                          </Label>
                          <PhoneInputField
                            control={control}
                            name={"phone"}
                            invalid={!!errors.phone}
                          />
                          <FormFeedback invalid>
                            {errors.phone?.message}
                          </FormFeedback>
                        </FormGroup>
                      </Col> */}
                      <Col sm={3}>
                        <FormGroup className="mb-4">
                          <Label>
                            Project Value Variation (%)
                            <span className="mandatory">*</span>
                          </Label>
                          <UFInput
                            control={control}
                            placeholder="Enter the Variation"
                            name={"percentage"}
                            invalid={!!errors.percentage}
                          />
                          <FormFeedback invalid>
                            {errors.percentage?.message}
                          </FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col sm={3}>
                        <FormGroup className="mb-4">
                          <Label>
                            Preliminary Percentage (%)
                            <span className="mandatory">*</span>
                          </Label>
                          <UFInput
                            control={control}
                            placeholder="Enter the Preliminary Percentage"
                            name={"preliminary_per"}
                            invalid={!!errors.preliminary_per}
                          />
                          <FormFeedback invalid>
                            {errors.preliminary_per?.message}
                          </FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col sm={3}>
                        <FormGroup className="mb-4">
                          <Label>
                            Overheads Percentage (%)
                            <span className="mandatory">*</span>
                          </Label>
                          <UFInput
                            control={control}
                            placeholder="Enter the Overheads Percentage"
                            name={"overheads_per"}
                            invalid={!!errors.overheads_per}
                          />
                          <FormFeedback invalid>
                            {errors.overheads_per?.message}
                          </FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col sm={3}>
                        <FormGroup className="mb-4">
                          <Label>
                            Tax (%)
                            <span className="mandatory">*</span>
                          </Label>
                          <UFInput
                            control={control}
                            placeholder="Enter the Tax"
                            name={"tax"}
                            invalid={!!errors.tax}
                          />
                          <FormFeedback invalid>
                            {errors.tax?.message}
                          </FormFeedback>
                        </FormGroup>
                      </Col>

                      {/* <Col sm={3}>
                        <FormGroup className="mb-4">
                          <Label>
                            Logo
                            <span className="mandatory">*</span>
                          </Label>
                          <SingleFileInput
                            control={control}
                            accept=".svg"
                            name={`companylogo`}
                            label="Upload File"
                            onlyIcon={true}
                            tooltip="Document Upload"
                            handleChange={handleChange}
                            // downloadPath={documentName}
                            // multiple={true}
                          />
                          <FormFeedback invalid>
                            {errors.tax?.message}
                          </FormFeedback>
                        </FormGroup>
                      </Col> */}
                      <Col sm={3}>
                        <div className="p-2">
                          <img src={file} />
                        </div>
                      </Col>
                    </Row>
                    <Row className="mb-4 mt-2">
                      <Col sm={3}>
                        <FormGroup className="mb-4">
                          <Label>
                            GRN Terms and Conditions
                            <span className="mandatory">*</span>
                          </Label>
                          <UFEditor
                            control={control}
                            placeholder="Enter Terms and Conditions"
                            rows="5"
                            name={"grn_text"}
                            invalid={!!errors.grn_text}
                          />
                          <FormFeedback invalid>
                            {errors.grn_text?.message}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col sm={3}>
                        <FormGroup className="mb-4">
                          <Label>
                            PO Terms and Conditions
                            <span className="mandatory">*</span>
                          </Label>
                          <UFEditor
                            control={control}
                            placeholder="Enter Terms and Conditions"
                            rows="5"
                            name={"po_pay_term"}
                            invalid={!!errors.po_pay_term}
                          />
                          <FormFeedback invalid>
                            {errors.po_pay_term?.message}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col sm={3}>
                        <FormGroup className="mb-4">
                          <Label>
                            PO Remarks
                            <span className="mandatory">*</span>
                          </Label>
                          <UFEditor
                            control={control}
                            placeholder="Enter Remarks"
                            rows="5"
                            name={"po_remarks"}
                            invalid={!!errors.po_remarks}
                          />
                          <FormFeedback invalid>
                            {errors.po_remarks?.message}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                      {/* <Col sm={3}>
                        <FormGroup className="mb-4">
                          <Label>
                            Company Address
                            <span className="mandatory">*</span>
                          </Label>
                          <UFEditor
                            control={control}
                            placeholder="Enter address"
                            rows="5"
                            name={"companyaddress"}
                            invalid={!!errors.companyaddress}
                          />
                          <FormFeedback invalid>
                            {errors.companyaddress?.message}
                          </FormFeedback>
                        </FormGroup>
                      </Col> */}
                    </Row>
                    {/* <Row>
                      <Col sm={6}>
                        <FormGroup className="mb-4">
                          <Label>
                            Logo
                            <span className="mandatory">*</span>
                          </Label>
                          <SingleFileInput
                            control={control}
                            accept=".png,.jpg,.jpeg,.svg"
                            name={`companylogo`}
                            label="Upload File"
                            onlyIcon={true}
                            tooltip="Cerificate Upload"
                            handleChange={handleChange}
                            downloadPath={profile}
                          />
                          <FormFeedback invalid>
                            {errors.companylogo?.message}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row> */}
                    {/* <Col sm={12} className="general-setting">
                      <UncontrolledAccordion defaultOpen={["1"]}>
                        <AccordionItem>
                          <AccordionHeader targetId="1">
                            <h6>Project Setting</h6>
                          </AccordionHeader>
                          <AccordionBody accordionId="1">
                            <Row className="mb-4 mt-2">
                              <Col sm={3}>
                                <FormGroup className="mb-4">
                                  <Label>
                                    Project Value Variation (%)
                                    <span className="mandatory">*</span>
                                  </Label>
                                  <UFInput
                                    control={control}
                                    placeholder="Enter the Variation"
                                    name={"percentage"}
                                    invalid={!!errors.percentage}
                                  />
                                  <FormFeedback invalid>
                                    {errors.percentage?.message}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                            </Row>
                          </AccordionBody>
                        </AccordionItem>
                        <hr className="m-0" />
                        <AccordionItem>
                          <AccordionHeader targetId="2">
                            <h6>GRN Setting</h6>
                          </AccordionHeader>
                          <AccordionBody accordionId="2">
                            <Row className="mb-4 mt-2">
                              <Col sm={4}>
                                <FormGroup className="mb-4">
                                  <Label>
                                    Terms and Conditions
                                    <span className="mandatory">*</span>
                                  </Label>
                                  <UFEditor
                                    control={control}
                                    placeholder="Enter Terms and Conditions"
                                    rows="5"
                                    name={"grn_text"}
                                    invalid={!!errors.grn_text}
                                  />
                                  <FormFeedback invalid>
                                    {errors.grn_text?.message}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                            </Row>
                          </AccordionBody>
                        </AccordionItem>
                        <hr className="m-0" />
                        <AccordionItem>
                          <AccordionHeader targetId="3">
                            <h6>Purchase Order Setting</h6>
                          </AccordionHeader>
                          <AccordionBody accordionId="3">
                            <Row className="mb-4 mt-2">
                              <Col sm={3}>
                                <FormGroup className="mb-4">
                                  <Label>
                                    Terms and Conditions
                                    <span className="mandatory">*</span>
                                  </Label>
                                  <UFEditor
                                    control={control}
                                    placeholder="Enter Terms and Conditions"
                                    rows="5"
                                    name={"po_pay_term"}
                                    invalid={!!errors.po_pay_term}
                                  />
                                  <FormFeedback invalid>
                                    {errors.po_pay_term?.message}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                              <Col sm={3}>
                                <FormGroup className="mb-4">
                                  <Label>
                                    Remarks
                                    <span className="mandatory">*</span>
                                  </Label>
                                  <UFEditor
                                    control={control}
                                    placeholder="Enter Remarks"
                                    rows="5"
                                    name={"po_remarks"}
                                    invalid={!!errors.po_remarks}
                                  />
                                  <FormFeedback invalid>
                                    {errors.po_remarks?.message}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                            </Row>
                          </AccordionBody>
                        </AccordionItem>
                        <hr className="m-0" />
                      </UncontrolledAccordion>
                    </Col> */}
                  </Row>
                </Card>
              </Container>
            </Form>
          </FormProvider>
        </div>
      </div>
    </React.Fragment>
  )
}

export default EditProjectSetting
