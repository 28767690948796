import React, { useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Button, Card, Col, Container, Row } from "reactstrap"
import Logo from "../../../assets/images/logo.svg"
import phoneImg from "../../../assets/images/phone.svg"
import webImg from "../../../assets/images/web.svg"
import locationImg from "../../../assets/images/location.svg"
import moment from "moment"
import {
  downloadgrn,
  fetchGRN,
  fetchManpowerGRN,
} from "../../../services/procurement/grn"
import parse from "html-react-parser"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"

const View = ({ fromDashboard, viewValue }) => {
  const { id } = useParams()

  const viewId = fromDashboard ? viewValue?.value?.id : id

  const [grnDetails, setGrnDetails] = useState()
  const [tabledata, setTabledata] = useState()

  const selectLoginState = state => state.Login
  const LoginProperties = createSelector(selectLoginState, login => ({
    loggedUser: login.loggedUser,
  }))

  const { loggedUser } = useSelector(LoginProperties)

  useEffect(() => {
    const fetchGRNdata = async () => {
      const response = await fetchManpowerGRN(viewId)
      setGrnDetails(response?.data)
      // setTabledata(
      //   (response?.data?.grnquantitieslist).filter(
      //     item => item.received_quantity != 0
      //   )
      // )
    }
    if (viewId !== undefined) fetchGRNdata()
  }, [])

  // const result = tabledata?.reduce(
  //   (acc, item) => {
  //     const received = item.received_quantity || 0
  //     return {
  //       totalreceived: Number(acc.totalreceived) + Number(received),
  //     }
  //   },
  //   { totalreceived: 0 }
  // )

  // const { totalreceived } = result || {}
  return (
    <React.Fragment>
      <div
        className={`${
          fromDashboard ? "pt-0 dashboard-page-content-view" : "page-content"
        }`}
      >
        <div className="container-fluid">
          <div className="d-flex justify-content-between me-2">
            {!fromDashboard && (
              <Breadcrumbs
                title="Procurement"
                breadcrumbObject={[
                  { title: "Procurement", link: "/procurement" },
                  { title: "GRN quantity", link: "/procurement-grn" },
                  {
                    title: "GRN View",
                    link: `/procurement-grn/view/${viewId}`,
                  },
                ]}
              />
            )}
          </div>
          <Container fluid className="ps-lg-0">
            <Card className="supplier-card">
              <div className={`purchase-view-div ${fromDashboard && "wdt-96"}`}>
                <Row>
                  <Col>
                    <div className="d-flex justify-content-between me-2">
                      <h3 className="main-heading">GRN </h3>
                      <img className="view-logo" src={Logo} alt="logo" />
                    </div>

                    <Row className=" pb-2 mt-3">
                      <Col lg="3">
                        <span>Manpower GRN No</span>
                        <br />
                        <span className="fw-bold-700">
                          {grnDetails?.grn_code}
                        </span>
                      </Col>
                      <Col lg="3">
                        <span>GRN Date</span>
                        <br />
                        <span className="fw-bold-700">
                          {moment(grnDetails?.created_at).format("DD-MM-YYYY")}
                        </span>
                      </Col>
                      <Col lg="3">
                        <span>PO NO</span>
                        <br />
                        <span className="fw-bold-700">
                          {grnDetails?.po_req_id?.manpower_requests_code}
                        </span>
                      </Col>
                      <Col lg="3">
                        <span>PO Date</span>
                        <br />
                        <span className="fw-bold-700">
                          {moment(grnDetails?.po_req_id?.created_at).format(
                            "DD-MM-YYYY"
                          )}
                        </span>
                      </Col>
                    </Row>
                    <Row className=" pb-2 mt-3">
                      <Col lg="3">
                        <span>Received Date </span>
                        <br />
                        <span className="fw-bold-700">
                          {moment(grnDetails?.recieved_date).format(
                            "DD-MM-YYYY"
                          )}
                        </span>
                      </Col>
                      <Col lg="3">
                        <span>Received by</span>
                        <br />
                        <span className="fw-bold-700">
                          {grnDetails?.received_by}
                        </span>
                      </Col>
                      <Col lg="6">
                        <span>Supplier Name</span>
                        <br />
                        <span className="fw-bold-700">
                          {grnDetails?.po_req_id?.supplier?.name}
                        </span>
                      </Col>
                    </Row>
                    <div className="table_height">
                      <Row className="mt-4">
                        <Col lg={12}>
                          <h5 className="productDetail">Manpower Details</h5>
                        </Col>
                        <Col lg={12}>
                          <table className="estimation-table mb-4">
                            <thead>
                              <tr>
                                <th>S No</th>
                                <th>Cat Code</th>
                                <th>Designation</th>
                                <th>Remarks</th>
                                <th>Number of Hours</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>{1}</td>
                                <td>
                                  {grnDetails?.cat_id?.category_code
                                    ? grnDetails?.cat_id?.category_code
                                    : "-"}
                                </td>
                                <td>
                                  {grnDetails?.cat_id?.designation?.name
                                    ? grnDetails?.cat_id?.designation?.name
                                    : "-"}{" "}
                                </td>
                                <td>
                                  {grnDetails?.description
                                    ? grnDetails?.description
                                    : "-"}
                                </td>
                                <td>
                                  {grnDetails?.no_hours
                                    ? grnDetails?.no_hours
                                    : "-"}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </Col>
                      </Row>
                      <div className="d-flex justify-content-between me-2">
                        <p className="quotation-ten-amount">
                          {grnDetails?.pay_terms &&
                            parse(grnDetails?.pay_terms)}
                        </p>
                        <p>
                          Total Hours Received{" "}
                          <span className="bg-total">
                            {grnDetails?.no_hours ? grnDetails?.no_hours : "-"}
                          </span>
                        </p>
                      </div>
                    </div>

                    <div className="contact-us d-flex align-items-end justify-content-between text-blue border-top mt-5 py-3 font-size-12">
                      <div className="font-bold-800">Contact Us</div>
                      <div>
                        <img
                          src={phoneImg}
                          alt="location"
                          className="footer-logo"
                        />{" "}
                        +{loggedUser?.settings?.phone}
                      </div>
                      <div>
                        <img
                          src={webImg}
                          alt="location"
                          className="footer-logo"
                        />{" "}
                        {loggedUser?.settings?.website}
                      </div>
                      <div>
                        <img
                          src={locationImg}
                          alt="location"
                          className="footer-logo"
                        />{" "}
                        {loggedUser?.settings?.companyaddress}
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              {/* {!fromDashboard && ( */}
              <Row>
                <Col lg={fromDashboard ? 12 : 8}>
                  <div
                    className={`d-flex justify-content-between me-2 ${
                      fromDashboard ? "pe-3" : "pr-50"
                    }`}
                  >
                    <span></span>
                    <Button
                      form={`supplier-form`}
                      color={"primary"}
                      className={"px-2 mb-3 btn btn-primary"}
                      onClick={() => downloadgrn(viewId, "grn.pdf")}
                    >
                      Download
                    </Button>
                  </div>
                </Col>
              </Row>
              {/* )} */}
            </Card>
          </Container>
        </div>
      </div>
    </React.Fragment>
  )
}

export default View
