import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  PopoverBody,
  Row,
  UncontrolledPopover,
} from "reactstrap"
import UpdateTable from "../../../components/Common/UpdatedTable"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import action from "../../../assets/icons/action.svg"
import { toastError, toastSuccess } from "../../../helpers/msg_helper"
import { FormProvider, useForm } from "react-hook-form"
import { UFInput } from "../../../components/Common/Fields/Input"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import LoadingButton from "../../../components/Common/LoadingButton"
import {
  fetchHrmsPayroll,
  fetchPayrollEdit,
  postHrmsPayroll,
  updatePayrollStatus,
} from "../../../services/Hrms/hrms"
import HrmsSearch from "../search"

const schema = yup.object().shape({
  name: yup.string().required("Payroll is Required"),
})
const Payroll = () => {
  document.title = "Payroll | Everise"

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  })
  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: true,
    defaultValues: {
      name: "",
    },
  })
  const [isLoading, setIsLoading] = useState(false)

  const [page, setPage] = useState("")

  const [payrollList, setPayroll] = useState()
  const [count, setCount] = useState(0)
  const [search, setSearch] = useState("")
  const [editPayroll, setEditPayroll] = useState("")
  const [deductionCheck, setDeductionCheck] = useState(false)
  console.log(" deductionCheck :", deductionCheck)
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchHrmsPayroll({
        params: `${search ? `&searchText=${search}` : "&searchText"}${
          page ? `&page=${page}` : ""
        }`,
      })
      setPayroll(response)
    }
    fetchData()
  }, [count, search, page])

  useEffect(() => {
    if (editPayroll.id) {
      reset({ ...editPayroll })
      setDeductionCheck(editPayroll?.is_deduction == "1" ? true : false)
    }
  }, [editPayroll.id])

  const handlePayroll = async data => {
    try {
      setIsLoading(true)
      const createdSeries = await postHrmsPayroll({
        ...data,
        is_deduction: deductionCheck ? 1 : 0,
      })
      toastSuccess(createdSeries?.message)
      reset({
        name: "",
        is_deduction: false,
      })
      setDeductionCheck(false)
      setCount(count + 1)
    } catch (err) {
      toastError(err?.message)
    } finally {
      setIsLoading(false)
      setEditPayroll("")
    }
  }

  const handleEdit = async item => {
    const response = await fetchPayrollEdit(item)
    setEditPayroll(response?.data)
  }

  const handleStatus = async id => {
    try {
      const response = await updatePayrollStatus(id)
      toastSuccess(response?.message)
      setCount(count + 1)
    } catch (err) {
      toastError(err?.message)
    }
  }

  const columns = [
    {
      label: "Payroll",
      renderCell: item => <div className="capitalize-letter">{item?.name}</div>,
      sortKey: "name",
    },
    {
      label: "Is Deduction",
      renderCell: item => (item?.is_deduction == 0 ? "Benefit" : "Deduction"),
      sortKey: "is_deduction",
    },
    {
      label: "Status",
      renderCell: item => (
        <div>
          <div
            className={`font-13 ${
              item?.status == 1 ? "status-active" : "status-inactive"
            }`}
          >
            {item?.status == 1 ? "Active" : "Inactive"}
          </div>
        </div>
      ),
    },
    {
      label: "Action",
      renderCell: item => (
        <>
          <div>
            <span id={`PopoverLegacy-${item?.id}`}>
              <img src={action} alt="" className="pointer" />
            </span>
          </div>
          <UncontrolledPopover
            placement="bottom"
            target={`PopoverLegacy-${item?.id}`}
            trigger="legacy"
          >
            <PopoverBody>
              {item?.id != 1 && (
                <>
                  {item?.status == 1 && (
                    <div
                      className="table-popup-content"
                      onClick={() => handleEdit(item?.id)}
                    >
                      Edit
                    </div>
                  )}
                  <div
                    className={`${
                      item?.status == 1 ? "popover-border" : ""
                    } table-popup-content`}
                    onClick={() => handleStatus(item?.id)}
                  >
                    {item?.status == 1 ? "Deactivate" : "Activate"}
                  </div>
                </>
              )}
            </PopoverBody>
          </UncontrolledPopover>
        </>
      ),
    },
  ]

  const handlePaginate = page => {
    setPage(page)
  }

  const handleSearchChange = data => {
    setSearch(data)
    setPage(1)
  }

  const handleCancel = () => {
    reset({
      name: "",
    })
    setEditPayroll("")
    setDeductionCheck(false)
  }

  const handleDeductionCheck = dt => {
    setDeductionCheck(!dt)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="d-flex justify-content-between">
            <Breadcrumbs
              title="HRMS"
              breadcrumbObject={[
                { title: "HRMS", link: "/hrms" },
                { title: "Payroll", link: "/hrms-payroll" },
              ]}
            />
          </div>

          <Row className="mt-2">
            <Col xs={5}>
              <Card className="me-2 px-2 pb-2 input-card">
                <div className="input-card-head">
                  {editPayroll?.id ? "Edit" : "Add"}
                </div>
                <hr className="table-divide" />
                <FormProvider {...methods}>
                  <Form id={`ev-form`} onSubmit={handleSubmit(handlePayroll)}>
                    <Row className="mt-2">
                      <Col sm={12}>
                        <FormGroup className="mb-4">
                          <Label>
                            Payroll<span className="mandatory">*</span>
                          </Label>
                          <UFInput
                            control={control}
                            placeholder="Enter the payroll"
                            name={"name"}
                            invalid={!!errors.name}
                          />
                          <FormFeedback invalid>
                            {errors.name?.message}
                          </FormFeedback>
                        </FormGroup>
                        <Col
                          lg={"auto"}
                          onClick={() => handleDeductionCheck(deductionCheck)}
                        >
                          <FormGroup>
                            <input
                              type="checkbox"
                              className="ever-rise-checkbox"
                              name={"is_deduction"}
                              checked={deductionCheck}
                            />
                            <Label className="ms-2">Is Deduction</Label>
                          </FormGroup>
                        </Col>
                        <div className="float-end">
                          <Button
                            className="btn-secondary me-2"
                            form={`ev-form`}
                            color={"secondary"}
                            onClick={handleCancel}
                          >
                            Cancel
                          </Button>
                          <LoadingButton
                            form={`ev-form`}
                            color={"primary"}
                            loading={isLoading}
                            disabled={isLoading}
                            type="submit"
                          >
                            {editPayroll?.id ? "Update" : "Create"}
                          </LoadingButton>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </FormProvider>
              </Card>
            </Col>
            <Col xs={7}>
              <Card className="me-2 px-2 pb-2">
                <HrmsSearch
                  handleSearchChange={handleSearchChange}
                  placeholder="Payroll"
                />
                <hr className="table-divide" />
                <Row className="mt-2 ever-rise-table">
                  <Col xs={12}>
                    {payrollList?.data?.data && (
                      <UpdateTable
                        className="mt-5"
                        nodes={payrollList?.data?.data}
                        paginate={payrollList?.data}
                        columns={columns}
                        CurrentPage={page}
                        handlePagination={handlePaginate}
                        tableWidth="40% 25% 20% 15%"
                      />
                    )}
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Payroll
