import React, { useEffect, useState } from "react"
import { Card, Col, PopoverBody, Row, UncontrolledPopover } from "reactstrap"
import UpdateTable from "../../../components/Common/UpdatedTable"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import action from "../../../assets/icons/action.svg"
import EditUnit from "./edit"
import { toastError, toastSuccess } from "../../../helpers/msg_helper"
import InventoryFilter from "../filter"
import {
  fetchCurrency,
  updateCurrencyStatus,
} from "../../../services/Settings/settings"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"

const Currency = () => {
  document.title = "Currency | Everise"

  const selectLoginState = state => state.Login
  const LoginProperties = createSelector(selectLoginState, login => ({
    loggedUser: login.loggedUser,
  }))

  const { loggedUser } = useSelector(LoginProperties)

  const [page, setPage] = useState("")

  const [currency, setCurrency] = useState()
  const [count, setCount] = useState(0)
  const [search, setSearch] = useState("")
  const [status, setStatus] = useState("")
  const [editCurrency, setEditCurrency] = useState({ modal: false, value: "" })

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchCurrency({
        params: `${status == "0" || status == "1" ? `&status=${status}` : ""}${
          search ? `&searchText=${search}` : "&searchText"
        }${page ? `&page=${page}` : ""}`,
      })
      setCurrency(response)
    }
    fetchData()
  }, [count, status, search, page])

  const handleEdit = item => {
    setEditCurrency({ value: item, modal: true })
  }

  const handleStatus = async id => {
    try {
      const response = await updateCurrencyStatus(id)
      toastSuccess(response?.message)
      setCount(count + 1)
    } catch (err) {
      toastError(err?.message)
    }
  }

  const columns = [
    {
      label: "Currency Code",
      renderCell: item => (
        <div className="uppercase-letter">{item?.currency_code}</div>
      ),
      sortKey: "currency_code",
    },
    {
      label: "Currency",
      renderCell: item => <div className="capitalize-letter">{item?.name}</div>,
      sortKey: "name",
    },
    {
      label: "Status",
      renderCell: item => (
        <div>
          <div
            className={`font-13 ${
              item?.status === 1 ? "status-active" : "status-inactive"
            }`}
          >
            {item?.status === 1 ? "Active" : "Inactive"}
          </div>
        </div>
      ),
    },
    {
      label: "Action",
      renderCell: item => (
        <>
          <div>
            <span id={`PopoverLegacy-${item?.id}`}>
              <img src={action} alt="" className="pointer" />
            </span>
          </div>
          <UncontrolledPopover
            placement="bottom"
            target={`PopoverLegacy-${item?.id}`}
            trigger="legacy"
          >
            <PopoverBody>
              {item?.status === 1 &&
                loggedUser?.permission?.some(
                  permissionItem => permissionItem?.name === "currency_edit"
                ) && (
                  <div
                    className="table-popup-content"
                    onClick={() => handleEdit(item)}
                  >
                    Edit
                  </div>
                )}
              <div
                className={`${
                  item?.status === 1 ? "popover-border" : ""
                } table-popup-content`}
                onClick={() => handleStatus(item?.id)}
              >
                {item?.status === 1 ? "Deactivate" : "Activate"}
              </div>
            </PopoverBody>
          </UncontrolledPopover>
        </>
      ),
    },
  ]

  const handlePaginate = page => {
    setPage(page)
  }

  const handleUpdate = () => {
    setCount(count + 1)
  }

  const handleResetValue = () => {
    setEditCurrency("")
  }

  const handleStatusChange = id => {
    setStatus(id)
    setPage(1)
  }
  const handleSearchChange = data => {
    setSearch(data)
    setPage(1)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="d-flex justify-content-between">
            <Breadcrumbs
              title="Settings"
              breadcrumbObject={[
                { title: "Settings", link: "/settings" },
                { title: "Currency", link: "/settings-currency" },
              ]}
            />
            {loggedUser?.permission?.some(
              permissionItem => permissionItem?.name === "currency_create"
            ) && (
              <EditUnit
                key={"x"}
                onUpdate={handleUpdate}
                onReset={handleResetValue}
                buttonLabel="+&nbsp; Add Currency"
                className="px-2 mb-3 btn btn-primary"
                editItem={editCurrency}
              />
            )}
          </div>
          <Card className="me-2 px-2 pb-2">
            <InventoryFilter
              handleStatusChange={handleStatusChange}
              handleSearchChange={handleSearchChange}
              sampleUrl={"download/currency.xlsx"}
              importUrl={"currency/import"}
              showExport={false}
            />
            <hr className="table-divide" />
            <Row className="mt-2 ever-rise-table">
              <Col xs={12}>
                {currency?.data?.data && (
                  <UpdateTable
                    className="mt-5"
                    nodes={currency?.data?.data}
                    paginate={currency?.data}
                    CurrentPage={page}
                    columns={columns}
                    handlePagination={handlePaginate}
                    tableWidth="30% 30% 30% 10%"
                  />
                )}
              </Col>
            </Row>
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Currency
