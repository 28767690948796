import React, { useEffect, useMemo, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"
import Logo from "../../../assets/images/logo.svg"
import phoneImg from "../../../assets/images/phone.svg"
import webImg from "../../../assets/images/web.svg"
import locationImg from "../../../assets/images/location.svg"
import {
  downloadmr,
  fetchRequest,
} from "../../../services/procurement/materialrequest"
import moment from "moment"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import { fetchRequestManPower } from "../../../services/procurement/purchaseOrder"

const View = ({ fromDashboard, viewValue }) => {
  const { id } = useParams()
  const viewId = fromDashboard ? viewValue?.value?.id : id
  const [requestDetails, setRequestDetails] = useState()
  const [tabledata, setTabledata] = useState()
  useEffect(() => {
    const fetchPOdata = async () => {
      const response = await fetchRequestManPower(viewId)
      setRequestDetails(response?.data)
      setTabledata(response?.data?.requests)
    }
    if (viewId !== undefined) fetchPOdata()
  }, [viewId])

  const selectLoginState = state => state.Login
  const LoginProperties = createSelector(selectLoginState, login => ({
    loggedUser: login.loggedUser,
  }))

  const { loggedUser } = useSelector(LoginProperties)

  console.log(viewId, requestDetails, "requestDetails")
  return (
    <React.Fragment>
      <div
        className={`${
          fromDashboard ? "pt-0 dashboard-page-content-view" : "page-content"
        }`}
      >
        <div className="container-fluid">
          <div className="d-flex justify-content-between me-2">
            {!fromDashboard && (
              <Breadcrumbs
                title="Procurement"
                breadcrumbObject={[
                  { title: "Procurement", link: "/procurement" },
                  {
                    title: "Manpower Request",
                    link: "/procurement-materiareq",
                  },
                  {
                    title: "View Manpower Request",
                    link: "",
                  },
                ]}
              />
            )}
          </div>
          <Container fluid className="ps-lg-0">
            <Card className="supplier-card">
              <div className={`purchase-view-div ${fromDashboard && "wdt-96"}`}>
                <Row>
                  <Col>
                    <div className="d-flex justify-content-between me-2">
                      <h3 className="main-heading">Manpower Request </h3>
                      <img className="view-logo" src={Logo} alt="logo" />
                    </div>

                    <Row className=" pb-2 mt-3">
                      <Col lg="3">
                        <span>MPR No</span>
                        <br />
                        <span className="fw-bold-700">
                          {requestDetails?.manpower_code}
                        </span>
                      </Col>
                      <Col lg="3">
                        <span>MR Date</span>
                        <br />
                        <span className="fw-bold-700">
                          {moment(requestDetails?.req_date).format(
                            "DD-MM-YYYY"
                          )}
                        </span>
                      </Col>
                      <Col lg="3">
                        <span>Project Code</span>
                        <br />
                        <span className="fw-bold-700">
                          {requestDetails?.project_id?.project_code}
                        </span>
                      </Col>
                      <Col lg="3">
                        <span>Requested by </span>
                        <br />
                        <span className="fw-bold-700">
                          {requestDetails?.req_by?.name}
                        </span>
                      </Col>
                    </Row>
                    <Row className=" pb-2 mt-3">
                      <Col lg="3">
                        <span>From Date</span>
                        <br />
                        <span className="fw-bold-700">
                          {requestDetails?.from_date}
                        </span>
                      </Col>
                      <Col lg="6">
                        <span>To Date</span>
                        <br />
                        <span className="fw-bold-700">
                          {requestDetails?.to_date}
                        </span>
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <Col lg={12}>
                        <h5 className="productDetail">
                          Manpower Request Details
                        </h5>
                      </Col>
                      <Col lg={12} className="table_height">
                        <table className="estimation-table mb-4">
                          <thead>
                            <tr>
                              <th>S No</th>
                              <th>Cat Code</th>
                              <th>Designation</th>
                              <th>Remarks</th>
                              <th>Number of Hours</th>
                              <th>Expected Nos</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{1}</td>
                              <td>{requestDetails?.category?.category_code}</td>
                              <td>
                                {requestDetails?.category?.designation?.name}
                              </td>
                              <td>{requestDetails?.req_remarks}</td>
                              <td>{requestDetails?.req_manpower_no} Hours</td>
                              <td>{requestDetails?.exp_qty}/day</td>
                            </tr>
                          </tbody>
                        </table>
                      </Col>
                    </Row>
                    <div className="contact-us d-flex align-items-end justify-content-between text-blue border-top mt-5 py-3 font-size-12">
                      <div className="font-bold-800">Contact Us</div>
                      <div>
                        <img
                          src={phoneImg}
                          alt="location"
                          className="footer-logo"
                        />{" "}
                        +{loggedUser?.settings?.phone}
                      </div>
                      <div>
                        <img
                          src={webImg}
                          alt="location"
                          className="footer-logo"
                        />{" "}
                        {loggedUser?.settings?.website}
                      </div>
                      <div>
                        <img
                          src={locationImg}
                          alt="location"
                          className="footer-logo"
                        />{" "}
                        {loggedUser?.settings?.companyaddress}
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              {!fromDashboard && (
                <Row>
                  <Col lg={8}>
                    <div className="d-flex justify-content-between me-2 pr-50">
                      <span></span>
                      <Button
                        form={`supplier-form`}
                        color={"primary"}
                        className={"px-2 mb-3 btn btn-primary"}
                        onClick={() => downloadmr(viewId, "mr.pdf")}
                      >
                        Download
                      </Button>
                    </div>
                  </Col>
                </Row>
              )}
            </Card>
          </Container>
        </div>
      </div>
    </React.Fragment>
  )
}

export default View
