import { yupResolver } from "@hookform/resolvers/yup"
import React, { useState, useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormFeedback,
  Spinner,
  Form,
  FormGroup,
  Label,
} from "reactstrap"
import * as yup from "yup"
import LoadingButton from "../../../components/Common/LoadingButton"
import { UFDate, UFInput } from "../../../components/Common/Fields/Input"
import classnames from "classnames"
import closeIcon from "../../../assets/icons/close.svg"
import { Link } from "react-router-dom"
import { toastError, toastSuccess } from "../../../helpers/msg_helper"
import { postFinancialYear } from "../../../services/Settings/settings"
import CurrencyList from "../../../components/Common/chooseFields/currency"
import moment from "moment"

const schema = yup.object().shape({
  start_date: yup.mixed().required("Start Date is Required"),
  end_date: yup.mixed().required("End Date is Required"),
})

const Edit = ({ loading, onUpdate, id, error, close, ...props }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  })
  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: true,
  })
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (props?.editItem?.modal) {
      reset({ ...props?.editItem?.value })
    }
  }, [props?.editItem?.modal])

  const handleRegistration = async data => {
    try {
      setIsLoading(true)
      const createdSeries = await postFinancialYear({
        ...data,
        start_date: moment(data?.start_date).format("YYYY-MM-DD"),
        end_date: moment(data?.end_date).format("YYYY-MM-DD"),
        label: "/" + moment(data?.start_date).format("YY") + "/",
      })
      toastSuccess(createdSeries?.message)
      onUpdate()
    } catch (err) {
      toastError(err?.message)
    } finally {
      setIsLoading(false)
      reset()
      close()
    }
  }
  let dateWatch = watch("start_date")

  useEffect(() => {
    if (watch("start_date")) {
      setValue(
        "end_date",
        moment(dateWatch)
          .add(1, "years")
          .subtract(1, "days")
          .format("YYYY-MM-DD")
      )
    }
  }, [watch("start_date")])

  return (
    <>
      <ModalHeader className="custom-modal-header">
        <div className="d-flex justify-content-between">
          <h5>
            {props?.editItem?.modal
              ? "Edit Financial Year"
              : "New Financial Year"}
          </h5>{" "}
          <img
            src={closeIcon}
            alt=""
            onClick={close}
            width="10"
            role="button"
          />
        </div>
      </ModalHeader>
      {loading ? (
        <div
          className="px-8"
          style={{
            width: "100%",
            textAlign: "center",
            minHeight: "200px",
            paddingTop: "90px",
          }}
        >
          <Spinner
            className={classnames({
              "mx-2": true,
              "position-relative": true,
              visible: true,
            })}
            size="lg"
          />
        </div>
      ) : (
        <>
          <ModalBody>
            <FormProvider {...methods}>
              <Form id={`ev-form`} onSubmit={handleSubmit(handleRegistration)}>
                <Row>
                  {/* <Col sm={12}>
                    <FormGroup className="mb-4">
                      <Label>
                        Currency<span className="mandatory">*</span>
                      </Label>
                      <CurrencyList
                        control={control}
                        placeholder=""
                        name={"currency_code"}
                        invalid={!!errors.currency_code}
                      />
                      <FormFeedback invalid>
                        {errors.currency_code?.message}
                      </FormFeedback>
                    </FormGroup>
                  </Col> */}
                  <Col sm={12}>
                    <FormGroup className="mb-4">
                      <Label>
                        Start Date<span className="mandatory">*</span>
                      </Label>
                      {/* <UFInput
                        control={control}
                        placeholder=""
                        name={"iso_code"}
                        invalid={!!errors.iso_code}
                      /> */}
                      <UFDate
                        control={control}
                        name={"start_date"}
                        invalid={!!errors.start_date}
                        // minDate={moment().format("YYYY-MM-DD")}
                      />
                      <FormFeedback invalid>
                        {errors.start_date?.message}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm={12}>
                    <FormGroup className="mb-4">
                      <Label>
                        End Date<span className="mandatory">*</span>
                      </Label>
                      <UFDate
                        control={control}
                        name={"end_date"}
                        invalid={!!errors.end_date}
                        disableCondition={true}
                      />
                      <FormFeedback invalid>
                        {errors.end_date?.message}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </FormProvider>
          </ModalBody>
          <ModalFooter>
            <LoadingButton
              form={`ev-form`}
              color={"primary"}
              loading={isLoading}
              disabled={isLoading}
              type="submit"
            >
              {props?.editItem?.modal ? "Update" : "Save"}
            </LoadingButton>
          </ModalFooter>
        </>
      )}
    </>
  )
}

const EditCalender = ({ buttonLabel, className, ...props }) => {
  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)

  useEffect(() => {
    if (props?.editItem?.modal) {
      toggle()
    }
  }, [props?.editItem?.modal])

  return (
    <>
      <Link to="#" className={className} onClick={toggle}>
        {buttonLabel}
      </Link>
      <Modal isOpen={modal} toggle={toggle} backdrop={"static"}>
        <Edit
          close={() => {
            setModal(false)
            props?.onReset()
          }}
          {...props}
        />
      </Modal>
    </>
  )
}

export default EditCalender
