// invetory main index
import Inventory from "../../pages/Inventory/index"
import InventoryList from "../../pages/Invoices/invoices-list"
import InvoiceDetail from "../../pages/Invoices/invoices-detail"
import Unit from "../../pages/Inventory/unit"
import Type from "../../pages/Inventory/type"
import GroupIndex from "../../pages/Inventory/group/index"
import Brand from "../../pages/Inventory/brand"
import Product from "../../pages/Inventory/product/index"
import InventoryFil from "../../pages/Invoices"

export const inventoryRoutes = [
  { path: "/inventory", component: <Inventory /> },
  { path: "/inventory-unit", component: <Unit /> },
  { path: "/inventory-type", component: <Type /> },
  { path: "/inventory-group", component: <GroupIndex /> },
  { path: "/inventory-brand", component: <Brand /> },
  { path: "/inventory-product", component: <Product /> },
  { path: "/inventory-list", component: <InventoryList /> },
  { path: "/invoices-detail", component: <InvoiceDetail /> },
  { path: "/invoices-detail/:id", component: <InvoiceDetail /> },
  { path: "/inventory-fil", component: <InventoryFil /> },
]
