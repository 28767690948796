import { get, post } from "../../helpers/api_helper"

export const postGRN = data => {
  return !data?.id
    ? post(`grn/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`grn/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}

export const searchGRN = data => {
  return get(`grn/search?${data?.params}`)
}
export const fetchGRN = data => {
  return get(`grn/fetch/${data}`)
}

export const updateSupplierStatus = data => {
  return get(`suppliers/updatestatus/${data}`)
}

export const tradeDocDownload = data => {
  return get(`suppliers-trade_download/${data.id}`, {
    responseType: "blob",
  }).then(response => {
    const url = window.URL.createObjectURL(new Blob([response]))
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", `trade`)
    document.body.appendChild(link)
    link.click()
  })
}

export const downloadgrn = (id, fileName) => {
  return get(`grn/pdf-statement/${id}`, {
    responseType: "blob",
  }).then(response => {
    const url = window.URL.createObjectURL(new Blob([response]))
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", fileName)
    document.body.appendChild(link)
    link.click()
  })
}

export const postManpowerGRN = data => {
  return !data?.id
    ? post(`manpower-request-grn/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`manpower-request-grn/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}

export const fetchManpowerGRN = data => {
  return get(`manpower-request-grn/fetch/${data}`)
}