import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  PopoverBody,
  Row,
  UncontrolledPopover,
} from "reactstrap"
import UpdateTable from "../../../components/Common/UpdatedTable"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import action from "../../../assets/icons/action.svg"
import { toastError, toastSuccess } from "../../../helpers/msg_helper"
import { FormProvider, useForm } from "react-hook-form"
import { UFInput } from "../../../components/Common/Fields/Input"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import LoadingButton from "../../../components/Common/LoadingButton"
import {
  fetchHrmsTicketClass,
  fetchTicketClassEdit,
  postHrmsTicketClass,
  updateTicketClassStatus,
} from "../../../services/Hrms/hrms"
import HrmsSearch from "../search"

const schema = yup.object().shape({
  name: yup.string().required("Tickect Class Code is Required"),
})
const TicketClass = () => {
  document.title = "Ticket Class | Everise"

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  })
  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: true,
    defaultValues: {
      name: "",
    },
  })
  const [isLoading, setIsLoading] = useState(false)

  const [page, setPage] = useState("")

  const [listData, setListData] = useState()
  const [count, setCount] = useState(0)
  const [search, setSearch] = useState("")
  const [editData, setEditData] = useState("")

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchHrmsTicketClass({
        params: `${search ? `&searchText=${search}` : "&searchText"}${
          page ? `&page=${page}` : ""
        }`,
      })
      setListData(response)
    }
    fetchData()
  }, [count, search, page])

  useEffect(() => {
    if (editData.id) {
      reset({ ...editData })
    }
  }, [editData.id])

  const handleFormSubmit = async data => {
    try {
      setIsLoading(true)
      const createdSeries = await postHrmsTicketClass(data)
      toastSuccess(createdSeries?.message)
      reset({
        name: "",
      })
      setCount(count + 1)
    } catch (err) {
      toastError(err?.message)
    } finally {
      setIsLoading(false)
      setEditData("")
    }
  }

  const handleEdit = async item => {
    const response = await fetchTicketClassEdit(item)
    setEditData(response?.data)
  }

  const handleStatus = async id => {
    try {
      const response = await updateTicketClassStatus(id)
      toastSuccess(response?.message)
      setCount(count + 1)
    } catch (err) {
      toastError(err?.message)
    }
  }

  const columns = [
    {
      label: "Ticket Class",
      renderCell: item => <div className="capitalize-letter">{item?.name}</div>,
    },
    {
      label: "Status",
      renderCell: item => (
        <div>
          <div
            className={`font-13 ${
              item?.status === 1 ? "status-active" : "status-inactive"
            }`}
          >
            {item?.status === 1 ? "Active" : "Inactive"}
          </div>
        </div>
      ),
    },
    {
      label: "Action",
      renderCell: item => (
        <>
          <div>
            <span id={`PopoverLegacy-${item?.id}`}>
              <img src={action} alt="" className="pointer" />
            </span>
          </div>
          <UncontrolledPopover
            placement="bottom"
            target={`PopoverLegacy-${item?.id}`}
            trigger="legacy"
          >
            <PopoverBody>
              {item?.status === 1 && (
                <div
                  className="table-popup-content"
                  onClick={() => handleEdit(item?.id)}
                >
                  Edit
                </div>
              )}
              <div
                className={`${
                  item?.status === 1 ? "popover-border" : ""
                } table-popup-content`}
                onClick={() => handleStatus(item?.id)}
              >
                {item?.status === 1 ? "Deactivate" : "Activate"}
              </div>
            </PopoverBody>
          </UncontrolledPopover>
        </>
      ),
    },
  ]

  const handlePaginate = page => {
    setPage(page)
  }

  const handleSearchChange = data => {
    setSearch(data)
    setPage(1)
  }

  const handleCancel = () => {
    reset({
      name: "",
    })
    setEditData("")
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="d-flex justify-content-between">
            <Breadcrumbs
              title="HRMS"
              breadcrumbObject={[
                { title: "HRMS", link: "/hrms" },
                { title: "Ticket Class", link: "/hrms-ticketClass" },
              ]}
            />
          </div>

          <Row className="mt-2">
            <Col xs={5}>
              <Card className="me-2 px-2 pb-2 input-card">
                <div className="input-card-head">
                  {editData?.id ? "Edit" : "Add"}
                </div>
                <hr className="table-divide" />
                <FormProvider {...methods}>
                  <Form
                    id={`ev-form`}
                    onSubmit={handleSubmit(handleFormSubmit)}
                  >
                    <Row className="mt-2">
                      <Col sm={12}>
                        <FormGroup className="mb-4">
                          <Label>
                            Ticket Class<span className="mandatory">*</span>
                          </Label>
                          <UFInput
                            control={control}
                            placeholder="Enter the Ticket Class"
                            name={"name"}
                            invalid={!!errors.name}
                          />
                          <FormFeedback invalid>
                            {errors.name?.message}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>
                    <div className="float-end">
                      <Button
                        className="btn-secondary me-2"
                        form={`ev-form`}
                        color={"secondary"}
                        onClick={handleCancel}
                      >
                        Cancel
                      </Button>
                      <LoadingButton
                        form={`ev-form`}
                        color={"primary"}
                        loading={isLoading}
                        disabled={isLoading}
                        type="submit"
                      >
                        {editData?.id ? "Update" : "Create"}
                      </LoadingButton>
                    </div>
                  </Form>
                </FormProvider>
              </Card>
            </Col>
            <Col xs={7}>
              <Card className="me-2 px-2 pb-2">
                <HrmsSearch
                  handleSearchChange={handleSearchChange}
                  placeholder="Ticket Class"
                />
                <hr className="table-divide" />
                <Row className="mt-2 ever-rise-table">
                  <Col xs={12}>
                    {listData?.data?.data && (
                      <UpdateTable
                        className="mt-5"
                        nodes={listData?.data?.data}
                        paginate={listData?.data}
                        columns={columns}
                        CurrentPage={page}
                        handlePagination={handlePaginate}
                        tableWidth="40% 45% 15%"
                      />
                    )}
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default TicketClass
