import Hrms from "../../pages/Hrms"
import Benefits from "../../pages/Hrms/benefits"
import Department from "../../pages/Hrms/department"
import Designation from "../../pages/Hrms/designation"
import EmployeeType from "../../pages/Hrms/employeeType"
import Entitlement from "../../pages/Hrms/entitlement"
import Grade from "../../pages/Hrms/grade"
import Gratuity from "../../pages/Hrms/gratuity"
import Language from "../../pages/Hrms/language"
import LeaveType from "../../pages/Hrms/leaveType"
import Qualification from "../../pages/Hrms/qualification"
import Religion from "../../pages/Hrms/religion"
import Sponsors from "../../pages/Hrms/sponsors"
import TransactionType from "../../pages/Hrms/transactionType"
import TicketClass from "../../pages/Hrms/ticketClass"
import TicketRoute from "../../pages/Hrms/route"
import Degree from "../../pages/Hrms/degree"
import EducationDepartment from "../../pages/Hrms/educationDepartment"
import Payroll from "../../pages/Hrms/payroll"
import Document from "../../pages/Hrms/document"
import GradeControl from "../../pages/Hrms/gradeControl"
import EditGradeControl from "../../pages/Hrms/gradeControl/edit"
import Employee from "../../pages/Hrms/employee"
import EditEmployee from "../../pages/Hrms/employee/edit"
import PayrollControl from "../../pages/Hrms/payrollControl"
import EditPayrollControl from "../../pages/Hrms/payrollControl/edit"
import ViewEmployee from "../../pages/Hrms/employee/view"
import LeaveManagement from "../../pages/Hrms/leaveManagement"
import TimeSheet from "../../pages/Hrms/timeSheet"
import PayrollGenerateIndex from "../../pages/Hrms/payrollGenerate"
import AssignStaffIndex from "../../pages/Hrms/assignStaff"
import PayrollView from "../../pages/Hrms/payrollGenerate/view"

export const hrmsRoutes = [
  { path: "/hrms", component: <Hrms /> },
  { path: "/hrms-grade", component: <Grade /> },
  { path: "/hrms-employee-type", component: <EmployeeType /> },
  { path: "/hrms-gratuity", component: <Gratuity /> },
  { path: "/hrms-qualification", component: <Qualification /> },
  { path: "/hrms-religion", component: <Religion /> },
  { path: "/hrms-language", component: <Language /> },
  { path: "/hrms-department", component: <Department /> },
  { path: "/hrms-designation", component: <Designation /> },
  { path: "/hrms-documents", component: <Document /> },
  { path: "/hrms-entitlement", component: <Entitlement /> },
  { path: "/hrms-sponser", component: <Sponsors /> },
  { path: "/hrms-transaction-type", component: <TransactionType /> },
  { path: "/hrms-benefits", component: <Benefits /> },
  { path: "/hrms-route", component: <TicketRoute /> },
  { path: "/hrms-ticket-class", component: <TicketClass /> },
  { path: "/hrms-trade-category", component: <Language /> },
  { path: "/hrms-leave-type", component: <LeaveType /> },
  { path: "/hrms-degree", component: <Degree /> },
  { path: "/hrms-education-department", component: <EducationDepartment /> },
  { path: "/hrms-payroll", component: <Payroll /> },
  { path: "/hrms-grade-control", component: <GradeControl /> },
  { path: "/hrms-grade-control/add", component: <EditGradeControl /> },
  { path: "/hrms-employee", component: <Employee /> },
  { path: "/hrms-employee/add", component: <EditEmployee /> },
  { path: "/hrms-employee/edit/:id", component: <EditEmployee /> },
  { path: "/hrms-employee/view/:id", component: <ViewEmployee /> },
  { path: "/hrms-grade-control/:id", component: <EditGradeControl /> },
  { path: "/hrms-payroll-control", component: <PayrollControl /> },
  { path: "/hrms-payroll-control/add", component: <EditPayrollControl /> },
  { path: "/hrms-payroll-control/:id", component: <EditPayrollControl /> },
  { path: "/hrms-leave-management", component: <LeaveManagement /> },
  { path: "/hrms-timesheet", component: <TimeSheet /> },
  { path: "/hrms-payroll-generate", component: <PayrollGenerateIndex /> },
  { path: "/hrms-payroll/view/:id", component: <PayrollView /> },
  { path: "/hrms-payroll/edit/:id", component: <PayrollView /> },
  { path: "/hrms-assignstaff", component: <AssignStaffIndex /> },
]
