import { useState, useEffect, useRef } from "react"
import {
  Table,
  Header,
  HeaderRow,
  Body,
  Row,
  HeaderCell,
  Cell,
} from "@table-library/react-table-library/table"
import RightArrow from "../../assets/icons/rightarrow.svg"
import LeftArrow from "../../assets/icons/lefarrow.svg"
import redLeftArrow from "../../assets/icons/redLeftArrow.svg"
import sort_arrows from "../../assets/icons/sort_arrows.svg"
import disabledRightArrow from "../../assets/icons/disabledRightArrow.svg"
import {
  useSort,
  HeaderCellSort,
} from "@table-library/react-table-library/sort"
import { usePagination } from "@table-library/react-table-library/pagination"
import { useTheme } from "@table-library/react-table-library/theme"
import { UncontrolledPopover } from "reactstrap"

import {
  HeaderCellSelect,
  CellSelect,
  SelectClickTypes,
  SelectTypes,
  useRowSelect,
} from "@table-library/react-table-library/select"

const UpdateTable = ({
  nodes,
  paginate,
  columns,
  handlePagination,
  handleSizePerPage,
  tableWidth,
  expand,
  subColumn,
  anotherSubColumn,
  subName,
  anotherSubName,
  subHeader,
  CurrentPage,
  checkbox = false,
}) => {
  const values = { nodes }

  const INITIAL_PARAMS = {
    search: "",
    filter: false,
    page: 1,
  }

  const theme = useTheme({
    Table: `--data-table-library_grid-template-columns:${tableWidth}`,
  })

  const countsPerPage = [2, 3, 5, 7, 8, 9]

  const [data, setData] = useState({
    nodes: [],
    from: 0,
    to: 0,
    cur_page: 1,
    totalPages: 0,
    per_page: 0,
    count: 0,
  })
  const [dataPerPage, setDataPerPage] = useState(data.per_page)
  const handleChange = event => {
    pagination.fns.onSetPage(event.target.value)
  }
  const handlePerPageChange = event => {
    setDataPerPage(event.target.value)
    handleSizePerPage(event.target.value)
  }

  // For Dynamic sorting
  const generateSortFns = columns => {
    const sortFns = {}
    columns.forEach(column => {
      const { label, sortKey } = column
      sortFns[label] = array => {
        if (typeof sortKey === "string") {
          return array.sort((a, b) => a[sortKey]?.localeCompare(b[sortKey]))
        } else if (typeof sortKey === "function") {
          return array.sort(sortKey)
        }
        return array
      }
    })
    return sortFns
  }

  const sort = useSort(
    values,
    {
      onChange: onSortChange,
    },
    {
      sortIcon: {
        // position: SortIconPositions.Prefix, //if we need to bring the sort icon front
        size: "12px",
        iconDefault: <img src={sort_arrows} alt="DEFAULT_IMG" />,
        iconUp: <img src={sort_arrows} alt="UP_IMG" />,
        iconDown: <img src={sort_arrows} alt="DOWN_IMG" />,
      },
      sortFns: generateSortFns(columns),
    }
  )

  function onSortChange(action, state) {
    console.log(action, state)
  }

  // For Pagination
  const pagination = usePagination(
    data,
    {
      state: {
        page: INITIAL_PARAMS.page,
      },
      onChange: onPaginationChange,
    },
    {
      isServer: true,
    }
  )

  useEffect(() => {
    setData({
      nodes: [],
      from: paginate?.from ? paginate?.from : 0,
      to: paginate?.to ? paginate?.to : 0,
      cur_page: paginate?.current_page ? paginate?.current_page : 0,
      totalPages: paginate?.last_page ? paginate?.last_page : 0,
      per_page: paginate?.per_page ? paginate?.per_page : 0,
      count: paginate?.total ? paginate?.total : 0,
    })
  }, [paginate])
  useEffect(() => {
    if (CurrentPage) {
      pagination.fns.onSetPage(CurrentPage)
      setData({
        nodes: [],
        from: paginate?.from ? paginate?.from : 0,
        to: paginate?.to ? paginate?.to : 0,
        cur_page: CurrentPage ? CurrentPage : paginate?.current_page,
        totalPages: paginate?.last_page ? paginate?.last_page : 0,
        per_page: paginate?.per_page ? paginate?.per_page : 0,
        count: paginate?.total ? paginate?.total : 0,
      })
    }
  }, [CurrentPage])
  console.log("CurrentPage ::", CurrentPage)

  function onPaginationChange(action, state) {
    handlePagination(action?.payload?.page)
  }

  const renderPagination = () => {
    const pagesToShow = []

    if (data?.totalPages < 7) {
      for (let i = 1; i <= data?.totalPages; i++) {
        pagesToShow.push(i)
      }
    } else {
      if (
        pagination.state.page % 5 >= 0 &&
        pagination.state.page > 4 &&
        pagination.state.page + 2 < data?.totalPages
      ) {
        pagesToShow.push(
          1,
          pagination.state.page - 1,
          pagination.state.page,
          pagination.state.page + 1,
          data?.totalPages
        )
      } else if (
        pagination.state.page % 5 >= 0 &&
        pagination.state.page > 4 &&
        pagination.state.page + 2 >= data?.totalPages
      ) {
        pagesToShow.push(
          1,
          data?.totalPages - 3,
          data?.totalPages - 2,
          data?.totalPages - 1,
          data?.totalPages
        )
      } else {
        pagesToShow.push(1, 2, 3, 4, 5, data?.totalPages)
      }
    }
    return (
      <span>
        {pagesToShow.map((page, index) => (
          <span key={index} onClick={() => pagination.fns.onSetPage(page)}>
            {pagesToShow[index - 1] + 2 < page ? (
              <span>
                <button
                  className={`${
                    pagination.state.page === page ? "active" : ""
                  }`}
                >
                  ...
                </button>
                <button
                  className={`${
                    pagination.state.page === page ? "active" : ""
                  }`}
                >
                  {page}
                </button>
              </span>
            ) : (
              <button
                className={`${pagination.state.page === page ? "active" : ""}`}
              >
                {page}
              </button>
            )}
          </span>
        ))}
      </span>
    )
  }

  const [ids, setIds] = useState()
  const [subIds, setSubIds] = useState()

  const handleExpand = item => {
    if (ids?.id == item.id) {
      setIds()
      setSubIds()
    } else {
      setIds(item)
      setSubIds()
    }
  }
  const handleSubExpand = item => {
    if (subIds?.id == item.id) {
      setSubIds()
    } else {
      setSubIds(item)
    }
  }

  const onSelectChange = (action, state) => {
    console.log("onSelect change", action, state)
  }

  const select = useRowSelect(data, {
    onChange: onSelectChange,
  })

  // const hoverDataRef = useRef(null)

  // useEffect(() => {
  //   if (hoverDataRef.current) {
  //     const idValue = hoverDataRef.current.getAttribute("id")
  //     console.log("idValue ::", idValue) // Log the value of the id attribute
  //   }
  // }, [hoverDataRef.current])
  return (
    <>
      <div className="h-100 d-flex flex-column justify-content-between">
        <Table
          data={values}
          sort={sort}
          theme={theme}
          select={checkbox && select}
          layout={{ custom: true, horizontalScroll: true }}
          className="mt-0"
        >
          {tableList => (
            <>
              <Header>
                <HeaderRow>
                  {checkbox && <HeaderCellSelect />}
                  {columns.map((column, index) => (
                    <>
                      {column?.sortKey ? (
                        <HeaderCellSort
                          sortKey={column?.label}
                          className="h-50"
                        >
                          {column.label}
                        </HeaderCellSort>
                      ) : (
                        <HeaderCell key={index} className="h-100">
                          {console.log("column.label  :", column)}
                          {column.checkedHead
                            ? column.headerCell(tableList)
                            : column.label}
                        </HeaderCell>
                      )}
                    </>
                  ))}
                </HeaderRow>
              </Header>

              <Body>
                {tableList?.length > 0 ? (
                  tableList.map(item => (
                    <>
                      <Row key={item.id} item={item} className="table_row">
                        {checkbox && <CellSelect item={item} />}

                        {columns.map((column, index) => (
                          <Cell
                            key={index}
                            className={`font-16 ${
                              item?.[subName]?.length && "cursor-pointer"
                            } `}
                            onClick={() =>
                              expand && index == 0 && handleExpand(item)
                            }
                          >
                            <div
                            // className="hover-data"
                            // id={`Popover-${item?.id}`}
                            // ref={hoverDataRef}
                            >
                              {column.renderCell(item)}
                              {expand &&
                              index == 0 &&
                              item?.[subName]?.length ? (
                                <img
                                  src={redLeftArrow}
                                  className="expand-arrow"
                                />
                              ) : (
                                <></>
                              )}
                            </div>
                            {/* <UncontrolledPopover
                              placement="bottom"
                              target={`Popover-${item?.id}`}
                              trigger="hover"
                            >
                              <div className="table-popup-content">
                                {"data is comming"}
                              </div>
                            </UncontrolledPopover> */}
                          </Cell>
                        ))}
                      </Row>
                      <>
                        {ids?.id == item.id && item?.[subName]?.length ? (
                          <Row key={item.id} item={item} className="table_row ">
                            <span className="sub-table-row">
                              <div className="d-flex">
                                {subHeader &&
                                  subColumn.map((column, index) => (
                                    <HeaderCell
                                      key={index}
                                      className="h-100 sub-header w-20"
                                    >
                                      {column.label}
                                    </HeaderCell>
                                  ))}
                              </div>
                              {item?.[subName]?.map(subItem => (
                                <>
                                  <div className="d-flex">
                                    {subColumn?.map((column, index) => (
                                      <>
                                        <Cell
                                          key={index}
                                          className={`font-16 ever-rise-subTable w-20 ${
                                            item?.[subName]?.length &&
                                            "cursor-pointer"
                                          } `}
                                          onClick={() =>
                                            expand &&
                                            index == 0 &&
                                            handleSubExpand(subItem)
                                          }
                                        >
                                          {column.renderCell(subItem)}
                                        </Cell>
                                      </>
                                    ))}
                                  </div>
                                  <div>
                                    {ids?.id == item.id &&
                                    subIds?.id == subItem?.id &&
                                    anotherSubName?.length ? (
                                      <div
                                        key={item.id}
                                        item={item}
                                        className="table_row another_sub_table_row"
                                      >
                                        <span className="another-sub-table-row ">
                                          <div className="d-flex">
                                            {subHeader &&
                                              anotherSubColumn.map(
                                                (column, index) => (
                                                  <HeaderCell
                                                    key={index}
                                                    className="h-100 another-sub-header w-50"
                                                  >
                                                    {column.label}
                                                  </HeaderCell>
                                                )
                                              )}
                                          </div>
                                          <div className="d-flex">
                                            {anotherSubColumn?.map(
                                              (column, index) => (
                                                <Cell
                                                  key={index}
                                                  className="font-16 ever-rise-next-subTable w-50"
                                                >
                                                  {column.renderCell(subIds)}
                                                </Cell>
                                              )
                                            )}
                                          </div>
                                        </span>
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </>
                              ))}
                            </span>
                          </Row>
                        ) : (
                          <></>
                        )}
                      </>
                    </>
                  ))
                ) : (
                  <Row>
                    <Cell className="font-16 table-no-data ">
                      <div className="d-flex justify-content-center">
                        No Data Found
                      </div>
                    </Cell>
                  </Row>
                )}
              </Body>
            </>
          )}
        </Table>
        {data?.totalPages ? (
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
            class="mt-2 pagination-details"
          >
            <span>
              <button
                type="button"
                className="left-pagination-button"
                disabled={data.cur_page === 1}
                onClick={() => pagination.fns.onSetPage(data.cur_page - 1)}
              >
                <img
                  src={data.cur_page === 1 ? LeftArrow : redLeftArrow}
                  alt="Left Arrow"
                />{" "}
                Back
              </button>

              <span>{renderPagination()}</span>
              <button
                type="button"
                className="right-pagination-button"
                disabled={data.cur_page === data.totalPages}
                onClick={() => pagination.fns.onSetPage(data.cur_page + 1)}
              >
                Next{" "}
                <img
                  src={
                    data.cur_page === data.totalPages
                      ? disabledRightArrow
                      : RightArrow
                  }
                  alt="right Arrow"
                />
              </button>
            </span>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  )
}
export default UpdateTable
