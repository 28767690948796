import React, { useEffect, useState } from "react"
import Select from "react-select"
import { Controller } from "react-hook-form"
import { getBrandsList } from "../../../services/Inventory/inventory"

function ucfirst(str) {
  var firstLetter = str.substr(0, 1)
  return firstLetter.toUpperCase() + str.substr(1)
}

const Brands = ({ control, name, onSelectChange }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [fetchResponse, setFetchResponse] = useState([])

  const loadOptions = async () => {
    const data = await getBrandsList()
    setIsLoading(false)
    const response = data?.data?.map(dta => {
      return {
        label: dta?.description && ucfirst(dta.description),
        value: dta.id,
        ...dta,
      }
    })

    setFetchResponse(response)
  }

  useEffect(() => {
    loadOptions()
  }, [])

  return (
    <div>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, ref, value } }) => {
          return (
            <Select
              options={fetchResponse}
              placeholder={"Select"}
              isLoading={isLoading}
              inputRef={ref}
              onChange={e => {
                onChange(e)
                if (onSelectChange) onSelectChange(e)
              }}
              value={value}
              classNamePrefix="react-select"
              className="elevate-dropdown"
              menuPortalTarget={document.querySelector(".modal")}
            />
          )
        }}
      />
    </div>
  )
}

export default Brands
