import React, { useState } from "react"

import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Row,
  Col,
  Form,
  FormGroup,
} from "reactstrap"
import SimpleBar from "simplebar-react"
//i18n
import { withTranslation } from "react-i18next"
import { useForm, FormProvider } from "react-hook-form"
import { UFInput } from "../../components/Common/Fields/Input"
import LoadingButton from "../../components/Common/LoadingButton"
import close from "../../assets/icons/close.svg"
import filter from "../../assets/icons/filter.svg"

const NotificationDropdown = props => {
  // Declare a new state variable, which we'll call "menu"
  const { control, reset } = useForm()
  const [menu, setMenu] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  return (
    <>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className=" noti-icon waves-effect filter-toggle-icon"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <div className="d-flex align-items-center filter-icon">
            <img src={filter} alt="" width="15" />
            <div>Filter</div>
            <div>
              <img src={close} alt="" width="10" />
            </div>
          </div>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0 filter-dropdown-menu">
          <div className="filter-form-wrapper">
            <Form id="user-form">
              <Row className="justify-content-end">
                <Col sm={3} className="pe-1">
                  <FormGroup className="form-group-bottom">
                    <UFInput
                      control={control}
                      placeholder="Product Code"
                      name={"name"}
                    />
                  </FormGroup>
                </Col>
                <Col sm={3} className="pe-1">
                  <FormGroup className="form-group-bottom">
                    <UFInput
                      control={control}
                      placeholder="Part Number"
                      name={"description"}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <hr />
            </Form>
          </div>
          <div className="apply-wrapper">
            <LoadingButton
              form={`ev-form`}
              color={"primary"}
              loading={isLoading}
              disabled={isLoading}
              type="submit"
            >
              Apply Filters
            </LoadingButton>
          </div>
        </DropdownMenu>
      </Dropdown>
    </>
  )
}

export default withTranslation()(NotificationDropdown)

NotificationDropdown.propTypes = {
  t: PropTypes.any,
}
