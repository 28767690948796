import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormFeedback,
  Spinner,
} from "reactstrap"
import classnames from "classnames"
import { Link } from "react-router-dom"
import LoadingButton from "../../../components/Common/LoadingButton"
import { UFDate, UFInput } from "../../../components/Common/Fields/Input"
import { useForm, FormProvider } from "react-hook-form"
import { Form, FormGroup, Label } from "reactstrap"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import closeIcon from "../../../assets/icons/close.svg"
import { toastError, toastSuccess } from "../../../helpers/msg_helper"
import EmployeeList from "../../../components/Common/chooseFields/employee"
import LeaveType from "../../../components/Common/chooseFields/leaveType"
import {
  fetchLeaveManagement,
  postHrmsLeaveManagement,
} from "../../../services/Hrms/hrms"
import moment from "moment"

const schema = yup.object().shape({
  employee_name: yup.string().required("Employee Name is Required"),
  employee_id: yup.mixed().required("Employee ID is Required"),
  requested_date: yup.string().required("Request Date is Required"),
  leave_type: yup.mixed().required("Leave Type is Required"),
  leave_from: yup.string().required("Leave From is Required"),
  leave_to: yup.string().required("Leave To is Required"),
})
const Edit = ({ loading, onUpdate, close, getEditValue }) => {
  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: true,
    defaultValues: {
      employee_id: null,
      leave_type: null,
      employee_name: "",
      requested_date: "",
      leave_from: "",
      leave_to: "",
    },
  })
  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: true,
  })
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (getEditValue) {
      reset({
        ...getEditValue?.data,
        employee_name: `${getEditValue?.data?.employee?.first_name} ${getEditValue?.data?.employee?.middle_name} ${getEditValue?.data?.employee?.last_name}`,
        employee_id: {
          label: getEditValue?.data?.employee?.employee_code,
          value: getEditValue?.data?.employee?.id,
        },
        leave_type: {
          label: getEditValue?.data?.leave_type?.name,
          value: getEditValue?.data?.leave_type?.id,
        },
      })
    }
  }, [getEditValue])

  const handleRegistration = async data => {
    try {
      setIsLoading(true)
      const updateDepartment = await postHrmsLeaveManagement({
        ...data,
        employee_id: data?.employee_id?.value,
        leave_type: data?.leave_type?.value,
        requested_date: moment(data?.requested_date).format("YYYY-MM-DD"),
        leave_from: moment(data?.leave_from).format("YYYY-MM-DD"),
        leave_to: moment(data?.leave_to).format("YYYY-MM-DD"),
      })
      toastSuccess(updateDepartment?.message)
      onUpdate()
      close()
    } catch (err) {
      toastError(err?.message)
    } finally {
      setIsLoading(false)
    }
  }

  const handleEmployeeChange = e => {
    console.log(" event :", e)
    setValue(
      "employee_name",
      `${e?.first_name} ${e?.middle_name} ${e?.last_name}`
    )
  }

  const handleFromDate = () => {
    setValue("leave_to", null)
  }

  return (
    <>
      <ModalHeader className="custom-modal-header">
        <div className="d-flex justify-content-between">
          <h5>{getEditValue?.data?.id ? "Edit Leave" : "New Leave"}</h5>
          <img
            src={closeIcon}
            alt=""
            onClick={close}
            width="10"
            role="button"
          />
        </div>
      </ModalHeader>
      {loading ? (
        <div
          className="px-8"
          style={{
            width: "100%",
            textAlign: "center",
            minHeight: "200px",
            paddingTop: "90px",
          }}
        >
          <Spinner
            className={classnames({
              "mx-2": true,
              "position-relative": true,
              visible: true,
            })}
            size="md"
          />
        </div>
      ) : (
        <>
          <ModalBody>
            <FormProvider {...methods}>
              <Form id={`ev-form`} onSubmit={handleSubmit(handleRegistration)}>
                <Row>
                  <Col sm={6}>
                    <FormGroup className="mb-4">
                      <Label>
                        Employee ID<span className="mandatory">*</span>
                      </Label>
                      <EmployeeList
                        control={control}
                        name={"employee_id"}
                        invalid={!!errors.employee_id}
                        onSelectChange={e => handleEmployeeChange(e)}
                      />
                      <FormFeedback invalid>
                        {errors.employee_id?.message}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm={6}>
                    <FormGroup className="mb-4">
                      <Label>
                        Employee Name<span className="mandatory">*</span>
                      </Label>
                      <UFInput
                        control={control}
                        placeholder=""
                        rows="3"
                        name={"employee_name"}
                        disabled={true}
                        invalid={!!errors.employee_name}
                      />
                      <FormFeedback invalid>
                        {errors.employee_name?.message}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm={6}>
                    <FormGroup className="mb-4">
                      <Label>
                        Request Date<span className="mandatory">*</span>
                      </Label>

                      <UFDate
                        control={control}
                        name={"requested_date"}
                        invalid={!!errors.requested_date}
                        maxDate={moment().format("YYYY-MM-DD")}
                      />
                      <FormFeedback invalid>
                        {errors.requested_date?.message}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm={6}>
                    <FormGroup className="mb-4">
                      <Label>
                        Leave Type<span className="mandatory">*</span>
                      </Label>
                      <LeaveType
                        control={control}
                        name={"leave_type"}
                        invalid={!!errors.leave_type}
                      />
                      <FormFeedback invalid>
                        {errors.leave_type?.message}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm={6}>
                    <FormGroup className="mb-4">
                      <Label>
                        Leave From<span className="mandatory">*</span>
                      </Label>
                      <UFDate
                        control={control}
                        name={"leave_from"}
                        invalid={!!errors.leave_from}
                        onDateChange={handleFromDate}
                        minDate={moment(watch(`requested_date`)).format(
                          "YYYY-MM-DD"
                        )}
                      />
                      <FormFeedback invalid>
                        {errors.leave_from?.message}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm={6}>
                    <FormGroup className="mb-4">
                      <Label>
                        Leave To<span className="mandatory">*</span>
                      </Label>
                      <UFDate
                        control={control}
                        name={"leave_to"}
                        invalid={!!errors.leave_to}
                        minDate={moment(watch(`leave_from`)).format(
                          "YYYY-MM-DD"
                        )}
                      />
                      <FormFeedback invalid>
                        {errors.leave_to?.message}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </FormProvider>
          </ModalBody>
          <ModalFooter>
            <LoadingButton
              form={`ev-form`}
              color={"primary"}
              loading={isLoading}
              disabled={isLoading}
              type="submit"
            >
              {getEditValue?.data?.id ? "Update" : "Save"}
            </LoadingButton>
          </ModalFooter>
        </>
      )}
    </>
  )
}

const EditLeaveManagement = ({
  buttonLabel,
  className,
  editValue,
  ...props
}) => {
  const [modal, setModal] = useState(false)
  const [getEditValue, setGetEditValue] = useState("")

  const toggle = () => setModal(!modal)
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchLeaveManagement(editValue?.id)
      if (response?.status) {
        setGetEditValue(response)
      }
    }
    if (editValue?.id) {
      toggle()
      fetchData()
    }
  }, [editValue?.id])
  return (
    <>
      <Link to="#" className={className} onClick={toggle}>
        {buttonLabel}
      </Link>
      <Modal isOpen={modal} toggle={toggle} size="lg" backdrop={"static"}>
        <Edit
          close={() => {
            setModal(false)
            setGetEditValue("")
            props?.onReset()
          }}
          getEditValue={getEditValue}
          {...props}
        />
      </Modal>
    </>
  )
}

export default EditLeaveManagement
