import { get, post } from "../../helpers/api_helper"

export const postMaterialRequest = data => {
  console.log(data, "post data")
  return !data?.id
    ? post(`material-request/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`material-request/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}

export const searchMaterialRequest = data => {
  console.log(data, "data")
  return get(`material-request/search?${data?.params}`)
}
export const fetchRequest = data => {
  return get(`material-request/fetch/${data}`)
}

export const materiarRequestProductlist = data => {
  return get(`/material-request/productlist/${data}`)
}
export const poProductlist = data => {
  return get(`/purchase-oreder/productlist/${data}`)
}

export const updateSupplierStatus = data => {
  return get(`suppliers/updatestatus/${data}`)
}

export const tradeDocDownload = data => {
  return get(`suppliers-trade_download/${data.id}`, {
    responseType: "blob",
  }).then(response => {
    const url = window.URL.createObjectURL(new Blob([response]))
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", `trade`)
    document.body.appendChild(link)
    link.click()
  })
}
export const downloadmr = (id, fileName) => {
  return get(`/material-request/pdf-statement/${id}`, {
    // params: { ...filter },
    responseType: "blob", // important
  }).then(response => {
    const url = window.URL.createObjectURL(new Blob([response]))
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", fileName)
    document.body.appendChild(link)
    link.click()
  })
}

// Action
export const updateAction = data => {
  return get(
    `material-request/updatestatus?material_request_id=${data?.materialReq}&status=${data?.status}`
  )
}

export const updateManPowerStatus = data => {
  return get(`manpower-request/update-status?manpower_requests_id=${data?.manPowerReq}&status=${data?.status}`)
}
