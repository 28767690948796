import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormFeedback,
  Spinner,
  Card,
  CardBody,
} from "reactstrap"
import classnames from "classnames"
import { Link, useParams } from "react-router-dom"
import LoadingButton from "../../../components/Common/LoadingButton"
import { UFDate, UFInput } from "../../../components/Common/Fields/Input"
import { useForm, FormProvider } from "react-hook-form"
import { Form, FormGroup, Label } from "reactstrap"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import closeIcon from "../../../assets/icons/close.svg"
import { toastError, toastSuccess } from "../../../helpers/msg_helper"
import { fetchProduct } from "../../../services/Inventory/inventory"
import EducationalDegree from "../../../components/Common/chooseFields/eduDegree"
import EducationalDepartment from "../../../components/Common/chooseFields/eduDepartment"
import SingleFileInput from "../../../components/Common/Fields/singleFileInput"
import {
  fetchEmployeeEducation,
  postHrmsEmployeeEducation,
  postHrmsEmployeeIncrement,
} from "../../../services/Hrms/hrms"
import moment from "moment"

const schema = yup.object().shape({
  inv_amount: yup
    .string()
    .matches(/^([+]?)?[0-9]+$|^$/, "Amount must be only digits")
    .required("Amount is Required"),
  date: yup.string().required("Date is Required"),
})
const Edit = ({ loading, onUpdate, close, getEditValue }) => {
  const { id } = useParams()
  const [certificateSts, setCertificateSts] = useState()
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      inv_amount: "",
      date: "",
    },
  })
  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: true,
  })
  const [isLoading, setIsLoading] = useState(false)

  // useEffect(() => {
  //   if (getEditValue?.data?.id) {
  //     setCertificateSts(getEditValue?.data?.certificates)
  //     reset({
  //       ...getEditValue?.data,
  //       basicdetails_id: id,
  //       degree_id: {
  //         label: getEditValue?.data?.degree?.degree,
  //         value: getEditValue?.data?.degree?.id,
  //       },
  //       degree_department_id: {
  //         label: getEditValue?.data?.degree_department?.name,
  //         value: getEditValue?.data?.degree_department?.id,
  //       },
  //     })
  //   }
  // }, [getEditValue])

  const handleRegistration = async data => {
    try {
      setIsLoading(true)

      const updateDepartment = await postHrmsEmployeeIncrement({
        ...data,
        basicdetails_id: id,
        amount:
          (Number(getEditValue?.value?.[0]?.total_salary) +
            Number(data?.inv_amount)) *
          12,
        date: moment(data?.date).format("YYYY-MM-DD"),
      })
      onUpdate()
      close()
      toastSuccess(updateDepartment?.message)
    } catch (err) {
      toastError(err?.message)
    } finally {
      setIsLoading(false)
    }
  }

  const handleChange = (name, file) => {
    setValue(name, file)
    setCertificateSts("")
  }

  return (
    <>
      <ModalHeader className="custom-modal-header">
        <div className="d-flex justify-content-between">
          <h5>{getEditValue?.data?.id ? "Edit Increment" : "Add Increment"}</h5>
          <img
            src={closeIcon}
            alt=""
            onClick={close}
            width="10"
            role="button"
          />
        </div>
      </ModalHeader>
      {loading ? (
        <div
          className="px-8"
          style={{
            width: "100%",
            textAlign: "center",
            minHeight: "200px",
            paddingTop: "90px",
          }}
        >
          <Spinner
            className={classnames({
              "mx-2": true,
              "position-relative": true,
              visible: true,
            })}
            size="md"
          />
        </div>
      ) : (
        <>
          <ModalBody>
            <FormProvider {...methods}>
              <Form id={`ev-form`} onSubmit={handleSubmit(handleRegistration)}>
                <Row>
                  <Col sm={6}>
                    <FormGroup className="mb-4">
                      <Label>
                        Current Annual Salary(AED)
                        <span className="mandatory">*</span>
                      </Label>
                      <div className="supplier-td-body">
                        {Number(getEditValue?.value?.[0]?.total_salary) * 12}
                      </div>
                    </FormGroup>
                  </Col>
                  <Col sm={6}>
                    <FormGroup className="mb-4">
                      <Label>
                        Current Monthly Salary (AED)
                        <span className="mandatory">*</span>
                      </Label>
                      <div className="supplier-td-body">
                        {Number(getEditValue?.value?.[0]?.total_salary)}
                      </div>
                    </FormGroup>
                  </Col>
                  <Col sm={6}>
                    <FormGroup className="mb-4">
                      <Label>
                        Annual Increment Amount (AED)
                        <span className="mandatory">*</span>
                      </Label>
                      <UFInput
                        control={control}
                        placeholder="Enter the Increment Amount"
                        name={`inv_amount`}
                        invalid={!!errors?.inv_amount}
                      />
                      <FormFeedback invalid>
                        {errors.inv_amount?.message}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm={6}>
                    <FormGroup className="mb-4">
                      <Label>
                        Increment Date
                        <span className="mandatory">*</span>
                      </Label>
                      <UFDate
                        control={control}
                        name={`date`}
                        invalid={!!errors?.date}
                      />
                      <FormFeedback invalid>
                        {errors.date?.message}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col lg={6}></Col>
                  <Col sm={6}>
                    <FormGroup className="mb-4 ">
                      <Card className="rounded-0 border-0 increment-card">
                        <CardBody>
                          <Label>Increment Monthly Gross Salary (AED)</Label>
                          <div>
                            {(Number(getEditValue?.value?.[0]?.total_salary) +
                              Number(watch("inv_amount"))) *
                              12}
                          </div>
                        </CardBody>
                      </Card>
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </FormProvider>
          </ModalBody>
          <ModalFooter>
            <LoadingButton
              form={`ev-form`}
              color={"primary"}
              loading={isLoading}
              disabled={isLoading}
              type="submit"
            >
              {getEditValue?.data?.id ? "Update" : "Save"}
            </LoadingButton>
          </ModalFooter>
        </>
      )}
    </>
  )
}

const EditIncrement = ({ buttonLabel, className, editValue, ...props }) => {
  const [modal, setModal] = useState(false)
  const [getEditValue, setGetEditValue] = useState("")

  const toggle = () => setModal(!modal)
  console.log("editValue:", editValue)
  return (
    <>
      <Link to="#" className={className} onClick={toggle}>
        {buttonLabel}
      </Link>
      <Modal isOpen={modal} toggle={toggle} size="lg" backdrop={"static"}>
        <Edit
          close={() => {
            setModal(false)
            setGetEditValue("")
            props?.onReset()
          }}
          getEditValue={editValue}
          {...props}
        />
      </Modal>
    </>
  )
}

export default EditIncrement
