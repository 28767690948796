import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormFeedback,
  Spinner,
} from "reactstrap"
import { isEmpty } from "lodash"
import classnames from "classnames"
import { Link } from "react-router-dom"
import LoadingButton from "../../components/Common/LoadingButton"
import { UFInput } from "../../components/Common/Fields/Input"
import FileInput from "../../components/Common/Fields/FileInput"
import { useForm, FormProvider } from "react-hook-form"
import { Form, FormGroup, Label } from "reactstrap"
// import { postDepartment, getDepartment } from "../../services/inventory"
import { yupResolver } from "@hookform/resolvers/yup"
import { toastError, toastSuccess } from "../../helpers/msg_helper"
import * as yup from "yup"
import closeIcon from "../../assets/icons/close.svg"

const schema = yup.object().shape({
  name: yup.string().required("Department Name is Required"),
  description: yup.string().required("Description is Required"),
})
const EditHoc = ({ loading, onUpdate, id, error, close, ...props }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  })
  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: true,
  })
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    // const fetchData = async () => {
    //   const department = await getDepartment(id)
    //   if (!isEmpty(department.data) && id === department?.data?.id) {
    //     reset({
    //       ...department.data
    //     })
    //   }
    // }
    // if (id !== undefined) fetchData();
  }, [])

  const handleRegistration = async data => {
    //  try {
    //   setIsLoading(true);
    //   const updateDepartment = await postDepartment({
    //     ...data
    //    })
    //   toastSuccess(updateDepartment.message)
    //   setIsLoading(false);
    //   onUpdate();
    //   close();
    // } catch (err) {
    //   toastError(err?.message)
    // }
  }

  return (
    <>
      <ModalHeader className="custom-modal-header">
        <div className="d-flex justify-content-between">
          <h5>{id ? "Edit" : "New Country"}</h5>{" "}
          <img
            src={closeIcon}
            alt=""
            onClick={close}
            width="10"
            role="button"
          />
        </div>
      </ModalHeader>
      {loading ? (
        <div
          className="px-8"
          style={{
            width: "100%",
            textAlign: "center",
            minHeight: "200px",
            paddingTop: "90px",
          }}
        >
          <Spinner
            className={classnames({
              "mx-2": true,
              "position-relative": true,
              visible: true,
            })}
            size="lg"
          />
        </div>
      ) : (
        <>
          <ModalBody>
            <FormProvider {...methods}>
              <Form id={`ev-form`} onSubmit={handleSubmit(handleRegistration)}>
                <Row>
                  <Col sm={12}>
                    <FormGroup className="mb-4">
                      <Label>
                        Country Code<span className="mandatory">*</span>
                      </Label>
                      <UFInput
                        control={control}
                        placeholder=""
                        name={"name"}
                        invalid={!!errors.name}
                      />
                      <FormFeedback invalid>
                        {errors.name?.message}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm={12}>
                    <FormGroup className="mb-4">
                      <Label>
                        Country Name<span className="mandatory">*</span>
                      </Label>
                      <UFInput
                        control={control}
                        placeholder=""
                        name={"description"}
                        invalid={!!errors.description}
                      />
                      <FormFeedback invalid>
                        {errors.description?.message}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm={12}>
                    <FormGroup className="mb-3 mt-2">
                      <FileInput
                        accept="image/png, image/jpg, image/jpeg"
                        name="logo"
                        // label="Logo"
                      />
                      {/* <FormFeedback invalid style={{ display: validation ? 'block' : 'none' }}>Logo is Required</FormFeedback> */}
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </FormProvider>
          </ModalBody>
          <ModalFooter>
            <LoadingButton
              form={`ev-form`}
              color={"primary"}
              loading={isLoading}
              disabled={isLoading}
              type="submit"
            >
              Save
            </LoadingButton>
          </ModalFooter>
        </>
      )}
    </>
  )
}

const HOCGiftForm = EditHoc

const EditInventory = ({ buttonLabel, className, ...props }) => {
  const [modal, setModal] = useState(false)

  const toggle = () => setModal(!modal)

  return (
    <>
      <Link to="#" className={className} onClick={toggle}>
        {buttonLabel}
      </Link>
      <Modal isOpen={modal} toggle={toggle}>
        <HOCGiftForm
          close={() => {
            setModal(false)
          }}
          {...props}
        />
      </Modal>
    </>
  )
}

export default EditInventory
