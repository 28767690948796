import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Col, Row, Card, UncontrolledPopover, PopoverBody } from "reactstrap"
import UpdateTable from "../../components/Common/UpdatedTable"
import action from "../../assets/icons/action.svg"
import check from "../../assets/icons/estimation_check.svg"
import EstimationFilter from "./filter"
import { Link, useNavigate } from "react-router-dom"
import { searchEstimation } from "../../services/estimation/estimation"
import moment from "moment"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"

const EstimationIndex = () => {
  document.title = "Estimation | Ever Rise"

  const selectLoginState = state => state.Login
  const LoginProperties = createSelector(selectLoginState, login => ({
    loggedUser: login.loggedUser,
  }))

  const { loggedUser } = useSelector(LoginProperties)

  const navigate = useNavigate()
  const [classData, setClassData] = useState()
  const [status, setStatus] = useState("")
  const [search, setSearch] = useState("")
  const [customer, setCustomer] = useState("")
  const [location, setLocation] = useState("")
  const [financial, setFinancial] = useState(true)
  const [page, setPage] = useState("")
  const [datefrom, setDatefrom] = useState()
  const [dateto, setDateto] = useState()

  const columns = [
    {
      label: "Estimation Code",
      renderCell: item => {
        return (
          <div
            onClick={() => navigate(`/estimation-view/${item?.id}`)}
            className="pointer fw-bold"
          >
            <span className="pe-2"> {item?.estimation_code}</span>
            {!item?.estimation_revision?.every(item => item?.status == 0) && (
              <img src={check} />
            )}
          </div>
        )
      },
    },
    {
      label: "Customer Name",
      renderCell: item => item?.customers?.customer_name,
      sortKey: "customer_name",
    },
    {
      label: "Bid date",
      renderCell: item => moment(item?.bid_date).format("DD-MM-YYYY"),
    },
    {
      label: "Update date",
      renderCell: item => moment(item?.updated_at).format("DD-MM-YYYY"),
    },
    {
      label: "Amount(AED)",
      renderCell: item => {
        if (item?.estimation_revision?.some(item => item?.status == 1)) {
          return Number(
            Number(
              item?.estimation_revision.find(item => item?.status == 1)?.amount
            ).toFixed(2)
          ).toLocaleString("en", { minimumFractionDigits: 2 })
        } else {
          return Number(
            Number(
              item?.estimation_revision[item?.estimation_revision?.length - 1]
                ?.amount
            ).toFixed(2)
          ).toLocaleString("en", { minimumFractionDigits: 2 })
        }
      },

      // item?.estimation_material_sum_price +
      // item?.estimation_manpower_sum_price +
      // item?.estimation_overhead_sum_price +
      // item?.estimation_subcontract_sum_price,
    },

    {
      label: "Action",
      renderCell: item => (
        <>
          <div>
            <span id={`PopoverLegacy-${item?.id}`}>
              <img src={action} alt="" className="pointer" />
            </span>
          </div>
          <UncontrolledPopover
            placement="bottom"
            target={`PopoverLegacy-${item?.id}`}
            trigger="legacy"
          >
            <PopoverBody>
              <>
                {item?.estimation_revision?.every(item => item?.status == 0) &&
                item?.estimation_code?.includes(loggedUser?.finance) ? (
                  <div
                    className="table-popup-content"
                    onClick={() =>
                      navigate(
                        `/estimation-edit/${
                          item?.estimation_revision?.[
                            item?.estimation_revision?.length - 1
                          ]?.id
                        }`
                      )
                    }
                  >
                    Edit
                  </div>
                ) : // item?.estimation_revision?.every(
                //     item => item?.status == 0
                //   ) &&
                item?.project_status == 0 &&
                  !item?.estimation_revision?.every(
                    item => item?.status == 0
                  ) &&
                  (loggedUser?.permission?.some(
                    permissionItem =>
                      permissionItem?.name === "Convert to Project"
                  ) ||
                    loggedUser?.data?.id === 1) ? (
                  <div
                    className="table-popup-content"
                    onClick={() =>
                      navigate(
                        `/project-master/add/${
                          item?.estimation_revision.find(
                            item => item?.status == 1
                          )?.id
                        }`
                      )
                    }
                  >
                    Convert to Project
                  </div>
                ) : (
                  <></>
                )}
                <div
                  className={`${
                    item?.status === 1 ? "popover-border" : ""
                  } table-popup-content`}
                  onClick={() => navigate(`/estimation-view/${item?.id}`)}
                >
                  View revisions
                </div>
              </>
            </PopoverBody>
          </UncontrolledPopover>
        </>
      ),
    },
  ]

  const handlePaginate = page => {
    setPage(page)
  }

  const handleDateChange = (fromdate, todate) => {
    setDatefrom(fromdate)
    setDateto(todate)
    setPage(1)
  }

  console.log(
    `chk`,
    loggedUser?.permission?.some(
      permissionItem => permissionItem?.name != "Estimation Approve"
    )
  )

  useEffect(() => {
    const fetchClass = async () => {
      const response = await searchEstimation({
        params: `${status == "0" || status == "1" ? `&status=${status}` : ""}${
          search ? `&searchText=${search}` : "&searchText"
        }${customer ? `&client_code=${customer}` : ""}${
          location ? `&location_id=${location}` : ""
        }${page ? `&page=${page}` : ""}${`&financial=${financial}`}${
          datefrom ? `&from_date=${datefrom}` : ""
        }${dateto ? `&to_date=${dateto}` : ""}`,
      })
      setClassData(response)
    }
    if (loggedUser?.data?.id) {
      fetchClass()
    }
  }, [
    status,
    search,
    customer,
    page,
    location,
    loggedUser,
    financial,
    datefrom,
    dateto,
  ])

  const handleStatusChange = id => {
    setStatus(id)
    setPage(1)
  }
  const handleCustomerChange = id => {
    setCustomer(id)
    setPage(1)
  }
  const handleLocationChange = id => {
    setLocation(id)
    setPage(1)
  }
  const handleFinacialChange = id => {
    setFinancial(id)
    setPage(1)
  }
  const handleSearchChange = data => {
    setSearch(data)
    setPage(1)
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="d-flex justify-content-between">
            <Breadcrumbs
              title="Estimation"
              breadcrumbObject={[
                { title: "Estimation", link: "/estimation" },
                { title: "Estimation List", link: "/estimation" },
              ]}
            />
            {loggedUser?.permission?.some(
              permissionItem => permissionItem?.name === "estimations_create"
            ) && (
              <Link
                to="/estimation-add"
                className={"px-2 mb-3 btn btn-primary"}
              >
                {"+ Add Estimation"}
              </Link>
            )}
          </div>
          <Card className="me-2 px-2 pb-2">
            <EstimationFilter
              handleStatusChange={handleStatusChange}
              handleSearchChange={handleSearchChange}
              handleCustomerChange={handleCustomerChange}
              handleLocationChange={handleLocationChange}
              handleFinacialChange={handleFinacialChange}
              handleDateChange={handleDateChange}
              sampleUrl={"download/suppliers.xlsx"}
              importUrl={"suppliers/import"}
              isImport={false}
            />
            <hr className="table-divide" />
            <Row className="mt-2 ever-rise-table">
              <Col xs={12}>
                {classData && (
                  <UpdateTable
                    className="mt-5"
                    nodes={classData?.data?.data}
                    paginate={classData?.data}
                    columns={columns}
                    handlePagination={handlePaginate}
                    CurrentPage={page}
                    tableWidth="15% 20% 20% 20% 15% 10%"
                  />
                )}
              </Col>
            </Row>
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}

export default EstimationIndex
