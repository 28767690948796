import React, { useState, useEffect } from "react"
import { useNavigate, useParams, useLocation } from "react-router-dom"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  Row,
} from "reactstrap"
import { FormProvider, useFieldArray, useForm, useWatch } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { toastError, toastSuccess } from "../../../helpers/msg_helper"
import {
  RichTextEditor,
  UFDate,
  UFEditor,
  UFInput,
} from "../../../components/Common/Fields/Input"
import LoadingButton from "../../../components/Common/LoadingButton"
import lightTrash from "../../../assets/icons/light-trash.svg"
import moment from "moment"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { fetchPurchase } from "../../../services/procurement/purchaseOrder"
import { fetchGRN, postGRN } from "../../../services/procurement/grn"
import { convertToRaw } from "draft-js"
import draftToHtml from "draftjs-to-html"
import POPRoducts from "../../../components/Common/chooseFields/poproducts"
import GRNPurchaseNo from "../../../components/Common/chooseFields/grnPurchaseNo"
import Unit from "../../../components/Common/chooseFields/unit"
import { useSelector } from "react-redux"
import GRNCastNo from "../../../components/Common/chooseFields/grnCosting"
import PaymentMode from "../../../components/Common/chooseFields/paymentMode"
import CreditPeriod from "../../../components/Common/chooseFields/creditPeriod"
import SingleFileInput from "../../../components/Common/Fields/singleFileInput"
import FinanceSubCat from "../../../components/Common/chooseFields/financeSubCat"
import { postGRNCosting } from "../../../services/finance/grnCosting"
import FinanceCat from "../../../components/Common/chooseFields/financeCat"
import { postFinanceSubCategory } from "../../../services/finance/finance"

const schema = yup.object().shape({
    grn_id: yup.mixed().required("GRN is required"),
  //   recieved_date: yup
  //     .mixed()
  //     .test(
  //       "is-future-date",
  //       "Make sure the received date is forward-looking",
  //       function (value) {
  //         if (!value) return true
  //         const currentDate = moment()
  //         const selectedDate = moment(value)
  //         return selectedDate.isSameOrBefore(currentDate, "day")
  //       }
  //     )
  //     .required("Received date is required"),
  // received_by: yup.string().required("Received by is required"),
  //   grnproductlist: yup.array().of(
  //     yup.object().shape({
  //       product_code: yup.mixed().required("product code is required"),
  //       description: yup.string().required("description is required"),
  //       unit: yup.mixed().required("Unit is Required"),
  //       received_quantity: yup
  //         .string()
  //         .matches(
  //           /^\d*(\.\d+)?$/,
  //           "Pending quantity must be a non-negative number"
  //         )
  //         .test(
  //           "max",
  //           "pending quantity must not exceed the ordered quantity",
  //           function (value) {
  //             const { pending_quantity } = this.parent
  //             return parseInt(value) <= pending_quantity
  //           }
  //         )
  //         .required("pending quantity is required"),
  //     })
  //   ),
})

const EditGRNCosting = ({ fromDashboard, projectId, poValue, toggle }) => {
  const navigate = useNavigate()
  const { editId } = useParams()
  const id = fromDashboard ? "" : editId
  const [isLoading, setIsLoading] = useState(false)
  const [idPO, setIdPO] = useState()
  const [grnVal, setGrnVal] = useState()
  const [refresh, setRefresh] = useState(0)
  const location = useLocation()

  const loggedUser = useSelector(state => state.Login.loggedUser)
  console.log(`grnVal`, grnVal)
  const {
    control,
    handleSubmit,
    watch,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      grn_id: null,
      received_by: "",
      transaction_date: moment().format("YYYY-MM-DD"),
      recieved_date: null,
      material_requests_id: "",
      grnproductlist: [
        {
          product_code: null,
          description: "",
          unit: null,
          ordered_quantity: 0,
          received_quantity: 0,
          pending_quantity: 0,
        },
      ],
    },
  })

  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: true,
  })
  const {
    fields: poFields,
    append: materialAppend,
    remove: materialRemove,
  } = useFieldArray({
    control,
    name: "grnproductlist",
  })
  const watchTest = useWatch({
    control,
    name: "grnproductlist",
    defaultValue: poFields,
  })
  useEffect(() => {
    if (location?.state && !poValue) {
      reset({
        grn_id: location?.state?.purchase_orders_code && {
          label: location?.state?.purchase_orders_code,
          value: location?.state?.id,
        },
      })

      fetchPotData(location?.state?.id)
      setIdPO(location?.state?.id)
    }
  }, [location])

  useEffect(() => {
    if (fromDashboard) {
      reset({
        grn_id: poValue?.value && {
          label: poValue?.label,
          value: poValue?.value,
        },
      })

      handlePoChange(poValue)
    }
  }, [fromDashboard])
  const handleRegistration = async data => {
    // const Payterms = data?.pay_terms
    //   ? draftToHtml(convertToRaw(data?.pay_terms?.getCurrentContent()))
    //   : ""

    console.log(`Register `, data)

    try {
      setIsLoading(true)
      const update = await postGRNCosting({
        // ...data,
        project_id: data?.project_id?.id,
        grn_id: data?.grn_id?.value,
        pay: data?.pay,
        payment_mode_id: data?.payment_mode?.value,
        transaction_date: data?.transaction_date,
        description: data?.description,
        transaction_doc: data?.transaction_doc,

        // received_by: loggedUser?.data?.name,
        // recieved_date: moment(data?.recieved_date).format("YYYY-MM-DD"),
        // pay_terms: data?.pay_terms,
        // grnproductlist: arrayData,
      })
      if (fromDashboard) {
        toggle()
      } else {
        navigate("/finance-grn-costing")
      }
      toastSuccess(update?.message)
    } catch (err) {
      toastError(err?.message)
    } finally {
      setIsLoading(false)
    }

    // if (
    //   data?.grnproductlist?.reduce(
    //     (a, b) => a + Number(b?.received_quantity),
    //     0
    //   ) > 0
    // ) {
    //   let arrayData = data?.grnproductlist?.map(element => ({
    //     product_code: element?.product_code?.value,
    //     description: element.description,
    //     unit: element.unit.value,
    //     ordered_quantity: element.ordered_quantity,
    //     received_quantity: element.received_quantity,
    //     pending_quantity: element.ordered_quantity - element.received_quantity,
    //   }))
    //   console.log(`arrayData`, arrayData)
    //   try {
    //     setIsLoading(true)
    //     const update = await postGRN({
    //       ...data,
    //       project_id: data?.project_id,
    //       material_requests_id: data?.material_requests_id,
    //       grn_id: data?.grn_id?.value,
    //       received_by: loggedUser?.data?.name,
    //       recieved_date: moment(data?.recieved_date).format("YYYY-MM-DD"),
    //       pay_terms: data?.pay_terms,
    //       grnproductlist: arrayData,
    //     })
    //     if (fromDashboard) {
    //       toggle()
    //     } else {
    //       navigate("/procurement-grn")
    //     }
    //     toastSuccess(update?.message)
    //   } catch (err) {
    //     toastError(err?.message)
    //   } finally {
    //     setIsLoading(false)
    //   }
    // } else {
    //   toastError("To generate a GRN, at least one product must be received.")
    // }
  }
  const handlePoChange = () => {
    const { grn_id } = getValues()
    fetchPotData(grn_id?.value)
    setIdPO(grn_id?.value)
  }
  const fetchPotData = async idData => {
    const response = await fetchGRN(idData)
    console.log(`response`, response)
    setGrnVal(response)
    if (idData == response.data?.id) {
      const previousDatas = getValues()
      reset({
        ...previousDatas,
        grn_date: response?.data?.recieved_date,
        po_id: response?.data?.po_id?.purchase_orders_code,
        supplier_code: response?.data?.po_id?.supplier?.suppliers_code,
        payment_mode: {
          label: response?.data?.po_id?.supplier?.payment?.name,
          value: response?.data?.po_id?.supplier?.payment?.id,
        },
        creditperiod: {
          label: `${
            response?.data?.po_id?.supplier?.creditperiod == 0
              ? "30 Days"
              : response?.data?.po_id?.supplier?.creditperiod == 1
              ? "60 Days"
              : "90 Days"
          }`,
          value: response?.data?.po_id?.supplier?.creditperiod,
        },
        project_id: response.data?.project_id,
        supplier_name: response.data?.supplier?.name,
        pay_terms: response.data?.pay_terms,
      })
    }
  }
  const handleChange = (name, file) => {
    console.log(`handleChange`, name, file)
    setValue(name, file[0])
  }
  // const handleSubCat = async ({ cat_id, name }) => {
  //   try {
  //     const response = await postFinanceSubCategory({
  //       cat_id: cat_id?.value,
  //       name: name,
  //     })
  //     toastSuccess(response?.message)
  //     setValue("sub_cat_id", null)
  //     setRefresh(refresh + 1)
  //   } catch (err) {
  //     toastError(err?.message)
  //   }
  // }

  return (
    <React.Fragment>
      <div
        className={`${
          fromDashboard ? "pt-0 dashboard-page-content" : "page-content"
        }`}
      >
        <div className="container-fluid">
          <FormProvider {...methods}>
            <Form
              id={`request-form`}
              onSubmit={handleSubmit(handleRegistration)}
            >
              <div className="sticky-top bg-white w-100">
                <div className="d-flex justify-content-between pe-3">
                  {fromDashboard ? (
                    <div></div>
                  ) : (
                    <Breadcrumbs
                      title="Finance"
                      breadcrumbObject={[
                        { title: "Finance", link: "/finance" },
                        {
                          title: "GRN Costing Add",
                          link: "/finance-grn-costing/add",
                        },
                        {
                          title: id ? "Edit GRN Costing" : "Add GRN Costing",
                          link: "",
                        },
                      ]}
                    />
                  )}
                  {watch("sub_cat_id")?.label == "Add Others" ? (
                    <></>
                  ) : (
                    <LoadingButton
                      form={`request-form`}
                      color={"primary"}
                      loading={isLoading}
                      className={"px-2 mb-3 btn btn-primary"}
                      type="submit"
                    >
                      {id ? "Update" : "Save"}
                    </LoadingButton>
                  )}
                </div>
              </div>
              <Container fluid className="ps-lg-0">
                <Card className="invoice-main-card p-3 pt-4">
                  <Row className="mb-4 mt-2">
                    <Col sm={4}>
                      <FormGroup className="mb-4">
                        <Label>
                          GRN number <span className="mandatory">*</span>
                        </Label>
                        <GRNCastNo
                          control={control}
                          placeholder="Enter the PO number"
                          name={"grn_id"}
                          target={fromDashboard ? ".modal" : "body"}
                          onSelectChange={handlePoChange}
                          invalid={!!errors.grn_id}
                          param="0"
                          projectId={fromDashboard ? projectId : ""}
                        />
                        <FormFeedback invalid>
                          {errors.grn_id?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col sm={4}>
                      <FormGroup className="mb-4">
                        <Label>GRN Date</Label>
                        <UFDate
                          control={control}
                          name={"grn_date"}
                          invalid={!!errors.grn_date}
                          maxDate={moment().format("YYYY-MM-DD")}
                          disableCondition={true}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm={4}>
                      <FormGroup className="mb-4">
                        <Label>PO no</Label>
                        <UFInput
                          control={control}
                          placeholder="Enter the PO no"
                          name={"po_id"}
                          disabled={true}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm={4}>
                      <FormGroup className="mb-4">
                        <Label>Supplier Code</Label>
                        <UFInput
                          control={control}
                          placeholder="Enter the supplier code"
                          name={"supplier_code"}
                          disabled={true}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm={4}>
                      <FormGroup className="mb-4">
                        <Label>
                          Payment Mode<span className="mandatory">*</span>
                        </Label>
                        <PaymentMode
                          control={control}
                          placeholder="select"
                          name={"payment_mode"}
                          invalid={!!errors.payment_mode}
                          target={fromDashboard ? ".modal" : "body"}
                          disable={true}
                        />
                        <FormFeedback invalid>
                          {errors.payment_mode?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col sm={4}>
                      <FormGroup className="mb-4">
                        <Label>
                          Transaction Date<span className="mandatory">*</span>
                        </Label>
                        <UFDate
                          control={control}
                          name={"transaction_date"}
                          invalid={!!errors.transaction_date}
                          maxDate={moment().format("YYYY-MM-DD")}
                        />
                        <FormFeedback invalid>
                          {errors.transaction_date?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col sm={4}>
                      <FormGroup className="mb-4">
                        <Label>
                          Credit Period<span className="mandatory">*</span>
                        </Label>
                        <CreditPeriod
                          control={control}
                          placeholder="select"
                          name={"creditperiod"}
                          invalid={!!errors.creditperiod}
                          target={fromDashboard ? ".modal" : "body"}
                          disable={true}
                        />
                        <FormFeedback invalid>
                          {errors.creditperiod?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col sm={4}>
                      <FormGroup className="mb-4">
                        <Label>
                          Document<span className="mandatory">*</span>
                        </Label>
                        <SingleFileInput
                          accept=".png,.jpg,.jpeg,.svg,.pdf"
                          name={`transaction_doc`}
                          label="Upload File"
                          onlyIcon={true}
                          tooltip="Document Upload"
                          handleChange={handleChange}
                          // downloadPath={certificateSts}
                        />
                        <FormFeedback invalid>
                          {errors.transaction_doc?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    {/* <Col sm={4}>
                      <FormGroup className="mb-4">
                        <Label>
                          Sub-Category<span className="mandatory">*</span>
                        </Label>
                        <FinanceSubCat
                          control={control}
                          placeholder="select"
                          name={"sub_cat_id"}
                          invalid={!!errors.creditperiod}
                          target={fromDashboard ? ".modal" : "body"}
                          loadUpdate={refresh}
                        />
                        <FormFeedback invalid>
                          {errors.creditperiod?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col> */}
                    <Col sm={4}>
                      <FormGroup className="mb-4">
                        <Label>Description</Label>
                        <UFEditor
                          control={control}
                          placeholder="Enter the description"
                          name={"description"}
                          rows="5"
                        />
                        <FormFeedback invalid>
                          {errors.description?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    {/* <Col sm={8}>
                      {watch("sub_cat_id")?.label == "Add Others" ? (
                        <Row>
                          <Col sm={6}>
                            <FormGroup className="mb-4">
                              <Label>
                                Category<span className="mandatory">*</span>
                              </Label>
                              <FinanceCat
                                control={control}
                                placeholder="Enter the Category"
                                name={"cat_id"}
                                invalid={!!errors.cat_id}
                              />
                              <FormFeedback invalid>
                                {errors.cat_id?.message}
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                          <Col sm={6}>
                            <FormGroup className="mb-4">
                              <Label>
                                Sub Category<span className="mandatory">*</span>
                              </Label>
                              <UFInput
                                control={control}
                                placeholder="Enter the Sub Category"
                                name={"name"}
                                invalid={!!errors.name}
                              />
                              <FormFeedback invalid>
                                {errors.name?.message}
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                          <Col className="d-flex justify-content-end" sm={12}>
                            <Button
                              type="button"
                              color="primary"
                              className="btn-sm btn-rounded waves-effect waves-light"
                              onClick={() =>
                                handleSubCat({
                                  cat_id: watch("cat_id"),
                                  name: watch("name"),
                                })
                              }
                            >
                              Add Sub Cat
                            </Button>
                          </Col>
                        </Row>
                      ) : (
                        <></>
                      )}
                    </Col> */}
                  </Row>
                  <hr className="m-0" />

                  <Row className="py-4 mt-4">
                    <h5>Material Details</h5>
                  </Row>

                  <Row className="mb-4">
                    <Col lg={12}>
                      <table className="estimation-table mb-4">
                        <thead>
                          <tr>
                            <th>Product Code</th>
                            <th>Description</th>
                            <th>Unit</th>
                            <th>Ordered quantity</th>
                            <th>Received quantity</th>
                          </tr>
                        </thead>
                        <tbody>
                          {grnVal?.data?.grnquantitieslist?.map(
                            (item, index) => (
                              <tr key={`poFields${index}`}>
                                <td>
                                  {item?.product?.product_code}
                                  {/* <POPRoducts
                                  control={control}
                                  placeholder="Product Code"
                                  name={`grnproductlist.${index}.product_code`}
                                  Poid={idPO}
                                  selectedProduct={watch(`grnproductlist`)}
                                  index={index}
                                  invalid={
                                    !!errors?.grnproductlist?.[index]
                                      ?.product_code
                                  }
                                />
                                <FormFeedback invalid>
                                  {
                                    errors?.grnproductlist?.[index]
                                      ?.product_code?.message
                                  }
                                </FormFeedback> */}
                                </td>
                                <td>
                                  {item?.product?.description}
                                  {/* <UFInput
                                  control={control}
                                  placeholder="Description"
                                  name={`grnproductlist.${index}.description`}
                                  invalid={
                                    !!errors?.grnproductlist?.[index]
                                      ?.description
                                  }
                                />
                                <FormFeedback invalid>
                                  {
                                    errors?.grnproductlist?.[index]?.description
                                      ?.message
                                  }
                                </FormFeedback> */}
                                </td>
                                <td>
                                  {item?.unit?.unit_code}
                                  {/* <Unit
                                    control={control}
                                    placeholder="Unit"
                                    name={`grnproductlist.${index}.unit`}
                                  />
                                  <FormFeedback invalid>
                                    {
                                      errors?.grnproductlist?.[index]?.unit
                                        ?.message
                                    }
                                  </FormFeedback> */}
                                </td>
                                <td>{item?.ordered_quantity}</td>
                                <td>
                                  {Number(item?.ordered_quantity) -
                                    Number(item?.pending_quantity)}
                                  {/* <UFInput
                                    control={control}
                                    placeholder="Received"
                                    name={`grnproductlist.${index}.received_quantity`}
                                    invalid={
                                      !!errors?.grnproductlist?.[index]
                                        ?.received_quantity
                                    }
                                  />
                                  <FormFeedback invalid>
                                    {
                                      errors?.grnproductlist?.[index]
                                        ?.received_quantity?.message
                                    }
                                  </FormFeedback> */}
                                </td>
                                {/* <td>
                                  <p>
                                    {watchTest?.[index]?.pending_quantity -
                                      watchTest?.[index]
                                        ?.received_quantity}{" "}
                                  </p>
                                </td>
                                <td>
                                  <img
                                    src={lightTrash}
                                    alt="light-trash"
                                    className="trash-img pointer"
                                    onClick={() => materialRemove(index)}
                                  />
                                </td> */}
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                      {/* <Col
                        lg="12"
                        xs={12}
                        md={2}
                        sm={12}
                        className="d-flex justify-content-between"
                      >
                        {!fromDashboard && (
                          <Button
                            color="secondary"
                            className="addButton mt-2"
                            onClick={() =>
                              materialAppend({
                                product_code: null,
                                description: "",
                                // ordered_quantity: 0,
                                received_quantity: 0,
                                // pending_quantity: 0
                              })
                            }
                          >
                            Add Row
                          </Button>
                        )}
                      </Col> */}
                    </Col>
                  </Row>
                  <Row className="py-4">
                    <Col sm={4}>
                      <FormGroup className="mb-4">
                        <Label>Payment Terms</Label>
                        <UFEditor
                          control={control}
                          placeholder="Enter the Payment Terms"
                          rows="5"
                          name={"pay_terms"}
                          invalid={!!errors.pay_terms}
                          className={"pb-0"}
                        />
                        {/* <RichTextEditor
                          control={control}
                          name={"pay_terms"}
                          invalid={!!errors.terms}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                        /> */}
                      </FormGroup>
                    </Col>
                    <Col sm={4}></Col>
                    <Col sm={4}>
                      <FormGroup className="mb-4">
                        <Label>Total Pay</Label>
                        <UFInput
                          control={control}
                          placeholder="Enter the Amount"
                          name={"pay"}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Card>
              </Container>
            </Form>
          </FormProvider>
        </div>
      </div>
    </React.Fragment>
  )
}

export default EditGRNCosting
