import { get, post } from "../../helpers/api_helper"

export const postSupplier = data => {
  return !data?.id
    ? post(`suppliers/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`suppliers/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}

export const searchSupplier = data => {
  return get(`suppliers/search?${data?.params}`)
}
export const fetchSupplier = data => {
  return get(`suppliers/fetch/${data}`)
}

export const updateSupplierStatus = data => {
  return get(`suppliers/updatestatus/${data}`)
}

export const tradeDocDownload = ({ url, path, downloadName, extension }) => {
  return get(`${url}`, {
    responseType: "blob",
  }).then(response => {
    const url = window.URL.createObjectURL(new Blob([response]))
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", `${downloadName}_${Date.now()}.${extension}`)
    document.body.appendChild(link)
    link.click()
  })
}
