import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
  Col,
  Row,
  Card,
  UncontrolledPopover,
  PopoverBody,
  FormGroup,
  Label,
  FormFeedback,
} from "reactstrap"
import UpdateTable from "../../../components/Common/UpdatedTable"
import action from "../../../assets/icons/action.svg"
import check from "../../../assets/icons/estimation_check.svg"
// import EstimationFilter from "./filter"
import { Link, useNavigate } from "react-router-dom"
import { searchEstimation } from "../../../services/estimation/estimation"
import moment from "moment"
import { UFInput } from "../../../components/Common/Fields/Input"

const AssignStaffIndex = () => {
  document.title = "Assign Staff | Ever Rise"

  const navigate = useNavigate()
  const [classData, setClassData] = useState()
  const [status, setStatus] = useState("")
  const [search, setSearch] = useState("")
  const [customer, setCustomer] = useState("")
  const [location, setLocation] = useState("")
  const [page, setPage] = useState("")

  const columns = [
    {
      label: "Employee Code",
      renderCell: item => {
        return (
          <div
            onClick={() => navigate(`/estimation-view/${item?.id}`)}
            className="pointer fw-bold"
          >
            <span className="pe-2"> {item?.estimation_code}</span>
            {!item?.estimation_revision?.every(item => item?.status == 0) && (
              <img src={check} />
            )}
          </div>
        )
      },
    },
    {
      label: "Employee Name",
      renderCell: item => item?.customers?.customer_name,
    },
    {
      label: "Email ID",
      renderCell: item => {
        if (item?.estimation_revision?.some(item => item?.status == 1)) {
          return item?.estimation_revision.find(item => item?.status == 1)
            ?.amount
        } else {
          return item?.estimation_revision[
            item?.estimation_revision?.length - 1
          ]?.amount
        }
      },

      // item?.estimation_material_sum_price +
      // item?.estimation_manpower_sum_price +
      // item?.estimation_overhead_sum_price +
      // item?.estimation_subcontract_sum_price,
    },
    {
      label: "Grade",
      renderCell: item => moment(item?.updated_at).format("DD-MM-YYYY"),
    },
    {
      label: "Employee Type",
      renderCell: item => moment(item?.updated_at).format("DD-MM-YYYY"),
    },
    {
      label: "Status",
      renderCell: item => (
        <div>
          <div
            className={`font-13 ${
              item?.status == 1 ? "status-active" : "status-inactive"
            }`}
          >
            {item?.status == 1 ? "Active" : "Inactive"}
          </div>
        </div>
      ),
    },
    {
      label: "Action",
      renderCell: item => (
        <>
          <div>
            <span id={`PopoverLegacy-${item?.id}`}>
              <img src={action} alt="" className="pointer" />
            </span>
          </div>
          <UncontrolledPopover
            placement="bottom"
            target={`PopoverLegacy-${item?.id}`}
            trigger="legacy"
          >
            <PopoverBody>
              <>
                {item?.estimation_revision?.every(item => item?.status == 0) ? (
                  <div
                    className="table-popup-content"
                    onClick={() =>
                      navigate(
                        `/estimation-edit/${
                          item?.estimation_revision?.[
                            item?.estimation_revision?.length - 1
                          ]?.id
                        }`
                      )
                    }
                  >
                    Edit
                  </div>
                ) : // item?.estimation_revision?.every(
                //     item => item?.status == 0
                //   ) &&
                item?.project_status == 0 ? (
                  <div
                    className="table-popup-content"
                    onClick={() =>
                      navigate(
                        `/project-master/add/${
                          item?.estimation_revision.find(
                            item => item?.status == 1
                          )?.id
                        }`
                      )
                    }
                  >
                    Convert to Project
                  </div>
                ) : (
                  <></>
                )}
                <div
                  className={`${
                    item?.status === 1 ? "popover-border" : ""
                  } table-popup-content`}
                  onClick={() => navigate(`/estimation-view/${item?.id}`)}
                >
                  View revisions
                </div>
              </>
            </PopoverBody>
          </UncontrolledPopover>
        </>
      ),
    },
  ]

  const handlePaginate = page => {
    setPage(page)
  }

  useEffect(() => {
    const fetchClass = async () => {
      const response = await searchEstimation({
        params: `${status == "0" || status == "1" ? `&status=${status}` : ""}${
          search ? `&searchText=${search}` : "&searchText"
        }${customer ? `&client_code=${customer}` : ""}${
          location ? `&location_id=${location}` : ""
        }${page ? `&page=${page}` : ""}`,
      })
      setClassData(response)
    }
    fetchClass()
  }, [status, search, customer, page, location])

  const handleStatusChange = id => {
    setStatus(id)
    setPage(1)
  }
  const handleCustomerChange = id => {
    setCustomer(id)
    setPage(1)
  }
  const handleLocationChange = id => {
    setLocation(id)
    setPage(1)
  }
  const handleSearchChange = data => {
    setSearch(data)
    setPage(1)
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="d-flex justify-content-between">
            <Breadcrumbs
              title="HRMS"
              breadcrumbObject={[
                { title: "HRMS", link: "/hrms" },
                { title: "Projects", link: "/hrms-assignstaff" },
                { title: "Assign Staff", link: "/hrms-assignstaff" },
              ]}
            />
            {/* <Link to="/estimation-add" className={"px-2 mb-3 btn btn-primary"}>
              {"+ Add Estimation"}
            </Link> */}
          </div>
          <Card className="me-2 px-2 pb-2 h-auto">
            {" "}
            <Col sm={3}>
              <FormGroup className="mb-4">
                <Label>
                  Official Email ID
                  <span className="mandatory">*</span>
                </Label>
                {/* <UFInput
                  control={control}
                  placeholder="Enter the Email ID"
                  name={"official_email_id"}
                  // invalid={!!errors.official_email_id}
                /> */}
                <FormFeedback invalid>
                  {/* {errors.official_email_id?.message} */}
                </FormFeedback>
              </FormGroup>
            </Col>
          </Card>
          <Card className="me-2 px-2 pb-2">
            {/* <hr className="table-divide" /> */}
            <Row className="mt-2 ever-rise-table">
              <Col xs={12}>
                {classData && (
                  <UpdateTable
                    className="mt-5"
                    nodes={classData?.data?.data}
                    paginate={classData?.data}
                    columns={columns}
                    CurrentPage={page}
                    handlePagination={handlePaginate}
                    tableWidth="15% 20% 15% 15% 15% 10% 10%"
                  />
                )}
              </Col>
            </Row>
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}

export default AssignStaffIndex
