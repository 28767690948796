import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import {
  Col,
  Row,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  ModalHeader,
  Modal,
  ModalBody,
  Button,
} from "reactstrap"
import {
  fetchCurrentEmp,
  fetchProjectDashboard,
  fetchProjectDetailDashboard,
  fetchStoreMaterialRequest,
  searchManpowerhRequest,
  searchProcurmentDashRequest,
  searchProjectManager,
} from "../../../../services/projectManager/projectMaster"
import { useNavigate, useParams } from "react-router-dom"
import LoadingButton from "../../../../components/Common/LoadingButton"
import ReactApexChart from "react-apexcharts"
import present from "../../../../assets/icons/present.svg"
import absent from "../../../../assets/icons/absent.svg"
import RightArrow from "../../../../assets/icons/rightarrowlong.svg"
import UpdateTable from "../../../../components/Common/UpdatedTable"
import DownArrow from "../../../../assets/icons/downarrow.svg"
// import DarkDot from "../../../assets/icons/darkdot.svg"
// import LightDot from "../../../assets/icons/lightdo.svg"
import moment from "moment"
import TimeLineDD from "../../../../components/Common/chooseFields/TimeLineDD"
import { useForm } from "react-hook-form"
import {
  UFDate,
  UFInputDashboard,
} from "../../../../components/Common/Fields/Input"
import closeIcon from "../../../../assets/icons/close.svg"
// import WorkersDetailList from "./workerDetails"
import redLeftArrow from "../../../../assets/icons/redLeftArrow.svg"
import {
  searchMaterialRequest,
  updateAction,
  updateManPowerStatus,
} from "../../../../services/procurement/materialrequest"
import { toastError, toastSuccess } from "../../../../helpers/msg_helper"
import MaterialRequest from "../../../Procurement/materialrequest"
import MRView from "../../../Procurement/materialrequest/view"
import POView from "../../../Procurement/purchaseOrder/view"
import GrnView from "../../../Procurement/grn/view"
import ManPowerRequest from "../../../Procurement/manpowerRequest"
import TransactionHistory from "../../../Finance/transactionHistory"
import GRNCosting from "../../../Finance/grnCosting.js"
import EditGRNCosting from "../../../Finance/grnCosting.js/edit.js"
import {
  fetchFinanceDashboardAlert,
  fetchFinanceDashboardEmp,
  fetchFinanceDashboardEmpDetail,
  fetchFinanceDashboardSub,
  fetchFinanceDashboardVal,
} from "../../../../services/finance/finance.js"
import { isEmpty } from "lodash"
import resetIcon from "../../../../assets/icons/reset.svg"

const FinanceDashboard = () => {
  document.title = "Finance Dashboard | Ever Rise"

  const { id } = useParams()

  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState("1")
  const [activeTab2, setActiveTab2] = useState("1")
  const [refresh, setRefresh] = useState(0)
  const [page, setPage] = useState("")
  const [subPage, setSubPage] = useState("")
  const [search, setSearch] = useState("")

  const { control, getValues, setValue, watch, reset } = useForm({
    defaultValues: {
      req_date: null,
    },
  })

  const [classData, setClassData] = useState()
  const [fetchedData, setFetchedData] = useState()
  const [modal, setModal] = useState(false)
  const [viewData, setViewData] = useState()
  const [financePD, setFinancePD] = useState()
  const [financeSubCat, setFinanceSubCat] = useState()
  const [financeAlert, setFinanceAlert] = useState()
  const [empList, setEmpList] = useState()

  const [materialRequestData, setMaterialRequestData] = useState()

  const [materialData, setMaterialData] = useState()
  const [manPowerData, setManPowerData] = useState()
  const [refresh1, setRefresh1] = useState(0)
  const [refresh2, setRefresh2] = useState(0)

  const [isLoading, setIsLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [currEmp, setCurrEmp] = useState()

  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")

  const [modalState, setModalState] = useState("")

  const [view, setView] = useState(false)

  const toggleModal = () => setModal(!modal)

  //   console.log(`classData`, classData, fetchedData)

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }
  const toggle2 = tab => {
    if (activeTab2 !== tab) setActiveTab2(tab)
  }

  useEffect(() => {
    setSearch(watch("search"))
    setPage(1)
  }, [watch("search")])

  //   useEffect(() => {
  //     const fetchClass = async () => {
  //       const response = await fetchProjectDashboard({
  //         params: `${search ? `&searchText=${search}` : ""}${
  //           id ? `&project_id=${id}` : ""
  //         }${
  //           activeTab
  //             ? `&type=${
  //                 activeTab == 1
  //                   ? "material"
  //                   : activeTab == 2
  //                   ? "manpower"
  //                   : activeTab == 3
  //                   ? "subcontract"
  //                   : "overheads"
  //               }`
  //             : ""
  //         }`,
  //       })
  //       setClassData(response)
  //     }
  //     if (id) {
  //       fetchClass()
  //       console.log(`iii`, id)
  //     }
  //   }, [refresh, id, activeTab, search])

  useEffect(() => {
    const fetchMaterialSearch = async () => {
      const response = await searchProcurmentDashRequest({
        params: `${"&searchText"}${id ? `&project_id=${id}` : ""}`,
      })
      setMaterialRequestData(response)
    }
    const fetchFinancePD = async () => {
      const response = await fetchFinanceDashboardVal(id)
      setFinancePD(response)
      console.log(`response`, response)
    }
    if (id) {
      fetchMaterialSearch()
      fetchFinancePD()
    }
  }, [id, refresh1])

  useEffect(() => {
    const fetchFinanceSub = async () => {
      const response = await fetchFinanceDashboardSub({
        params: `${`&searchText=${watch("search-sub")}`}${`&sizePerPage=5`}${
          id ? `&project_id=${id}` : ""
        }${subPage ? `&page=${subPage}` : ""}${
          activeTab ? `&parm=${activeTab == 1 ? "credit" : "debit"}` : ""
        }`,
      })
      setFinanceSubCat(response)
    }
    if (id) {
      fetchFinanceSub()
    }
  }, [id, subPage, activeTab, watch("search-sub")])

  useEffect(() => {
    const fetchFinanceAlert = async () => {
      const response = await fetchFinanceDashboardAlert({
        params: `${`&searchText=${watch("search-alert")}`}${`&sizePerPage=5`}${
          id ? `&project_id=${id}` : ""
        }${subPage ? `&page=${subPage}` : ""}`,
      })
      setFinanceAlert(response)
    }
    if (id) {
      fetchFinanceAlert()
    }
  }, [id, subPage, watch("search-alert")])

  useEffect(() => {
    const fetchFinanceEmpList = async () => {
      const response = await fetchFinanceDashboardEmp({
        params: `${`&searchText=`}${id ? `&project_id=${id}` : ""}${
          startDate ? `&start_date=${startDate}` : ""
        }${endDate ? `&end_date=${endDate}` : ""}`,
      })
      setEmpList(response)
      console.log(`fetchFinanceEmpList`, response)
    }
    if (id) {
      fetchFinanceEmpList()
    }
  }, [id, activeTab2, startDate, endDate])

  useEffect(() => {
    const fetchEmpDetail = async () => {
      const response = await fetchFinanceDashboardEmpDetail({
        params: `${`&searchText=`}${
          id ? `&project_id=${id}` : ""
        }${`&employee_id=${1}`}`,
      })
      console.log(`fetchEmpDetail`, response)
    }
    fetchEmpDetail()
  }, [id])

  useEffect(() => {
    const fetchMR = async () => {
      const response = await searchMaterialRequest({
        params: `${"&searchText"}${`&sizePerPage=5`}${`&filter_status=0`}${
          id ? `&project_id=${id}` : ""
        }`,
      })
      setMaterialData(response)
    }
    fetchMR()
  }, [id, refresh1])

  useEffect(() => {
    const fetchManPower = async () => {
      const response = await searchManpowerhRequest({
        params: `${"&searchText"}${
          id ? `&project_id=${id}` : ""
        }${`&sizePerPage=5`}${`&filter_status=0`}`,
      })
      setManPowerData(response)
    }
    fetchManPower()
  }, [id, refresh2])

  console.log(`materialRequestData`, materialRequestData)

  useEffect(() => {
    const fetchData = async id => {
      const response = await fetchProjectDetailDashboard(id)
      setFetchedData(response?.data)
    }
    if (id) {
      fetchData(id)
      setValue("time", { label: "Today", value: "today" })
    }
  }, [id])

  const handleView = () => {
    setViewData({ id: 1 })
    toggleModal()
  }
  console.log(`viewData`, viewData)

  const handleAction = async data => {
    try {
      // setIsLoading(data?.status == 1 ? true : false)
      // setLoading(data?.status == 2 ? true : false)
      const action = data?.materialReq
        ? updateAction(data)
        : updateManPowerStatus(data)
      const response = await action
      toastSuccess(response?.message)
      data?.materialReq ? setRefresh1(refresh1 + 1) : setRefresh2(refresh2 + 1)
      // setIsLoading(false)
      // setLoading(false)
    } catch (err) {
      toastError(err?.message)
    }
  }
  useEffect(() => {
    const subCatArr = {
      totalItems: 2,
      title: "Account Payables Search Result",
      data: {
        current_page: 1,
        data: [
          {
            id: 2,
            code: "IVC/24/00002",
            value: 1000,
          },
          {
            id: 1,
            code: "IVC/24/00001",
            value: 500,
          },
        ],
        first_page_url:
          "http://127.0.0.1:8000/api/account-payables/search?page=1",
        from: 1,
        last_page: 1,
        last_page_url:
          "http://127.0.0.1:8000/api/account-payables/search?page=1",
        links: [
          { url: null, label: "&laquo; Previous", active: false },
          {
            url: "http://127.0.0.1:8000/api/account-payables/search?page=1",
            label: "1",
            active: true,
          },
          { url: null, label: "Next &raquo;", active: false },
        ],
        next_page_url: null,
        path: "http://127.0.0.1:8000/api/account-payables/search",
        per_page: 10,
        prev_page_url: null,
        to: 2,
        total: 2,
      },
      searchText: null,
      sortOrder: "desc",
      currentPage: 1,
      filter: [],
      status: true,
    }
    setClassData(subCatArr)
  }, [])

  const columns = [
    {
      label: "Sub-cat ID",
      renderCell: item => item?.sub_category?.code,
    },
    {
      label: "Value",
      renderCell: item => {
        return (
          <div>
            {Number(Number(item?.total_amount).toFixed(2)).toLocaleString(
              "en",
              { minimumFractionDigits: 2 }
            )}{" "}
            AED
          </div>
        )
      },
    },
  ]
  const alertColumn = [
    {
      label: "GRN ID",
      renderCell: item => item?.grn_no,
    },
    {
      label: "Number of Days",
      renderCell: item => {
        return (
          <div
            className={`${
              item?.days_remaining_or_passed > 0
                ? "text-alert-green"
                : item?.days_remaining_or_passed != 0
                ? "text-alert-red"
                : ""
            }`}
          >
            {item?.days_remaining_or_passed == 0
              ? "Today"
              : `${item?.days_remaining_or_passed} Days`}
          </div>
        )
      },
    },
  ]
  const empColumn = [
    {
      label: "Emp Code",
      renderCell: item => {
        return <div>{item?.employee_code}</div>
      },
    },
    {
      label: "Emp Name",
      renderCell: item => item?.first_name,
    },
  ]

  const handlePaginate = page => {
    setPage(page)
  }
  const handleSubPaginate = subPage => {
    setSubPage(subPage)
  }

  const resetAfterCreatePo = () => {
    setModal(!modal)
    if (!modal === false) {
      setViewData()
    }
  }

  const cardData = [
    {
      name: "Estimation Value",
      amount: `${Number(
        Number(
          financePD?.data?.estimated_amount
            ? financePD?.data?.estimated_amount
            : 0
        ).toFixed(2)
      ).toLocaleString("en", { minimumFractionDigits: 2 })} AED`,
    },
    {
      name: "Project Value",
      amount: `${Number(
        Number(
          financePD?.data?.project_value ? financePD?.data?.project_value : 0
        ).toFixed(2)
      ).toLocaleString("en", { minimumFractionDigits: 2 })} AED`,
    },
    {
      name: "Balance Value",
      amount: `${Number(
        Number(
          Number(
            financePD?.data?.estimated_amount
              ? financePD?.data?.estimated_amount
              : 0
          ).toFixed(2) -
            Number(
              financePD?.overallTotalDebit ? financePD?.overallTotalDebit : 0
            ).toFixed(2)
        ).toFixed(2)
      ).toLocaleString("en", { minimumFractionDigits: 2 })} AED`,
    },
    {
      name: "Spend Value",
      amount: `${Number(
        Number(
          financePD?.overallTotalDebit ? financePD?.overallTotalDebit : 0
        ).toFixed(2)
      ).toLocaleString("en", { minimumFractionDigits: 2 })} AED`,
    },
  ]

  const handleRequestdate = data => {
    console.log(data, "data")
    let fromDate = ""
    let toDate = ""
    fromDate = !isEmpty(data) ? moment(data[0]).format("YYYY-MM-DD") : ""
    toDate = !isEmpty(data) ? moment(data[1]).format("YYYY-MM-DD") : ""
    console.log(fromDate, toDate, "toDate")
    setStartDate(fromDate)
    setEndDate(toDate)
  }

  const handleReset = () => {
    reset({ req_date: null })
    setStartDate("")
    setEndDate("")
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="d-flex justify-content-between">
            <Breadcrumbs
              title="Project Management"
              breadcrumbObject={[
                { title: "Project Management", link: "/project" },
                {
                  title: "Finance Dashboard",
                  link: `/project-finance-dash/${id}`,
                },
              ]}
            />
          </div>
          <Card className="me-2 px-2 pb-2">
            <div className="d-flex justify-content-between">
              <Row className="py-3">
                <Col lg={"auto"} className="secondary-text text-16">
                  Project Name
                </Col>
                <Col className="primary-text text-16 fw-500">
                  {fetchedData?.project_name ? fetchedData?.project_name : "-"}
                </Col>
              </Row>

              {/* <Row className="py-3">
                <Col lg={"auto"} className="secondary-text text-16">
                  Project Loaction
                </Col>
                <Col className="primary-text text-16 fw-500">
                  {fetchedData?.location?.name
                    ? fetchedData?.location?.name
                    : "-"}
                </Col>
              </Row> */}
            </div>

            <Row className="mt-3 mx-1 mb-0">
              {cardData?.length &&
                cardData.map((ele, key) => (
                  <Col md={4} xl={3} sm={6} xs={12} key={key}>
                    <Card className="rounded-0 border-0 invoice-card dash-card-height">
                      <CardBody>
                        <Row>
                          {/* <Col lg={"auto"}>
                            <img src={ele?.icon} />
                          </Col> */}
                          <Col
                            lg={"auto"}
                            className="d-flex justify-content-center flex-column"
                          >
                            <div className="supplier-td-head pb-1">
                              {ele?.name}
                            </div>
                            <div className="dark-text fw-500 text-18">
                              {ele?.amount}
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
            </Row>
            <Row className="mx-1 mb-0">
              <Col sm={4}>
                <Card className="me-2 px-2 dash-table-bg finance-card-ht">
                  <div className="px-2 py-3">Project Detail</div>
                  <hr className="table-divide" />
                  <CardBody>
                    <Row>
                      <Col className="py-2 payroll-body" sm={5}>
                        Project Manager
                      </Col>
                      <Col className="py-2 dsah-project-detail" sm={7}>
                        {fetchedData?.project_manager?.name
                          ? fetchedData?.project_manager?.name
                          : "-"}
                      </Col>
                      <Col className="py-2 payroll-body" sm={5}>
                        Contact No
                      </Col>
                      <Col className="py-2 dsah-project-detail" sm={7}>
                        +364526345723
                      </Col>
                      <Col className="py-2 payroll-body" sm={5}>
                        Email
                      </Col>
                      <Col className="py-2 dsah-project-detail" sm={7}>
                        {fetchedData?.project_manager?.email
                          ? fetchedData?.project_manager?.email
                          : "-"}
                      </Col>
                      <Col className="py-2 payroll-body" sm={5}>
                        Store Keeper
                      </Col>
                      <Col className="py-2 dsah-project-detail" sm={7}>
                        {fetchedData?.store_keeper?.name
                          ? fetchedData?.store_keeper?.name
                          : "-"}
                      </Col>
                      <Col className="py-2 payroll-body" sm={5}>
                        Contact No
                      </Col>
                      <Col className="py-2 dsah-project-detail" sm={7}>
                        +364526345723
                      </Col>
                      <Col className="py-2 payroll-body" sm={5}>
                        Email
                      </Col>
                      <Col className="py-2 dsah-project-detail" sm={7}>
                        {fetchedData?.store_keeper?.email
                          ? fetchedData?.store_keeper?.email
                          : "-"}
                      </Col>
                      <Col className="py-2 payroll-body" sm={5}>
                        Project Loaction
                      </Col>
                      <Col className="py-2 dsah-project-detail" sm={7}>
                        {fetchedData?.location?.name
                          ? fetchedData?.location?.name
                          : "-"}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col sm={4}>
                <Card className="me-2 px-2 dash-table-bg finance-card-ht">
                  <div className="d-flex justify-content-between p-2">
                    <div className="d-flex align-items-center">Due Pay</div>
                    <div>
                      <UFInputDashboard
                        control={control}
                        name={"search-alert"}
                        placeho
                      />
                    </div>
                  </div>
                  <hr className="table-divide" />
                  <CardBody>
                    <Row className="ever-rise-table dashboardTable">
                      <Col xs={12}>
                        {financeAlert && (
                          <UpdateTable
                            // className="mt-5"
                            nodes={financeAlert?.data?.data}
                            paginate={financeAlert?.data}
                            CurrentPage={page}
                            columns={alertColumn}
                            handlePagination={handlePaginate}
                            tableWidth="50% 50%"
                          />
                        )}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>

              <Col sm={4}>
                <Card className="me-2 px-2 dash-table-bg finance-card-ht">
                  <div className="d-flex justify-content-between p-2">
                    <div className="d-flex align-items-center">
                      Sub-Category
                    </div>
                    <div>
                      <UFInputDashboard control={control} name={"search-sub"} />
                    </div>
                  </div>
                  <hr className="table-divide" />
                  <CardBody>
                    <Nav tabs className="tab-active-bg">
                      <NavItem>
                        <NavLink
                          className={`${
                            activeTab === "1" ? "active pointer" : "pointer"
                          } py-2`}
                          onClick={() => {
                            toggle("1")
                          }}
                        >
                          Credit
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={`${
                            activeTab === "2" ? "active pointer" : "pointer"
                          } py-2`}
                          onClick={() => {
                            toggle("2")
                          }}
                        >
                          Debit
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent
                      activeTab={activeTab}
                      className="pt-3 dashboard-update-table"
                    >
                      <TabPane
                        tabId={activeTab}
                        className="dashboard-table-pan"
                      >
                        <Row className="ever-rise-table dashboardTable">
                          <Col xs={12}>
                            {financeSubCat?.data && (
                              <UpdateTable
                                // className="mt-5"
                                nodes={financeSubCat?.data}
                                paginate={financeSubCat}
                                CurrentPage={subPage}
                                columns={columns}
                                handlePagination={handleSubPaginate}
                                tableWidth="50% 50%"
                              />
                            )}
                          </Col>
                        </Row>
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col sm={12} lg={6}>
                <TransactionHistory fromDashboard={true} projectId={id} />
              </Col>
              <Col sm={12} lg={6}>
                <Card className={`me-2 px-2 pb-2 dash-table-bg`}>
                  <div className="d-flex justify-content-between px-2 py-3">
                    <span>Expences</span>
                    {activeTab2 === "1" ? (
                      <Button
                        //   className="hover-button"
                        color={"primary"}
                        onClick={() => handleView()}
                      >
                        Add GRN Costing
                      </Button>
                    ) : (
                      <></>
                    )}
                  </div>
                  <hr className="table-divide" />
                  <Nav tabs className="tab-active-bg px-1">
                    <NavItem>
                      <NavLink
                        className={`${
                          activeTab2 === "1" ? "active pointer" : "pointer"
                        } py-2`}
                        onClick={() => {
                          toggle2("1")
                        }}
                      >
                        GRN Costing
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={`${
                          activeTab2 === "2" ? "active pointer" : "pointer"
                        } py-2`}
                        onClick={() => {
                          toggle2("2")
                        }}
                      >
                        Salary Deduction
                      </NavLink>
                    </NavItem>
                  </Nav>
                  {activeTab2 == 2 ? (
                    <Row className="pt-3 px-2">
                      <Col sm={6}>
                        <UFDate
                          control={control}
                          placeholder="Date"
                          name={"req_date"}
                          mode="range"
                          className="ufinput-dashboard"
                          onDateChange={e => handleRequestdate(e)}
                        />
                      </Col>
                      <Col sm={3}></Col>
                      <Col sm={3}>
                        <div
                          className="pointer me-2 text-white btn btn-primary float-end"
                          onClick={() => handleReset()}
                        >
                          <img
                            src={resetIcon}
                            height={15}
                            width={15}
                            className="me-2 svg-clr-reset"
                          />{" "}
                          Reset
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    <></>
                  )}
                  <TabContent
                    activeTab={activeTab2}
                    className="pt-3 dashboard-update-table"
                  >
                    <TabPane tabId="1" className="dashboard-table-pan">
                      <GRNCosting
                        fromDashboard={true}
                        projectId={id}
                        view={viewData}
                        handleView={handleView}
                      />
                    </TabPane>
                    <TabPane tabId="2" className="dashboard-table-pan">
                      <Row className="ever-rise-table dashboardTable px-2">
                        <Col xs={12}>
                          {empList && activeTab2 == 2 && (
                            <UpdateTable
                              nodes={empList?.data?.data}
                              paginate={empList?.data}
                              CurrentPage={page}
                              columns={empColumn}
                              handlePagination={handlePaginate}
                              tableWidth="50% 50%"
                            />
                          )}
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </Card>
              </Col>
            </Row>
          </Card>
        </div>
      </div>
      <Modal
        isOpen={modal}
        toggle={toggleModal}
        unmountOnClose={false}
        size={viewData?.id == 1 || 2 ? "xl" : "lg"}
      >
        <>
          <ModalHeader className="custom-modal-header">
            <div className="d-flex justify-content-between">
              <h5 className="ms-1">
                {viewData?.id == 1
                  ? "Add GRN Costing"
                  : viewData?.id == 2
                  ? "Manpower Request"
                  : viewData?.id == 4
                  ? "Material Request View"
                  : viewData?.id == 7
                  ? "GRN View"
                  : "Workers Details"}
              </h5>
              <img
                src={closeIcon}
                alt=""
                onClick={toggleModal}
                width="10"
                role="button"
              />
            </div>
          </ModalHeader>
          <ModalBody>
            {viewData?.id == 1 ? (
              <EditGRNCosting
                fromDashboard={true}
                projectId={id}
                toggle={resetAfterCreatePo}
              />
            ) : viewData?.id == 2 ? (
              <ManPowerRequest fromDashboard={true} projectId={id} />
            ) : viewData?.id == 4 ? (
              <MRView
                fromDashboard={true}
                viewValue={viewData}
                // toggle={resetAfterCreatePo}
              />
            ) : viewData?.id == 5 ? (
              <POView
                fromDashboard={true}
                viewValue={viewData}
                // toggle={resetAfterCreatePo}
              />
            ) : viewData?.id == 7 ? (
              <GrnView
                fromDashboard={true}
                viewValue={viewData}
                // toggle={resetAfterCreatePo}
              />
            ) : (
              //   <WorkersDetailList />
              <></>
            )}
          </ModalBody>
        </>
      </Modal>
    </React.Fragment>
  )
}

export default FinanceDashboard
