import React, { useEffect, useState } from "react"
import { Card, Col, PopoverBody, Row, UncontrolledPopover } from "reactstrap"
import UpdateTable from "../../../components/Common/UpdatedTable"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import action from "../../../assets/icons/action.svg"
import {
  fetchType,
  updateTypeStatus,
} from "../../../services/Inventory/inventory"
import EditType from "./edit"
import { toastError, toastSuccess } from "../../../helpers/msg_helper"
import InventoryFilter from "../filter"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"

const Type = () => {
  document.title = "Types | Everise"

  const selectLoginState = state => state.Login
  const LoginProperties = createSelector(selectLoginState, login => ({
    loggedUser: login.loggedUser,
  }))

  const { loggedUser } = useSelector(LoginProperties)

  const [page, setPage] = useState("")

  const [type, setType] = useState()
  const [count, setCount] = useState(0)
  const [search, setSearch] = useState("")
  const [editType, setEditType] = useState({ modal: false, value: "" })
  const [status, setStatus] = useState("")

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchType({
        params: `${status == "0" || status == "1" ? `&status=${status}` : ""}${
          search ? `&searchText=${search}` : "&searchText"
        }${page ? `&page=${page}` : ""}`,
      })
      setType(response)
    }
    fetchData()
  }, [count, status, search, page])

  const handleEdit = item => {
    setEditType({ value: item, modal: true })
  }

  const handleStatusChange = id => {
    setStatus(id)
    setPage(1)
  }
  const handleSearchChange = data => {
    setSearch(data)
    setPage(1)
  }

  const handleStatus = async id => {
    try {
      const response = await updateTypeStatus(id)
      toastSuccess(response?.message)
      setCount(count + 1)
    } catch (err) {
      toastError(err?.message)
    }
  }

  const columns = [
    {
      label: "Description",
      renderCell: item => (
        <div className="capitalize-letter">{item?.description}</div>
      ),
      sortKey: "description",
    },
    {
      label: "Status",
      renderCell: item => (
        <div>
          <div
            className={`font-13 ${
              item?.status === 1 ? "status-active" : "status-inactive"
            }`}
          >
            {item?.status === 1 ? "Active" : "Inactive"}
          </div>
        </div>
      ),
    },
    {
      label: "Action",
      renderCell: item => (
        <>
          <div>
            <span id={`PopoverLegacy-${item?.id}`}>
              <img src={action} alt="" className="pointer" />
            </span>
          </div>
          <UncontrolledPopover
            placement="bottom"
            target={`PopoverLegacy-${item?.id}`}
            trigger="legacy"
          >
            <PopoverBody>
              {item?.status === 1 &&
                loggedUser?.permission?.some(
                  permissionItem => permissionItem?.name === "type_edit"
                ) && (
                  <div
                    className="table-popup-content"
                    onClick={() => handleEdit(item)}
                  >
                    Edit
                  </div>
                )}
              <div
                className={`${
                  item?.status === 1 ? "popover-border" : ""
                } table-popup-content`}
                onClick={() => handleStatus(item?.id)}
              >
                {item?.status === 1 ? "Deactivate" : "Activate"}
              </div>
            </PopoverBody>
          </UncontrolledPopover>
        </>
      ),
    },
  ]

  const handlePaginate = page => {
    setPage(page)
  }

  const handleUpdate = () => {
    setCount(count + 1)
  }

  const handleResetValue = () => {
    setEditType("")
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="d-flex justify-content-between">
            <Breadcrumbs
              title="Inventory"
              breadcrumbObject={[
                { title: "Product Master", link: "/inventory" },
                { title: "Type", link: "/inventory-type" },
              ]}
            />
            {loggedUser?.permission?.some(
              permissionItem => permissionItem?.name === "type_edit"
            ) && (
              <EditType
                key={"x"}
                onUpdate={handleUpdate}
                onReset={handleResetValue}
                buttonLabel="+&nbsp; Add Type"
                className="px-2 mb-3 btn btn-primary"
                editItem={editType}
              />
            )}
          </div>
          <Card className="me-2 px-2 pb-2">
            <InventoryFilter
              handleStatusChange={handleStatusChange}
              handleSearchChange={handleSearchChange}
              sampleUrl={"download/type.xlsx"}
              importUrl={"type/import"}
              isExport={
                loggedUser?.permission?.some(
                  permissionItem => permissionItem?.name === "type_create"
                )
                  ? true
                  : false
              }
            />
            <hr className="table-divide" />
            <Row className="mt-2 ever-rise-table">
              <Col xs={12}>
                {type?.data?.data && (
                  <UpdateTable
                    className="mt-5"
                    nodes={type?.data?.data}
                    paginate={type?.data}
                    columns={columns}
                    CurrentPage={page}
                    handlePagination={handlePaginate}
                    tableWidth="45% 45% 10%"
                  />
                )}
              </Col>
            </Row>
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Type
