import React, { useEffect, useState } from 'react';
import Pusher from 'pusher-js';
import { useSelector } from 'react-redux';
import { toastSuccess } from '../../helpers/msg_helper';
import addNotification from 'react-push-notification';

const Notification = () => {
    const [message, setMessage] = useState('');
    const loggedUser = useSelector(state => state.Login.loggedUser);

    useEffect(() => {
        if (!loggedUser?.data?.id) return;

        const pusher = new Pusher('da936c6d620ae409576a', {
            cluster: 'ap2',
            encrypted: true
        });

        const channel = pusher.subscribe(`my-channel.${loggedUser.data.id}`);
        channel.bind('my-event', function(data) {
            setMessage(data.message);

            addNotification({
                title: 'Ever Rise',
                subtitle: 'Notification',
                message: data.message,
                theme: 'darkblue',
                native: true ,
                silent: false
            });
        });

        return () => {
            channel.unbind_all();
            channel.unsubscribe();
            pusher.disconnect();
        };
    }, [loggedUser]);

};

export default Notification;
