import React, { useEffect, useState } from "react"
import Select from "react-select"
import { Controller } from "react-hook-form"
import { getFinanceSubCat } from "../../../services/Settings/dropdown"

function ucfirst(str) {
  var firstLetter = str.substr(0, 1)
  return firstLetter.toUpperCase() + str.substr(1)
}

const FinanceSubCat = ({
  control,
  name,
  onSelectChange,
  disabled,
  target,
  filter,
  loadUpdate,
  }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [seriesData, setSeriesData] = useState([])

  const loadOptions = async () => {
    const data = await getFinanceSubCat()
    setIsLoading(false)
    {
      !filter && data?.data?.push({ value: "Add Others", label: "Add Others" })
    }

    const series = data?.data?.map(dta => {
      return {
        label:
          dta?.code &&
          ucfirst(filter ? dta.code : `${dta.code}-${dta.name}`),
        value: dta.id,
        ...dta,
      }
    })

    setSeriesData(series)
  }

  useEffect(() => {
    loadOptions()
  }, [loadUpdate])

  return (
    <div>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, ref, value } }) => {
          return (
            <Select
              options={seriesData}
              placeholder={"Sub-Cat"}
              isLoading={isLoading}
              inputRef={ref}
              onChange={e => {
                onChange(e)
                if (onSelectChange) onSelectChange(e)
              }}
              value={value}
              isDisabled={disabled}
              classNamePrefix="react-select"
              className="elevate-dropdown"
              menuPortalTarget={document.querySelector(
                target ? target : ".modal"
              )}
            />
          )
        }}
      />
    </div>
  )
}

export default FinanceSubCat
