import React from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom";
import arrow from "../../assets/icons/arrow.svg";

const InventoryFil = () => {
    return (
        <div className="page-content">
            <Container fluid className="ps-lg-0 ">
                <Card>
                    <Row className="mt-3 mx-1 mb-0">
                        {Array(4).fill().map((ele, key) => (
                            <Col md={3} xl={3} sm={4} xs={12} key={key}>
                                <Card className="rounded-0 border-0 invoice-card">
                                    <CardBody>

                                    </CardBody>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                    <hr/>
                    <Row className="mt-4 mx-1 mb-3">
                        <Col lg={6} sm={6} xs={12}>
                            <div className="sub-menu-title mb-3">Your Shortcuts</div>
                            <Row>
                                <Col lg={4} sm={6} xs={12}>
                                <Link to="/inventory-list"><div className="mb-1"><label className="sub-menu-link" role="button">Country</label> <img src={arrow} alt="" width="7" /></div></Link>
                                <Link to="/inventory-list"><div className="mb-1"><label className="sub-menu-link" role="button">Location</label> <img src={arrow} alt="" width="7" /></div></Link>
                                </Col>
                                <Col lg={4} sm={6} xs={12}>
                                <Link to="/inventory-list"><div className="mb-1"><label className="sub-menu-link" role="button">Type</label> <img src={arrow} alt="" width="7" /></div></Link>
                                <Link to="/inventory-list"><div className="mb-1"><label className="sub-menu-link" role="button">Brand</label> <img src={arrow} alt="" width="7" /></div></Link>
                                </Col>
                                <Col lg={4} sm={6} xs={12}>
                                <Link to="/inventory-list"><div className="mb-1"><label className="sub-menu-link" role="button">Category</label> <img src={arrow} alt="" width="7" /></div></Link>
                                <Link to="/inventory-list"><div className="mb-1"><label className="sub-menu-link" role="button">Products</label> <img src={arrow} alt="" width="7" /></div></Link>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={6}>
                            <div className="sub-menu-title mb-3">Reports</div>
                        </Col>
                    </Row>
                </Card>
            </Container>
        </div>
    );
}


export default InventoryFil
