import React, { useEffect, useState } from "react"
import { Card, Col, PopoverBody, Row, UncontrolledPopover } from "reactstrap"
import UpdateTable from "../../../components/Common/UpdatedTable"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import action from "../../../assets/icons/action.svg"
import { searchCentralStoreProductList } from "../../../services/centralStore/centralStore"
import ProductFilter from "./filter"

const ProductList = () => {
  document.title = "Product List | Everise"

  const [page, setPage] = useState("")
  const [productList, setProductList] = useState("")
  const [count, setCount] = useState(0)
  const [status, setStatus] = useState("")
  const [search, setSearch] = useState("")

  useEffect(() => {
    const fetchData = async () => {
      const response = await searchCentralStoreProductList({
        params: `${status == "0" || status == "1" ? `&status=${status}` : ""}${
          search ? `&searchText=${search}` : "&searchText"
        }${page ? `&page=${page}` : ""}&type=centralrequest`,
      })
      setProductList(response)
    }
    fetchData()
  }, [count, status, search, page])

  const columns = [
    {
      label: "Product Code",
      renderCell: item => item?.product_id?.product_code,
    },
    {
      label: "Quantity",
      renderCell: item => item?.quantity,
    },
  ]

  const handlePaginate = page => {
    setPage(page)
  }

  const handleUpdate = () => {
    setCount(count + 1)
  }

  const handleSearchChange = data => {
    setSearch(data)
    setPage(1)
  }
  const handleStatusChange = id => {
    setStatus(id)
    setPage(1)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="d-flex justify-content-between">
            <Breadcrumbs
              title="Central Store"
              breadcrumbObject={[
                { title: "Central Store", link: "/central-store" },
                { title: "Product List", link: "/central-store/product-list" },
              ]}
            />
          </div>

          <Card className="me-2 px-2 pb-2">
            <ProductFilter
              handleSearchChange={handleSearchChange}
              placeholder="Search"
            />
            <hr className="table-divide" />
            <Row className="mt-2 ever-rise-table">
              <Col xs={12}>
                {productList?.data?.data && (
                  <UpdateTable
                    className="mt-5"
                    nodes={productList?.data?.data}
                    paginate={productList?.data}
                    columns={columns}
                    CurrentPage={page}
                    handlePagination={handlePaginate}
                    tableWidth="50% 50%"
                  />
                )}
              </Col>
            </Row>
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}
export default ProductList
