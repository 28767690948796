import { post, del, get, put } from "../helpers/api_helper";

export const getDepartments = (filter) => {
    return get(`/api/department/${filter.page}`, { params: { ...filter } });
}

export const getDepartment = id => post(`/api/department/fetch/${id}`);

export const deleteDepartment = (id) =>
  post(`/api/department/delete/${id}`)

export const postDepartment = (data) => {
  if(data.id === undefined){
    return post(`/api/department/create`, {...data})
  }
  else
    return post(`/api/department/edit/${data.id}`, {...data})
}

export const exportDepartments = (filter, type) => {
    const extension = type === 'excel' ? 'xlsx' : 'pdf';
    return get(`/api/department/export/${extension}`, {
        params: { ...filter },
        responseType: 'blob', // important
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `department_report_${Date.now()}.${extension}`);
        document.body.appendChild(link);
        link.click();
    });
}

export const getUser = () => {
  return get(`user`)
}