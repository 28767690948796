import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Col, Row, Card, UncontrolledPopover, PopoverBody } from "reactstrap"
import UpdateTable from "../../components/Common/UpdatedTable"
import action from "../../assets/icons/action.svg"
import check from "../../assets/icons/estimation_check.svg"
import RolesFilter from "./filter"
import { Link, useNavigate } from "react-router-dom"
import { searchEstimation } from "../../services/estimation/estimation"
import moment from "moment"
import { deleteRole, searchRoles } from "../../services/Inventory/inventory"
import { toastError, toastSuccess } from "../../helpers/msg_helper"

const RolesIndex = () => {
  document.title = "Roles | Ever Rise"

  const navigate = useNavigate()
  const [classData, setClassData] = useState()
  const [status, setStatus] = useState("")
  const [search, setSearch] = useState("")
  const [customer, setCustomer] = useState("")
  const [location, setLocation] = useState("")
  const [page, setPage] = useState("")
  const [refresh, setRefresh] = useState(0)

  const columns = [
    {
      label: "Role Name",
      renderCell: item => item?.name,
    },
    {
      label: "Created On",
      renderCell: item => moment(item?.created_at).format("DD-MM-YYYY"),
    },
    {
      label: "Action",
      renderCell: item => (
        <>
          <div>
            <span id={`PopoverLegacy-${item?.id}`}>
              <img src={action} alt="" className="pointer" />
            </span>
          </div>
          <UncontrolledPopover
            placement="bottom"
            target={`PopoverLegacy-${item?.id}`}
            trigger="legacy"
          >
            <PopoverBody>
              <>
                <div
                  className="table-popup-content"
                  onClick={() => navigate(`/user-management/role-edit/${item?.id}`)}
                >
                  Edit
                </div>
                <div
                  className="table-popup-content"
                  onClick={() => handleRemove(item?.id)}
                >
                  Delete
                </div>
              </>
            </PopoverBody>
          </UncontrolledPopover>
        </>
      ),
    },
  ]

  const handlePaginate = page => {
    setPage(page)
  }

  useEffect(() => {
    const fetchClass = async () => {
      const response = await searchRoles({
        params: `${status == "0" || status == "1" ? `&status=${status}` : ""}${
          search ? `&searchText=${search}` : "&searchText"
        }${customer ? `&client_code=${customer}` : ""}${
          location ? `&location_id=${location}` : ""
        }${page ? `&page=${page}` : ""}`,
      })
      setClassData(response)
    }
    fetchClass()
  }, [status, search, customer, page, location, refresh])

  const handleRemove = async id => {
    try {
      const responce = await deleteRole(id)
      toastSuccess(responce?.message)
      setRefresh(refresh + 1)
    } catch (err) {
      toastError(err?.message)
    }
  }

  const handleStatusChange = id => {
    setStatus(id)
    setPage(1)
  }
  const handleCustomerChange = id => {
    setCustomer(id)
    setPage(1)
  }
  const handleLocationChange = id => {
    setLocation(id)
    setPage(1)
  }
  const handleSearchChange = data => {
    setSearch(data)
    setPage(1)
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="d-flex justify-content-between">
            <Breadcrumbs
              title="Roles"
              breadcrumbObject={[
                { title: "User Management", link: "/user-management" },
                { title: "Roles List", link: "/roles" },
              ]}
            />
            <Link to="/user-management/role-add" className={"px-2 mb-3 btn btn-primary"}>
              {"+ Add Roles"}
            </Link>
          </div>
          <Card className="me-2 px-2 pb-2">
            <RolesFilter
              handleStatusChange={handleStatusChange}
              handleSearchChange={handleSearchChange}
              handleCustomerChange={handleCustomerChange}
              handleLocationChange={handleLocationChange}
              sampleUrl={"download/roles.xlsx"}
              importUrl={"roles/import"}
              isImport={false}
            />
            <hr className="table-divide" />
            <Row className="mt-2 ever-rise-table">
              <Col xs={12}>
                {classData && (
                  <UpdateTable
                    className="mt-5"
                    nodes={classData?.data?.data}
                    paginate={classData?.data}
                    CurrentPage={page}
                    columns={columns}
                    handlePagination={handlePaginate}
                    tableWidth="45% 45% 10%"
                  />
                )}
              </Col>
            </Row>
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}

export default RolesIndex
