import React from "react"
import { Navigate } from "react-router-dom"

import Dashboard from "../pages/Dashboard/index"
import Login from "../pages/Authentication/Login"
import { inventoryRoutes } from "./route/inventory"
import { settingsRoutes } from "./route/settings"
import { procurementRoutes } from "./route/procurement"
import { hrmsRoutes } from "./route/hrms"
import { estimationRoutes } from "./route/estimation"
import { projectManagementRoutes } from "./route/projectManagement"
import Logout from "../pages/Authentication/Logout"
import ModulesIndex from "../pages/modules"
import RolesIndex from "../pages/Roles"
import EditRole from "../pages/Roles/edit"
import { centalStoreRoutes } from "./route/centralStore"
import { financeRoutes } from "./route/finance"
import UserProfile from "../pages/Profile/userProfile/index"
import { hrSelfServiceRoutes } from "./route/hrSelfService"
import { userManagementRoutes } from "./route/userManagement"

const userRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
  { path: "/logout", component: <Logout /> },
  { path: "/profile", component: <UserProfile /> },
  { path: "/modules", component: <ModulesIndex /> },
  { path: "/roles", component: <RolesIndex /> },
  { path: "/user-management/role-edit/:id", component: <EditRole /> },
  { path: "/user-management/role-add", component: <EditRole /> },
  { path: "*", component: <Navigate to="/dashboard" /> },
  ...inventoryRoutes,
  ...settingsRoutes,
  ...hrmsRoutes,
  ...procurementRoutes,
  ...estimationRoutes,
  ...projectManagementRoutes,
  ...centalStoreRoutes,
  ...financeRoutes,
  ...hrSelfServiceRoutes,
  ...userManagementRoutes,
]

const authRoutes = [{ path: "/login", component: <Login /> }]

export { userRoutes, authRoutes }
