import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import { useLocation } from "react-router-dom"
import { FormProvider, useFieldArray, useForm } from "react-hook-form"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  PopoverBody,
  Row,
  UncontrolledPopover,
} from "reactstrap"
import LoadingButton from "../../../../components/Common/LoadingButton"
import { useNavigate, useParams } from "react-router-dom"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import MaterialRequest from "../../../../components/Common/chooseFields/materialrequest"
import PaymentMode from "../../../../components/Common/chooseFields/paymentMode"
import {
  RichTextEditor,
  UFDate,
  UFEditor,
  UFInput,
} from "../../../../components/Common/Fields/Input"
import SupplierList from "../../../../components/Common/chooseFields/supplier"
import uploadicon from "../../../../assets/icons/export-light.svg"
import lightTrash from "../../../../assets/icons/light-trash.svg"
import {
  fetchPurchase,
  postPurchaseOrder,
} from "../../../../services/procurement/purchaseOrder"
import { fetchRequest } from "../../../../services/procurement/materialrequest"
import { fetchSupplier } from "../../../../services/procurement/supplier"
import { toastError, toastSuccess } from "../../../../helpers/msg_helper"
import Productcode from "../../../../components/Common/chooseFields/Product"
import draftToHtml from "draftjs-to-html"
import htmlToDraft from "html-to-draftjs"
import { EditorState, convertToRaw, ContentState } from "draft-js"
import POMaterialRequest from "../../../../components/Common/chooseFields/poMaterialRequest"
import Unit from "../../../../components/Common/chooseFields/unit"
import PhoneInputField from "../../../../components/Common/Fields/phoneInput"
import moment from "moment"
import { useSelector } from "react-redux"
import Info from "../../../../assets/icons/info.svg"
import UpdateTable from "../../../../components/Common/UpdatedTable"
import { searchProcurmentDashRequest } from "../../../../services/projectManager/projectMaster"
import { postAssignEmployee } from "../../../../services/Hrms/hrms"

const RejectEmp = ({ fromDashboard, projectDetails, toggle }) => {
  document.title = "Purchase Order | Ever Rise"
  const { action, editId } = useParams()
  const navigate = useNavigate()
  const [manpowerCheck, setManpowerCheck] = useState(false)
  const id = fromDashboard ? "" : editId
  const schema = yup.object().shape({
    // material_requests_id: yup.mixed().required("MR no is Required"),
  })

  const [isLoading, setIsLoading] = useState(false)
  //pagination state
  const [page, setPage] = useState("")

  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: true,
  })

  const {
    control,
    handleSubmit,
    watch,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      tax: 0,
    },
  })

  const handleRegistration = async data => {
    try {
      setIsLoading(true)
      const updateDepartment = await postAssignEmployee({
        manpower_request_id: projectDetails?.id,

        status: 2,
      })
      toggle()
      toastSuccess(updateDepartment?.message)
    } catch (err) {
      toastError(err?.message)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    const fetchMaterialSearch = async () => {
      const response = await searchProcurmentDashRequest({
        params: `${"&searchText"}${id ? `&project_id=${id}` : ""}`,
      })
      setMaterialRequestData(response)
    }
    fetchMaterialSearch()
  }, [])

  const [materialRequestData, setMaterialRequestData] = useState()
  const handleManpowerCheck = dt => {
    setManpowerCheck(!dt)
    if (!dt == false) {
    }
  }
  console.log("materialRequestData :", materialRequestData)
  const assignColumn = [
    {
      label: "Employee Name",
      renderCell: item => item?.material_requests_code,
    },
    {
      label: "Projects",
      renderCell: item => {
        return <div>{item?.requests_sum_quantity}</div>
      },
    },
    {
      label: "Assign",
      renderCell: item => (
        <Col lg={"auto"} onClick={() => handleManpowerCheck(manpowerCheck)}>
          <FormGroup>
            <input
              type="checkbox"
              className="ever-rise-checkbox"
              name={"is_assign"}
              checked={manpowerCheck}
            />
          </FormGroup>
        </Col>
      ),
    },
  ]

  const handlePaginate = page => {
    setPage(page)
  }

  const mainCard = [
    {
      id: 1,
      name: "Project Name",
      value: "Burj khalifa",
    },
    {
      id: 2,
      name: "Date",
      value: "09-01-2023 to 20-04-2026",
    },
    {
      id: 3,
      name: "Designation",
      value: "Plumber",
    },
  ]
  return (
    <React.Fragment>
      <div className={` ${fromDashboard ? "pt-0" : ""}`}>
        <div className="container-fluid">
          <FormProvider {...methods}>
            <Form
              id={`employee-reject-form`}
              onSubmit={handleSubmit(handleRegistration)}
            >
              <Container fluid className="ps-lg-0">
                <Col sm={12}>
                  <FormGroup className="mb-4">
                    <Label>
                      Reject Reason
                      <span className="mandatory">*</span>
                    </Label>
                    <UFEditor
                      control={control}
                      placeholder="Enter the Reject Reason"
                      rows="5"
                      name={"reject_reason"}
                      invalid={!!errors.reject_reason}
                      className={"pb-0"}
                    />
                    <FormFeedback invalid>
                      {errors.reject_reason?.message}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <>
                  <hr className="m-0" />
                  <div className=" d-flex justify-content-end py-3">
                    <LoadingButton
                      color={"secondary"}
                      className={"me-4"}
                      type="button"
                      onClick={() => toggle()}
                    >
                      Cancel
                    </LoadingButton>
                    <LoadingButton
                      form={`employee-reject-form`}
                      color={"primary"}
                      loading={isLoading}
                      disabled={isLoading}
                      type="submit"
                    >
                      Send
                    </LoadingButton>
                  </div>
                </>
              </Container>
            </Form>
          </FormProvider>
        </div>
      </div>
    </React.Fragment>
  )
}

export default RejectEmp
