import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  ButtonGroup,
  Card,
  CardText,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import { FormProvider, useFieldArray, useForm, useWatch } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import LoadingButton from "../../../components/Common/LoadingButton"
import { UFDate, UFInput } from "../../../components/Common/Fields/Input"
import Location from "../../../components/Common/chooseFields/location"
import CostCenter from "../../../components/Common/chooseFields/costCenter"
import FileInput from "../../../components/Common/Fields/FileInput"
import { fetchEstimation } from "../../../services/estimation/estimation"
import EstimationClass from "../../../components/Common/chooseFields/estimateClass"
import EstimationCategory from "../../../components/Common/chooseFields/estimateCategory"
import EstimationProduct from "../../../components/Common/chooseFields/estimationProduct"
import lightTrash from "../../../assets/icons/light-trash.svg"
import EstimationSubCategory from "../../../components/Common/chooseFields/estimationSubCategory"
import {
  fetchProjecrtMaster,
  postProjectMaster,
} from "../../../services/projectManager/projectMaster"
import { toastError, toastSuccess } from "../../../helpers/msg_helper"
import moment, { duration } from "moment"
import RoleList from "../../../components/Common/chooseFields/roleList"
import SingleFileInput from "../../../components/Common/Fields/singleFileInput"
import Unit from "../../../components/Common/chooseFields/unit"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import { quantity } from "chartist"
import MultiFileInput from "../../../components/Common/Fields/multipleFileInput"
import { values } from "lodash"

const EditProjectMaster = () => {
  document.title = "Project Master | Ever Rise"
  const { action, id } = useParams()
  const navigate = useNavigate()

  const selectLoginState = state => state.Login
  const LoginProperties = createSelector(selectLoginState, login => ({
    loggedUser: login.loggedUser,
  }))

  const { loggedUser } = useSelector(LoginProperties)

  const [isLoading, setIsLoading] = useState(false)
  const [activeTab, setActiveTab] = useState("1")
  const [fetchedData, setFetchedData] = useState()
  const [documentName, setDocumentName] = useState()
  const [rSelected, setRSelected] = useState(2)
  const [classCheck, setClassCheck] = useState(false)
  const [classMaterialSumArray, setClassMaterialtSumArray] = useState([])
  const [classManSumArray, setClassManSumArray] = useState([])
  const [checking, setChecking] = useState()

  const [open, setOpen] = useState("")
  const [accordVal, setAccordVal] = useState([])
  const toggleAcc = (dt, id) => {
    if (open === id) {
      setOpen()
    } else {
      setOpen(id)
      handleAccordVal(dt?.class_id?.value)
      setChecking(dt?.class_id?.value)
    }
  }

  // const [uniqeClassMaterials, setUniqeClassMaterials] = useState()
  // const [materialToManCheck, setMaterialToManCheck] = useState([])
  const schema = yup.object().shape({
    project_manager: yup.mixed().required("Project Manager is Required"),
    store_keeper: yup.mixed().required("Shop Manager is Required"),
    project_name: yup.string().required("Project Name is Required"),
    project_variation: yup
      .number()
      .min(50, "Project Variation must be at least 50%")
      .max(80, "Project Variation must be at less than 80%")
      .typeError("Project Variation must be a number"),
    // .required("Project Variation is required"),
    project_value: yup
      .number()
      .typeError("Project Value must be a valid number")
      .required("Project Value is Required"),
    commencement_date: yup.string().required("Commencement date is Required"),
    exp_completion_date: yup
      .string()
      .required("Exp. completion date is Required"),
    actual_completion_date: yup
      .string()
      .required("Actual completion date is Required"),
    contract_commencement_date: yup
      .string()
      .required("Contract commencement date is Required"),
    revised_completion_date: yup
      .string()
      .required("Revised completion date is Required"),
    estimated_amount: yup
      .number()
      .typeError("Estimation Value must be a valid number")
      .required("Estimated Amount is Required"),
    ...(action == "add"
      ? fetchedData?.estimation_material?.length
      : fetchedData?.project_material?.length && {
          project_materials: yup.array().of(
            yup.object().shape({
              price: yup
                .number()
                .typeError("Estimate Value must be a valid number")
                .required("Estimate Value is Required"),
              class_id: yup.mixed().required("Class Code is Required"),
              unit: yup.mixed().required("Unit is Required"),
              category_id: yup.mixed().required("Category Code is Required"),
              description: yup.string().required("Description is Required"),
              product_id: yup.mixed().required("Product Code is Required"),
              quantity: yup
                .number()
                .typeError("Quantity must be a valid number")
                .required("Quantity value is required")
                .min(0, "Quantity cannot be less than 0")
                .test(
                  "max",
                  `Quantity must not exceed estimated quantity`,
                  function (value) {
                    const { quantity_chk } = this.parent
                    // Custom validation logic here
                    // Remember to return true if validation passes, false otherwise
                    return value <= quantity_chk
                  }
                ),

              contract_value: yup
                .number()
                .typeError("Contract Value must be a valid number")
                .required("Contract value is required"),
              // .when(["price"], (estValue, schema) =>
              //   estValue
              //     ? schema.max(
              //         (estValue * parseFloat(projectVariation)) / 100,
              //         `Contract value cannot exceed ${projectVariation}% of estimated value`
              //       )
              //     : schema
              // ),
            })
          ),
        }),
    ...(action == "add"
      ? fetchedData?.estimation_manpower?.length
      : fetchedData?.project_manpower?.length && {
          project_manpowers: yup.array().of(
            yup.object().shape({
              price: yup
                .number()
                .typeError("Estimate Value must be a valid number")
                .required("Estimate Value is Required"),
              class_id: yup.mixed().required("Class Code is Required"),
              no_of_labours: yup
                .number()
                .typeError("Number of Labours must be a valid number")
                .required("Number of Labours is Required"),
              category_id: yup.mixed().required("Category Code is Required"),
              contract_value: yup
                .number()
                .typeError("Contract Value must be a valid number")
                .required("Contract value is required"),
              // .when(["price"], (estValue, schema) =>
              //   estValue
              //     ? schema.max(
              //         (estValue * parseFloat(projectVariation)) / 100,
              //         `Contract value cannot exceed ${projectVariation}% of estimated value`
              //       )
              //     : schema
              // ),
            })
          ),
        }),
    ...(action == "add"
      ? fetchedData?.estimation_subcontract?.length
      : fetchedData?.project_subcontract?.length && {
          project_subcontracts: yup.array().of(
            yup.object().shape({
              price: yup
                .number()
                .typeError("Estimate Value must be a valid number")
                .required("Estimate Value is Required"),
              class_id: yup.mixed().required("Class Code is Required"),
              unit: yup.mixed().required("Unit is Required"),
              category_id: yup.mixed().required("Category Code is Required"),
              description: yup.string().required("Description is Required"),
              duration: yup.string().required("Duration is Required"),
              contract_value: yup
                .number()
                .typeError("Contract Value must be a valid number")
                .required("Contract value is required"),
              // .when(["price"], (estValue, schema) =>
              //   estValue
              //     ? schema.max(
              //         (estValue * parseFloat(projectVariation)) / 100,
              //         `Contract value cannot exceed ${projectVariation}% of estimated value`
              //       )
              //     : schema
              // ),
            })
          ),
        }),
    ...(action == "add"
      ? fetchedData?.estimation_overhead?.length
      : fetchedData?.project_overhead?.length && {
          project_overheads: yup.array().of(
            yup.object().shape({
              price: yup
                .number()
                .typeError("Estimate Value must be a valid number")
                .required("Estimate Value is Required"),
              class_id: yup.mixed().required("Class Code is Required"),
              category_id: yup.mixed().required("Category Code is Required"),
              description: yup.string().required("Description is Required"),
              contract_value: yup
                .number()
                .typeError("Contract Value must be a valid number")
                .required("Contract value is required"),
              // .when(["price"], (estValue, schema) =>
              //   estValue
              //     ? schema.max(
              //         (estValue * parseFloat(projectVariation)) / 100,
              //         `Contract value cannot exceed ${projectVariation}% of estimated value`
              //       )
              //     : schema
              // ),
            })
          ),
        }),
  })

  const {
    control,
    handleSubmit,
    watch,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: true,
    defaultValues: {
      overheads_value: 0,
      project_materials: [
        {
          class_id: null,
          category_id: null,
          quantity: "",
          product_id: null,
          price: "",
          unit: null,
        },
      ],
      calss_material: [
        {
          class_id: null,
          category_id: null,
          quantity: "",
          product_id: null,
          price: "",
          unit: null,
        },
      ],
      project_manpowers: [
        {
          class_id: null,
          category_id: null,
          no_of_labours: "",
          description: "",
          price: "",
        },
      ],
      project_subcontracts: [
        {
          class_id: null,
          category_id: null,
          description: "",
          duration: "",
          price: "",
          unit: null,
        },
      ],
      project_overheads: [
        {
          class_id: null,
          category_id: null,
          description: "",
          price: "",
        },
      ],
      classbudget_material: [
        {
          class_id: "",
          value: "",
          type: "",
        },
      ],
      classbudget_manpower: [
        {
          class_id: "",
          value: "",
          type: "",
        },
      ],
    },
  })

  // const handleProductChange = (e, index) => {
  //   const getData = getValues()
  //   setValue(
  //     `project_materials.${index}.price`,
  //     getData?.project_materials?.[index]?.quantity
  //       ? getData?.project_materials?.[index]?.quantity * e?.price_aed
  //       : e?.price_aed
  //   )
  //   setValue(`project_materials.${index}.description`, e?.description)
  //   setValue(`project_materials.${index}.unit`, {
  //     label: e?.unit?.unit_code,
  //     value: e?.unit?.id,
  //   })
  //   if (productPrice.length <= index) {
  //     setProductPrice(prevPrices => [
  //       ...prevPrices,
  //       ...Array(index - prevPrices.length + 1).fill(null),
  //     ])
  //   }
  //   setProductPrice(prevPrices => {
  //     const updatedPrices = [...prevPrices]
  //     updatedPrices[index] = e?.price_aed
  //     return updatedPrices
  //   })
  // }

  const projectVariation = watch("project_variation")

  // if(projectVariation > 80){
  //   toastError("Project Value must not exceed above 80%,")
  //   setValue('project_variation', 80)
  // }else if(projectVariation < 50){
  //   setValue('project_variation', 50)
  // }

  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: true,
  })

  const handleChange = (name, file) => {
    setValue(name, file)
    console.log(`handleChange`, name, file)
  }
  const handleDocument = e => {
    const files = Array.from(e.target.files)
    console.log(`incoming`, files)
  }
  let uniqeClassMaterials
  useEffect(() => {
    const fetchProjectMasterId = async id => {
      let response
      if (action == "add") {
        response = await fetchEstimation(id)
        setFetchedData(response?.data)
      } else if (action == "edit") {
        response = await fetchProjecrtMaster(id)
        setFetchedData(response?.data)
      }

      const materialResponse =
        action == "add"
          ? response?.data?.estimation_material
          : response?.data?.project_material
      const materialArray = materialResponse?.map(dt => {
        const estValue = parseFloat(dt?.price)
        let fetchedQuantity = Number(dt?.quantity)
        console.log(`dt-- :`, dt)
        return {
          id: dt?.id,
          class_id: {
            value: dt?.class_id?.id,
            label: dt?.class_id?.class_code,
          },
          is_manpower_material: dt?.is_manpower_material,
          category_id: {
            value: dt?.category_id?.id,
            label: dt?.category_id?.category_code,
          },
          description:
            action == "add" ? dt?.products?.description : dt?.description,
          product_id: {
            value: action == "add" ? dt?.products?.id : dt?.product_code?.id,
            label:
              action == "add"
                ? dt?.products?.product_code
                : dt?.product_code?.product_code,
          },
          unit: {
            value: dt?.unit?.id,
            label: dt?.unit?.unit_code,
          },
          contract_value:
            action == "add"
              ? !isNaN(estValue) && response?.data?.var_value
                ? (estValue * Number(response?.data.var_value)) / 100
                : 0
              : action == "edit"
              ? Number(dt.con_value).toFixed(2)
              : 0,
          price:
            action == "add"
              ? Number(dt?.price).toFixed(2)
              : Number(dt?.est_value).toFixed(2),
          quantity: dt?.quantity,
          // est_value: dt?.est_value,
          quantity_chk: dt?.quantity,
          single_price: dt?.product_code?.price_aed,
        }
      })

      console.log("uniqeClassMaterials ashika:", materialArray)
      const materialClassResponce =
        action == "edit" && response?.data?.classbudget_material
      let materialClassArr
      if (action === "edit") {
        materialClassArr = materialClassResponce?.map(dt => ({
          class_id: { value: dt?.class?.id, label: dt?.class?.class_code },
          // label: action == "add" ? dt?.class_id?.label : dt?.class?.class_code,
          value: dt?.value,
          id: dt?.id,
        }))
      }
      console.log(`materialClassArr`, materialClassArr)
      const manpowerResponse =
        action == "add"
          ? response?.data?.estimation_manpower
          : response?.data?.project_manpower
      const manpowerArray = manpowerResponse?.map(dt => {
        const estValue = parseFloat(dt?.price)
        return {
          id: dt?.id,
          class_id: {
            value: dt?.class_id?.id,
            label: dt?.class_id?.class_code,
          },
          category_id: {
            value: dt?.category_id?.id,
            label: dt?.category_id?.category_code,
          },
          no_of_labours: dt?.no_of_labours,
          description: dt?.category_id?.description,
          // duration: dt?.duration,
          contract_value:
            action == "add"
              ? !isNaN(estValue) && response?.data?.var_value
                ? (estValue * Number(response?.data.var_value)) / 100
                : 0
              : action == "edit"
              ? Number(dt.con_value).toFixed(2)
              : 0,
          price:
            action == "add"
              ? Number(dt?.price).toFixed(2)
              : Number(dt?.est_value).toFixed(2),
        }
      })

      const manPowerClassRes =
        action == "edit" && response?.data?.classbudget_material
      let manPowerArr
      if (action == "edit") {
        manPowerArr = manPowerClassRes?.map(dt => ({
          class_id: { value: dt?.class?.id, label: dt?.class?.class_code },
          value: dt?.value,
          id: dt?.id,
        }))
      }

      const overheadsResponse =
        action == "add"
          ? response?.data?.estimation_overhead
          : response?.data?.project_overhead
      const overheadsArray = overheadsResponse?.map(dt => {
        const estValue = parseFloat(dt?.price)
        return {
          id: dt?.id,
          class_id: {
            value: dt?.class_id?.id,
            label: dt?.class_id?.class_code,
          },
          category_id: {
            value: dt?.category_id?.id,
            label: dt?.category_id?.category_code,
          },
          description: dt?.description,
          contract_value:
            action == "add"
              ? !isNaN(estValue) && response?.data?.var_value
                ? (estValue * Number(response?.data.var_value)) / 100
                : 0
              : action == "edit"
              ? Number(dt.con_value).toFixed(2)
              : 0,
          price:
            action == "add"
              ? Number(dt?.price).toFixed(2)
              : Number(dt?.est_value).toFixed(2),
        }
      })

      const contractResponse =
        action == "add"
          ? response?.data?.estimation_subcontract
          : response?.data?.project_subcontract
      const contractArray = contractResponse?.map(dt => {
        const estValue = parseFloat(dt?.price)
        return {
          id: dt?.id,
          class_id: {
            value: dt?.class_id?.id,
            label: dt?.class_id?.class_code,
          },
          category_id: {
            value: dt?.category_id?.id,
            label: dt?.category_id?.category_code,
          },
          unit: {
            value: dt?.unit?.id,
            label: dt?.unit?.unit_code,
          },
          duration: dt?.duration,
          description: dt?.description,
          contract_value:
            action == "add"
              ? !isNaN(estValue) && response?.data?.var_value
                ? (estValue * Number(response?.data.var_value)) / 100
                : 0
              : action == "edit"
              ? Number(dt.con_value).toFixed(2)
              : 0,
          price:
            action == "add"
              ? Number(dt?.price).toFixed(2)
              : Number(dt?.est_value).toFixed(2),
        }
      })
      reset({
        ...response?.data,
        budget_type: setClassCheck(
          response?.data?.budget_type == 1 ? true : false
        ),
        project_variation: Number(response?.data?.var_value),
        project_name:
          action == "add"
            ? response?.data?.client_code?.project_name
            : response?.data?.project_name,
        location_id: {
          label:
            action == "add"
              ? response?.data?.client_code?.location?.name
              : response?.data?.location?.name,
          value:
            action == "add"
              ? response?.data?.client_code?.location?.id
              : response?.data?.location?.id,
        },
        project_manager:
          action == "add"
            ? null
            : {
                label: response?.data?.project_manager?.name,
                value: response?.data?.project_manager?.id,
              },
        store_keeper:
          action == "add"
            ? null
            : {
                label: response?.data?.store_keeper?.name,
                value: response?.data?.store_keeper?.id,
              },
        estimated_amount: response?.data?.amount,
        customer_code:
          action == "add"
            ? response?.data?.client_code?.customers?.customer_code
            : response?.data?.customers?.customer_code,
        customer_name:
          action == "add"
            ? response?.data?.client_code?.customers?.customer_name
            : response?.data?.customers?.customer_name,
        customer_contact_person:
          action == "add"
            ? response?.data?.client_code?.customers?.contact_person
            : response?.data?.customers?.contact_person,
        designation:
          action == "add"
            ? response?.data?.client_code?.customers?.contact_person_designation
            : response?.data?.customers?.contact_person_designation,
        customer_number:
          action == "add"
            ? response?.data?.client_code?.customers?.customer_number
            : response?.data?.customers?.customer_number,
        classbudget_material: materialClassArr?.length
          ? materialClassArr
          : [{ class_id: "", value: "", type: "" }],
        project_materials: materialResponse?.length
          ? materialArray
          : [
              {
                class_id: null,
                category_id: null,
                quantity: "",
                product_id: null,
                price: "",
                unit: null,
              },
            ],
        project_manpowers: manpowerResponse?.length
          ? manpowerArray
          : [
              {
                class_id: null,
                category_id: null,
                no_of_labours: "",
                price: "",
              },
            ],
        project_subcontracts: contractResponse?.length
          ? contractArray
          : [
              {
                class_id: null,
                category_id: null,
                description: "",
                duration: "",
                price: "",
                unit: null,
              },
            ],
        project_overheads: overheadsResponse?.length
          ? overheadsArray
          : [
              {
                class_id: null,
                category_id: null,

                description: "",
                price: "",
              },
            ],
      })
      setDocumentName(response?.data?.document?.[0])
    }
    if (id) {
      fetchProjectMasterId(id)
    }
  }, [id])

  useEffect(() => {
    const materialClassArr = classMaterialSumArray?.map(dt => ({
      class_id: { value: dt?.class_id?.value, label: dt?.class_id?.label },
      value: dt?.total_contract_value,
      id: dt?.id,
    }))
    const data = getValues()
    console.log(`ssss`, data)
    setValue(
      `classbudget_material`,
      materialClassArr?.length
        ? materialClassArr
        : [{ class_id: "", value: "", type: "" }]
    )
    // reset({
    //   // ...data,
    //   classbudget_material: materialClassArr?.length
    //     ? materialClassArr
    //     : [{ class_id: "", value: "", type: "" }],
    // })
  }, [classMaterialSumArray])

  useEffect(() => {
    const data = getValues()
    const materialArray = data?.project_materials?.map(dt => {
      const estValue = parseFloat(dt?.price)
      return {
        ...dt,
        contract_value:
          action == "add"
            ? !isNaN(estValue) && projectVariation
              ? (estValue * Number(projectVariation)) / 100
              : 0
            : action == "edit"
            ? Number(dt.con_value).toFixed(2)
            : 0,
      }
    })
    const manpowerArray = data?.project_manpowers?.map(dt => {
      const estValue = parseFloat(dt?.price)
      return {
        ...dt,
        contract_value:
          action == "add"
            ? !isNaN(estValue) && projectVariation
              ? (estValue * Number(projectVariation)) / 100
              : 0
            : action == "edit"
            ? Number(dt.con_value).toFixed(2)
            : 0,
      }
    })
    const overheadsArray = data?.project_overheads?.map(dt => {
      const estValue = parseFloat(dt?.price)
      return {
        ...dt,
        contract_value:
          action == "add"
            ? !isNaN(estValue) && projectVariation
              ? (estValue * Number(projectVariation)) / 100
              : 0
            : action == "edit"
            ? Number(dt.con_value).toFixed(2)
            : 0,
      }
    })
    const contractArray = data?.project_subcontracts?.map(dt => {
      const estValue = parseFloat(dt?.price)
      return {
        ...dt,
        contract_value:
          action == "add"
            ? !isNaN(estValue) && projectVariation
              ? (estValue * Number(projectVariation)) / 100
              : 0
            : action == "edit"
            ? Number(dt.con_value).toFixed(2)
            : 0,
      }
    })
    if (action == "add") {
      reset({
        ...data,
        project_materials: materialArray,
        project_manpowers: manpowerArray,
        project_subcontracts: contractArray,
        project_overheads: overheadsArray,
        calss_material: materialArray,
      })
    }
  }, [projectVariation])

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  const {
    fields: materialFields,
    append: materialAppend,
    remove: materialRemove,
  } = useFieldArray({
    control,
    name: "project_materials",
  })
  const {
    fields: manpowerFields,
    append: manpowerAppend,
    remove: manpowerRemove,
  } = useFieldArray({
    control,
    name: "project_manpowers",
  })
  const { fields: accordValFields } = useFieldArray({
    control,
    name: "project_materials",
  })
  console.log(`accordValFields`, accordVal)

  const filteredArr = Array.from(
    new Map(
      materialFields.map(address => [address.class_id?.value, address])
    ).values()
  )

  const watchMaterial = useWatch({
    control,
    name: "project_materials",
    defaultValue: materialFields,
  })

  console.log(`watchMaterial`, watchMaterial)

  const watchManpower = useWatch({
    control,
    name: "project_manpowers",
    defaultValue: manpowerFields,
  })

  useEffect(() => {
    const calculateSumArray = () => {
      const sumByMaterialClassId = {}
      const sumByManpowerClassId = {}

      watch("project_materials").forEach(address => {
        const classIdValue = address.class_id?.value
        const classLabel = address.class_id?.label
        const contractValue = Number(address.contract_value)
        console.log(`address`, address, classIdValue)
        if (classIdValue) {
          if (!sumByMaterialClassId[classIdValue]) {
            sumByMaterialClassId[classIdValue] = { sum: 0, label: classLabel }
          }
          sumByMaterialClassId[classIdValue].sum += contractValue
        }
      })
      // Convert summed values into an array of objects
      const newSumArray = Object.keys(sumByMaterialClassId).map(
        classIdValue => ({
          class_id: {
            value: classIdValue,
            label: sumByMaterialClassId[classIdValue]?.label,
          },
          total_contract_value: sumByMaterialClassId[classIdValue].sum,
        })
      )

      console.log(`sumByMaterialClassId`, sumByMaterialClassId, newSumArray)

      watch("project_manpowers")?.forEach(address => {
        const classIdValue = address.class_id?.value
        const classLabel = address.class_id?.label
        const contractValue = Number(address.contract_value)

        if (classIdValue) {
          // Initialize sum if not already initialized
          if (!sumByManpowerClassId[classIdValue]) {
            sumByManpowerClassId[classIdValue] = {
              sum: 0,
              label: classLabel, // Set the label here
            }
          }

          // Accumulate the sum
          sumByManpowerClassId[classIdValue].sum += contractValue
        }
      })
      console.log(`sumByManpowerClassId`, sumByManpowerClassId)
      // Convert summed values into an array of objects
      const newSumManArray = Object.keys(sumByManpowerClassId).map(
        classIdValue => ({
          class_id: {
            value: classIdValue,
            label: sumByManpowerClassId[classIdValue].label,
          },
          total_contract_value: sumByManpowerClassId[classIdValue].sum,
        })
      )

      setClassMaterialtSumArray(newSumArray)
      setClassManSumArray(newSumManArray)
    }
    // if (watch("project_manpowers") || watch("project_materials")) {
    console.log(`chkkkkkkkkkk  contract_value`)
    calculateSumArray()
    // }
    // setAccordVal(materialFields)
  }, [
    materialFields,
    manpowerFields,
    watchMaterial,
    watchManpower,
    // materialFields?.map((dt, i)=>{
    //   watch(`project_materials.${i}.contract_value`)}),
    //   manpowerFields?.map((dt, i)=>{
    //   watch(`project_manpowers.${i}.contract_value`)}),
  ])
  console.log(`filteredArr`, watchMaterial)

  const classMaterialMap = materialFields.reduce((acc, item) => {
    const key = item.class_id?.value
    if (!acc[key]) {
      acc[key] = {
        class_id: item.class_id,
        total_contract_value: 0,
      }
    }
    acc[key].total_contract_value += Number(item.contract_value || 0)
    // setUniqeClassMaterials(Object.values(classMaterialMap))

    return acc
  }, {})

  uniqeClassMaterials = Object.values(classMaterialMap)
  console.log(`uniqeClassMaterials`, uniqeClassMaterials)

  const {
    fields: overheadsFields,
    append: overheadsAppend,
    remove: overheadsRemove,
  } = useFieldArray({
    control,
    name: "project_overheads",
  })

  const {
    fields: contractFields,
    append: contractAppend,
    remove: contractRemove,
  } = useFieldArray({
    control,
    name: "project_subcontracts",
  })

  const watchOverheads = useWatch({
    control,
    name: "project_overheads",
    defaultValue: overheadsFields,
  })

  const watchContract = useWatch({
    control,
    name: "project_subcontracts",
    defaultValue: contractFields,
  })

  const classMaterialWatch = useWatch({
    control,
    name: "classbudget_material",
  })
  console.log(`classMaterialWatch`, classMaterialWatch)
  let result
  let classResult
  useEffect(() => {
    result = [
      ...watchMaterial,
      ...watchManpower,
      // ...watchOverheads,
      ...watchContract,
    ].reduce(
      (acc, item) => {
        const contractValue = item.contract_value || 0
        return {
          subtotal: acc.subtotal + parseFloat(contractValue),
        }
      },
      { subtotal: 0 }
    )
    classResult = [...classMaterialWatch].reduce(
      (acc, item) => {
        const contractVal = item?.value || 0
        return {
          classSubTotal: acc.classSubTotal + parseFloat(contractVal),
        }
      },
      { classSubTotal: 0 }
    )
    if (action == "add") {
      setValue(
        "project_value",
        Number(result?.subtotal + watch("overheads_value"))
          // +
          // Number(result?.subtotal) * (Number(fetchedData?.tax) / 100) -
          // Number(result?.subtotal) * (Number(fetchedData?.discount) / 100)
          .toFixed(2)
      )
    } else {
      setValue(
        "project_value_compare",
        Number(result?.subtotal + watch("overheads_value"))
          // +
          // Number(result?.subtotal) * (Number(fetchedData?.tax) / 100) -
          // Number(result?.subtotal) * (Number(fetchedData?.discount) / 100)
          .toFixed(2)
      )
    }
  }, [
    watchMaterial,
    watchManpower,
    watchOverheads,
    watchContract,
    classMaterialWatch,
  ])

  useEffect(() => {
    result = [...watchMaterial, ...watchManpower, ...watchContract].reduce(
      (acc, item) => {
        const contractValue = item.price || 0
        return {
          subtotal: acc.subtotal + parseFloat(contractValue),
        }
      },
      { subtotal: 0 }
    )
    console.log(`result?.subtotal`, result?.subtotal)
    setValue(
      "overheads_value",
      (Number(result?.subtotal) * fetchedData?.overheads_per) / 100
    )
  }, [watchMaterial, watchManpower, watchOverheads, watchContract])

  const handleClassCheck = dt => {
    setClassCheck(!dt)
  }
  console.log(`classCheck`, watch("overheads_value"), watch(`project_value`))

  const handleRegistration = async data => {
    console.log(`handleRegistration`, data)
    if (
      data?.project_value < Number(data?.project_value_compare) &&
      action == "edit" &&
      data?.project_value_compare
    ) {
      toastError(`Project value cannot exceed ${data?.project_value}`)
    }
    // else if (data?.document == undefined) {
    //   toastError(`Document is Required`)
    // }
    else {
      const removeField = [
        "estimation_material",
        "estimation_manpower",
        "estimation_subcontract",
        "estimation_overhead",
        "project_overheads",
        "project_subcontracts",
        "project_manpowers",
        "project_materials",
      ]
      const filteredData = Object.fromEntries(
        Object.entries(data).filter(([key]) => !removeField.includes(key))
      )

      const classMaterialArr = []
      classCheck &&
        data?.classbudget_material?.map((dt, i) =>
          classMaterialArr.push({
            ...(dt?.id && { id: dt?.id }),
            class_id: dt?.class_id?.value,
            value: dt?.value,
            type: "material",
          })
        )

      const classManArr = []
      classCheck &&
        data?.classbudget_manpower?.map((dt, i) =>
          classManArr.push({
            ...(dt?.id && { id: dt?.id }),
            class_id: dt?.class_id?.value,
            value: dt?.value,
            type: "manpower",
          })
        )
      console.log(`classMaterialArr`, classManArr)
      const materialArray = []
      data?.project_materials?.map((dt, i) =>
        materialArray.push({
          ...(dt?.id && { id: dt?.id }),
          class_id: dt?.class_id?.value,
          category_id: dt?.category_id?.value,
          description: dt?.description,
          product_id: dt?.product_id?.value,
          con_value: dt?.contract_value,
          est_value: dt?.price,
          quantity: dt?.quantity,
          price: dt?.price,
          unit_id: dt?.unit?.value,
          is_manpower_material: dt?.is_manpower_material,
        })
      )

      const manPowerArray = []
      data?.project_manpowers?.map((dt, i) =>
        manPowerArray.push({
          ...(dt?.id && { id: dt?.id }),
          class_id: dt?.class_id?.value,
          category_id: dt?.category_id?.value,
          description: dt?.description,
          no_of_labours: dt?.no_of_labours,
          con_value: dt?.contract_value,
          est_value: dt?.price,
          price: dt?.price,
        })
      )
      const overHeadsArray = []
      data?.project_overheads?.map((dt, i) =>
        overHeadsArray.push({
          ...(dt?.id && { id: dt?.id }),
          class_id: dt?.class_id?.value,
          category_id: dt?.category_id?.value,
          description: dt?.description,
          con_value:
            dt?.contract_value <= (dt?.price * 80) / 100
              ? dt?.contract_value
              : (dt?.price * 80) / 100,
          est_value: dt?.price,
        })
      )
      const subContractArray = []
      data?.project_subcontracts?.map((dt, i) =>
        subContractArray.push({
          ...(dt?.id && { id: dt?.id }),
          class_id: dt?.class_id?.value,
          category_id: dt?.category_id?.value,
          description: dt?.description,
          duration: dt?.duration,
          con_value: dt?.contract_value,
          est_value: dt?.price,
          unit_id: dt?.unit?.value,
        })
      )

      try {
        setIsLoading(true)
        const createdSeries = await postProjectMaster({
          ...filteredData,
          id:
            action == "add" && fetchedData?.client_code?.project_status == 0
              ? ""
              : id,
          budget_type: classCheck == true ? 1 : 0,
          commencement_date: moment(data?.commencement_date).format(
            "YYYY-MM-DD"
          ),
          exp_completion_date: moment(data?.exp_completion_date).format(
            "YYYY-MM-DD"
          ),
          var_value: data?.project_variation,
          project_location: data?.location_id?.value,
          project_manager: data?.project_manager?.id,
          store_keeper: data?.store_keeper?.id,
          customer_code: data?.client_code?.customers?.id,
          overheads_per: fetchedData?.overheads_per,
          ...(data?.project_materials[0]?.class_id != null && {
            project_materials: materialArray,
          }),
          ...(data?.project_manpowers[0]?.class_id != null && {
            project_manpowers: manPowerArray,
          }),
          ...(data?.project_overheads[0]?.class_id != null && {
            project_overheads: overHeadsArray,
          }),
          ...(data?.project_subcontracts[0]?.class_id != null && {
            project_subcontracts: subContractArray,
          }),
          ...(classCheck && { classbudget: classMaterialArr, ...classManArr }),
          actual_completion_date: moment(data?.actual_completion_date).format(
            "YYYY-MM-DD"
          ),
          contract_commencement_date: moment(
            data?.contract_commencement_date
          ).format("YYYY-MM-DD"),
          revised_completion_date: moment(data?.revised_completion_date).format(
            "YYYY-MM-DD"
          ),
          estimated_amount: data?.estimated_amount,
          project_status:
            fetchedData?.client_code?.project_status == 0 ? 1 : "",
          estimation_id: fetchedData?.estimation_id
            ? fetchedData?.estimation_id
            : "",
          estimation_revision_id: data?.estimation_id,
          tax: data?.tax,
          discount: data?.discount,
          amount: data?.amount,
        })
        toastSuccess(createdSeries?.message)
        navigate(
          loggedUser?.permission?.some(
            permissionItem => permissionItem?.name === "Convert to Project"
          )
            ? "/estimation"
            : loggedUser?.data?.id === 1
            ? "/project-master"
            : "/project-master-dashboard"
        )
      } catch (err) {
        toastError(err?.message)
      } finally {
        setIsLoading(false)
      }
    }
  }
  console.log(
    `classbudget_material`,
    watch(`classbudget_material.[1].value`),
    watch("project_materials")
  )
  const handlePriceChange = (a, b) => {
    const priceWatch = watch(a)
    setValue(b, (priceWatch * 80) / 100)
  }
  const handleContractChange = (a, b) => {
    const contractWatch = watch(a)

    if (action == "add") {
      if (contractWatch > (watch(b) * Number(projectVariation)) / 100) {
        toastError(
          `Contract value cannot exceed ${projectVariation}% of estimated value`
        )
        setValue(a, (watch(b) * Number(projectVariation)) / 100)
      }
      // } else {
      //   if (contractWatch > Number(dt?.con_value)) {
      //     toastError(`Contract value cannot exceed ${dt?.con_value}`)
      //     setValue(a, dt?.con_value)
      //   }
    }
  }

  const handleQuantityPrice = (a, b, c) => {
    const quantityWatch = watch(a)
    const priceWatch = watch(b)
    setValue(c, (quantityWatch * (priceWatch * Number(projectVariation))) / 100)
  }
  const projectValueWatch = watch("project_value")

  const handleCategoryChange = (e, i) => {
    setValue(`project_manpowers.${i}.description`, e?.description)
  }
  const handleOverheadsCatChange = (e, i) => {
    setValue(`project_overheads.${i}.description`, e?.description)
  }
  const handleContractCatChange = (e, i) => {
    setValue(`project_subcontracts.${i}.description`, e?.description)
  }

  // const handleMaterialToManCheck = (e, index) => {
  //   setMaterialToManCheck(materialToMan => {
  //     const updatedValues = [...materialToMan]
  //     updatedValues[index] = !materialToMan[index]
  //     return updatedValues
  //   })
  // }

  const handleAccordVal = id => {
    const val = materialFields?.filter(item => item.class_id.value == id)
    setAccordVal(val)
  }
  console.log(`handleAccordVal`, accordVal)

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <FormProvider {...methods}>
            <Form
              id={`supplier-form`}
              onSubmit={handleSubmit(handleRegistration)}
            >
              <div className="d-flex justify-content-between me-2">
                <Breadcrumbs
                  title="Project Master"
                  breadcrumbObject={[
                    { title: "Project Managemant", link: "/estimation" },
                    {
                      title: "Project Master",
                      link: "/project-master",
                    },
                    {
                      title:
                        action == "edit"
                          ? "Edit Project"
                          : "Convert to Project",
                      link:
                        action == "edit"
                          ? `/project-master-edit/${id}`
                          : "/project-master-add",
                    },
                  ]}
                />

                <LoadingButton
                  form={`supplier-form`}
                  color={"primary"}
                  loading={isLoading}
                  disabled={isLoading}
                  className={"px-2 mb-3 btn btn-primary"}
                  type="submit"
                >
                  {action == "edit"
                    ? "Convert to Budget"
                    : "Convert to Project"}
                </LoadingButton>
              </div>
              <Container fluid className="ps-lg-0">
                <Card className="invoice-main-card p-3 pt-4">
                  {loggedUser?.permission?.some(
                    permissionItem =>
                      permissionItem?.name === "Convert to Project"
                  )}
                  <Row className="mb-4 mt-2">
                    <Col sm={4}>
                      <FormGroup className="mb-4">
                        <Label>
                          Project name<span className="mandatory">*</span>
                        </Label>
                        <UFInput
                          control={control}
                          placeholder="Enter the Project name"
                          name={"project_name"}
                          invalid={!!errors.project_name}
                          disabled={action == "edit" ? true : false}
                        />
                        <FormFeedback invalid>
                          {errors.project_name?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>

                    <Col sm={4}>
                      <FormGroup className="mb-4">
                        <Label>
                          Project location<span className="mandatory">*</span>
                        </Label>
                        <Location
                          control={control}
                          placeholder="Enter the Project Location"
                          name={"location_id"}
                          invalid={!!errors.location_id}
                          disabled={action == "edit" ? true : false}
                        />
                        <FormFeedback invalid>
                          {errors.location_id?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>

                    {/* <Col sm={4}>
                      <FormGroup className="mb-4">
                        <Label>Cost Center</Label>
                        <CostCenter
                          control={control}
                          placeholder="Select the Cost Center"
                          name={"cost_center_id"}
                          invalid={!!errors.cost_center_id}
                          target={"body"}
                          disabled={action == "edit" ? true : false}
                        />
                        <FormFeedback invalid>
                          {errors.cost_center_id?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col> */}

                    <Col sm={4}>
                      <FormGroup className="mb-4">
                        <Label>Customer Code</Label>
                        <UFInput
                          control={control}
                          placeholder="Enter the Customer Code"
                          name={"customer_code"}
                          invalid={!!errors.customer_code}
                          disabled={true}
                        />
                        <FormFeedback invalid>
                          {errors.customer_code?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>

                    <Col sm={4}>
                      <FormGroup className="mb-4">
                        <Label>Customer name</Label>
                        <UFInput
                          control={control}
                          placeholder="Enter the Client name"
                          name={"customer_name"}
                          invalid={!!errors.customer_name}
                          disabled={true}
                        />
                        <FormFeedback invalid>
                          {errors.customer_name?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>

                    <Col sm={4}>
                      <FormGroup className="mb-4">
                        <Label>Customer Contact Person</Label>
                        <UFInput
                          control={control}
                          placeholder="Enter the Contact person"
                          name={"customer_contact_person"}
                          invalid={!!errors.customer_contact_person}
                          disabled={true}
                        />
                        <FormFeedback invalid>
                          {errors.customer_contact_person?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>

                    <Col sm={4}>
                      <FormGroup className="mb-4">
                        <Label>Designation</Label>
                        <UFInput
                          control={control}
                          placeholder="Enter the Project Manager"
                          name={"designation"}
                          invalid={!!errors.designation}
                          disabled={true}
                        />
                        <FormFeedback invalid>
                          {errors.designation?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>

                    <Col sm={4}>
                      <FormGroup className="mb-4">
                        <Label>Customer number</Label>
                        <UFInput
                          control={control}
                          placeholder="Enter the Contact Number"
                          name={"customer_number"}
                          invalid={!!errors.customer_number}
                          disabled={true}
                        />
                        <FormFeedback invalid>
                          {errors.customer_number?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>

                    <Col sm={4}>
                      <FormGroup className="mb-4">
                        <Label>
                          Project Manager
                          <span className="mandatory">*</span>
                        </Label>
                        <RoleList
                          control={control}
                          placeholder="Select the Project Manager"
                          name={"project_manager"}
                          invalid={!!errors.project_manager}
                          target={"body"}
                          url={`employee-details/role-list/?parm=Project Manager`}
                          disabled={action == "edit" ? true : false}
                        />
                        <FormFeedback invalid>
                          {errors.project_manager?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>

                    <Col sm={4}>
                      <FormGroup className="mb-4">
                        <Label>
                          Store Keeper
                          <span className="mandatory">*</span>
                        </Label>
                        <RoleList
                          control={control}
                          placeholder="Select the Store Keeper"
                          name={"store_keeper"}
                          invalid={!!errors.store_keeper}
                          target={"body"}
                          url={`employee-details/role-list/?parm=Store Keeper`}
                          disabled={action == "edit" ? true : false}
                        />
                        <FormFeedback invalid>
                          {errors.store_keeper?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>

                    <Col sm={4}>
                      <FormGroup className="mb-4">
                        <Label>
                          Commencement date<span className="mandatory">*</span>
                        </Label>
                        <UFDate
                          control={control}
                          name={"commencement_date"}
                          invalid={!!errors.commencement_date}
                          disableCondition={action == "edit" ? true : false}
                        />
                        <FormFeedback invalid>
                          {errors.commencement_date?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>

                    <Col sm={4}>
                      <FormGroup className="mb-4">
                        <Label>
                          Contract commencement date
                          <span className="mandatory">*</span>
                        </Label>
                        <UFDate
                          control={control}
                          name={"contract_commencement_date"}
                          invalid={!!errors.contract_commencement_date}
                          disableCondition={action == "edit" ? true : false}
                        />
                        <FormFeedback invalid>
                          {errors.contract_commencement_date?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>

                    {loggedUser?.permission?.some(
                      permissionItem =>
                        permissionItem?.name === "Convert to Project"
                    ) && (
                      <Col sm={4}>
                        <FormGroup className="mb-4">
                          <Label>
                            Project Variation Amount (%)
                            <span className="mandatory">*</span>
                          </Label>
                          <UFInput
                            control={control}
                            placeholder="Enter the Project Value"
                            name={"project_variation"}
                            invalid={!!errors.project_variation}
                            // disabled={true}
                          />
                          <FormFeedback invalid>
                            {errors.project_variation?.message}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                    )}

                    <Col sm={4}>
                      <FormGroup className="mb-4">
                        <Label>Project Value (AED)</Label>
                        <UFInput
                          control={control}
                          placeholder="Enter the Project Value"
                          name={"project_value"}
                          invalid={!!errors.project_value}
                          disabled={true}
                        />
                        <FormFeedback invalid>
                          {errors.project_value?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>

                    {loggedUser?.permission?.some(
                      permissionItem =>
                        permissionItem?.name === "Convert to Project"
                    ) && (
                      <Col sm={4}>
                        <FormGroup className="mb-4">
                          <Label>Estimated Amount (AED)</Label>
                          <UFInput
                            control={control}
                            placeholder="Enter the Estimate Amount"
                            name={"estimated_amount"}
                            invalid={!!errors.estimated_amount}
                            disabled={true}
                          />
                          <FormFeedback invalid>
                            {errors.estimated_amount?.message}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                    )}

                    <Col sm={4}>
                      <FormGroup className="mb-4">
                        <Label>
                          Exp. completion date{" "}
                          <span className="mandatory">*</span>
                        </Label>
                        <UFDate
                          control={control}
                          name={"exp_completion_date"}
                          invalid={!!errors.exp_completion_date}
                          disableCondition={action == "edit" ? true : false}
                        />
                        <FormFeedback invalid>
                          {errors.exp_completion_date?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>

                    <Col sm={4}>
                      <FormGroup className="mb-4">
                        <Label>
                          Actual completion date
                          <span className="mandatory">*</span>
                        </Label>
                        <UFDate
                          control={control}
                          name={"actual_completion_date"}
                          invalid={!!errors.actual_completion_date}
                          disableCondition={action == "edit" ? true : false}
                        />
                        <FormFeedback invalid>
                          {errors.actual_completion_date?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>

                    <Col sm={4}>
                      <FormGroup className="mb-4">
                        <Label>
                          Revised completion date
                          <span className="mandatory">*</span>
                        </Label>
                        <UFDate
                          control={control}
                          name={"revised_completion_date"}
                          invalid={!!errors.revised_completion_date}
                          disableCondition={action == "edit" ? true : false}
                        />
                        <FormFeedback invalid>
                          {errors.revised_completion_date?.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    {loggedUser?.permission?.some(
                      permissionItem =>
                        permissionItem?.name === "Convert to Project"
                    ) && (
                      <Col sm={4}>
                        <FormGroup className="mb-3 mt-2">
                          <Label>
                            Document Upload<span className="mandatory">*</span>
                          </Label>
                          <SingleFileInput
                            control={control}
                            accept=".png,.jpg,.jpeg,.svg,.pdf"
                            name={`document`}
                            label="Upload File"
                            onlyIcon={true}
                            tooltip="Document Upload"
                            handleChange={handleChange}
                            downloadPath={documentName}
                            multiple={true}
                          />
                          {/* <FileInput
                          accept="image/png, image/jpg, image/jpeg"
                          name="importfile"
                          // label="Logo"
                        /> */}
                          {/* style={{ display: validation ? 'block' : 'none' }} */}
                          <FormFeedback invalid>
                            {errors.document?.message}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                    )}
                    <Col sm={4}>
                      <FormGroup className="mb-3 mt-2 pt-2">
                        <Label></Label>
                        <div
                          // lg={"auto"}
                          onClick={() =>
                            action === "add" && handleClassCheck(classCheck)
                          }
                          className=" d-flex align-items-center"
                        >
                          <input
                            type="checkbox"
                            className="ever-rise-checkbox"
                            name={"budget_type"}
                            checked={classCheck}
                            disabled={action == "edit" ? true : false}
                          />
                          <Label className="ms-2 mb-0">Class Budget</Label>
                        </div>
                      </FormGroup>
                    </Col>
                    {/* <Col sm={4}>
                      <FormGroup className="mb-3 mt-2">
                        <Label>
                          Document Upload<span className="mandatory">*</span>
                        </Label>
                        <div className="btn-upload pointer">
                          <label htmlFor="doc_file">
                            <input
                              // control={control}
                              // className="text-14 btn-upload fw-500"
                              className="file-input"
                              type="file"
                              placeholder="Choose File"
                              accept=".png, .jpg, .pdf"
                              multiple
                              name="document"
                              id="doc_file"
                              onChange={handleDocument}
                            />
                          </label>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col lg="4" xs={12} md={6} sm={12}>
                      <label htmlFor="doc_file">
                        <MultiFileInput
                          accept=".pdf,.doc"
                          name="document"
                          label="Reg Doc"
                          multiple="true"
                          mode="append"
                          downloadPath={id ? documentName : ""}
                          downloadAttachmentByID={id ? id : ""}
                          from="project"
                          // downloadAttachment={
                          //   id
                          //     ? () =>
                          //         downloadAttachment(
                          //           id,
                          //           fileAttachment
                          //             ?.split('/')
                          //             ?.slice(-1)
                          //             ?.join(),
                          //         )
                          //     : ''
                          // }
                        />
                      </label>
                      <FormFeedback invalid>
                        {errors.company_reg_doc?.message}
                      </FormFeedback>
                    </Col> */}
                  </Row>

                  {loggedUser?.permission?.some(
                    permissionItem =>
                      permissionItem?.name === "Convert to Project"
                  ) ? (
                    <></>
                  ) : (
                    <div>
                      {/* <div>
                        <div>Budget Calculation</div>
                        <ButtonGroup>
                          <Button
                            color="primary"
                            outline
                            onClick={() => setRSelected(1)}
                            active={rSelected === 1}
                          >
                            Class Budget
                          </Button>
                          <Button
                            color="primary"
                            outline
                            onClick={() => setRSelected(2)}
                            active={rSelected === 2}
                          >
                            Detailed Budget
                          </Button>
                        </ButtonGroup>
                      </div> */}
                      {/* <div className="d-flex justify-content-between"> */}
                      <Nav tabs>
                        {/* <div className="me-auto"> */}
                        <NavItem>
                          <NavLink
                            className={
                              activeTab === "1" ? "active pointer" : "pointer"
                            }
                            onClick={() => {
                              toggle("1")
                            }}
                          >
                            Material
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={
                              activeTab === "2" ? "active pointer" : "pointer"
                            }
                            onClick={() => {
                              toggle("2")
                            }}
                          >
                            Manpower
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={
                              activeTab === "3" ? "active pointer" : "pointer"
                            }
                            onClick={() => {
                              toggle("3")
                            }}
                          >
                            Subcontract
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={
                              activeTab === "4" ? "active pointer" : "pointer"
                            }
                            onClick={() => {
                              toggle("4")
                            }}
                          >
                            Overheads
                          </NavLink>
                        </NavItem>
                        {/* </div> */}
                      </Nav>
                      {/* <div className="d-flex"> */}
                      {/* <Col
                          lg={"auto"}
                          // onClick={() => handleFinacialCheck(finacialCheck)}
                          className=" d-flex align-items-center"
                        >
                          <input
                            type="checkbox"
                            className="ever-rise-checkbox"
                            name={"is_finacial_year"}
                            // checked={finacialCheck}
                          />
                          <Label className="ms-2 mb-0">Class Budget</Label>
                        </Col> */}
                      {/* </div> */}
                      <TabContent activeTab={activeTab} className="pt-4">
                        <TabPane tabId="1">
                          <Row className="mb-4">
                            <Col lg={12}>
                              {classCheck === false ? (
                                <>
                                  <table className="estimation-table mb-4">
                                    <thead>
                                      <tr>
                                        <th>Class Code</th>
                                        <th>Category Code</th>
                                        <th>Product</th>
                                        <th>Description</th>
                                        <th>Unit</th>
                                        <th>Quantity</th>
                                        <th>Contract Value (AED)</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {materialFields.map((item, index) => (
                                        <tr key={`materialFields${index}`}>
                                          <td>
                                            <EstimationClass
                                              control={control}
                                              placeholder="Select"
                                              name={`project_materials.${index}.class_id`}
                                              type={"materials"}
                                              disabled={true}
                                            />
                                          </td>
                                          <td>
                                            <EstimationCategory
                                              control={control}
                                              placeholder="Select"
                                              name={`project_materials.${index}.category_id`}
                                              classCode={watch(
                                                `project_materials.${index}.class_id`
                                              )}
                                              disabled={true}
                                            />
                                          </td>
                                          <td>
                                            <EstimationProduct
                                              control={control}
                                              placeholder="Product Name"
                                              name={`project_materials.${index}.product_id`}
                                              catCode={watch(
                                                `project_materials.${index}.category_id`
                                              )}
                                              // onSelectChange={e =>
                                              //   handleProductChange(e, index)
                                              // }
                                              selectedProduct={watch(
                                                `project_materials`
                                              )}
                                              index={index}
                                              disabled={true}
                                            />
                                          </td>
                                          <td>
                                            <UFInput
                                              control={control}
                                              placeholder="Description"
                                              name={`project_materials.${index}.description`}
                                            />
                                          </td>
                                          <td>
                                            <Unit
                                              control={control}
                                              placeholder="Unit"
                                              name={`project_materials.${index}.unit`}
                                            />
                                          </td>
                                          <td>
                                            <UFInput
                                              control={control}
                                              placeholder="Quantity"
                                              name={`project_materials.${index}.quantity`}
                                              onChange={() => {
                                                handleQuantityPrice(
                                                  `project_materials.${index}.quantity`,
                                                  `project_materials.${index}.single_price`,
                                                  `project_materials.${index}.contract_value`
                                                )
                                              }}
                                              // onChange={() =>
                                              //   handleQuantityChange(
                                              //     `project_materials.${index}.quantity`,
                                              //     `project_materials.${index}.price`
                                              //   )
                                              // }
                                            />
                                            <FormFeedback invalid>
                                              {
                                                errors?.project_materials?.[
                                                  index
                                                ]?.quantity?.message
                                              }
                                            </FormFeedback>
                                          </td>
                                          <td>
                                            <UFInput
                                              control={control}
                                              placeholder="Price"
                                              name={`project_materials.${index}.contract_value`}
                                              onChange={() =>
                                                handleContractChange(
                                                  `project_materials.${index}.contract_value`,
                                                  `project_materials.${index}.price`
                                                )
                                              }
                                            />
                                            <FormFeedback invalid>
                                              {
                                                errors?.project_materials?.[
                                                  index
                                                ]?.contract_value?.message
                                              }
                                            </FormFeedback>
                                          </td>
                                          {/* <td>
                                      <UFInput
                                        control={control}
                                        placeholder="Price"
                                        name={`project_materials.${index}.price`}
                                        onChange={() =>
                                          handlePriceChange(
                                            `project_materials.${index}.price`,
                                            `project_materials.${index}.contract_value`
                                          )
                                        }
                                      />
                                    </td> */}
                                          {/* <td>
                                        <div
                                          className="pe-2"
                                          onClick={e =>
                                            handleMaterialToManCheck(e, index)
                                          }
                                        >
                                          <input
                                            type="checkbox"
                                            className="ever-rise-checkbox"
                                            name={`project_materials.${index}.is_manpower_material`}
                                            // checked={materialToManCheck[index]}
                                            disabled={
                                              watch(
                                                `project_materials.${index}.product_id`
                                              )
                                                ? false
                                                : true
                                            }
                                          />
                                        </div>
                                      </td> */}
                                          {/* <td>
                                        <img
                                          src={lightTrash}
                                          alt="light-trash"
                                          className="trash-img pointer"
                                          onClick={() => materialRemove(index)}
                                        />
                                      </td> */}
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                  {/* <Row>
                                    <Col
                                      lg="12"
                                      xs={12}
                                      md={2}
                                      sm={12}
                                      className="d-flex justify-content-end"
                                    >
                                      <Row className="w-50 d-flex align-items-center">
                                        <Col
                                          lg={6}
                                          className="text-end dark-text"
                                        >
                                          Contract Value(AED)
                                        </Col>
                                        <Col
                                          lg={6}
                                          className="ps-5 dark-text"
                                        ></Col>
                                      </Row>
                                    </Col>
                                  </Row> */}
                                </>
                              ) : (
                                // <table className="estimation-table mb-4">
                                //   <thead>
                                //     <tr>
                                //       <th>Class Code</th>
                                //       <th>Price</th>
                                //     </tr>
                                //   </thead>
                                //   <tbody>
                                <>
                                  {classMaterialSumArray?.map((item, index) => (
                                    <Accordion
                                      open={open}
                                      toggle={() => toggleAcc(item, index)}
                                    >
                                      <AccordionItem>
                                        <AccordionHeader targetId={index}>
                                          <Row>
                                            <Col>
                                              {item?.class_id?.label}
                                              {/* <EstimationClass
                                                    control={control}
                                                    placeholder="Select"
                                                    name={`classbudget_material.${index}.class_id`}
                                                    type={"materials"}
                                                    disabled={true}
                                                  /> */}
                                              {/* <UFInput
                                            control={control}
                                            placeholder="Description"
                                            name={`classbudget_material.${index}.class_id`}
                                          /> */}
                                            </Col>
                                            <Col>
                                              {item?.total_contract_value}
                                              {/* <UFInput
                                                    control={control}
                                                    placeholder="Description"
                                                    name={`classbudget_material.${index}.value`}
                                                  /> */}
                                              {/* {item?.total_contract_value} */}
                                            </Col>
                                          </Row>
                                        </AccordionHeader>
                                        <AccordionBody accordionId={index}>
                                          <table className="estimation-table mb-4">
                                            <thead>
                                              <tr>
                                                <th>Class Code</th>
                                                <th>Category Code</th>
                                                <th>Product</th>
                                                <th>Description</th>
                                                <th>Unit</th>
                                                <th>Quantity</th>
                                                <th>Contract Value (AED)</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {materialFields?.map(
                                                (item, index) => (
                                                  <>
                                                    {item.class_id.value ==
                                                      checking && (
                                                      <tr
                                                        key={`materialFields${index}`}
                                                      >
                                                        {console.log(
                                                          `laa`,
                                                          item
                                                        )}
                                                        <td>
                                                          <EstimationClass
                                                            control={control}
                                                            placeholder="Select"
                                                            name={`project_materials.${index}.class_id`}
                                                            type={"materials"}
                                                            disabled={true}
                                                          />
                                                        </td>
                                                        <td>
                                                          <EstimationCategory
                                                            control={control}
                                                            placeholder="Select"
                                                            name={`project_materials.${index}.category_id`}
                                                            classCode={watch(
                                                              `project_materials.${index}.class_id`
                                                            )}
                                                            disabled={true}
                                                          />
                                                        </td>
                                                        <td>
                                                          <EstimationProduct
                                                            control={control}
                                                            placeholder="Product Name"
                                                            name={`project_materials.${index}.product_id`}
                                                            catCode={watch(
                                                              `project_materials.${index}.category_id`
                                                            )}
                                                            selectedProduct={watch(
                                                              `project_materials`
                                                            )}
                                                            index={index}
                                                            disabled={true}
                                                          />
                                                        </td>
                                                        <td>
                                                          <UFInput
                                                            control={control}
                                                            placeholder="Description"
                                                            name={`project_materials.${index}.description`}
                                                          />
                                                        </td>
                                                        <td>
                                                          <Unit
                                                            control={control}
                                                            placeholder="Unit"
                                                            name={`project_materials.${index}.unit`}
                                                          />
                                                        </td>
                                                        <td>
                                                          <UFInput
                                                            control={control}
                                                            placeholder="Quantity"
                                                            name={`project_materials.${index}.quantity`}
                                                            onChange={() => {
                                                              handleQuantityPrice(
                                                                `project_materials.${index}.quantity`,
                                                                `project_materials.${index}.single_price`,
                                                                `project_materials.${index}.contract_value`
                                                              )
                                                            }}
                                                          />
                                                          <FormFeedback invalid>
                                                            {
                                                              errors
                                                                ?.project_materials?.[
                                                                index
                                                              ]?.quantity
                                                                ?.message
                                                            }
                                                          </FormFeedback>
                                                        </td>
                                                        <td>
                                                          <UFInput
                                                            control={control}
                                                            placeholder="Price"
                                                            name={`project_materials.${index}.contract_value`}
                                                            onChange={() =>
                                                              handleContractChange(
                                                                `project_materials.${index}.contract_value`,
                                                                `project_materials.${index}.price`
                                                              )
                                                            }
                                                          />
                                                          <FormFeedback invalid>
                                                            {
                                                              errors
                                                                ?.project_materials?.[
                                                                index
                                                              ]?.contract_value
                                                                ?.message
                                                            }
                                                          </FormFeedback>
                                                        </td>
                                                      </tr>
                                                    )}
                                                  </>
                                                )
                                              )}
                                            </tbody>
                                          </table>
                                          {/* {handleAccordVal(item?.class_id?.value)} */}
                                        </AccordionBody>
                                      </AccordionItem>
                                    </Accordion>
                                    // <tr
                                    //   key={`classMaterialSumArray${index}`}
                                    // >
                                    //   <td>
                                    //     <EstimationClass
                                    //       control={control}
                                    //       placeholder="Select"
                                    //       name={`classbudget_material.${index}.class_id`}
                                    //       type={"materials"}
                                    //       disabled={true}
                                    //     />
                                    //     {/* <UFInput
                                    //     control={control}
                                    //     placeholder="Description"
                                    //     name={`classbudget_material.${index}.class_id`}
                                    //   /> */}
                                    //   </td>
                                    //   <td>
                                    //     <UFInput
                                    //       control={control}
                                    //       placeholder="Description"
                                    //       name={`classbudget_material.${index}.value`}
                                    //     />
                                    //     {/* {item?.total_contract_value} */}
                                    //   </td>
                                    // </tr>
                                  ))}
                                </>
                                //   </tbody>
                                // </table>
                              )}
                              {/* <Col
                                lg="12"
                                xs={12}
                                md={2}
                                sm={12}
                                className="d-flex justify-content-between"
                              >
                                <Button
                                  color="secondary"
                                  className="addButton mt-2"
                                  onClick={() =>
                                    materialAppend({
                                      class_id: "",
                                      category_id: "",
                                      quantity: "",
                                      product_id: "",
                                      price: "",
                                    })
                                  }
                                >
                                  Add Row
                                </Button>
                              </Col> */}
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId="2">
                          <Row className="mb-4">
                            <Col lg={12}>
                              {classCheck === false ? (
                                <table className="estimation-table mb-4">
                                  <thead>
                                    <tr>
                                      <th>Class </th>
                                      <th>Category </th>
                                      <th>Description</th>
                                      <th>No.of Labours</th>
                                      <th>Contract Value (AED)</th>
                                      {/* <th>Estimate Value (AED)</th> */}
                                      {/* <th></th> */}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {manpowerFields.map((item, index) => (
                                      <tr key={`project_manpowers${index}`}>
                                        <td>
                                          <EstimationClass
                                            control={control}
                                            placeholder="Select"
                                            name={`project_manpowers.${index}.class_id`}
                                            type={"manpowers"}
                                          />
                                        </td>
                                        <td>
                                          <EstimationCategory
                                            control={control}
                                            placeholder="Select"
                                            name={`project_manpowers.${index}.category_id`}
                                            classCode={watch(
                                              `project_manpowers.${index}.class_id`
                                            )}
                                            onSelectChange={e =>
                                              handleCategoryChange(e, index)
                                            }
                                          />
                                        </td>

                                        <td>
                                          <UFInput
                                            control={control}
                                            placeholder="Description"
                                            name={`project_manpowers.${index}.description`}
                                          />
                                        </td>
                                        <td>
                                          <UFInput
                                            control={control}
                                            placeholder="No.of Labours"
                                            name={`project_manpowers.${index}.no_of_labours`}
                                          />
                                        </td>
                                        <td>
                                          <UFInput
                                            control={control}
                                            placeholder="Price"
                                            name={`project_manpowers.${index}.contract_value`}
                                            onChange={() =>
                                              handleContractChange(
                                                `project_manpowers.${index}.contract_value`,
                                                `project_manpowers.${index}.price`
                                              )
                                            }
                                          />
                                        </td>
                                        {/* <td>
                                      <UFInput
                                        control={control}
                                        placeholder="Price"
                                        name={`project_manpowers.${index}.price`}
                                        onChange={() =>
                                          handlePriceChange(
                                            `project_manpowers.${index}.price`,
                                            `project_manpowers.${index}.contract_value`
                                          )
                                        }
                                      />
                                    </td> */}
                                        {/* <td>
                                        <img
                                          src={lightTrash}
                                          alt="light-trash"
                                          className="trash-img pointer"
                                          onClick={() => manpowerRemove(index)}
                                        />
                                      </td> */}
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              ) : (
                                <>
                                  {classManSumArray?.map((item, index) => (
                                    <Accordion
                                      open={open}
                                      toggle={() => toggleAcc(item, index)}
                                    >
                                      <AccordionItem>
                                        <AccordionHeader targetId={index}>
                                          <Row>
                                            <Col>
                                              {item?.class_id?.label}
                                              {console.log(`class---`, item)}
                                              {/* <EstimationClass
                                                control={control}
                                                placeholder="Select"
                                                name={`classbudget_manpower.${index}.class_id`}
                                                type={"materials"}
                                                disabled={true}
                                              /> */}
                                              {/* <UFInput
                                            control={control}
                                            placeholder="Description"
                                            name={`classbudget_manpower.${index}.class_id`}
                                          /> */}
                                            </Col>
                                            <Col>
                                              {/* <UFInput
                                                control={control}
                                                placeholder="Description"
                                                name={`classbudget_manpower.${index}.value`}
                                              /> */}
                                              {item?.total_contract_value}
                                            </Col>
                                          </Row>
                                        </AccordionHeader>
                                        <AccordionBody accordionId={index}>
                                          <table className="estimation-table mb-4">
                                            <thead>
                                              <tr>
                                                <th>Class </th>
                                                <th>Category </th>
                                                <th>Description</th>
                                                <th>No.of Labours</th>
                                                <th>Contract Value (AED)</th>
                                                {/* <th>Estimate Value (AED)</th> */}
                                                {/* <th></th> */}
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {manpowerFields?.map(
                                                (item, index) => (
                                                  <>
                                                    {item.class_id.value ==
                                                      checking && (
                                                      <tr
                                                        key={`project_manpowers${index}`}
                                                      >
                                                        <td>
                                                          <EstimationClass
                                                            control={control}
                                                            placeholder="Select"
                                                            name={`project_manpowers.${index}.class_id`}
                                                            type={"manpowers"}
                                                          />
                                                        </td>
                                                        <td>
                                                          <EstimationCategory
                                                            control={control}
                                                            placeholder="Select"
                                                            name={`project_manpowers.${index}.category_id`}
                                                            classCode={watch(
                                                              `project_manpowers.${index}.class_id`
                                                            )}
                                                            onSelectChange={e =>
                                                              handleCategoryChange(
                                                                e,
                                                                index
                                                              )
                                                            }
                                                          />
                                                        </td>

                                                        <td>
                                                          <UFInput
                                                            control={control}
                                                            placeholder="Description"
                                                            name={`project_manpowers.${index}.description`}
                                                          />
                                                        </td>
                                                        <td>
                                                          <UFInput
                                                            control={control}
                                                            placeholder="No.of Labours"
                                                            name={`project_manpowers.${index}.no_of_labours`}
                                                          />
                                                        </td>
                                                        <td>
                                                          <UFInput
                                                            control={control}
                                                            placeholder="Price"
                                                            name={`project_manpowers.${index}.contract_value`}
                                                            onChange={() =>
                                                              handleContractChange(
                                                                `project_manpowers.${index}.contract_value`,
                                                                `project_manpowers.${index}.price`
                                                              )
                                                            }
                                                          />
                                                        </td>
                                                        {/* <td>
                                                  <UFInput
                                                    control={control}
                                                    placeholder="Price"
                                                    name={`project_manpowers.${index}.price`}
                                                    onChange={() =>
                                                      handlePriceChange(
                                                        `project_manpowers.${index}.price`,
                                                        `project_manpowers.${index}.contract_value`
                                                      )
                                                    }
                                                  />
                                                </td> */}
                                                        {/* <td>
                                                    <img
                                                      src={lightTrash}
                                                      alt="light-trash"
                                                      className="trash-img pointer"
                                                      onClick={() => manpowerRemove(index)}
                                                    />
                                                  </td> */}
                                                      </tr>
                                                    )}
                                                  </>
                                                )
                                              )}
                                            </tbody>
                                          </table>
                                          {/* {handleAccordVal(item?.class_id?.value)} */}
                                        </AccordionBody>
                                      </AccordionItem>
                                    </Accordion>
                                    // <tr
                                    //   key={`classMaterialSumArray${index}`}
                                    // >
                                    //   <td>
                                    //     <EstimationClass
                                    //       control={control}
                                    //       placeholder="Select"
                                    //       name={`classbudget_material.${index}.class_id`}
                                    //       type={"materials"}
                                    //       disabled={true}
                                    //     />
                                    //     {/* <UFInput
                                    //     control={control}
                                    //     placeholder="Description"
                                    //     name={`classbudget_material.${index}.class_id`}
                                    //   /> */}
                                    //   </td>
                                    //   <td>
                                    //     <UFInput
                                    //       control={control}
                                    //       placeholder="Description"
                                    //       name={`classbudget_material.${index}.value`}
                                    //     />
                                    //     {/* {item?.total_contract_value} */}
                                    //   </td>
                                    // </tr>
                                  ))}
                                </>
                              )}
                              {/* <Col
                                lg="12"
                                xs={12}
                                md={2}
                                sm={12}
                                className="d-flex justify-content-between"
                              >
                                <Button
                                  color="secondary"
                                  className="addButton mt-2"
                                  onClick={() =>
                                    manpowerAppend({
                                      class_id: "",
                                      category_id: "",
                                      no_of_labours: "",
                                      price: "",
                                    })
                                  }
                                >
                                  Add Row
                                </Button>
                              </Col> */}
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId="3">
                          <Row className="mb-4">
                            <Col lg={12}>
                              <table className="estimation-table mb-4">
                                <thead>
                                  <tr>
                                    <th>Class </th>
                                    <th>Category </th>
                                    <th>Description</th>
                                    <th>Unit</th>
                                    <th>Duration</th>
                                    <th>Contract Value (AED)</th>
                                    {/* <th>Estimate Value (AED)</th> */}
                                    {/* <th></th> */}
                                  </tr>
                                </thead>
                                <tbody>
                                  {contractFields.map((item, index) => (
                                    <tr key={`project_subcontracts${index}`}>
                                      <td>
                                        <EstimationClass
                                          control={control}
                                          placeholder="Select"
                                          name={`project_subcontracts.${index}.class_id`}
                                          type={"subcontracts"}
                                        />
                                      </td>
                                      <td>
                                        <EstimationSubCategory
                                          control={control}
                                          placeholder="Select"
                                          name={`project_subcontracts.${index}.category_id`}
                                          classCode={watch(
                                            `project_subcontracts.${index}.class_id`
                                          )}
                                          onSelectChange={e =>
                                            handleContractCatChange(e, index)
                                          }
                                        />
                                      </td>
                                      <td>
                                        <UFInput
                                          control={control}
                                          placeholder="Description"
                                          name={`project_subcontracts.${index}.description`}
                                        />
                                      </td>
                                      <td>
                                        <Unit
                                          control={control}
                                          placeholder="Unit"
                                          name={`project_subcontracts.${index}.unit`}
                                        />
                                      </td>
                                      <td>
                                        <UFInput
                                          control={control}
                                          placeholder="Duration"
                                          name={`project_subcontracts.${index}.duration`}
                                        />
                                      </td>
                                      <td>
                                        <UFInput
                                          control={control}
                                          placeholder="Price"
                                          name={`project_subcontracts.${index}.contract_value`}
                                          onChange={() =>
                                            handleContractChange(
                                              `project_subcontracts.${index}.contract_value`,
                                              `project_subcontracts.${index}.price`
                                            )
                                          }
                                        />
                                      </td>
                                      {/* <td>
                                      <UFInput
                                        control={control}
                                        placeholder="Price"
                                        name={`project_subcontracts.${index}.price`}
                                        onChange={() =>
                                          handlePriceChange(
                                            `project_subcontracts.${index}.price`,
                                            `project_subcontracts.${index}.contract_value`
                                          )
                                        }
                                      />
                                    </td> */}
                                      {/* <td>
                                        <img
                                          src={lightTrash}
                                          alt="light-trash"
                                          className="trash-img pointer"
                                          onClick={() => contractRemove(index)}
                                        />
                                      </td> */}
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                              {/* <Col
                                lg="12"
                                xs={12}
                                md={2}
                                sm={12}
                                className="d-flex justify-content-between"
                              >
                                <Button
                                  color="secondary"
                                  className="addButton mt-2"
                                  onClick={() =>
                                    contractAppend({
                                      class_id: "",
                                      category_id: "",
                                      description: "",
                                      duration: "",
                                      price: "",
                                    })
                                  }
                                >
                                  Add Row
                                </Button>
                              </Col> */}
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId="4">
                          <Row className="mb-4">
                            <Col lg={12}>
                              <table className="estimation-table mb-4">
                                <thead>
                                  <tr>
                                    <th>Overheads Percentage </th>
                                    <th>Value (AED) </th>
                                    {/* <th>Description</th>
                                    <th>Contract Value (AED)</th> */}
                                    {/* <th>Estimate Value (AED)</th> */}
                                    {/* <th></th> */}
                                  </tr>
                                </thead>
                                <tbody>
                                  {/* {overheadsFields.map((item, index) => ( */}
                                  <tr>
                                    <td>
                                      {fetchedData?.overheads_per ? fetchedData?.overheads_per : 0} %
                                      {/* <EstimationClass
                                          control={control}
                                          placeholder="Select"
                                          name={`project_overheads.${index}.class_id`}
                                          type={"overheads"}
                                        /> */}
                                    </td>
                                    <td>
                                      {watch("overheads_value")}
                                      {/* <EstimationCategory
                                          control={control}
                                          placeholder="Select"
                                          name={`project_overheads.${index}.category_id`}
                                          classCode={watch(
                                            `project_overheads.${index}.class_id`
                                          )}
                                          onSelectChange={e =>
                                            handleOverheadsCatChange(e, index)
                                          }
                                        /> */}
                                    </td>
                                    {/* <td>
                                        <UFInput
                                          control={control}
                                          placeholder="Description"
                                          name={`project_overheads.${index}.description`}
                                        />
                                      </td> */}
                                    {/* <td>
                                      <UFInput
                                        control={control}
                                        placeholder="Description"
                                        name={`project_overheads.${index}.description`}
                                      />
                                    </td> */}
                                    {/* <td>
                                        <UFInput
                                          control={control}
                                          placeholder="Price"
                                          name={`project_overheads.${index}.contract_value`}
                                          onChange={() =>
                                            handleContractChange(
                                              `project_overheads.${index}.contract_value`,
                                              `project_overheads.${index}.price`
                                            )
                                          }
                                        />
                                      </td> */}
                                    {/* <td>
                                      <UFInput
                                        control={control}
                                        placeholder="Price"
                                        name={`project_overheads.${index}.price`}
                                        onChange={() =>
                                          handlePriceChange(
                                            `project_overheads.${index}.price`,
                                            `project_overheads.${index}.contract_value`
                                          )
                                        }
                                      />
                                    </td> */}
                                    {/* <td>
                                        <img
                                          src={lightTrash}
                                          alt="light-trash"
                                          className="trash-img pointer"
                                          onClick={() => overheadsRemove(index)}
                                        />
                                      </td> */}
                                  </tr>
                                  {/* ))} */}
                                </tbody>
                              </table>
                              {/* <Col
                                lg="12"
                                xs={12}
                                md={2}
                                sm={12}
                                className="d-flex justify-content-between"
                              >
                                <Button
                                  color="secondary"
                                  className="addButton mt-2"
                                  onClick={() =>
                                    overheadsAppend({
                                      class_id: "",
                                      category_id: "",
                                      description: "",

                                      price: "",
                                    })
                                  }
                                >
                                  Add Row
                                </Button>
                              </Col> */}
                            </Col>
                          </Row>
                        </TabPane>
                      </TabContent>
                    </div>
                  )}
                </Card>
              </Container>
            </Form>
          </FormProvider>
        </div>
      </div>
    </React.Fragment>
  )
}
export default EditProjectMaster
