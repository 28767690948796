import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
  Card,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  Row,
} from "reactstrap"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { toastError, toastSuccess } from "../../../helpers/msg_helper"
import { UFEditor, UFInput } from "../../../components/Common/Fields/Input"
import LoadingButton from "../../../components/Common/LoadingButton"

import { fetchPrefix, postPrefix } from "../../../services/Settings/settings"
import { useSelector } from "react-redux"

const schema = yup.object().shape({})

const Prefix = () => {
  const navigate = useNavigate()

  const loggedUser = useSelector(state => state.Login.loggedUser)

  const [isLoading, setIsLoading] = useState(false)
  const [refresh, setRefresh] = useState(0)
  const [fetchData, setFetchData] = useState(0)
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      percentage: 0,
      po_remarks: "",
      po_pay_term: "",
      grn_text: "",
    },
  })
  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: true,
  })

  const handleRegistration = async data => {
    for (const key in data) {
      const found = fetchData.find(item => item.model_name === key)
      if (found) {
        data[found.id] = data[key]
        delete data[key]
      } else {
        delete data[key]
      }
    }
    try {
      setIsLoading(true)
      const updateDepartment = await postPrefix({
        ...data,
      })
      setRefresh(refresh + 1)
      toastSuccess(updateDepartment?.message)
    } catch (err) {
      toastError(err?.message)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    const handleFetchPrefix = async () => {
      const response = await fetchPrefix()
      setFetchData(response?.prefixes)
      response?.prefixes?.map(dt => setValue(dt?.model_name, dt?.prefix))
    }

    handleFetchPrefix()
  }, [refresh])

  const fy = loggedUser?.finance?.match(/\d+/)[0]

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <FormProvider {...methods}>
            <Form
              id={`general-setting-form`}
              onSubmit={handleSubmit(handleRegistration)}
            >
              <div className="sticky-top bg-white w-100">
                <div className="d-flex justify-content-between me-2">
                  <Breadcrumbs
                    title="Setting"
                    breadcrumbObject={[
                      { title: "Setting", link: "/settings" },
                      { title: "Prefix Setting", link: "/settings-prefix" },
                    ]}
                  />
                  <LoadingButton
                    form={`general-setting-form`}
                    color={"primary"}
                    loading={isLoading}
                    disabled={isLoading}
                    className={"px-2 mb-3 btn btn-primary"}
                    type="submit"
                  >
                    Update
                  </LoadingButton>
                </div>
              </div>
              <Container fluid className="ps-lg-0">
                <Card className="invoice-main-card p-3 pt-4">
                  <Row className="">
                    <Row className="mb-4 mt-2">
                      {fetchData &&
                        fetchData?.map((dt, index) => (
                          <Col sm={3}>
                            <FormGroup className="mb-4">
                              <Label>
                                {dt?.model_name}
                                <span className="mandatory">*</span>
                              </Label>
                              <UFInput
                                control={control}
                                placeholder="Enter Prefix"
                                name={dt?.model_name}
                                invalid={!!errors.dt?.model_name}
                                nonEditableValue={fy}
                                className="prefix-border"
                              />
                              <FormFeedback invalid>
                                {errors.dt?.model_name?.message}
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                        ))}
                    </Row>
                  </Row>
                </Card>
              </Container>
            </Form>
          </FormProvider>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Prefix
