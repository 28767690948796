import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormFeedback,
  Spinner,
} from "reactstrap"
import classnames from "classnames"
import { Link } from "react-router-dom"
import LoadingButton from "../../../components/Common/LoadingButton"
import { UFEditor } from "../../../components/Common/Fields/Input"
import { useForm, FormProvider } from "react-hook-form"
import { Form, FormGroup, Label } from "reactstrap"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import closeIcon from "../../../assets/icons/close.svg"
import { toastError, toastSuccess } from "../../../helpers/msg_helper"
import {
  fetchGroup,
  postInventoryGroup,
} from "../../../services/Inventory/inventory"

const schema = yup.object().shape({
  description: yup.string().required("Description is Required"),
})
const Edit = ({
  loading,
  onUpdate,
  id,
  error,
  close,
  getEditValue,
  ...props
}) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  })
  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: true,
  })
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (getEditValue) {
      reset({
        ...getEditValue?.data,
      })
    }
  }, [getEditValue])

  const handleRegistration = async data => {
    try {
      setIsLoading(true)
      const updateDepartment = await postInventoryGroup({
        ...data,
      })
      toastSuccess(updateDepartment?.message)
      onUpdate()
      close()
    } catch (err) {
      toastError(err?.message)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <ModalHeader className="custom-modal-header">
        <div className="d-flex justify-content-between">
          <h5>{getEditValue?.data?.id ? "Edit Group" : "New Group"}</h5>
          <img
            src={closeIcon}
            alt=""
            onClick={close}
            width="10"
            role="button"
          />
        </div>
      </ModalHeader>
      {loading ? (
        <div
          className="px-8"
          style={{
            width: "100%",
            textAlign: "center",
            minHeight: "200px",
            paddingTop: "90px",
          }}
        >
          <Spinner
            className={classnames({
              "mx-2": true,
              "position-relative": true,
              visible: true,
            })}
            size="lg"
          />
        </div>
      ) : (
        <>
          <ModalBody>
            <FormProvider {...methods}>
              <Form id={`ev-form`} onSubmit={handleSubmit(handleRegistration)}>
                <Row>
                  <Col sm={12}>
                    <FormGroup className="mb-4">
                      <Label>
                        Description<span className="mandatory">*</span>
                      </Label>
                      <UFEditor
                        control={control}
                        placeholder="Enter Description"
                        rows="3"
                        name={"description"}
                        invalid={!!errors.description}
                      />
                      <FormFeedback invalid>
                        {errors.description?.message}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </FormProvider>
          </ModalBody>
          <ModalFooter>
            <LoadingButton
              form={`ev-form`}
              color={"primary"}
              loading={isLoading}
              disabled={isLoading}
              type="submit"
            >
              {getEditValue?.data?.id ? "Update" : "Save"}
            </LoadingButton>
          </ModalFooter>
        </>
      )}
    </>
  )
}

const EditGroup = ({ buttonLabel, className, editValue, ...props }) => {
  const [modal, setModal] = useState(false)
  const [getEditValue, setGetEditValue] = useState("")

  const toggle = () => setModal(!modal)
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchGroup(editValue?.id)
      if (response?.status) {
        setGetEditValue(response)
      }
    }
    if (editValue?.id) {
      toggle()
      fetchData()
    }
  }, [editValue?.id])
  return (
    <>
      <Link to="#" className={className} onClick={toggle}>
        {buttonLabel}
      </Link>
      <Modal isOpen={modal} toggle={toggle} backdrop={"static"}>
        <Edit
          close={() => {
            setModal(false)
            props?.onReset()
            setGetEditValue("")
          }}
          getEditValue={getEditValue}
          {...props}
        />
      </Modal>
    </>
  )
}

export default EditGroup
