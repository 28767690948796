import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
  Col,
  Row,
  Card,
  UncontrolledPopover,
  PopoverBody,
  FormGroup,
  Label,
  FormFeedback,
  Form,
} from "reactstrap"
import UpdateTable from "../../../components/Common/UpdatedTable"
import action from "../../../assets/icons/action.svg"
import check from "../../../assets/icons/estimation_check.svg"
import EstimationFilter from "./filter"
import { Link, useNavigate } from "react-router-dom"
import { searchEstimation } from "../../../services/estimation/estimation"
import moment from "moment"
import Department from "../../../components/Common/chooseFields/department"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { UFDate, UFMonthYear } from "../../../components/Common/Fields/Input"
import LoadingButton from "../../../components/Common/LoadingButton"
import {
  fetchHrmsEmployee,
  fileDownload,
  searchHrmsPayrollGenerate,
  updatePayrollGenerayeStatus,
} from "../../../services/Hrms/hrms"
import { toastError, toastSuccess } from "../../../helpers/msg_helper"

const schema = yup.object().shape({
  // first_name: yup.string().required("First Name is Required"),
})

const PayrollGenerateIndex = () => {
  document.title = "Payroll | Ever Rise"

  const navigate = useNavigate()
  const [classData, setClassData] = useState()
  const [status, setStatus] = useState("")
  const [search, setSearch] = useState("")
  const [customer, setCustomer] = useState("")
  const [location, setLocation] = useState("")
  const [page, setPage] = useState("")
  const [count, setCount] = useState(0)

  const handleGenerate = async id => {
    try {
      const response = await updatePayrollGenerayeStatus(id)
      toastSuccess(response?.message)
      setCount(count + 1)
    } catch (err) {
      toastError(err?.message)
    }
  }

  const columns = [
    {
      label: "Employee Code",

      renderCell: item => {
        return (
          <div
            onClick={() => navigate(`/hrms-payroll/view/${item?.id}`)}
            className="pointer fw-bold"
          >
            <span className="pe-2"> {item?.employee?.employee_code}</span>
          </div>
        )
      },
    },
    {
      label: "Employee Name",
      renderCell: item => (
        <div className="capitalize-letter">{item?.employee?.first_name}</div>
      ),
    },
    {
      label: "Email ID",
      renderCell: item => item?.employee?.official_email_id,
    },
    {
      label: "Grade",
      renderCell: item => (
        <div className="capitalize-letter">{item?.employee?.grade?.grade}</div>
      ),
    },

    {
      label: "Net Pay",
      renderCell: item => item?.net_pay,
    },
    {
      label: "Status",
      renderCell: item => (
        <div>
          <div
            className={`font-13 ${
              item?.status == 1
                ? "status-active"
                : item?.status == 2
                ? "status-active"
                : "status-inactive"
            }`}
          >
            {item?.status == 1
              ? "Generated"
              : item?.status == 2
              ? "Paid"
              : "Process"}
          </div>
        </div>
      ),
    },
    {
      label: "Action",
      renderCell: item => (
        <>
          <div>
            <span id={`PopoverLegacy-${item?.id}`}>
              <img src={action} alt="" className="pointer" />
            </span>
          </div>
          <UncontrolledPopover
            placement="bottom"
            target={`PopoverLegacy-${item?.id}`}
            trigger="legacy"
          >
            <PopoverBody>
              <>
                {item?.status != 1 && (
                  <>
                    <div
                      className={`${
                        item?.status === 1 ? "popover-border" : ""
                      } table-popup-content`}
                      onClick={() => handleGenerate(item?.id)}
                    >
                      Process Payroll
                    </div>
                    <div
                      className={`${
                        item?.status === 1 ? "popover-border" : ""
                      } table-popup-content`}
                      onClick={() => navigate(`/hrms-payroll/edit/${item?.id}`)}
                    >
                      Edit
                    </div>
                  </>
                )}
                <div
                  className={`${
                    item?.status === 1 ? "popover-border" : ""
                  } table-popup-content`}
                  onClick={() =>
                    handleDocumentDownload({
                      url: `payroll-generate/pdf-statement/${item?.id}`,
                      downloadName: `payroll`,
                    })
                  }
                >
                  Download
                </div>
              </>
            </PopoverBody>
          </UncontrolledPopover>
        </>
      ),
    },
  ]

  const handleDocumentDownload = ({ path, url, downloadName }) => {
    const handleDownload = async () => {
      const response = await fileDownload({
        url: url,
        path,
        downloadName: downloadName,
        extension: "pdf",
      })
    }
    handleDownload()
  }

  const handlePaginate = page => {
    setPage(page)
  }

  useEffect(() => {
    const controlData = getValues()
    const fetchClass = async () => {
      const response = await searchHrmsPayrollGenerate({
        params: `${status == "0" || status == "1" ? `&status=${status}` : ""}${
          search ? `&searchText=${search}` : "&searchText"
        }${
          controlData?.year_month
            ? `&year_month=${moment(controlData?.year_month).format(
                "YYYY-MM-DD"
              )}`
            : ""
        }${
          controlData?.designation_id?.value
            ? `&designation_id=${controlData?.designation_id?.value}`
            : ""
        }${page ? `&page=${page}` : ""}`,
      })
      setClassData(response)
    }
    fetchClass()
  }, [status, search, customer, page, location, count])

  const handleStatusChange = id => {
    setStatus(id)
    setPage(1)
  }
  const handleCustomerChange = id => {
    setCustomer(id)
    setPage(1)
  }
  const handleLocationChange = id => {
    setLocation(id)
    setPage(1)
  }
  const handleSearchChange = data => {
    setSearch(data)
    setPage(1)
  }

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      year_month: new Date(),
    },
  })
  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: true,
  })

  const handleRegistration = async data => {
    console.log(" data :", data)
    setCount(count + 1)
    setPage(1)
  }
  const handleReset = () => {
    reset({ year_month: new Date(), designation_id: null })
    setCount(count + 1)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="d-flex justify-content-between">
            <Breadcrumbs
              title="HRMS"
              breadcrumbObject={[
                { title: "HRMS", link: "/hrms" },
                { title: "Payroll", link: "/hrms-payroll-generate" },
              ]}
            />
          </div>
          <Card className="me-2 px-2 pb-2 input-card h-auto">
            <FormProvider {...methods}>
              <Form
                id={`employee-form`}
                onSubmit={handleSubmit(handleRegistration)}
              >
                <Row>
                  <Col sm={3}>
                    <FormGroup className="mb-4">
                      <Label>
                        Year and Month
                        <span className="mandatory">*</span>
                      </Label>
                      <UFMonthYear
                        control={control}
                        name={"year_month"}
                        invalid={!!errors.year_month}
                        month={true}
                        placeholder={"Select Date"}
                      />
                      <FormFeedback invalid>
                        {errors.year_month?.message}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  {/* <Col sm={3}>
                    <FormGroup className="mb-4">
                      <Label>
                        Department
                        <span className="mandatory">*</span>
                      </Label>
                      <Department
                        control={control}
                        placeholder="Select the Department"
                        name={"department_id"}
                        invalid={!!errors.department_id}
                        target={"body"}
                        param="department"
                      />
                      <FormFeedback invalid>
                        {errors.department_id?.message}
                      </FormFeedback>
                    </FormGroup>
                  </Col> */}
                  <Col sm={3}>
                    <FormGroup className="mb-4">
                      <Label>
                        Designation
                        <span className="mandatory">*</span>
                      </Label>
                      <Department
                        control={control}
                        placeholder="Select the Designation"
                        name={"designation_id"}
                        invalid={!!errors.designation_id}
                        target={"body"}
                        param="designation"
                      />
                      <FormFeedback invalid>
                        {errors.designation_id?.message}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm={"auto"} className="d-flex align-items-center">
                    <div>
                      <LoadingButton
                        form={`employee-form`}
                        color={"primary"}
                        // loading={isLoading}
                        // disabled={isLoading}
                        className={"px-4 py-1 btn btn-primary"}
                        type="submit"
                      >
                        Search
                      </LoadingButton>
                    </div>
                  </Col>
                  <Col sm={"auto"} className="d-flex align-items-center">
                    <div>
                      <LoadingButton
                        color={"secondary"}
                        className={"px-4 py-1 btn btn-secondary"}
                        onClick={handleReset}
                      >
                        Reset
                      </LoadingButton>
                    </div>
                  </Col>
                </Row>
              </Form>
            </FormProvider>
          </Card>
          <Card className="me-2 px-2 pb-2">
            {/* <EstimationFilter
              handleStatusChange={handleStatusChange}
              handleSearchChange={handleSearchChange}
              handleCustomerChange={handleCustomerChange}
              handleLocationChange={handleLocationChange}
              sampleUrl={"download/suppliers.xlsx"}
              importUrl={"suppliers/import"}
              isImport={false}
            />
            <hr className="table-divide" /> */}
            <Row className="mt-2 ever-rise-table">
              <Col xs={12}>
                {classData && (
                  <UpdateTable
                    className="mt-5"
                    nodes={classData?.data?.data}
                    paginate={classData?.data}
                    columns={columns}
                    CurrentPage={page}
                    handlePagination={handlePaginate}
                    tableWidth="15% 20% 15% 15% 15% 10% 10%"
                    // checkbox={true}
                  />
                )}
              </Col>
            </Row>
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}

export default PayrollGenerateIndex
