import { DRIVER_SET, DRIVER_SET_SUCCESS } from "./actionType"
// import {
//   DRIVER_SET_SUCCESS,
//   DRIVER_SET,
//   LOGIN_USER,
//   LOGIN_SUCCESS,
//   LOGOUT_USER,
//   LOGOUT_USER_SUCCESS,
//   API_ERROR,
//   SOCIAL_LOGIN,
//   GET_USER,
//   GET_USER_SUCCESS,
// } from "./actionTypes"

export const driverSet = (elementName, history) => {
  console.log(`user`, elementName, history)
  return {
    type: DRIVER_SET,
    payload: { elementName, history },
  }
}

// export const loggedUser = () => {
//   return {
//     type: GET_USER,
//   }
// }
export const driverSuccess = userData => {
  return {
    type: DRIVER_SET_SUCCESS,
    payload: userData,
  }
}

// export const loginSuccess = user => {
//   return {
//     type: LOGIN_SUCCESS,
//     payload: user?.role,
//   }
// }
