import { get, post } from "../../helpers/api_helper"

// fetch Category
export const fetchFinanceCategory = data => {
  return get(`finance-cat/search?${data?.params}`)
}

// post category
export const postFinanceCategory = data => {
  return !data?.id
    ? post(`finance-cat/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`finance-cat/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}

// update status
export const updateCatStatus = id => {
  return get(`finance-cat/updatestatus/${id}`)
}

// fetch Sub Category
export const fetchFinanceSubCategory = data => {
  return get(`finance-sub-cat/search?${data?.params}`)
}

// post sub category
export const postFinanceSubCategory = data => {
  return !data?.id
    ? post(`finance-sub-cat/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`finance-sub-cat/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}

// fetch Sub Category
export const fetchFinanceCostCenter = data => {
  return get(`cost-center/search?${data?.params}`)
}
// fetch finance dash-board-values
export const fetchFinanceDashboardVal = data => {
  return get(`finance-fetch/${data}`)
}
// fetch finance dash-board-sub
export const fetchFinanceDashboardSub = data => {
  return get(`finance/search?${data?.params}`)
}
// fetch finance dash-board-alert
export const fetchFinanceDashboardAlert = data => {
  return get(`finance-alert/search?${data?.params}`)
}
// fetch finance dashboard employee attendance
export const fetchFinanceDashboardEmp = data => {
  return get(`employee-attendence/attendence-search?${data?.params}`)
}
// fetch finance dashboard employee attendance
export const fetchFinanceDashboardEmpDetail = data => {
  return get(`employee-details/fetchattendence?${data?.params}`)
}

// fetch Accounts
export const fetchFinanceAccounts = data => {
  return get(`accounts/search?${data?.params}`)
}

// post accounts
export const postFinanceAccounts = data => {
  return !data?.id
    ? post(`accounts/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`accounts/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}
