import React, { useEffect, useState } from "react"
import {
  Card,
  Col,
  Nav,
  NavItem,
  NavLink,
  PopoverBody,
  Row,
  TabContent,
  TabPane,
  UncontrolledPopover,
} from "reactstrap"
import UpdateTable from "../../../components/Common/UpdatedTable"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { fetchStoreHistory } from "../../../services/centralStore/centralStore"
import TransferFilter from "./filter"
import { capitalize } from "lodash"

const StockTransferHistory = () => {
  document.title = "Product List | Everise"

  const [activeTab, setActiveTab] = useState("1")
  const [search, setSearch] = useState("")
  const [fetchedData, setFetchedData] = useState()
  const [page, setPage] = useState("")
  const [projectNo, setProjectNo] = useState("")
  const [type, setType] = useState("")
  const [productNo, setProductNo] = useState("")

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  const handlePaginate = page => {
    setPage(page)
  }

  const handleProjectChange = id => {
    setProjectNo(id)
    setPage(1)
  }

  const handleTypeChange = id => {
    setType(id)
    setPage(1)
  }

  const handleSearchChange = data => {
    setSearch(data)
    setPage(1)
  }
  const handleProductChange = data => {
    setProductNo(data)
    setPage(1)
  }

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchStoreHistory({
        params: `${
          activeTab && `&status=${activeTab == 1 ? 0 : activeTab == 2 ? 1 : 2}`
        }${search ? `&searchText=${search}` : "&searchText"}${
          projectNo ? `&project_id=${projectNo}` : ""
        }${type ? `&type=${type}` : ""}${
          productNo ? `&product_id=${productNo}` : ""
        }`,
      })
      setFetchedData(response)
    }
    fetchData(activeTab)
  }, [activeTab, type, projectNo, search, productNo])

  const columns = [
    // {
    //   label: "Requested From",
    //   renderCell: item => item?.request_id?.name,
    // },
    {
      label: "Project Number",
      renderCell: item =>
        item?.project_id != null
          ? item?.project_id?.project_code
          : "Company Products",
    },
    {
      label: "Product Number",
      renderCell: item => item?.product_id?.product_code,
    },
    {
      label: "Quantity",
      renderCell: item =>
        `${item?.activity === "add" ? "+" : "-"} ${item?.quantity}`,
    },
    {
      label: "Type",
      renderCell: item => (item?.type ? capitalize(item?.type) : "-"),
    },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="d-flex justify-content-between">
            <Breadcrumbs
              title="Central Store"
              breadcrumbObject={[
                { title: "Central Store", link: "/central-store" },
                {
                  title: "Stock Transfer History",
                  link: "/central-store/stock-transfer-history",
                },
              ]}
            />
          </div>

          <Card className="me-2 px-2 pb-2">
            <TransferFilter
              handleProjectChange={handleProjectChange}
              handleTypeChange={handleTypeChange}
              handleSearchChange={handleSearchChange}
              handleProductChange={handleProductChange}
            />
            <hr className="table-divide" />
            <div>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={activeTab === "1" ? "active pointer" : "pointer"}
                    onClick={() => {
                      toggle("1")
                    }}
                  >
                    New
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activeTab === "2" ? "active pointer" : "pointer"}
                    onClick={() => {
                      toggle("2")
                    }}
                  >
                    Issued
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activeTab === "3" ? "active pointer" : "pointer"}
                    onClick={() => {
                      toggle("3")
                    }}
                  >
                    Rejected
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab} className="pt-3">
                <TabPane tabId={activeTab}>
                  <Row className="ever-rise-table">
                    <Col xs={12}>
                      {fetchedData?.data?.data && (
                        <UpdateTable
                          //   className="mt-5"
                          nodes={fetchedData?.data?.data}
                          paginate={fetchedData?.data}
                          columns={columns}
                          CurrentPage={page}
                          handlePagination={handlePaginate}
                          tableWidth="25% 25% 25% 25%"
                        />
                      )}
                    </Col>
                  </Row>
                </TabPane>
                {/* <TabPane tabId="2">

                </TabPane> */}
              </TabContent>
            </div>
            <Row className="mt-2 ever-rise-table">
              <Col xs={12}></Col>
            </Row>
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}

export default StockTransferHistory
