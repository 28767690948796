import { get, post } from "../../helpers/api_helper"

export const postCustomer = data => {
  return !data?.id
    ? post(`customer/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : post(`customer/edit/${data?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}

export const searchCustomer = data => {
  return get(`customer/search?${data?.params}`)
}
export const fetchCustomer = data => {
  return get(`customer/fetch/${data}`)
}

export const updateCustomerStatus = data => {
  return get(`customer/updatestatus/${data}`)
}
