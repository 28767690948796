import { yupResolver } from "@hookform/resolvers/yup"
import React, { useState, useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormFeedback,
  Spinner,
  Form,
  FormGroup,
  Label,
} from "reactstrap"
import * as yup from "yup"
import LoadingButton from "../../components/Common/LoadingButton"
import { UFInput } from "../../components/Common/Fields/Input"
import classnames from "classnames"
import closeIcon from "../../assets/icons/close.svg"
import { Link } from "react-router-dom"
import {
  fetchModuleData,
  postModules,
} from "../../services/Inventory/inventory"
import { toastError, toastSuccess } from "../../helpers/msg_helper"
import ModulesList from "../../components/Common/chooseFields/modules"

const Edit = ({ loading, onUpdate, id, error, close, ...props }) => {
  const [isSubModuleCheck, setIsSubmoduleCheck] = useState(false)
  const [isMenuCheck, setIsMenuCheck] = useState(false)
  const [isCrudCheck, setIsCrudCheck] = useState(false)

  const schema = yup.object().shape({
    name: yup.string().required("Name is Required"),
    slug: yup.string().required("Slug is Required"),
    ...(isSubModuleCheck && {
      is_submodule: yup.mixed().required("Please Select the Parent Module"),
    }),
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  })
  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: true,
  })
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const handleFetchModules = async () => {
      const response = await fetchModuleData(props?.editItem?.value?.id)
      console.log("response :", response)
      reset({
        ...response?.data,
        is_submodule: {
          label: response?.data?.is_submodule?.name,
          value: response?.data?.is_submodule?.id,
        },
      })
      if (response?.data?.is_submodule) {
        setIsSubmoduleCheck(true)
      }
      if (response?.data?.is_menu) {
        setIsMenuCheck(true)
      }
      if (response?.data?.is_crudacess == 1) {
        setIsCrudCheck(true)
      }
    }

    if (props?.editItem?.modal) {
      console.log("comming inside useEffect ", props?.editItem?.modal)
      handleFetchModules()
    }
  }, [props?.editItem?.modal])

  const handleModuleAdd = async data => {
    try {
      setIsLoading(true)
      const createdSeries = await postModules({
        ...data,
        is_submodule: data?.is_submodule?.value ? data?.is_submodule?.value : 0,
        is_crudacess: isCrudCheck == true ? 1 : 0,
        is_menu: isMenuCheck == true ? 1 : 0,
      })
      toastSuccess(createdSeries?.message)
      onUpdate()
    } catch (err) {
      toastError(err?.message)
    } finally {
      setIsLoading(false)
      reset()
      close()
    }
  }

  const handleSubmoduleCheck = dt => {
    setIsSubmoduleCheck(!dt)
    setValue("is_submodule", null)
    setValue("tagline", "")
  }
  const handleMenuCheck = dt => {
    setIsMenuCheck(!dt)
  }
  const handleCrudCheck = dt => {
    setIsCrudCheck(!dt)
  }

  return (
    <>
      <ModalHeader className="custom-modal-header">
        <div className="d-flex justify-content-between">
          <h5>{props?.editItem?.modal ? "Edit Permissions" : "New Permissions"}</h5>{" "}
          <img
            src={closeIcon}
            alt=""
            onClick={close}
            width="10"
            role="button"
          />
        </div>
      </ModalHeader>
      {loading ? (
        <div
          className="px-8"
          style={{
            width: "100%",
            textAlign: "center",
            minHeight: "200px",
            paddingTop: "90px",
          }}
        >
          <Spinner
            className={classnames({
              "mx-2": true,
              "position-relative": true,
              visible: true,
            })}
            size="lg"
          />
        </div>
      ) : (
        <>
          <ModalBody>
            <FormProvider {...methods}>
              <Form id={`ev-form`} onSubmit={handleSubmit(handleModuleAdd)}>
                <Row>
                  <Col sm={12}>
                    <FormGroup className="mb-4">
                      <Label>
                      Permission Name
                        <span className="mandatory">*</span>
                      </Label>
                      <UFInput
                        control={control}
                        placeholder=""
                        name={"name"}
                        invalid={!!errors.name}
                      />
                      <FormFeedback invalid>
                        {errors.name?.message}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm={6}>
                    <FormGroup className="mb-4">
                      <Label>
                        Link
                        <span className="mandatory">*</span>
                      </Label>
                      <UFInput
                        control={control}
                        placeholder=""
                        name={"slug"}
                        invalid={!!errors.slug}
                      />
                      <FormFeedback invalid>
                        {errors.slug?.message}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm={6}>
                    <FormGroup className="mb-4">
                      <Label>Module Icon</Label>
                      <UFInput
                        control={control}
                        placeholder=""
                        name={"icon"}
                        invalid={!!errors.icon}
                      />
                      <FormFeedback invalid>
                        {errors.icon?.message}
                      </FormFeedback>
                    </FormGroup>
                  </Col>

                  <Row className="pe-0">
                    <Col
                      lg={4}
                      onClick={() => handleSubmoduleCheck(isSubModuleCheck)}
                    >
                      <div className="mt-2">
                        <input
                          type="checkbox"
                          className="ever-rise-checkbox"
                          name={"is_module"}
                          checked={isSubModuleCheck}
                        />
                        <Label className="ms-2">Is SubModule</Label>
                      </div>
                    </Col>
                    <Col lg={4} onClick={() => handleMenuCheck(isMenuCheck)}>
                      <div className="mt-2">
                        <input
                          type="checkbox"
                          className="ever-rise-checkbox"
                          name={"is_menu"}
                          checked={isMenuCheck}
                        />
                        <Label className="ms-2">Is Menu</Label>
                      </div>
                    </Col>
                    <Col lg={4} onClick={() => handleCrudCheck(isCrudCheck)}>
                      <div className="mt-2">
                        <input
                          type="checkbox"
                          className="ever-rise-checkbox"
                          name={"is_crudacess"}
                          checked={isCrudCheck}
                        />
                        <Label className="ms-2">Is CRUD</Label>
                      </div>
                    </Col>
                  </Row>
                  <Row className="pt-3">
                    {isSubModuleCheck && (
                      <>
                        <Col lg={6} className="pe-0">
                          <FormGroup className="mb-4">
                            <ModulesList
                              control={control}
                              placeholder="Select Parent Module"
                              name={"is_submodule"}
                              invalid={!!errors.is_submodule}
                            />
                            <FormFeedback invalid>
                              {errors.is_submodule?.message}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col lg={6} className="pe-0">
                          <FormGroup className="mb-4">
                            <UFInput
                              control={control}
                              placeholder="Enter TagLine"
                              name={"tagline"}
                              invalid={!!errors.tagline}
                            />
                            <FormFeedback invalid>
                              {errors.tagline?.message}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                      </>
                    )}
                  </Row>
                </Row>
              </Form>
            </FormProvider>
          </ModalBody>
          <ModalFooter>
            <LoadingButton
              form={`ev-form`}
              color={"primary"}
              loading={isLoading}
              disabled={isLoading}
              type="submit"
            >
              {props?.editItem?.modal ? "Update" : "Save"}
            </LoadingButton>
          </ModalFooter>
        </>
      )}
    </>
  )
}

const EditModules = ({ buttonLabel, className, ...props }) => {
  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)

  useEffect(() => {
    if (props?.editItem?.modal) {
      toggle()
    }
  }, [props?.editItem?.modal])

  return (
    <>
      <Link to="#" className={className} onClick={toggle}>
        {buttonLabel}
      </Link>
      <Modal isOpen={modal} toggle={toggle} backdrop={"static"}>
        <Edit
          close={() => {
            setModal(false)
            props?.onReset()
          }}
          {...props}
        />
      </Modal>
    </>
  )
}

export default EditModules
